import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Styled from 'styled-components';

import { BiUserCheck, BiSolidTimeFive } from 'react-icons/bi';
import { MdAccessTimeFilled } from 'react-icons/md';
import { GiNetworkBars } from 'react-icons/gi';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';

import axios from '../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TimePicker } from 'antd';

const initialError = { selectedTaskActivity: '', timeSpent: '', selectedBrand: '' };

function AddScheduler({
  MultiTeamoptions = [],
  showModal,
  setShowModal,
  refreshPage,
  setRefreshPage,
  filters,
  setFilters,
  options,
  user_id
}) {
  const [taskType, setTaskType] = useState(null);
  const [errors, setErrors] = useState({});
  const [SelectedTeamOptions, setSelectedTeamOptions] = useState([]);
  const [SelectedTeamValues, setSelectedTeamValues] = useState([]);
  const [Task, setTask] = useState('');
  const [timeSpent, setTimeSpent] = useState(null);
  const [pickerStyle, setPickerStyle] = useState(false);
  const [error, setError] = useState(initialError);
  const [podOptions,setPodOptions]=useState([])
  const [accountOptions,setAccountOptions]=useState([])
  const [userOptions,setUserOptions]=useState([])
  const [brandOptions,setBrandOptions]=useState([])
  const [titleOptions,setTitleOptions]=useState([])
  const [teamOptions,setTeamOptions]=useState([])
  const [skillSetOptions,setSkillSetOptions]=useState([])
  const [selectedValues,setSelectedValues]=useState({brands:[],titles:[],pods:[],
    skillsets:[],users:[],teams:[]})

   const [search,setSearch]=useState("") 

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // const handleTeamSelect = (SelectedTeamOptions) => {
  //   setSelectedTeamOptions(SelectedTeamOptions);
  // };
  const handleTeamSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedTeamOptions(selectedOptions);
    setSelectedTeamValues(selectedValues);
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    const modifyPods= selectedValues?.pods.map((item)=>item.value)
    const modifySkillsets= selectedValues?.skillsets.map((item)=>item.value)
    const modifyTeams= selectedValues?.teams.map((item)=>item.value)
    const modifyBrands= selectedValues?.brands.map((item)=>item.value)
    const modifyUsers= selectedValues?.users.map((item)=>item.value) 
    const modifyTitles= selectedValues?.titles.map((item)=>item.value)

    if(user_id){
      const response= await axios.post("/admin/setFilter",{user_id,filter:{pods:modifyPods,skillsets:modifySkillsets,teams:modifyTeams,brands:modifyBrands
        , users:modifyUsers,titles:modifyTitles,search}})  
    }
    
    setFilters({pods:modifyPods,skillsets:modifySkillsets,teams:modifyTeams,brands:modifyBrands
    , users:modifyUsers,titles:modifyTitles,search})
    setRefreshPage(!refreshPage)
    handleCloseModal()
    // axios
    //   .post('/admin/task', details)
    //   .then((response) => {
    //     // console.log(response);
    //     toast.success(response.data.message);
    //     setTimeout(() => {
    //       setShowModal(false);
    //       setRefreshPage(!refreshPage);
    //     }, 1000);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     toast.error(error.response.data.message);
    //   });
  };

  const populateOptions=()=>{
   
       setBrandOptions(options.brands?.filter((item)=>item.label !== 'Internal').sort((a,b)=>{
        return a.label > b.label ? 1 : -1;
      }))
       setPodOptions(options.pods.sort((a,b)=>{
        return a.label > b.label ? 1 : -1;
      }))
       setAccountOptions(options.accounts.sort((a,b)=>{
        return a.label > b.label ? 1 : -1;
      }))
       setUserOptions(options.users.sort((a,b)=>{
        return a.label > b.label ? 1 : -1;
      }))
       setTitleOptions(options.titles.sort((a,b)=>{
        return a.label > b.label ? 1 : -1;
      }))
       setTeamOptions(options.teams.sort((a,b)=>{
        return a.label > b.label ? 1 : -1;
      }))
       setSkillSetOptions(options.skillSets.sort((a,b)=>{
        return a.label > b.label ? 1 : -1;
      }))

    let brands=  options?.brands?.filter((item)=>{
        for(let i=0;i<filters.brands.length;i++){
          if(item?.value==filters?.brands[i]){
            return item
          }
        }
      }) 

      let pods=  options?.pods?.filter((item)=>{
        for(let i=0;i<filters.pods.length;i++){
          if(item?.value==filters.pods[i]){
            return item
          }
        }
      })
      let teams=  options?.teams?.filter((item)=>{
        for(let i=0;i<filters.teams.length;i++){
          if(item?.value==filters.teams[i]){
            return item
          }
        }
      })
      
      let titles=  options?.titles?.filter((item)=>{
        for(let i=0;i<filters.titles.length;i++){
          if(item?.value==filters.titles[i]){
            return item
          }
        }
      })
      
      let skillsets=  options?.skillSets?.filter((item)=>{
        for(let i=0;i<filters.skillsets.length;i++){
          if(item?.value==filters.skillsets[i]){
            return item
          }
        }
      })

      let users=  options?.users?.filter((item)=>{
        for(let i=0;i<filters.users.length;i++){
          if(item?.value==filters.users[i]){
            return item
          }
        }
      })
       console.log(users,pods,".njs;fja")
      setSelectedValues({pods,teams,titles,users,skillsets,brands})
      setSearch(filters.search)
       
   
  }

  const handleSelect=(e,name)=>{
   if(name=="users"){
    setSelectedValues((prev)=>({...prev,users:e}))
   }
   if(name=="pods"){
    setSelectedValues((prev)=>({...prev,pods:e}))
   }
   if(name=="titles"){
    setSelectedValues((prev)=>({...prev,titles:e}))
   }
   if(name=="skillsets"){
    setSelectedValues((prev)=>({...prev,skillsets:e}))
   }
   if(name=="brands"){
    setSelectedValues((prev)=>({...prev,brands:e}))
   }
   if(name=="teams"){
    setSelectedValues((prev)=>({...prev,teams:e}))
   }


  }



  const clearFilterHandler=(e)=>{
    e.preventDefault()
    setSelectedValues({brands:[],titles:[],pods:[],
      skillsets:[],users:[],teams:[]})
      setSearch("")
      setFilters({brands:[],titles:[],pods:[],
        skillsets:[],users:[],teams:[],search:""})
  }

  // const populateFilters = async (e) => {
    
  //   const modifyPods= selectedValues?.pods.map((item)=>item.value)
  //   const modifySkillsets= selectedValues?.skillsets.map((item)=>item.value)
  //   const modifyTeams= selectedValues?.teams.map((item)=>item.value)
  //   const modifyBrands= selectedValues?.brands.map((item)=>item.value)
  //   const modifyUsers= selectedValues?.users.map((item)=>item.value) 
  //   const modifyTitles= selectedValues?.titles.map((item)=>item.value)

  //   if(user_id){
  //     const response= await axios.post(`/admin/getFilter?user_id=${user_id}`)  
  //     console.log(response.data.data.filter)
  //     setFilters(response.data.data.filter)
      
    
  //   }
    
    
    
  // };


  useEffect(() => {
    populateOptions()
    // populateFilters()
  }, []);

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFilterSubmit}>
            <div class='row'>
              <div class='form-border'>
                <div class=''>
                  <div class='row'>
                    <div class='col-md-12 '>
                      <label class='form-label'>Search</label>
                      <div class='input-group mb-1 mx-2 search-container'>
                        <BsSearch className='search-icon' />
                        <input
                          className={`form-control search-input shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          value={search}
                          onChange={(e)=>setSearch(e.target.value)}
                          placeholder='Search for Resource, Brands, Teams, Pod, Title, Skill set'
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Name</label>
                      <div class='input-group mb-1 mx-2'>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          options={userOptions}
                          value={selectedValues?.users}
                          onChange={(e)=>handleSelect(e,"users")}
                          isMulti={true}
                          isDisabled={search?true:false}
                          placeholder='Enter resource name'
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Team</label>
                      <div class='input-group mb-1 mx-2'>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          options={teamOptions}
                          value={selectedValues?.teams}
                          onChange={(e)=>handleSelect(e,"teams")}
                          isMulti={true}
                          isDisabled={search?true:false}

                          placeholder='Enter team name'
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Title</label>
                      <div class='input-group mb-1 mx-2'>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          options={titleOptions}
                          value={selectedValues?.titles}
                          onChange={(e)=>handleSelect(e,"titles")}
                          isMulti={true}
                          isDisabled={search?true:false}
                          placeholder='Enter title'
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Brands</label>
                      <div class='input-group mb-1 mx-2'>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          options={brandOptions}
                          value={selectedValues?.brands}
                          onChange={(e)=>handleSelect(e,"brands")}
                          isMulti={true}
                          isDisabled={search?true:false} 
                         placeholder='Enter brands'
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div>

                  {/* <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Pod</label>
                      <div class='input-group mb-1 mx-2'>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          options={podOptions}
                
                          value={selectedValues?.pods}
                          onChange={(e)=>handleSelect(e,"pods")}
                          isMulti={true}
                          isDisabled={search?true:false}
                          placeholder='Enter pods name'
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div> */}

                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Skillsets</label>
                      <div class='input-group mb-1 mx-2'>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          options={skillSetOptions}
                          value={selectedValues?.skillsets}
                          onChange={(e)=>handleSelect(e,"skillsets")}
                          isMulti={true}
                          isDisabled={search?true:false}
                          placeholder='Enter skillsets name'
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div>

                  <div class='d-flex justify-content-center'>
                    {/* <div class='mt-2 mx-2'>
                      <button
                        class='btn btn-primary employee-cancel-button'
                        type='button'
                        onClick={() => handleCloseModal}
                      >
                        Cancel
                      </button>
                    </div> */}
                    <div class='mt-2 mx-2'>
                      <button class='btn btn-primary employee-save-button' type='submit'>
                        Apply Filter
                      </button>
                    </div>
                    <div class='mt-2 mx-2'>
                      <button class='btn btn-primary employee-save-button' onClick={clearFilterHandler}>
                        Clear filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default AddScheduler;

const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 10px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
.select-form-control{
    width:100%;
}
.select-form-control div{
    border-radius: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

.date-inputs{
    max-width:150px;
}

.search-container{
    position:relative;
}
.search-icon{
  position:absolute;
  height:20px;
  width:20px;
  color:black;
  z-index:999;
  font-weight:bold;
  top:10px;
  left:10px;
}
.search-input{
    padding-left:40px;
}
`;
