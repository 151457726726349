import React from 'react';
import { IoChevronBackCircleSharp, IoChevronForwardCircleSharp } from 'react-icons/io5';
import styled from 'styled-components';

function Pagination({ total, page, handleNext,handlePrev }) {
//   const handleNext = () => {
//     if (page < total) {
//       setPage(page + 1);
//     }
//   };

//   const handlePrev = () => {
//     if (page > 1) {
//       setPage(page - 1);
//     }
//   };

//   const handlePerPageChange = (e) => {
//     const newPerPage = parseInt(e.target.value);
//     setPerPage(newPerPage);
//     setPage(1);
//   };

  return (
    <Wrapper>
      <span onClick={()=>handlePrev()} className='icons'>
        <IoChevronBackCircleSharp />
      </span>
      <div className='text-muted total-text'>
        <span>{page}</span>
        <span className='total-middle-text'> of </span>
        <span>{total}</span>
      </div>
      <span onClick={()=>handleNext()} className='icons'>
        <IoChevronForwardCircleSharp />
      </span>
      {/* <div className='per-page-dropdown'>
        <label htmlFor='per-page'>Show per page:</label>
        <select id='per-page' value={perPage} onChange={handlePerPageChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
          <option value={500}>500</option>
        </select>
      </div> */}
    </Wrapper>
  );
}

export default Pagination;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0;

  .icons {
    font-size: 30px;
    margin: 0 15px;
    color: var(--sidebar-color);
    cursor: pointer;
  }

  .total-text {
    letter-spacing: 1px;
    margin: 0 10px;
  }

  .per-page-dropdown {
    margin-left: 20px;

    label {
      margin-right: 5px;
      font-size: 14px;
    }

    select {
      padding: 5px;
      font-size: 14px;
    }
  }
`;
