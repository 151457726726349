import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import Styled from 'styled-components';
import Pagination from '../../../../components/Pagination';
import Axios from '../../../../utils/api';
import moment from 'moment-timezone';
import * as XLSX from 'xlsx';
import TokenServices from '../../../../utils/token.services';
import { toast } from 'react-toastify';

const TableComp = ({ runAPI, setRunAPI, selectedMultiTeamValues, selectedMultiAccountsTypeValues, selectedMultiEmployeeValues, ApplyDateFilter, selectedYear, selectedMonth, setLoading, isExportClicked, setisExportClicked, areFiltersFetched, employeeOptions, employeeFetched }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  // const [paginationText, setPaginationText] = useState('Show employees per page:');
  const [paginationText, setPaginationText] = useState(' ');
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'employeeName', direction: 'ascending' });
  const [sortViewDownConfig, setSortViewDownConfig] = useState({ key: 'timerDuration', direction: 'ascending' });
  const [showBreakDownModal, setshowBreakDownModal] = useState(false);
  const [breakDownData, setbreakDownData] = useState({});

  // getAllSchedule
  const fetchData = async () => {
    try {
      const userData = TokenServices.getUser();
      if (userData.role === 'Admin' ||userData.role==="Employee"|| (userData.role !== 'Admin' && (selectedMultiTeamValues.length > 0 || selectedMultiAccountsTypeValues.length > 0 || selectedMultiEmployeeValues.length > 0))) {

        setLoading(true);
        const userData = TokenServices.getUser();
        let updatedEmployeeValue = [...selectedMultiEmployeeValues];
        if (userData && userData.role === 'Employee') {
          updatedEmployeeValue = [userData._id];
        } else if (userData && userData.role === 'Manager' && selectedMultiEmployeeValues.length === 0 && employeeOptions.length > 0) {
          updatedEmployeeValue = employeeOptions.map(emp => emp.value);
        }
         

        const response = await Axios.post(
          `/admin/utilization?page=${page}&limit=${perPage}`, {
          // `/admin/utilization?page=${page}&limit=${perPage}`, {
          team_ids: selectedMultiTeamValues,
          account_type: selectedMultiAccountsTypeValues,
          employee_id: updatedEmployeeValue,
          year: selectedYear,
          month: selectedMonth,
        });
        setData(response.data.data);
        // console.log(response.data.data)
        setTotalPages(Number(response.data.total_pages));
        setLoading(false);
      } else {
        toast.error('Select at least one filter');
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setPage(1);
    setData([]);
    if (areFiltersFetched && employeeFetched) {
      fetchData(1);
    }
  }, [
    runAPI
  ]);

  useEffect(() => {
    setData([]);
    if (areFiltersFetched && employeeFetched) {
      fetchData(page);
    }
  }, [
    page, perPage
  ]);

  const durationToMinutes = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const getTotalDuration = (row) => {
    // Extract the month and year from 'row.from'
    const rowMonth = moment(row.from).tz('America/New_York').toDate().getMonth() + 1;
    const rowYear = moment(row.from).tz('America/New_York').toDate().getFullYear();

    // Calculate the sum of durations in minutes for the specified month and year
    const totalMinutes = row?.user_timers
      ?.filter((timer) => {
        const timerMonth = moment(timer.date).tz('America/New_York').add(1, 'day').month() + 1;
        const timerYear = moment(timer.date).tz('America/New_York').add(1, 'day').year();

        // Compare both month and year
        return timerMonth === rowMonth && timerYear === rowYear;
      })
      .reduce((total, timer) => total + durationToMinutes(timer.duration), 0);

    // Convert total minutes back to the format hh:mm
    return `${Math.floor(totalMinutes / 60)}:${(totalMinutes % 60)
      .toString()
      .padStart(2, "0")}`;
  };

  function getWorkingDaysInMonth(year, month) {
    const firstDay = new Date(year, month - 1, 1); // Month is zero-based, so subtract 1
    const lastDay = new Date(year, month, 0);

    let workingDays = 0;

    for (let currentDay = firstDay; currentDay <= lastDay; currentDay.setDate(currentDay.getDate() + 1)) {
      const dayOfWeek = currentDay.getDay();

      // Check if the current day is not Saturday (6) or Sunday (0)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        workingDays++;
      }
    }

    return workingDays;
  }

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const requestViewBreakDownSort = (key) => {
    let direction = 'ascending';
    if (sortViewDownConfig && sortViewDownConfig.key === key && sortViewDownConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortViewDownConfig({ key, direction });
  }

  const renderSortIcon = (field) => {
    if (field === sortConfig.key) {
      return (
        <span className={`arrow ${sortConfig.direction === 'ascending' ? 'ascending' : 'descending'}`}>
          <span className="arrow-up">&#9650;</span>
          <span className="arrow-down">&#9660;</span>
        </span>
      );
    }
    return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
  };

  const renderSortIconViewBreakDown = (field) => {
    if (field === sortViewDownConfig.key) {
      return (
        <span className={`arrow ${sortViewDownConfig.direction === 'ascending' ? 'ascending' : 'descending'}`}>
          <span className="arrow-up">&#9650;</span>
          <span className="arrow-down">&#9660;</span>
        </span>
      );
    }
    return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
  };

  const newData = data?.map((row, index) => {
    const totalDuration = getTotalDuration(row);
    let totalDurationArray = totalDuration.split(":");
    let durationMinutes = ((totalDurationArray[1] / 60) * 100).toString().padStart(2, "0");

    const fromDate = moment.tz(row.from, 'America/New_York');
    const year = fromDate.year();
    const month = fromDate.month() + 1;

    const totalWorkingDaysInMonth = getWorkingDaysInMonth(year, month, 'America/New_York');
    const workingHoursPerDay = 8;
    const totalWorkingHoursInMonth = totalWorkingDaysInMonth * workingHoursPerDay;

    const percentageOfScheduledHours = (row.duration / totalWorkingHoursInMonth) * 100;

    const totalHours = Number(totalDurationArray[0]);
    const totalMinutes = Number(durationMinutes);

    const totalActualDurationInHours = totalHours + totalMinutes / 100;
    const percentageOfActualHours = (totalActualDurationInHours / totalWorkingHoursInMonth) * 100;
    // console.log(percentageOfActualHours,'percentageOfActualHours');
    // console.log(percentageOfScheduledHours,'percentageOfScheduledHours');
    // if (percentageOfActualHours != 0 && percentageOfScheduledHours != 0) {
    return {
      employeeName: `${row?.user_id?.first_Name} ${row?.user_id?.last_Name}`,
      teamName: `${row?.user_id?.team?.name}`,
      actualUtilization: percentageOfActualHours ? parseFloat(percentageOfActualHours.toFixed(1)) : 0.0,
      scheduledUtilization: percentageOfScheduledHours ? parseFloat(percentageOfScheduledHours.toFixed(1)) : 0.0,
      targetUtilization: row.user_id?.utilization ? row.user_id.utilization.toFixed(1) : 0.0,
      month: fromDate.format('MMM YYYY'),
      user_timer: row.user_timers,
      from: row.from,
      user_id: row.user_id?._id
    };

    // }
    // else {
    //   return null; 
    // }

  }).filter(Boolean); // Remove null values from the resulting array;

  const sortedData = React.useMemo(() => {
    console.log(sortConfig);
    if (sortConfig.key !== null) {
      return [...newData].sort((a, b) => {
        // Use the correct key path for sorting
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof (aValue) === 'string' && (sortConfig.key === 'employeeName' || sortConfig.key === 'teamName')) {
          if (aValue.toLowerCase() < bValue.toLowerCase()) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue.toLowerCase() > bValue.toLowerCase()) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
        }

        if (parseFloat(aValue) < parseFloat(bValue)) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (parseFloat(aValue) > parseFloat(bValue)) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return newData;
  }, [newData, sortConfig]);

  const totalTimerDuration = Object.keys(breakDownData).reduce((sum, brandId) => {
    return sum + breakDownData[brandId].timerDuration;
  }, 0);

  const totalScheduleDuration = Object.keys(breakDownData).reduce((sum, brandId) => {
    return sum + breakDownData[brandId].scheduleDuration;
  }, 0);

  const sortBreakDownData = React.useMemo(() => {
    let keys = Object.keys(breakDownData);

    keys.sort((a, b) => {
      const aValue = breakDownData[a][sortViewDownConfig.key];
      const bValue = breakDownData[b][sortViewDownConfig.key];

      if (aValue < bValue) {
        return sortViewDownConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortViewDownConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    let sortedObj = {};
    keys.forEach(key => {
      sortedObj[key] = breakDownData[key];
    });

    return sortedObj;
  }, [breakDownData, sortViewDownConfig]);

  console.log(sortedData, 'sortedData');

  const exportToExcel = () => {
    const excelData = [];

    // Add headers
    excelData.push([
      // 'S.No.',
      'Employee',
      'Team',
      'Actual Utilization %',
      'Scheduled Utilization %',
      'Target Utilization %',
      'Month',
      'Total Available Hours',
      'Scheduled Hours',
      'Target Hours',
      'Actual Hours'
    ]);

    // Add data rows
    sortedData?.map((row, index) => {
      let actualUtilization = row.actualUtilization / 100;
      let scheduledUtilization = row.scheduledUtilization / 100;
      let targetUtilization = parseFloat(row.targetUtilization) / 100;
      let year = parseInt(row.month?.split(' ')[1]);
      let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(row.month?.split(' ')[0]) + 1;
      let totalAvailableHours = getWorkingDaysInMonth(year, month) * 8;
      let scheduledHours = parseFloat((totalAvailableHours * scheduledUtilization).toFixed(2));
      let targetHours = parseFloat((totalAvailableHours * targetUtilization).toFixed(2));
      let actualHours = parseFloat((totalAvailableHours * actualUtilization).toFixed(2));
      excelData.push([
        `${row.employeeName}`,
        `${row.teamName}`,
        actualUtilization,
        scheduledUtilization,
        targetUtilization,
        `${row.month}`,
        totalAvailableHours,
        scheduledHours,
        targetHours,
        actualHours
      ]);
    });

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Save the file
    var expFileName = 'utilization data';
    if (selectedMonth.length > 0) {
      let lastMonthKey = selectedMonth.length - 1;
      expFileName = selectedMonth[0] + ' to ' + selectedMonth[lastMonthKey];
    }

    XLSX.writeFile(wb, expFileName + '.xlsx');
  }

  // useEffect(() => {
  //   if (isExportClicked === true) {
  //     exportToExcel();
  //     setisExportClicked(false);
  //   }
  // }, [isExportClicked])

  const handleViewBreakdown = async (e, row) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await Axios.post(
        `/admin/getScheduleBreakDown`, {
        user_id: row.user_id,
        date: row.from
      });
      setLoading(false);

      if (response.data) {
        let scheduleData = response.data.data

        const rowMonth = moment(row.from).tz('America/New_York').toDate().getMonth() + 1;
        const rowYear = moment(row.from).tz('America/New_York').toDate().getFullYear();

        let totalDurationBasedOnBrand = {};
        // Calculate the sum of durations in minutes for the specified month and year
        const updatedTimerData = row?.user_timer
          ?.filter((timer) => {
            const timerMonth = moment(timer.date).tz('America/New_York').add(1, 'day').month() + 1;
            const timerYear = moment(timer.date).tz('America/New_York').add(1, 'day').year();

            // Compare both month and year
            return timerMonth === rowMonth && timerYear === rowYear;
          });

        updatedTimerData?.forEach(item => {
          if (item.brand_id && item.brand_id._id) {
            const brandId = item.brand_id._id;
            const duration = durationToMinutes(item.duration);
            if (totalDurationBasedOnBrand[brandId]) {
              totalDurationBasedOnBrand[brandId].timerDuration += duration;
            } else {
              totalDurationBasedOnBrand[brandId] = { brand_name: item.brand_id.brand_name, timerDuration: duration, scheduleDuration: 0 };
            }
          }
        });

        const fromDate = moment.tz(row.from, 'America/New_York');
        const year = fromDate.year();
        const month = fromDate.month() + 1;

        const totalWorkingDaysInMonth = getWorkingDaysInMonth(year, month, 'America/New_York');
        const totalWorkingHoursInMonth = totalWorkingDaysInMonth * 8;
        for (let key in totalDurationBasedOnBrand) {
          totalDurationBasedOnBrand[key].timerDuration = totalDurationBasedOnBrand[key].timerDuration / (totalWorkingHoursInMonth * 60) * 100;
        }

        for (let key in scheduleData) {
          let data = scheduleData[key];
          if (totalDurationBasedOnBrand[key]) {
            totalDurationBasedOnBrand[key].scheduleDuration = data.duration / (totalWorkingHoursInMonth) * 100;
          } else if (data.duration > 0) {
            totalDurationBasedOnBrand[key] = {
              timerDuration: 0,
              scheduleDuration: data.duration / (totalWorkingHoursInMonth) * 100,
              brand_name: data.brand_name
            }
          }
        }
        setbreakDownData(totalDurationBasedOnBrand);
        setshowBreakDownModal(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const handleCloseBreakDownModal = () => {
    setshowBreakDownModal(false);
  }
  return (
    <Wrapper>
      <Modal className='breakdown' show={showBreakDownModal} onHide={handleCloseBreakDownModal} centered>
        <Wrapper>
          <Modal.Header closeButton>
            <Modal.Title>Utilization % Breakdown</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th className='text-center'>
                    Brand
                  </th>
                  <th className='text-center' onClick={() => requestViewBreakDownSort('timerDuration')}>
                    Actual Utilization % {renderSortIconViewBreakDown('timerDuration')}
                  </th>
                  <th className='text-center' onClick={() => requestViewBreakDownSort('scheduleDuration')}>
                    Schedule Utilization % {renderSortIconViewBreakDown('scheduleDuration')}
                  </th>
                  <th className='text-center'>
                    Variance
                  </th>
                </tr>
              </thead>

              <tbody>
                {Object.keys(sortBreakDownData).map((brandId, index) => {
                  const brandData = breakDownData[brandId];
                  return (
                    <tr key={index}>
                      <td className='text-center'>
                        {brandData.brand_name}
                      </td>
                      <td className='text-center'>
                        {brandData.timerDuration.toFixed(2)}
                      </td>
                      <td className='text-center'>
                        {brandData.scheduleDuration.toFixed(2)}
                      </td>
                      <td className='text-center'>
                        {(brandData.scheduleDuration - brandData.timerDuration).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
                {Object.keys(sortBreakDownData).length > 0 && (
                  <tr>
                    <td className='text-center fw-bold'>
                      Total:
                    </td>
                    <td className='text-center fw-bold'>
                      {totalTimerDuration.toFixed(2)}
                    </td>
                    <td className='text-center fw-bold'>
                      {totalScheduleDuration.toFixed(2)}
                    </td>
                    <td className='text-center fw-bold'>
                      {(totalScheduleDuration - totalTimerDuration).toFixed(2)}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Modal.Body>
        </Wrapper>
      </Modal>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='section-left'>
            <>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th className='text-center' onClick={() => requestSort('employeeName')}>
                      Employee {renderSortIcon('employeeName')}
                    </th>
                    <th className='text-center' onClick={() => requestSort('teamName')}>
                      Team {renderSortIcon('teamName')}
                    </th>
                    <th className='text-center' onClick={() => requestSort('actualUtilization')}>
                      Actual Utilization % {renderSortIcon('actualUtilization')}
                    </th>
                    <th className='text-center' onClick={() => requestSort('scheduledUtilization')}>
                      Scheduled Utilization % {renderSortIcon('scheduledUtilization')}
                    </th>
                    <th className='text-center' onClick={() => requestSort('targetUtilization')}>
                      Target Utilization % {renderSortIcon('targetUtilization')}
                    </th>
                    <th className='text-center'>
                      View Breakdown
                    </th>
                    <th className='text-center' onClick={() => requestSort('month')}>Month</th>
                  </tr>
                </thead>

                <tbody>
                  {sortedData?.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>{row.employeeName}</td>
                        <td>{row.teamName}</td>
                        <td className='text-center'>{row.actualUtilization}</td>
                        <td className='text-center'>{row.scheduledUtilization}</td>
                        <td className='text-center'>{row.targetUtilization}</td>
                        <td className='text-center'><a className='view-breakdown' onClick={(e) => handleViewBreakdown(e, row)}>View Breakdown</a></td>
                        <td className='text-center'>{row.month}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <Pagination
                total={totalPages}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                paginationText={paginationText}
              />
            </>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default TableComp;




const Wrapper = Styled.div`
  .section {
    border-radius: 16px;
    background: #FFF;
    margin: 15px 0;
    padding: 15px;
  }
  .section-left {
    border-radius: 16px;
    background: #FFF;
    margin: 15px 0;
  }
  .secttion-right{
    margin-left:10px;
  }
  .hSpent-text{
    color: #0D1821;
/* text-align: center; */
font-size: 20px;

  }
  
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
  border-radius: 16px;
    background: #fff;
}
.modal-body{
.table-responsive{
  height: auto;
}
}
td{
    /* text-align: center; */
    vertical-align: middle;
    padding:6px 10px;
    border-color: #fff;

    .view-breakdown{
      cursor: pointer;
    }
  }
th{
  padding:10px 10px;
    /* text-align: center; */
    vertical-align: middle;
    border-color: #EAF3FF;
  }
  th {
  cursor: pointer;
  // font-size: 0.9rem;
  border-right: 1px solid #dcdfe5;
}
  .bar-chart{
    margin-top:20px
  }

  /* Default style for the arrow icon */
.arrow {
  font-size: 12px; /* Adjust the font size as needed */
  color: #ccc; /* Default color */
  margin-left: 5px;
  float: right;

}

/* Style for ascending order */
.ascending .arrow-up{
  color: #7a7c7f;
}

/* Style for descending order */
.descending .arrow-down{
  color: #7a7c7f;
}
`;
