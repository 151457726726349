import * as CryptoJS from 'crypto-js';

let _key = "secret_key"


class TokenService {

   encryptData(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), _key).toString();
}

// Function to decrypt data
 decryptData(encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, _key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}


 

  setRefreshToken(refreshToken){
    const encryptedData = this.encryptData({refreshToken});
    localStorage.setItem("refreshToken", encryptedData);
  }

  getRefreshToken() {
    const encryptedData = localStorage.getItem("refreshToken");
    if (encryptedData) {
        return this.decryptData(encryptedData).refreshToken;
    }
    return "";
  }

  getAccessToken() {
    const encryptedData = localStorage.getItem("token");
    if (encryptedData) {
        return this.decryptData(encryptedData).token;
    }
    return null;
  }

  // getToken() {
  //   const encryptedData = localStorage.getItem("token");
  //   if (encryptedData) {
  //       return this.decryptData(encryptedData);
  //   }
  //   return "";
  // }

  setAccessToken(token) {
    const encryptedData = this.encryptData({token});
    localStorage.setItem("token", encryptedData);  }

  getUser() {
   const encryptedData = localStorage.getItem("user");
    if (encryptedData) {
        return this.decryptData(encryptedData);
    }
    return null;
}
  setUser(user) {
    const encryptedData = this.encryptData(user);
    localStorage.setItem("user", encryptedData);
  }

  removeUser() {
    localStorage.removeItem('user');
  }


}
export default new TokenService();
