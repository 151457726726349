import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import axios from '../../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactSelect from 'react-select';

function AddPodModal({ show, setShow, refreshPage, setRefreshPage, employeeOptions }) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState({ name: '', owner: '' });
  const [errors, setErrors] = useState({});
  const [selectedMultiEmployeeOptions, setSelectedMultiEmployeeOptions] = useState(null);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!data.name.trim()) {
      setErrors({ name: 'Please enter the pod name' });
      return;
    }
    if (!selectedMultiEmployeeOptions || selectedMultiEmployeeOptions.length === 0) {
      setErrors({owner: 'Please select owner'});
      return;
    }
    axios
      .post('/admin/pod', data)
      .then((response) => {
        // console.log(response);
        toast.success(response.data.message);
        setTimeout(() => {
          setShow(false);
          setRefreshPage(!refreshPage);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  const handleMultiSelectEmployeeChange = (selectedOptions) => {
    setSelectedMultiEmployeeOptions(selectedOptions);
    setData({...data, owner: selectedOptions.value});
  };

  useEffect(() => {
    if(!show){
      setSelectedMultiEmployeeOptions(null);
    }
  }, [show])
  
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Wrapper>
          <Modal.Header closeButton>
            <Modal.Title>New Pod</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className='input-container d-flex  align-items-end w-100 '>
                <div className='me-3 mb-2'>
                  <img
                    src={require('../../../../assets/icons/profile-check.png')}
                    alt='profile-check'
                  />
                </div>
                <div className='w-100'>
                  <label htmlFor='podname'>Pod Name</label>
                  <input
                    type='text'
                    placeholder='eg. Creative'
                    className={`form-control shadow-none ${errors.name ? 'is-invalid' : ''}`}
                    name='name'
                    onChange={handleChange}
                    value={data.name}
                  // required
                  />
                  {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                </div>
              </div>
              <div className='input-container d-flex  align-items-end w-100 '>
                <div className='me-3 mb-2'>
                  <img
                    src={require('../../../../assets/icons/profile-check.png')}
                    alt='profile-check'
                  />
                </div>
                <div className='w-100'>
                  <div className='multi-select-container'>
                    <label>
                      Employee
                      <ReactSelect
                        id='multiSelectAccounts'
                        options={employeeOptions}
                        value={selectedMultiEmployeeOptions}
                        onChange={handleMultiSelectEmployeeChange}
                      />
                    </label>
                    {errors.owner && <div className='invalid-owner-feedback'>{errors.owner}</div>}
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end my-3">
        <Button variant="outline-primary" className="me-4" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="outline-primary" className="me-2">Add Pod</Button>
        </div> */}
              <div class='d-flex justify-content-center'>
                <div class='mt-2 mx-2'>
                  <button
                    class='btn btn-primary employee-cancel-button'
                    type='button'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
                <div class='mt-2 mx-2'>
                  <button class='btn btn-primary employee-save-button' type='submit'>
                    Add Pod
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Wrapper>
      </Modal>
    </>
  );
}

export default AddPodModal;
const Wrapper = styled.div`
  /* .modal-header{
        background: #EAF3FF;
    }
    .input-container{
        width:100%;

    } */
  .modal-title {
    font-weight: 500;
    font-size: 16px;
  }
  .modal-header .btn-close {
    width: 24px;
    height: 24px;
  }
  .modal-header {
    background: #eaf3ff;
    border-radius: 16px 16px 0px 0px;
  }

  .employee-cancel-button {
    background: #ffffff;
    border: none;
    color: #2984fd;
  }
  .employee-save-button {
    background: #2984fd;
    border-radius: 8px;
    border-color: #2984fd;
  }

  .input-container .multi-select-container label{
  width: 100%;
  margin-top: 10px;
  }

  .invalid-owner-feedback{
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
  }
`;
