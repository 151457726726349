import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import ReactSelect from 'react-select';
import { Header } from '../../../../components/Header/Header';
// import { Sidebar } from "./Sidebar";
// import { BiSearch } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';
import axios from '../../../../utils/api';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

import AddAccountsModal from './AddAccountsModal';

// import { Modal } from 'react-bootstrap';
import AccountsTabBody from './AccountsTabBody';
function Accounts() {
  const { Option } = Select;
  const [selectedDate, setSelectedDate] = useState(null);
  const [pickerMode, setPickerMode] = useState('date');
  const [SelectedDateFormattedData, setSelectedDateFormattedData] = useState({
    pickerMode: '',
    formattedDate: '',
    startDate: '',
    endDate: '',
    formattedMonth: '',
  });

  const [showAddAccountsModal, setshowAddAccountsModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [PodOption, setPodOption] = useState([]);
  const [LeadOptions, setLeadOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);

  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [selectedMultiAccountsOptions, setSelectedMultiAccountsOptions] = useState([]);
  const [selectedMultiAccountsValues, setSelectedMultiAccountsValues] = useState([]);
  const [selectedBrandData, setSelectedBrandData] = useState([]);

  //  console.log('pickerMode',pickerMode,'selectedDate',selectedDate)
  //  else if (pickerMode === "week") {
  //   console.log(selectedDate);
  //   const startDate = moment(selectedDate).startOf('week').format('YYYY-MM-DD');
  //   const endDate = moment(selectedDate).endOf('week').format('YYYY-MM-DD');
  //   console.log(startDate, endDate); // Week Range - From Date to Date
  // } else if (pickerMode === "month") {
  //   console.log(selectedDate);
  //   const formattedMonth = moment(selectedDate).format('MMMM YYYY');
  //   console.log(formattedMonth); // Selected Month with Year
  // }

  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
    if (pickerMode === 'date') {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setSelectedDateFormattedData({
        pickerMode,
        formattedDate,
        startDate: '',
        endDate: '',
        formattedMonth: '',
      });
    } else if (pickerMode === 'week') {
      const startDate = dayjs(date).startOf('week').format('YYYY-MM-DD');
      const endDate = dayjs(date).endOf('week').format('YYYY-MM-DD');
      setSelectedDateFormattedData({
        pickerMode,
        formattedDate: '',
        startDate,
        endDate,
        formattedMonth: '',
      });
    } else if (pickerMode === 'month') {
      const formattedMonth = dayjs(date).format('MM YYYY');
      setSelectedDateFormattedData({
        pickerMode,
        formattedDate: '',
        startDate: '',
        endDate: '',
        formattedMonth,
      });
    }
  };

  const handlePickerModeChange = (value) => {
    setPickerMode(value);
  };

  const renderCalendar = () => {
    if (pickerMode === 'date') {
      return <DatePicker value={selectedDate} onChange={handleDateChange} mode='date' />;
    } else if (pickerMode === 'week') {
      return <DatePicker.WeekPicker value={selectedDate} onChange={handleDateChange} />;
    } else if (pickerMode === 'month') {
      return <DatePicker.MonthPicker value={selectedDate} onChange={handleDateChange} />;
    }
  };

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
    console.log('selectedMultiTeamValues', selectedMultiTeamValues);
  };

  // const handleMultiSelectAccountsChange = (selectedMultiAccountsOptions) => {
  //   setSelectedMultiAccountsOptions(selectedMultiAccountsOptions);
  //   console.log('selectedMultiAccountsOptions',selectedMultiAccountsOptions);
  // };
  const handleMultiSelectAccountsChange = (selectedOptions) => {
    // console.log('selectedOptions', selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiAccountsOptions(selectedOptions);
    setSelectedMultiAccountsValues(selectedValues);
    // console.log('selectedMultiAccountsValues', selectedMultiAccountsValues);
  };
  const handleMultiSelectBrandChange = (selectedBrand) => {
    const brandIndex = selectedBrandData.findIndex((brand) => brand.id === selectedBrand._id);
  
    if (brandIndex === -1) {
      // If not in selectedBrandData, add it
      setSelectedBrandData([...selectedBrandData, { id: selectedBrand._id, name: selectedBrand.brand_name }]);
    } else {
      // If already in selectedBrandData, remove it
      const updatedBrandData = [...selectedBrandData];
      updatedBrandData.splice(brandIndex, 1);
      setSelectedBrandData(updatedBrandData);
    }
  };
  
  useEffect(() => {
    axios
      .get('/admin/pods_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setPodOption(response.data.data.map((item) => ({ value: item._id, label: item.name })).sort((a,b)=>{
          if(typeof(a.label) === 'string'){
            return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
          }
          return a.label > b.label ? 1 : -1;
        }));
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post('/admin/accounts?page=&limit=', {
        account_id: '',
        team_ids: '',
        SelectedDateFormattedData: '',
      })
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        const modifyData = response.data.data.map((item) => {
          for (let i = 0; i < item.brands.length; i++) {
            item.brands[i].isChecked = false
          }
          return item
        })
        setAccountOptions(
          modifyData
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/teams_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setTeamOptions(response.data.data.map((item) => ({ value: item._id, label: item.name })).sort((a,b)=>{
          if(typeof(a.label) === 'string'){
            return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
          }
          return a.label > b.label ? 1 : -1;
        }));
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/lead_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setLeadOptions(
          response.data.data.map((item) => ({
            value: item._id,
            label: item.first_Name + ' ' + item.last_Name,
          })).sort((a,b)=>{
            if(typeof(a.label) === 'string'){
              return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
            }
            return a.label > b.label ? 1 : -1;
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refreshPage]);

  // Update accountOptions based on selectedBrandData whenever needed
useEffect(() => {
  const updatedAccountOptions = accountOptions.map((account) => ({
    ...account,
    brands: account.brands.map((brand) => ({
      ...brand,
      isSelected: selectedBrandData.some((selectedBrand) => selectedBrand.id === brand._id),
    })),
  }));

  setAccountOptions(updatedAccountOptions);
}, [selectedBrandData]);
console.log('ffd',accountOptions);
  return (
    <Wrapper>
      {showAddAccountsModal && (
        <AddAccountsModal
          showModal={showAddAccountsModal}
          setShowModal={setshowAddAccountsModal}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          TeamOptions={TeamOptions}
          PodOption={PodOption}
          LeadOptions={LeadOptions}
        />
      )}

      <div>
        <div className='tabs-ride-container'>
          <div className='multi-select-container'>
            <label>
              Accounts
              <ReactSelect
                id='multiSelectAccounts'
                isMulti
                options={accountOptions.map((account) => ({
                  value: account._id,
                  label: account.name,
                  brands: account.brands, // Include the brands as part of the option
                }))}
                value={selectedMultiAccountsOptions}
                onChange={handleMultiSelectAccountsChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                filterOption={(option, inputValue) => {
                  const lowercaseInput = inputValue.toLowerCase();
                
                  // Check if the account label matches the input value
                  const accountLabel = option?.label?.toLowerCase();
                  if (accountLabel?.includes(lowercaseInput)) {
                    return true;
                  }
                
                  // Check if any of the brand names within this account match the input value
                  const brandLabels = option?.data?.brands?.map((brand) => brand.brand_name.toLowerCase());
                  return brandLabels?.some((label) => label.includes(lowercaseInput));
                }}
                components={{
                  Option: (props) => <CustomCheckboxOption {...props} selectBrandOption={handleMultiSelectBrandChange} />,
                }}
              
              />
            </label>
          </div>

          <div className='multi-select-container'>
            <label>
              Team
              <ReactSelect
                id='multiSelectTeam'
                isMulti
                options={TeamOptions}
                value={selectedMultiTeamOptions}
                onChange={handleMultiSelectTeamChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: CheckboxOption }}
              />
            </label>
          </div>

          {/* <label htmlFor="search-input">Date */}
          {/* <div className="search-input-container">       */}
          {/* <div className="search-icon-container">
        <BiSearch className="react-icon" />
        </div> */}
          {/* <input type="date" id="Date" /> */}
          <div style={{ display: 'flex', flexDirection: 'row', padding: '0px' }}>
            <Select
              defaultValue='date'
              // style={{ border:"1px solid" }}
              onChange={handlePickerModeChange}
            >
              <Option value='date'>Date</Option>
              <Option value='week'>Week</Option>
              <Option value='month'>Month</Option>
            </Select>
            {renderCalendar()}
          </div>
          {/* </div> */}
          {/* </label> */}

          <div className='add-task-btn-container' onClick={() => setshowAddAccountsModal(true)}>
            <div className='search-icon-container'>
              <BsPlus className='react-icon' />
            </div>
            <input type='button' id='Add_Account' value='Add Account' />
          </div>
        </div>
        <AccountsTabBody
          selectedMultiTeamValues={selectedMultiTeamValues}
          selectedMultiAccountsValues={selectedMultiAccountsValues}
          selectedBrandData={selectedBrandData}
          TeamOptions={TeamOptions}
          PodOption={PodOption}
          LeadOptions={LeadOptions}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          SelectedDateFormattedData={SelectedDateFormattedData}
        />
      </div>
    </Wrapper>
  );
}

// Custom component to render checkboxes
const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

// Custom component to render checkboxes for Accounts
const CustomCheckboxOption = (props) => {
  // console.log('CustomCheckboxOption props', props);
  return (<div className='custom-option'>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
        id={`account-checkbox-${props.data.value}`}
      />
      <label className='checkbox-label' htmlFor={`account-checkbox-${props.data.value}`}>
        {props.label}
      </label>
    </div>
    {props.data.brands && props.isSelected && (

      <div className='brand-checkboxes'>
        {props.data.brands.map((brand) => (
          <div key={brand._id} className='checkbox-container'>
            <input
              type='checkbox'
              checked={brand.isSelected}
              onChange={() => props.selectBrandOption(brand)}
              id={`brand-checkbox-${brand._id}`}
            />
            <label className='checkbox-label' htmlFor={`brand-checkbox-${brand._id}`}>
              {brand.brand_name}
            </label>
          </div>
        ))}
      </div>
    )}
  </div>
  )
}


export default Accounts;

const Wrapper = Styled.div`

.react-icon{
    height: 22px;
    width: 22px;
}
    .bg .row{
    --bs-gutter-x: 0px !important;
    }

.tabs-ride-container{
    position:absolute;
    /* left:50%; */
    right: 1%;
    top:6px;
    display: flex;
flex-direction: row;
align-items: flex-end;
padding: 0px;
gap: 5px !important;
}
label {
    font-size: 12px;
    color: #201E20;
}
.search-input-container {
  display: flex;
  align-items: center;
  width: 255px;
  height: 38px;

  border: 1px solid #636263;
border-radius: 8px;
  padding: 5px;
}
.add-task-btn-container {
  display: flex;
  align-items: center;
  width: 130px;
  height: 38px;

  border: 1px solid #2984FD;
  background: #2984FD;
border-radius: 8px;
  padding: 5px;
  color:#fff;
  cursor: pointer;
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  min-width: 160px!important;
  border: 1px solid #636263;
border-radius: 8px;
}
.ant-picker{
  height: 38px;
    border: 1px solid #636263;
    width: 125px;
}
.ant-select-selector{
  height: 38px !important;
    border: 1px solid #636263!important;
    /* border-right:none; */
}
.search-icon-container {
  margin-right: 5px;
}

.search-input-container input[type="date"] {
  flex: 1;
  border: none;
  outline: none;
}
.add-task-btn-container input[type="button"] {
    background: #2984FD;
    color:#fff;
  flex: 1;
  border: none;
  outline: none;
  padding: 7px 0px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

.multi-select-container{
  /* height: 200px;  */
  /* overflow-y: scroll;  */
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
    .nav-tabs {
    display: block !important;
}
    .nav-tabs li{
      color: rgb(255, 255, 255);
    border-radius: 8px;
    background-color: rgb(28, 43, 72)
}
}

.custom-option {
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type='checkbox'] {
  margin-right: 8px; /* Add some spacing between the checkbox and label */
}

.brand-checkboxes {
  margin-left: 20px; /* Indent the brand checkboxes */
}

/* Style the labels to make them more visually appealing */
.checkbox-label {
  font-weight: bold;
  font-size: 14px;
  color: #333; /* Change the color to your desired text color */
}

/* Style the checkboxes */
.checkbox-container input[type='checkbox'] {
  cursor: pointer;
}

/* Style the selected checkboxes */
// .checkbox-container input[type='checkbox']:checked::before {
//   content: '✔'; /* You can use any symbol you like, or replace with a checkmark image */
//   font-size: 12px;
//   color: #007bff; /* Change the color to your desired selected color */
// }
`;
