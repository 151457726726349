import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Styled from 'styled-components';
import axios from '../../../utils/api';
import dayjs from "dayjs"
import { BsRepeat } from 'react-icons/bs';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineWarning} from "react-icons/md"


function UtilizationModal({ showModal, setShowModal, users,from,to }) {
  console.log(users,"utilization users")
  const [data,setData]=useState([])
  const [tableHeadings,setTableHeadings]=useState([])
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
 const  fetchData=async()=>{
  const response= await axios.post("/admin/utilizationTargets",{users,from:dayjs(from).format("YYYY-MM-DD"),to:dayjs(to).format("YYYY-MM-DD")})
  console.log(response)
  setData(response.data.data)
  const headings= Object.keys(response.data.data[0])
  setTableHeadings(headings)
 }

 useEffect(()=>{
  fetchData()
 },[])

  return (
    <Modal show={showModal} size='lg' onHide={handleCloseModal} scrollable={true} centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title className='d-flex justify-content-center'>
              
              
              <div className='mx-3 ' style={{textAlign:"center"}}>Remaining hours</div>
              
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {/* {data?.map((item,i)=>{
            return (
                <div key={i} className='text-danger'>
                   {item} 
                </div>
            )
         })} */}

         <div className='table-container'>

         {<table className="table  table-sm table-striped  " >
  <thead>
    <tr>
     {data.length>0 && Object.keys(data[0]).map((item,i)=>{
      return(<th className='table-heading text-center'  key={i} >{item}</th>)
     })} 
      
    </tr>
  </thead>
  <tbody>
     {data.length>0 && data.map((item,i)=>{
      const tds=  Object.values(data[i]).map((ele,j)=>{
          return (<td  key={i+j} className='text-center'>{ele}</td>)
        })        
        
     return  <tr  key={i}>{tds}</tr>
     })} 
    
  </tbody>
</table>}

         </div>

               <div class='d-flex justify-content-center'>
                    <div class='mt-2 mx-2'>
                      <button
                        class='btn btn-primary employee-cancel-button'
                        type='button'
                        onClick={() => handleCloseModal()}
                      >
                        Cancel
                      </button>
                    </div>
                    <div class='mt-2 mx-2'>
                      <button class='btn  btn-primary'
                       onClick={() => {handleCloseModal();}} >
                        close
                      </button>
                    </div>
                  </div>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default UtilizationModal;

const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 10px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background:red;
    color:white;
border-radius: 8px;
border-color:red;
}
.select-form-control{
    width:100%;
}
.select-form-control div{
    border-radius: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

.date-inputs{
    max-width:150px;
}
.warning-message{
    color:red;
}
/* .table{
  overflow:auto !important;
  max-width:500px;
} */
.table-container{
  max-width:100%;
  /* background-color:white; 
   color:black; */
  overflow:auto;
  max-height:400px;
  
}
.table{
  height:100%;
}
.table > tbody > tr:nth-of-type(even) > * {
  background-color:white;
}
.table th {
  border-bottom: 1px solid #eeeff1;
  border-right:1px solid #eeeff1 ;
  font-weight: bold;
}
.table-heading{
  text-transform:capitalize;
  background-color:#EAF3FF;
}

`;
