import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import { Table, Image, Button, ButtonGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from '../../../../components/Pagination';
import Select from 'react-select';
// import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';
import axios from '../../../../utils/api';

import EditTitleModal from './EditTitleModal';
import DeleteTitleModal from '../../../../components/modals/DeleteModal';
import ArchiveTitleModal from '../../../../components/modals/ArchiveModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/LoaderWave';

const EmployeeTabBody = ({ refreshPage, setRefreshPage, search }) => {
  const [loading, setLoading] = useState(false);
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [showDeleteTitleModal, setShowDeleteTitleModal] = useState(false);
  const [showArchiveTitleModal, setShowArchiveTitleModal] = useState(false);
  const [slectedtitleDatas, setslectedtitleDatas] = useState(null);

  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const renderTooltip = (text) => <Tooltip>{text}</Tooltip>;

  const fetchData = async () => {
    // console.log('search',search);
    try {
      setLoading(true);
      const response = await axios.get(
        `/admin/titles?page=${page}&limit=${perPage}&keyword=${search}`,
        {
          // params: { page, perPage },
        },
      );
      setData(response.data.data);
      setTotalPages(Number(response.data.total_pages));
      if(search && page > Number(response.data.total_pages)){
        setPage(1)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const sortData = () => {
    const sortedData = [...data]; // Make a copy of your data
    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        if(typeof(a[sortBy]) === 'string'){
          return a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1;
        }
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        if(typeof(a[sortBy]) === 'string'){
          return a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : -1;
        }
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });
    return sortedData;
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      // Toggle the sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set a new sorting field and default to ascending order
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (field) => {
    if (field === sortBy) {
      return <span className={`arrow ${sortOrder === 'asc' ? 'asc' : 'desc'}`}><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
    }
    return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>; // Add a default icon
  };

  const handleCloseDeleteTitleModal = () => setShowDeleteTitleModal(false);
  const handleCloseArchiveTitleModal = () => setShowArchiveTitleModal(false);
  const handleShowDeleteModal = (title) => {
    setslectedtitleDatas(title);
    setShowDeleteTitleModal(true);
  };
  const handleShowArchiveTitleModal = (title) => {
    setslectedtitleDatas(title);
    setShowArchiveTitleModal(true);
  };
  const handleConfirmDelete = () => {
    handleTitleDelete(slectedtitleDatas);
  };
  const handleConfirmArchive = () => {
    handleTitleArchive(slectedtitleDatas);
  };
  const handleTitleDelete = async (slectedtitleDatas) => {
    try {
      const response = await axios.delete(`/admin/title?_id=${slectedtitleDatas._id}`);
      console.log(response.data); // do something with the response
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseDeleteTitleModal();
      setslectedtitleDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };
  const handleTitleArchive = async (slectedtitleDatas) => {
    try {
      const response = await axios.delete(`/admin/archiveTitle?_id=${slectedtitleDatas._id}`);
      console.log(response.data); // do something with the response
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseArchiveTitleModal();
      setslectedtitleDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [page, perPage, refreshPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Wrapper>
      {loading && <Loader />}
      <div className='third-row'>
        <DeleteTitleModal
          show={showDeleteTitleModal}
          setShow={handleCloseDeleteTitleModal}
          onYes={handleConfirmDelete}
          message='Are you sure you want to Delete this title?'
        />
        <ArchiveTitleModal
          show={showArchiveTitleModal}
          setShow={handleCloseArchiveTitleModal}
          onYes={handleConfirmArchive}
          message='Are you sure you want to Archive this title?'
        />
        {showEditTitleModal && (
          <EditTitleModal
            show={showEditTitleModal}
            setShow={setShowEditTitleModal}
            editingTitle={slectedtitleDatas}
            refreshPage={refreshPage}
            setRefreshPage={setRefreshPage}
          />
        )}
        <>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => handleSort('name')}>
                  Title Name
                  {renderSortIcon('name')}
                </th>
                <th onClick={() => handleSort('utilization')}>
                  Utilization Target
                  {renderSortIcon('utilization')}
                </th>
                <th onClick={() => handleSort('hourly_rate')}>
                  Hourly Rate
                  {renderSortIcon('hourly_rate')}
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortData()?.map((row, index) => (
                <tr key={row._id}>
                  <td>{perPage * (page - 1) + index + 1}</td>

                  <td>{row.name}</td>
                  <td>{row.utilization}%</td>
                  <td>${row.hourly_rate}</td>
                  <td>
                    <ButtonGroup className='action-btn'>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Edit')}>
                        <Button
                          className='edit-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => {
                            setShowEditTitleModal(true);
                            setslectedtitleDatas(row);
                          }}
                        >
                          <FaEdit style={{ color: '#2984FD' }} />
                        </Button>
                      </OverlayTrigger>

                      <OverlayTrigger placement='top' overlay={renderTooltip('Archive')}>
                        <Button
                          className='archive-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleShowArchiveTitleModal(row)}
                        >
                          <BiArchiveIn style={{ color: '#008000' }} />
                        </Button>
                      </OverlayTrigger>

                      {/* <OverlayTrigger placement="top" overlay={renderTooltip('Delete')}>
      <Button className='delete-btn' variant="outline-secondary" style={{borderRadius: '8px'}} onClick={() => handleShowDeleteModal(row)}><FaTrashAlt style={{color:'#FF4D4F'}}/></Button>
      </OverlayTrigger> */}
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            total={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </>
      </div>
    </Wrapper>
  );
};

const Wrapper = Styled.div`

.third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
.avatar-container {
  display: flex;
  align-items: center;
}

.avatar-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.action-btn {
    background: none;
    border: none;
    border-radius: 0px !important;
}
.edit-btn{
    margin-right:10px;
    background: #D4E6FF;
    border:1px solid #D4E6FF;
    border-radius: 8px;
}
.archive-btn{
    margin-right:10px;
    background: #CCE6CC;
    border:1px solid #CCE6CC;
    border-radius: 8px;
}
.delete-btn{
    background: #FFDBDC;
    border:1px solid #FFDBDC;
    border-radius: 8px;
}

.btn-group .button:only-child {
    border-radius: 6px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
.active{
  background:none;
}
    `;

export default EmployeeTabBody;
