import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import { Table, Image, Button, ButtonGroup, Modal, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import Pagination from '../../../../components/Pagination';
import Select from 'react-select';
import Axios from '../../../../utils/api';
import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';

import { HiOutlineUser } from 'react-icons/hi';
import { HiOutlineMail } from 'react-icons/hi';
import { BiUserCircle } from 'react-icons/bi';
import { AiOutlineIdcard } from 'react-icons/ai';
import { BiUserCheck } from 'react-icons/bi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import DeleteEmployeeModal from '../../../../components/modals/DeleteModal';
import ArchiveEmployeeModal from '../../../../components/modals/ArchiveModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/LoaderWave';

const EmployeeTabBody = ({
  search,
  MultiTeamoptions,
  multiManagerOptions,
  selectedMultiTeamValues,
  refreshPage,
  setRefreshPage,
  TitleOptions,
  TeamOptions,
  setTeamOptions,
}) => {
  const renderTooltip = (text) => <Tooltip>{text}</Tooltip>;
  const [errors, setErrors] = useState({});
  const [showDeleteEmployeeModal, setShowDeleteEmployeeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showArchiveEmployeeModal, setShowArchiveEmployeeModal] = useState(false);
  const [slectedemployeeDatas, setslectedemployeeDatas] = useState(null);
  const [data, setData] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [SelectedTitleOptions, setSelectedTitleOptions] = useState([]);
  const [SelectedTeamOptions, setSelectedTeamOptions] = useState([]);
  const [SelectedRoleOptions, setSelectedRoleOptions] = useState([]);
  const [sortBy, setSortBy] = useState('first_Name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [emailAlertCheckbox, setEmailAlertCheckbox] = useState(false);
  const [profileColor, setprofileColor] = useState(sessionStorage.getItem('color'));

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const [multiManagerOptions, setMultiManagerOptions] = useState([]);
  const [selectedManagerOption, setSelectedManagerOption] = useState()
  const RoleOptions = [
    { value: 'Employee', label: 'Employee' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Lead', label: 'Lead' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Contractor', label: 'Contractor' },
  ].sort((a, b) => {
    return a.label > b.label ? 1 : -1;
  });

  const handleEmailAlertCheckbox = (e) => {
    setEmailAlertCheckbox(e.target.checked);
  }


  const sortData = () => {
    const sortedData = [...data]; // Make a copy of your data
    if (sortBy == 'title' || sortBy == 'hourly_rate' || sortBy == 'utilization' || sortBy == 'role') {
      sortedData.sort((a, b) => {
        console.log(a.title?.[0].name,'aaaa');
        if (sortOrder === 'asc') {
          if (sortBy === 'title') {
            return a.title?.[0].name.toLowerCase() > b.title?.[0].name.toLowerCase() ? 1 : -1;
          }
          if (sortBy === 'hourly_rate') {
            return parseFloat(a[sortBy]) > parseFloat(b[sortBy]) ? 1 : -1;
          }
          if (typeof (a[sortBy]) === 'string') {
            return a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1;
          }
          return a[sortBy] > b[sortBy] ? 1 : -1;
        } else {
          if (sortBy === 'title') {
            return a.title?.[0].name.toLowerCase() < b.title?.[0].name.toLowerCase() ? 1 : -1;
          }
          if (sortBy === 'hourly_rate') {
            return parseFloat(a[sortBy]) < parseFloat(b[sortBy]) ? 1 : -1;
          }
          if (typeof (a[sortBy]) === 'string') {
            return a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : -1;
          }
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });
    }
    
    return sortedData;
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      // Toggle the sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set a new sorting field and default to ascending order
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (field) => {
    if (field === sortBy) {
      return <span className={`arrow ${sortOrder === 'asc' ? 'asc' : 'desc'}`}><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
    }
    return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>; // Add a default icon
  };

  const handleMultiSelectTeamChange = (selectedMultiTeamOptions) => {
    setSelectedMultiTeamOptions(selectedMultiTeamOptions);
  };
  const handleTitleSelect = (SelectedTitleOptions) => {
    setSelectedTitleOptions(SelectedTitleOptions);

    // console.log(SelectedTitleOptions.hourly_rate);
    setEditingUser({
      ...editingUser,
      hourly_rate: SelectedTitleOptions.hourly_rate,
      utilization: SelectedTitleOptions.utilization,
    });
  };
  const handleTeamSelect = (SelectedTeamOptions) => {
    setSelectedTeamOptions(SelectedTeamOptions);
  };
  const handleRoleSelect = (SelectedRoleOptions) => {
    setSelectedRoleOptions(SelectedRoleOptions);
  };
  const handleOpenEditModal = (user) => {
    console.log('edit', user);
    setEditingUser(user);
    setShowEditModal(true);

    setSelectedTitleOptions({ value: user?.title?.[0]._id, label: user?.title?.[0].name });
    if(user?.team.length > 0){
      setSelectedTeamOptions({ value: user?.team?.[0]._id, label: user?.team?.[0].name });
    }
    setSelectedRoleOptions({ value: user.role, label: user.role });

    setSelectedManagerOption({ value: user?.manager?.[0]._id, label: user?.manager?.[0].first_Name + " " + user?.manager?.[0].last_Name })
    if (!user.manager) {
      setSelectedManagerOption({ value: "", label: "" })
    }
    setEmailAlertCheckbox(user?.emailAlert);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setErrors({});
  };

  const handleCloseDeleteEmployeeModal = () => setShowDeleteEmployeeModal(false);
  const handleCloseArchiveEmployeeModal = () => setShowArchiveEmployeeModal(false);
  const handleShowDeleteModal = (employee) => {
    setslectedemployeeDatas(employee);
    setShowDeleteEmployeeModal(true);
  };
  const handleShowArchiveEmployeeModal = (employee) => {
    setslectedemployeeDatas(employee);
    setShowArchiveEmployeeModal(true);
  };
  const handleConfirmDelete = () => {
    handleTitleDelete(slectedemployeeDatas);
  };
  const handleConfirmArchive = () => {
    handleTitleArchive(slectedemployeeDatas);
  };
  const handleTitleDelete = async (slectedemployeeDatas) => {
    try {
      const response = await Axios.delete(`/admin/resource?_id=${slectedemployeeDatas._id}`);
      // console.log(response.data);
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseDeleteEmployeeModal();
      setslectedemployeeDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };
  const handleTitleArchive = async (slectedemployeeDatas) => {
    try {
      const response = await Axios.delete(
        `/admin/archiveResources?_id=${slectedemployeeDatas._id}`,
      );
      // console.log(response.data);
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseArchiveEmployeeModal();
      setslectedemployeeDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await Axios.post(
        `/admin/resources?page=${page}&limit=${perPage}&keyword=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}`,
        {
          teamIds: selectedMultiTeamValues,
        },
      );
      // console.log(document.cookie,"cookiesss...")
      // console.log('emptab', response.data.data);
      setData(response.data.data);
      setTotalPages(Number(response.data.total_pages));
      if (search && page > Number(response.data.total_pages)) {
        setPage(1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!editingUser.first_Name.trim()) {
      errors.first_Name = 'Please enter the first name';
    }
    if (!editingUser.last_Name.trim()) {
      errors.last_Name = 'Please enter the last name';
    }

    if (!SelectedTitleOptions.value) {
      errors.Title = 'Please select the Title';
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[^\s@]+@code3\.com$/;
    if (!editingUser.email.trim()) {
      errors.email = 'Email is required!';
    } else if (!regex.test(editingUser.email)) {
      errors.email = 'Please enter valid email!';
    } else if (!emailRegex.test(editingUser.email)) {
      errors.email = 'Please use email with code3 domain only';
    }

    if (!SelectedRoleOptions.value) {
      errors.role = 'Please select the role';
    }
    setErrors(errors);

    // Check if any errors exist
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      return;
    }

    const updatedEmployee = {
      ...editingUser,
      title: SelectedTitleOptions.value && SelectedTitleOptions.value,
      team: SelectedTeamOptions.value && SelectedTeamOptions.value,
      role: SelectedRoleOptions.value && SelectedRoleOptions.value,
      manager: selectedManagerOption?.value,
      emailAlert: emailAlertCheckbox
      // _id: selectedItem._id,
      // name: selectedItem.name,
    };
    Axios.put(`/admin/resource`, updatedEmployee)
      .then((response) => {
        // console.log(response);
        toast.success(response.data.message);
        setTimeout(() => {
          setShowEditModal(false);
          setRefreshPage(!refreshPage);
        }, 1000);
        // Do something after successful update
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        // Do something with error
      });
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [page, perPage, refreshPage, selectedMultiTeamValues]);

  useEffect(() => {
    if (sortBy == 'team' || sortBy == 'manager' || sortBy == 'first_Name') {
      setData([]);
      
      setPage(pre=>1)  
      fetchData();
    }
    
  }, [sortOrder, sortBy]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Wrapper>
      <div className='third-row'>
        {loading && <Loader />}
        <DeleteEmployeeModal
          show={showDeleteEmployeeModal}
          setShow={handleCloseDeleteEmployeeModal}
          onYes={handleConfirmDelete}
          message='Are you sure you want to Delete this employee?'
        />
        <ArchiveEmployeeModal
          show={showArchiveEmployeeModal}
          setShow={handleCloseArchiveEmployeeModal}
          onYes={handleConfirmArchive}
          message='Are you sure you want to Archive this employee?'
        />
        <>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => handleSort('first_Name')}>
                  Resource Name {renderSortIcon('first_Name')}
                </th>
                <th onClick={() => handleSort('team')}>
                  Team {renderSortIcon('team')}
                </th>
                <th className='UtilizationTargetTh' onClick={() => handleSort('utilization')}>
                  Utilization Target {renderSortIcon('utilization')}
                </th>
                <th className='HourlyRateTh' onClick={() => handleSort('hourly_rate')}>
                  Hourly Rate {renderSortIcon('hourly_rate')}
                </th>
                <th onClick={() => handleSort('title')}>
                  Title {renderSortIcon('title')}
                </th>
                <th onClick={() => handleSort('manager')}>
                  Manager {renderSortIcon('manager')}
                </th>
                <th>
                  Email
                </th>
                <th onClick={() => handleSort('role')}>
                  Role {renderSortIcon('role')}
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortData()?.map((row, index) => (
                <tr key={row._id}>
                  <td>{perPage * (page - 1) + index + 1}</td>
                  <td>
                    <div className='avatar-container'>
                      {row.profile_pic ? (
                        <Image
                          src={row.profile_pic}
                          roundedCircle
                          className='avatar-image'
                        />
                      ) : (
                        <div>
                          <div className='avatar-image profile-image' style={{ background: profileColor }}>
                            <span>{row.first_Name ? row.first_Name?.toUpperCase()[0] : ''}</span>
                          </div>
                        </div>
                      )}
                      <div className='avatar-name'>
                        {row.first_Name} {row.last_Name && row.last_Name}
                        <div className='profile-role'>{row.title && row.title.name}</div>
                      </div>
                    </div>
                  </td>
                  <td>{row.team?.[0]?.name}</td>
                  <td>{row.utilization && row.utilization} %</td>
                  <td>${row.hourly_rate && row.hourly_rate}</td>
                  {/* <td>{row?.title?.name}</td> */}
                  <td>{row?.title?.[0]?.name}</td>
                  {/* <td>{row.emp_code && row.emp_code}</td> */}
                  <td>{(row?.manager) ? (row?.manager?.[0]?.first_Name + " " + row?.manager?.[0]?.last_Name) : " "}</td>
                  <td className='email'>{row.email && row.email}</td>
                  <td>{row.role && row.role}</td>
                  <td>
                    <ButtonGroup className='action-btn'>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Edit')}>
                        <Button
                          className='edit-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleOpenEditModal(row)}
                        >
                          <FaEdit style={{ color: '#2984FD' }} />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Archive')}>
                        <Button
                          className='archive-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleShowArchiveEmployeeModal(row)}
                        >
                          <BiArchiveIn style={{ color: '#008000' }} />
                        </Button>
                      </OverlayTrigger>
                      {/* <OverlayTrigger placement="top" overlay={renderTooltip('Delete')}>
                    <Button className='delete-btn' variant="outline-secondary" style={{ borderRadius: '8px' }} onClick={() => handleShowDeleteModal(row)}><FaTrashAlt style={{ color: '#FF4D4F' }} /></Button>
                    </OverlayTrigger> */}
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            total={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </>
      </div>

      <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
        <Wrapper>
          <Modal.Header closeButton>
            <Modal.Title>Edit Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleEditSubmit}>
              <div class='row'>
                <div class='form-border'>
                  <div class=''>
                    <div class='row mt-2'>
                      <div class='col-md-6'>
                        <label class='form-label'>First Name</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUser />
                          </span>
                          <input
                            type='text'
                            className={`form-control shadow-none ${errors.first_Name ? 'is-invalid' : ''
                              }`}
                            placeholder='e.g Peter'
                            value={editingUser ? editingUser.first_Name : ''}
                            onChange={(event) =>
                              setEditingUser({
                                ...editingUser,
                                first_Name: event.target.value,
                              })
                            }
                          />
                          {errors.first_Name && (
                            <div className='invalid-feedback'>{errors.first_Name}</div>
                          )}
                        </div>
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Last Name</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUser />
                          </span>
                          <input
                            type='text'
                            className={`form-control shadow-none ${errors.last_Name ? 'is-invalid' : ''
                              }`}
                            value={editingUser ? editingUser.last_Name : ''}
                            placeholder='e.g. Parker'
                            onChange={(event) =>
                              setEditingUser({
                                ...editingUser,
                                last_Name: event.target.value,
                              })
                            }
                          />
                          {errors.last_Name && (
                            <div className='invalid-feedback'>{errors.last_Name}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Title</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <BiUserCheck />
                          </span>
                          <Select
                            className={`select-form-control shadow-none ${errors.Title ? 'is-invalid' : ''
                              }`}
                            id='TitleSelect'
                            options={TitleOptions}
                            value={SelectedTitleOptions}
                            // value={
                            //   editingUser?.title.name
                            //     ? { value: editingUser.title._id, label: editingUser.title.name }
                            //     : null
                            // }
                            onChange={handleTitleSelect}
                          />
                          {errors.Title && <div className='invalid-feedback'>{errors.Title}</div>}
                        </div>
                      </div>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Utilization Target</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <img src={require('../../../../assets/icons/clock.png')} alt='profile-check' />
                          </span>
                          <input
                            type='number'
                            placeholder='eg.12'
                            className={`form-control shadow-none remove-arrow ${errors.utilization ? 'is-invalid' : ''
                              }`}
                            name='utilization'
                            onChange={(event) =>
                              setEditingUser({
                                ...editingUser,
                                utilization: event.target.value,
                              })
                            }
                            value={editingUser?.utilization ? editingUser.utilization : ''}
                          />
                          <div class='input-group-append'>
                            <span class='input-group-text'>%</span>
                          </div>
                          {/* {errors.utilization && (
                              <div className='invalid-feedback'>{errors.utilization}</div>
                            )} */}
                        </div>
                      </div>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Team</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUserGroup />
                          </span>
                          <Select
                            className='select-form-control'
                            id='TeamSelect'
                            options={MultiTeamoptions}
                            // value={
                            //   editingUser?.team.name
                            //     ? { value: editingUser.team._id, label: editingUser.team.name }
                            //     : null
                            // }
                            value={SelectedTeamOptions}
                            onChange={handleTeamSelect}
                          />
                        </div>
                      </div>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Manager</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUserGroup />
                          </span>
                          <Select
                            className='select-form-control'
                            id='TeamSelect'
                            options={multiManagerOptions}
                            // value={
                            //   editingUser?.team.name
                            //     ? { value: editingUser.team._id, label: editingUser.team.name }
                            //     : null
                            // }
                            value={selectedManagerOption}
                            onChange={(e) => { setSelectedManagerOption(e); }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='row mt-2'>
                      <div class='col-md-6'>
                        <label class='form-label'>Hourly Rate</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUser />
                          </span>
                          {/* <div class="input-group"> */}
                          <div class='input-group-prepend'>
                            <span class='input-group-text'>$</span>
                          </div>
                          <input
                            type='number'
                            class='form-control shadow-none '
                            placeholder=''
                            value={editingUser?.hourly_rate || ''}
                            // onChange={(event) =>
                            //   setEditingUser({
                            //     ...editingUser,
                            //     hourly_rate: event.target.value,
                            //   })}
                            disabled
                          />

                          {/* </div> */}
                        </div>
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Employee ID</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <AiOutlineIdcard />
                          </span>
                          <input
                            type='text'
                            className={`form-control shadow-none `}
                            value={editingUser?.emp_code || ''}
                            placeholder='e.g. EMP-4584'
                            onChange={(event) =>
                              setEditingUser({
                                ...editingUser,
                                emp_code: event.target.value,
                              })
                            }
                          />
                          {/* {errors.emp_code && <div className="invalid-feedback">{errors.emp_code}</div>} */}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Email</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineMail />
                          </span>
                          <input
                            type='text'
                            className={`form-control shadow-none ${errors.email ? 'is-invalid' : ''
                              }`}
                            placeholder='e.g. markspencer@code3.com'
                            value={editingUser?.email || ''}
                            onChange={(event) =>
                              setEditingUser({
                                ...editingUser,
                                email: event.target.value,
                              })
                            }
                          />
                          {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                        </div>
                      </div>

                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Role</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <BiUserCircle />
                          </span>
                          <Select
                            className={`select-form-control shadow-none ${errors.role ? 'is-invalid' : ''
                              }`}
                            id='RoleSelect'
                            options={RoleOptions}
                            value={SelectedRoleOptions}
                            onChange={handleRoleSelect}
                          />
                          {errors.role && <div className='invalid-feedback'>{errors.role}</div>}
                        </div>
                      </div>

                      <div class='col-md-12 mt-3'>
                        <div className="email-alert">
                          <Form.Check
                            type='checkbox'
                            id='emailAlert'
                            label={`Email Alert`}
                            checked={emailAlertCheckbox}
                            onChange={handleEmailAlertCheckbox}
                          />
                        </div>
                      </div>
                    </div>

                    <div class='d-flex justify-content-center'>
                      <div class='mt-2 mx-2'>
                        <button
                          class='btn btn-primary employee-cancel-button'
                          type='button'
                          onClick={handleCloseEditModal}
                        >
                          Cancel
                        </button>
                      </div>
                      <div class='mt-2 mx-2'>
                        <button class='btn btn-primary employee-save-button' type='submit'>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Wrapper>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = Styled.div`

.third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr th{
  display: flex;
  align-items:center;
}
.UtilizationTargetTh{
    min-width: 158px;
}
.HourlyRateTh{
    min-width: 122px;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}

.avatar-container {
  display: flex;
  align-items: center;
}

.avatar-image {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.action-btn {
    background: none;
    border: none;
    border-radius: 0px !important;
}
.edit-btn{
    margin-right:10px;
    background: #D4E6FF;
    border:1px solid #D4E6FF;
    border-radius: 8px;
}
.archive-btn{
    margin-right:10px;
    background: #CCE6CC;
    border:1px solid #CCE6CC;
    border-radius: 8px;
}
.delete-btn{
    background: #FFDBDC;
    border:1px solid #FFDBDC;
    border-radius: 8px;
}

.btn-group .button:only-child {
    border-radius: 6px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

.email-alert{
  margin-left: 33px;
}

div.avatar-image{
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 36px;
}

.remove-arrow::-webkit-inner-spin-button, .remove-arrow::-webkit-outer-spin-button{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

    `;

export default EmployeeTabBody;
