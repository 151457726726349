import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import cryptoRandomString from 'crypto-random-string';
import {
  Table,
  Image,
  Button,
  ButtonGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Select from 'react-select';
import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../../../utils/api';
import Loader from '../../../../components/LoaderWave';
import Axios from '../../../../utils/api';
import Pagination from '../../../../components/Pagination';
import DeleteModal from '../../../../components/modals/DeleteModal';
import ArchiveModal from '../../../../components/modals/ArchiveModal';

function ExpandableRow(props) {
  const { data, refreshPage, setRefreshPage,expandedData } = props;
  const [expandedRows2, setExpandedRows2] = useState(expandedData.exp1);

  const toggleExpandedRow2 = (rowId) => {
    if (expandedRows2.includes(rowId)) {
      setExpandedRows2(expandedRows2.filter((id) => id !== rowId));
    } else {
      setExpandedRows2([...expandedRows2, rowId]);
    }
  };

  // console.log(data);
  return (
    <>
      {data.map((row, index) => (
        <tr  key={index}>
          <td colSpan='8'>
            <Table responsive bordered>
              <tbody>
                <tr className='expandable-tr'>
                  <td >
                    <button className='btn btn-link' onClick={() => toggleExpandedRow2(row._id)}>
                      {expandedRows2.includes(row._id) ? (
                        <span>&#x25BC;</span>
                      ) : (
                        <span>&#x25B6;</span>
                      )}
                    </button>
                    {row.brand_name}
                  </td>

                  <td  className="text-center" >{row.brandDuration} h</td>
                  <td></td>
                  <td></td>

                  <td></td>
                </tr>
                {expandedRows2.includes(row._id) && (
                  <ExpandableRow2
                    data={row.employees}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                    expandedData={expandedData}
                  />
                )}
              </tbody>
            </Table>
          </td>
        </tr>
      ))}
    </>
  );
}

function ExpandableRow2(props) {
  const { data, refreshPage, setRefreshPage,expandedData } = props;
  const [expandedRows3, setExpandedRows3] = useState(expandedData.exp2);
  const toggleExpandedRow3 = (rowName) => {
    if (expandedRows3.includes(rowName)) {
      setExpandedRows3(expandedRows3.filter((name) => name !== rowName));
    } else {
      setExpandedRows3([...expandedRows3, rowName]);
    }
  };

  // console.log(data);
  return (
    <>
      {data.map((row, index) => (
        <tr  key={index}>
          <td colSpan='8'>
            <Table responsive bordered>
              <tbody>
                <tr className='expandable-tr'>
                  <td>
                    <button className='btn btn-link' onClick={() => toggleExpandedRow3(row.name)}>
                      {expandedRows3.includes(row.name) ? (
                        <span>&#x25BC;</span>
                      ) : (
                        <span>&#x25B6;</span>
                      )}
                    </button>
                    {row.name}
                  </td>
                  <td className='text-center'>{row.totalHours} h</td>
                  <td></td>
                  <td className='text-start'>{row.team}</td>
                  <td></td>
                </tr>
                {expandedRows3.includes(row.name) && (
                  <ExpandableRow3
                    data={row.tasks}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                    expandedData={expandedData}
                  />
                )}
              </tbody>
            </Table>
          </td>
        </tr>
      ))}
    </>
  );
}


function ExpandableRow3(props) {
  let { data, refreshPage, setRefreshPage,expandedData } = props;
  const [expandedRows4, setExpandedRows4] = useState(expandedData.exp3);
  data = data?.sort((a,b)=>{
    return a.name[0] > b.name[0] ? 1 : -1;
  })
  const toggleExpandedRow4 = (rowName) => {
    if (expandedRows4.includes(rowName)) {
      setExpandedRows4(expandedRows4.filter((name) => name !== rowName));
    } else {
      setExpandedRows4([...expandedRows4, rowName]);
    }
  };

  // console.log(data);
  return (
    <>
      {data.map((row, index) => (
        <tr  key={index}>
          <td colSpan='8'>
            <Table responsive bordered>
              <tbody>
                <tr className='expandable-tr'>
                  
                    {/* <button className='btn btn-link' onClick={() => toggleExpandedRow4(row._id)}>
                      {expandedRows4.includes(row._id) ? (
                        <span>&#x25BC;</span>
                      ) : (
                        <span>&#x25B6;</span>
                      )}
                    </button> */}
                    <td>{row.name}</td>
                  
                  <td className='text-center'>{row.duration} h</td>
                  <td className='text-start'>{row.task_type}</td>
                  <td className='text-start'></td>
                  <td></td>
                </tr>
                {/* {expandedRows4.includes(row._id) && (
                  <ExpandableRow4
                    data={Object.values(row)[0]}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                    expandedData={expandedData}
                  />
                )} */}
              </tbody>
            </Table>
          </td>
        </tr>
      ))}
    </>
  );






  
}

function ExpandableRow4(props) {
  const { data, refreshPage, setRefreshPage,expandedData } = props;
  // console.log(data);
  return (
    <>
      {data.map((row, index) => (
        <tr  key={index}>
          <td colSpan='8'>
            <Table responsive bordered>
              <tbody>
                <tr className='expandable-tr'>
                  <td>{row.task}</td>
                  <td className='text-center'  >{row?.duration} h</td>
                  <td className='text-start'>{row.task_type}</td>
                  <td className='text-start' >{row.team}</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </td>
        </tr>
      ))}
    </>
  );
}

const Htable = ({
  SelectedDateFormattedData,
  selectedMultiTeamValues,
  selectedMultiAccountsValues,
  selectedBrandData,
  refreshPage,
  setRefreshPage,
  tableData,
  setTableData,
  setExportObject
}) => {
  const [expandAllBtn,setExpandAllBtn]=useState("Expand All")
  const [compData,setCompData]=useState([])
  const [groupBy,setGroupBy]=useState({value:"Account",label:"Account"})
  const [groupByOptions,setGroupByOptions]=useState([{value:"Account",label:"Account"},{value:"Employee",label:"Employee"},{value:"Task",label:"Activity"}])
  const [expandedRows, setExpandedRows] = useState([]);
  const [exp1,setExp1]=useState([])
  const [exp2,setExp2]=useState([])
  const [exp3,setExp3]=useState([])
  const [exp4,setExp4]=useState([])


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [User, setUser] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [slectedDatas, setslectedDatas] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const sortData = () => {
    const sortedData = [...data]; // Make a copy of your data
    // sortedData.sort((a, b) => {
    //   if (sortOrder === 'asc') {
    //     return a[sortBy] > b[sortBy] ? 1 : -1;
    //   } else {
    //     return a[sortBy] < b[sortBy] ? 1 : -1;
    //   }
    // });
    return sortedData;
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      // Toggle the sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set a new sorting field and default to ascending order
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  // const renderSortIcon = (field) => {
  //   if (field === sortBy) {
  //     return <span className={`arrow ${sortOrder === 'asc' ? 'asc' : 'desc'}`}><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
  //   }
  //   return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>; // Add a default icon
  // };

  const toggleExpandedRow = (rowId) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
      

    } else {
      setExpandedRows([...expandedRows, rowId]);
    }
    
  };

  const toggleAllExpandedRow = () => {
    let rows=[]
    let exp1=[],exp2=[],exp3=[],exp4=[]
    if(expandedRows.length>0){
      setExpandAllBtn("Expand All")
      setExpandedRows(rows)
      setExp1(exp1)
      setExp2(exp2)
      setExp3(exp3)
      setExp4(exp4)
      return
    }
     compData.timerData.forEach((item,i)=>{
     rows.push(item._id)
     item?.brand?.forEach((item2,i2)=>{
      exp1.push(item2._id)
      item2?.employees?.forEach((item3,i3)=>{
        exp2.push(item3.name)
        exp3.push(item3._id)
        exp4.push(item3._id)

       
      })
     })
    })
    setExpandAllBtn("Shrink All")
    setExpandedRows(rows)
    setExp1(exp1)
    setExp2(exp2)
    setExp3(exp3)
    setExp4(exp4) 
  };




  const fetchData = async () => {
    // console.log('inside fetchData ', SelectedDateFormattedData);
    try {
      setLoading(true);
      // const response = await Axios.post(`/admin/accounts?page=${page}&limit=${perPage}&keyword=${search}`, {
      //   account_names: selectedMultiTeamNames
      // }
      const response = await Axios.post(`/admin/accounts?page=${page}&limit=${perPage}`, {
        account_id: selectedMultiAccountsValues,
        team_ids: selectedMultiTeamValues,
        SelectedDateFormattedData: '',
      });
      // console.log('accounts',response.data.data);
      // Filter the data based on selectedBrandData
      const filteredData = response.data.data.map(account => {
        if (selectedBrandData.length > 0) {
          // Check if the brand ID exists in selectedBrandData
          const filteredBrands = account.brands.filter(brand => selectedBrandData.some(selectedBrand => selectedBrand.id === brand._id));
          // Update the account's brands with the filtered ones or keep all brands if none match
          account.brands = filteredBrands.length > 0 ? filteredBrands : account.brands;
        }
        return account;
      });

      setData(filteredData);
      // setData(response.data.data);
      // setTotalPages(Number(response.data.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [
    page,
    perPage,
    refreshPage,
    selectedMultiTeamValues,
    selectedMultiAccountsValues,
    SelectedDateFormattedData,
    selectedBrandData
  ]);

  useEffect(()=>{
    setExpandAllBtn("Expand All")
    setExpandedRows([])
      setExp1([])
      setExp2([])
      setExp3([])
      setExp4([])

      if(groupBy.value==="Employee"){
        let employee={}
        let modified_data=[]
        tableData?.timerData?.forEach((item1)=>{
         item1.brand.forEach((item2)=>{
          item2.employees.forEach((item3)=>{
            if(employee.hasOwnProperty(item3.name)){
              employee[item3.name]["duration"]=employee[item3.name]["duration"]+item3.totalDuration
             let account_index=-1 
             employee[item3.name].accounts.forEach((ele,i)=>{
               if(ele.account_id===item1._id){
               account_index=i
               }
            })  
             if(account_index>-1){
              employee[item3.name].accounts[account_index].brands.push({name:item2.brand_name,duration:item3.totalDuration,tasks:item3.tasks}) 
             }
             else{
              employee[item3.name].accounts.push({account_id:item1._id,
                _id:cryptoRandomString({length:10}),brand_name:item1.name,duration:item3.totalDuration,
                brands:[{name:item2.brand_name,duration:item3.totalDuration,tasks:item3.tasks}]})
             }
            
            }
            else{
            employee[item3.name]={name:item3.name,duration:item3.totalDuration,title:item3.title,team:item3.team,
              account_id:cryptoRandomString({length: 10}),pod:"",accounts:[{account_id:item1._id,
                _id:cryptoRandomString({length:10}),brand_name:item1.name,duration:item3.totalDuration,
                brands:[{name:item2.brand_name,duration:item3.totalDuration,tasks:item3.tasks}]}]}
            
              }
          })
         })
        })
       
  
        for(let key in employee){
        modified_data.push(employee[key])
        }
  
  modified_data=modified_data.map((item)=>{
    
    item._id=item.account_id
    let employee_total_duration=0
  
    item.accounts.forEach((item1)=>{
      let account_total_duration=0
      item1.employees=[]
      item1.brands.forEach((item2)=>{
       account_total_duration=account_total_duration+item2.duration
       employee_total_duration=employee_total_duration+item2.duration
      item2.totalHours=convertTime(item2.duration)
      item2.team=""
      item2.title=""
     
      item1.employees.push(item2)
      })
      item1.brandDuration=convertTime(account_total_duration)
    })
    item.accountDuration=convertTime(employee_total_duration)
    item.totalDuration=employee_total_duration
  
    item.brand=item.accounts
  
  return item
  })

  modified_data.sort((a,b)=>b.totalDuration-a.totalDuration)
  console.log(modified_data)
  setCompData({timerData:modified_data,totalDuration:tableData?.totalDuration})
  setExportObject({exportData:modified_data,groupBy:groupBy.value})
      }
    else if(groupBy.value==="Account"){
      let modify_data=tableData?.timerData?.map((item,i)=>{
        item.total_duration=convertToMinutes(item.accountDuration)
        return item
      })
      modify_data?.sort((a,b)=>{
        if(a.total_duration>b.total_duration){
          return -1
        }
      })
     setCompData({timerData:modify_data,totalDuration:tableData?.totalDuration})
     setExportObject({exportData:modify_data,groupBy:groupBy.value})

    }
    else if(groupBy.value==="Task"){
      let tasks={}
      let modified_data=[]
      tableData?.timerData?.forEach((item)=>{
        item?.brand.forEach((item2)=>{
          item2.employees.forEach((item3)=>{
            item3.tasks.forEach((item4)=>{
             if(tasks.hasOwnProperty(item4.name[0])){
            
              
             let account_index=-1
             let brand_index=-1
             let employee_index=-1
             tasks[item4.name[0]].brand.forEach((ele,i)=>{
              if(ele.account_id.toString()===item._id.toString()){
                account_index= i
              }
             })
             

              if(account_index>-1){
                tasks[item4.name[0]].brand[account_index].employees.forEach((ele,i)=>{
                  if(ele.brand_id.toString()===item2._id.toString()){
                    brand_index= i
                  }
                 }) 
              }

              if(brand_index>-1){
                tasks[item4.name[0]].brand[account_index].employees[brand_index].tasks.forEach((ele,i)=>{
                  if(item3.name===ele.name[0]){
                    employee_index=i
                  }
                })
              }
              console.log("inside if",account_index,brand_index,employee_index)
              if(employee_index<0 && brand_index>-1 && account_index>-1){
                console.log("inside nested if")
                tasks[item4.name[0]].brand[account_index].employees[brand_index].tasks.push({name:[item3.name],team:item3.team, title:item3.title,totalDuration:convertToMinutes(item4.duration)})
                
                // tasks[item4.name[0]].duration=tasks[item4.name[0]].duration+ convertToMinutes(item4.duration)
                // tasks[item4.name[0]].brand[account_index]=tasks[item4.name[0]].brand[account_index]+convertToMinutes(item4.duration)
                // tasks[item4.name[0]].brand[account_index].employees[brand_index].totalDuration=tasks[item4.name[0]].brand[account_index].employees[brand_index].totalDuration+convertToMinutes(item4.duration)            
              }
              else if(employee_index>-1 && brand_index>-1 && account_index>-1){
                tasks[item4.name[0]].brand[account_index].employees[brand_index].tasks[employee_index].totalDuration=
                tasks[item4.name[0]].brand[account_index].employees[brand_index].tasks[employee_index].totalDuration+convertToMinutes(item4.duration)

              }
              else if( account_index>-1 && brand_index<0){

                tasks[item4.name[0]].brand[account_index].employees.push({name:item2.brand_name,team:"",title:"",totalDuration:convertToMinutes(item4.duration),brand_id:item2._id,
                tasks:[{name:[item3.name],team:item3.team, title:item3.title,totalDuration:convertToMinutes(item4.duration)}]})
              }
              else if(account_index<0){
                tasks[item4.name[0]].brand.push({account_id:item._id,_id:item._id,brand_name:item.name,duration:convertToMinutes(item4.duration),
                  employees:[{name:item2.brand_name,team:"",title:"",totalDuration:convertToMinutes(item4.duration),brand_id:item2._id,
                  tasks:[{name:[item3.name],team:item3.team, title:item3.title,totalDuration:convertToMinutes(item4.duration)}]}]})
              }



             }
             else{
              tasks[item4.name[0]]={name:item4.name[0],task_type:item4.task_type,pod:"",team:"",duration:convertToMinutes(item4.duration),_id:item4._id,
              brand:[{account_id:item._id,_id:item._id,brand_name:item.name,duration:convertToMinutes(item4.duration),
                employees:[{name:item2.brand_name,team:"",title:"",totalDuration:convertToMinutes(item4.duration),brand_id:item2._id,
                tasks:[{name:[item3.name],team:item3.team, title:item3.title,totalDuration:convertToMinutes(item4.duration)}]}]}]}
             }
            })
          })
        })
      })

      console.log(modified_data)

      for(let key in tasks){
        modified_data.push(tasks[key])
      }

      modified_data=modified_data.map((item)=>{
  
        let task_total_duration=0
      
        item.brand.forEach((item1)=>{
          let account_total_duration=0
          
          
          item1.employees.forEach((item2)=>{
            let brand_total_duration=0
          item2.tasks.forEach((item3)=>{
            item3.duration=convertTime(item3.totalDuration)
            brand_total_duration= brand_total_duration+item3.totalDuration
            
          })   
          item2.totalDuration=brand_total_duration
          item2.totalHours= convertTime(brand_total_duration)
          account_total_duration=account_total_duration+brand_total_duration

           
          })
          task_total_duration=task_total_duration+account_total_duration
          item1.brandDuration=convertTime(account_total_duration)
        })
        item.accountDuration=convertTime(task_total_duration)
        item.totalDuration=task_total_duration
      
        
      
      return item
      })
      modified_data.sort((a,b)=>b.totalDuration-a.totalDuration)

      setCompData({timerData:modified_data,totalDuration:tableData?.totalDuration})
      setExportObject({exportData:modified_data,groupBy:groupBy.value})


    }

    function convertTime(seconds){
      let hours= Math.floor(seconds/60)
      let minutes= seconds-(hours*60)
      return hours+":"+minutes
    }

    function convertToMinutes(n) {
      let duration = n
      // console.log(duration)
      if (!duration) {
        return 0
      }
      let hours = Number(duration.slice(0, duration.indexOf(":")))
      // console.log(hours,"hours")
      let minutes = Number(duration.slice(duration.indexOf(":") + 1, duration.length)) + hours * 60
      //  console.log(minutes) 
      return minutes
    }


  },[groupBy,refreshPage])

  return (
    <Wrapper>
      {loading && <Loader />}

      <div className='third-row'>
      <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1 '>
      <div className='d-flex mb-2'>
      <div className='multi-select-container'>
                        <label>
                          Group By
                          <Select
                            id='multiSelectAccounts'
                            options={groupByOptions}
                            value={groupBy}
                            onChange={(e)=>setGroupBy(e)}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                          />
                        </label>

                      </div>

                      <div className='d-flex align-items-end'>
                      <button className='expand-all-btn' onClick={()=>toggleAllExpandedRow()}>{expandAllBtn}</button>

                      </div>

      </div>
                    

                    </div>

        <>
          <Table responsive bordered >
            <thead>
              <tr >
                {/* <th>#</th> */}
                {/* <th onClick={() => handleSort('name')}>Account Name {renderSortIcon('name')}</th>
                <th onClick={() => handleSort('account_status')}>Account Stage {renderSortIcon('account_status')}</th> */}
                <th className='max-account text-center'>{groupBy?.value=="Account"?"Account":(groupBy?.value=="Employee")?"Employee":"Activity"}</th>
                {/* <th className='max-account text-center'>Account</th> */}

                <th className='text-center'>Actual Time</th>
                <th className='text-center' >Task Type</th>
                <th className='text-center'>Team</th>
                <th className='text-center'>Pod</th>
              </tr>
            </thead>
            <tbody>
              {compData?.timerData?.map((row, index) => (
                <>
                  <tr key={row._id} >
                    {/* <td className='sno'>{perPage * (page - 1) + index + 1}</td> */}
                    {/* <td className='sno'>{index + 1}</td> */}

                    <td>
                      <button className='btn btn-link  ' onClick={() => toggleExpandedRow(row._id)}>
                        {expandedRows.includes(row._id) ? (
                          <span>&#x25BC;</span>
                        ) : (
                          <span>&#x25B6;</span>
                        )}
                      </button>
                      <span className='fw-bold fw-extra-bold'>{row.name}</span>
                    </td>
                    <td  className='text-center' >{row.accountDuration} h</td>
                    <td>{groupBy.value==="Task"?row.task_type:""}</td>
                    <td className='text-start'>{groupBy.value==="Employee"?row.team:""}</td>
                    <td className='text-start'>{row.pod}</td>

                  </tr>
                  {expandedRows.includes(row._id) && (
                    <ExpandableRow
                      data={row.brand}
                      refreshPage={refreshPage}
                      setRefreshPage={setRefreshPage}
                      expandedData={{exp1,exp2,exp3,exp4}}
                    />
                  )}
                </>
              ))}
              <tr>
                <td className='fw-bold total-duration-text fw-extra-bold'>Total Duration:</td>
                <td className='fw-bold text-center fw-extra-bold'>{compData?.totalDuration} h</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
                             

          </Table>
          {/* <Pagination
            total={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          /> */}
        </>
      </div>
    </Wrapper>
  );
};

const Wrapper = Styled.div`



.third-row{
    padding:20px;
    background: #fff;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
.overlap{
  margin-left:-12px
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
  .expandable-tr {
    border-width: 0;
}
.expandable-tr-2 {
    border-width: 0;
    border-top:0;
}
.expandable-tr-2 td {
  padding: 10px 10px !important;
}
  .expandable-tr td{
    padding: 10px 10px !important;
}
  .expandable-row {
    border-width: 0;
}
  tr td {
    width: 100%;
    min-width: 220px;
}
.expandable-row tr td {
     width: 100px;
}
.sno{
  width: 10%;
    min-width: 20px;
}
tr td:last-child {
    // width: 40%;
    // min-width: 120px;
}

#toggle-switch:checked{
  background-color: #008000; /* checked color */
}
#toggle-switch:not(:checked){
  background-color: #908F90; /* Unchecked color */
  color: #fff !important;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e")
;

}
.btn-link{
  --bs-btn-color:#000;
  text-decoration: none;
}

.total-duration-text{
  text-align: center;
}

@media(min-width:1400px){
  .total-duration-text{
    text-align: left;
  }
}



td{
  /* text-align: center; */
  vertical-align: middle;
  padding:3px 10px;
  border-color: #fff;
}
tr th{
padding:10px 10px;
  /* text-align: center; */
  vertical-align: middle;
  border-color: #EAF3FF;
  cursor: pointer;
  font-size: 0.9rem;
  border-right: 1px solid #dcdfe5;
  max-width:300px!important;
}

// tr td:nth-child(2){
//   font-weight:bold;
// }

/* Default style for the arrow icon */
.arrow {
  font-size: 12px; /* Adjust the font size as needed */
  color: #ccc; /* Default color */
  margin-left: 5px;
  float: right;

}

/* Style for ascending order */
.asc .arrow-up{
  color: #7a7c7f;
}



/* Style for descending order */
.desc .arrow-down{
  color: #7a7c7f;
}

.table-responsive{
   height:auto; 

  table {
    thead{
      position: sticky !important;
      top: -1px;
      z-index: 0;
    }
    tbody{
      overflow-y: auto;
    }
  }
}
.expand-all-btn{
  width:100px;
  height:40px;
  background-color:rgb(41, 132, 253);
  color:white;
  display:block;
  border:none;
  margin-left:20px;
}
    `;

export default Htable;
