import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import timezone from 'dayjs/plugin/timezone';

import { RRuleSet, rrulestr } from 'rrule';
import config from '../config/scheduler';
import behaviors from '../helper/behaviors';
import { ViewType, CellUnit, DATE_FORMAT, DATETIME_FORMAT } from './index';

export default class SchedulerData {
  constructor(
    date = dayjs(),
    viewType = ViewType.Week,
    showAgenda = false,
    isEventPerspective = false,
    newConfig = undefined,
    newBehaviors = undefined,
  ) {
    this.resources = [];
    this.events = [];
    this.eventGroups = [];
    this.eventGroupsAutoGenerated = true;
    this.viewType = viewType;
    this.cellUnit = viewType === ViewType.Day ? CellUnit.Hour : CellUnit.Day;
    this.showAgenda = showAgenda;
    this.isEventPerspective = isEventPerspective;
    this.resizing = false;
    this.scrollToSpecialDayjs = false;
    this.documentWidth = 0;
    this._shouldReloadViewType = false;

    this.calendarPopoverLocale = undefined;
    dayjs.extend(quarterOfYear);
    dayjs.extend(weekday);
    dayjs.extend(utc);
  
    this.localeDayjs = dayjs;
    this.config = newConfig == undefined ? config : { ...config, ...newConfig };
    this._validateMinuteStep(this.config.minuteStep);
    this.behaviors = newBehaviors == undefined ? behaviors : { ...behaviors, ...newBehaviors };
    this._resolveDate(0, dayjs(date));
    this._createHeaders();
    this._createRenderData();
  }

  setSchedulerLocale(preset) {
    if (!preset) return;

    this.localeDayjs.locale(preset);
    this._shouldReloadViewType = true;
    this.setViewType(this.viewType, this.showAgenda, this.isEventPerspective);
  }

  setCalendarPopoverLocale(lang) {
    if (!!lang) {
      this.calendarPopoverLocale = lang;
    }
  }

  setResources(resources) {
    this._validateResource(resources);
    this.resources = Array.from(new Set(resources));
    this._createRenderData();
    this.setScrollToSpecialDayjs(true);
  }

  setEventGroupsAutoGenerated(autoGenerated) {
    this.eventGroupsAutoGenerated = autoGenerated;
  }

  //optional
  setEventGroups(eventGroups) {
    this._validateEventGroups(eventGroups);
    this.eventGroups = Array.from(new Set(eventGroups));
    this.eventGroupsAutoGenerated = false;
    this._createRenderData();
    this.setScrollToSpecialDayjs(true);
  }

  setMinuteStep(minuteStep) {
    if (this.config.minuteStep !== minuteStep) {
      this._validateMinuteStep(minuteStep);
      this.config.minuteStep = minuteStep;
      this._createHeaders();
      this._createRenderData();
    }
  }

  setBesidesWidth(besidesWidth) {
    if (besidesWidth >= 0) {
      this.config.besidesWidth = besidesWidth;
    }
  }

  getMinuteStepsInHour() {
    return 60 / this.config.minuteStep;
  }

  addResource(resource) {
    let existedResources = this.resources.filter((x) => x.id === resource.id);
    if (existedResources.length === 0) {
      this.resources.push(resource);
      this._createRenderData();
    }
  }

  addEventGroup(eventGroup) {
    let existedEventGroups = this.eventGroups.filter((x) => x.id === eventGroup.id);
    if (existedEventGroups.length === 0) {
      this.eventGroups.push(eventGroup);
      this._createRenderData();
    }
  }

  removeEventGroupById(eventGroupId) {
    let index = -1;
    this.eventGroups.forEach((item, idx) => {
      if (item.id === eventGroupId) index = idx;
    });
    if (index !== -1) this.eventGroups.splice(index, 1);
  }

  containsEventGroupId(eventGroupId) {
    let index = -1;
    this.eventGroups.forEach((item, idx) => {
      if (item.id === eventGroupId) index = idx;
    });
    return index !== -1;
  }

  setEvents(events) {
    this._validateEvents(events);
    // this.events = Array.from(events);
    this.events = events.sort(function(a,b){
      return new Date(dayjs(a.start)) - new Date(dayjs(b.start));
    });

    // console.log(this.events,"from set resources")

    // if (this.eventGroupsAutoGenerated) this._generateEventGroups();
    // if (this.config.recurringEventsEnabled) this._handleRecurringEvents();

    this._createRenderData();
  }

  setScrollToSpecialDayjs(scrollToSpecialDayjs) {
    if (this.config.scrollToSpecialDayjsEnabled) this.scrollToSpecialDayjs = scrollToSpecialDayjs;
  }

  prev() {
    this._resolveDate(-1);
    this.events = [];
    this._createHeaders();
    this._createRenderData();
  }

  next() {
    this._resolveDate(1);
    this.events = [];
    this._createHeaders();
    this._createRenderData();
  }

  setDate(date = dayjs(new Date())) {
    this._resolveDate(0, date);
    this.events = [];
    this._createHeaders();
    this._createRenderData();
  }

  setViewType(viewType = ViewType.Week, showAgenda = false, isEventPerspective = false) {
    this.showAgenda = showAgenda;
    this.isEventPerspective = isEventPerspective;
    this.cellUnit = CellUnit.Day;

    if (this.viewType !== viewType || this._shouldReloadViewType) {
      let date = this.startDate;

      if (
        viewType === ViewType.Custom ||
        viewType === ViewType.Custom1 ||
        viewType === ViewType.Custom2
      ) {
        this.viewType = viewType;
        this._resolveDate(0, date);
      } else {
        if (this.viewType < viewType) {
          if (viewType === ViewType.Week) {
            this.startDate = this.localeDayjs(new Date(date)).startOf('week');
            this.endDate = this.localeDayjs(new Date(this.startDate)).endOf('week');
          } else if (viewType === ViewType.Month) {
            this.startDate = this.localeDayjs(new Date(date)).startOf('month');
            this.endDate = this.localeDayjs(new Date(this.startDate)).endOf('month');
          } else if (viewType === ViewType.Quarter) {
            this.startDate = this.localeDayjs(new Date(date)).startOf('quarter');
            this.endDate = this.localeDayjs(new Date(this.startDate)).endOf('quarter');
          } else if (viewType === ViewType.Year) {
            this.startDate = this.localeDayjs(new Date(date)).startOf('year');
            this.endDate = this.localeDayjs(new Date(this.startDate)).endOf('year');
          }
        } else {
          let start = this.localeDayjs(new Date(this.startDate));
          let end = this.localeDayjs(new Date(this.endDate)).add(1, 'days');

          if (this.selectDate !== undefined) {
            let selectDate = this.localeDayjs(new Date(this.selectDate));
            if (selectDate >= start && selectDate < end) {
              date = this.selectDate;
            }
          }

          let now = this.localeDayjs();
          if (now >= start && now < end) {
            date = now.startOf('day');
          }

          if (viewType === ViewType.Day) {
            this.startDate = date;
            this.endDate = this.startDate;
            this.cellUnit = CellUnit.Hour;
          } else if (viewType === ViewType.Week) {
            this.startDate = this.localeDayjs(new Date(date)).startOf('week');
            this.endDate = this.localeDayjs(new Date(this.startDate)).endOf('week');
          } else if (viewType === ViewType.Month) {
            this.startDate = this.localeDayjs(new Date(date)).startOf('month');
            this.endDate = this.localeDayjs(new Date(this.startDate)).endOf('month');
          } else if (viewType === ViewType.Quarter) {
            this.startDate = this.localeDayjs(new Date(date)).startOf('quarter');
            this.endDate = this.localeDayjs(new Date(this.startDate)).endOf('quarter');
          }
        }

        this.viewType = viewType;
      }

      this._shouldReloadViewType = false;

      this.events = [];
      this._createHeaders();
      this._createRenderData();
      this.setScrollToSpecialDayjs(true);
    }
  }

  setSchedulerMaxHeight(newSchedulerMaxHeight) {
    this.config.schedulerMaxHeight = newSchedulerMaxHeight;
  }

  isSchedulerResponsive() {
    return !!this.config.schedulerWidth.endsWith && this.config.schedulerWidth.endsWith('%');
  }

  toggleExpandStatus(slotId) {
    let slotEntered = false;
    let slotIndent = -1;
    let isExpanded = false;
    let expandedMap = new Map();
    this.renderData.forEach((item) => {
      if (slotEntered === false) {
        if (item.slotId === slotId && item.hasChildren) {
          slotEntered = true;

          isExpanded = !item.expanded;
          item.expanded = isExpanded;
          slotIndent = item.indent;
          expandedMap.set(item.indent, {
            expanded: item.expanded,
            render: item.render,
          });
        }
      } else {
        if (item.indent > slotIndent) {
          let expandStatus = expandedMap.get(item.indent - 1);
          item.render = expandStatus.expanded && expandStatus.render;

          if (item.hasChildren) {
            expandedMap.set(item.indent, {
              expanded: item.expanded,
              render: item.render,
            });
          }
        } else {
          slotEntered = false;
        }
      }
    });
  }

  isResourceViewResponsive() {
    let resourceTableWidth = this.getResourceTableConfigWidth();
    return !!resourceTableWidth.endsWith && resourceTableWidth.endsWith('%');
  }

  isContentViewResponsive() {
    let contentCellWidth = this.getContentCellConfigWidth();
    return !!contentCellWidth.endsWith && contentCellWidth.endsWith('%');
  }

  getSchedulerWidth() {
    
    let baseWidth =
      this.documentWidth - this.config.besidesWidth > 0
        ? this.documentWidth - this.config.besidesWidth
        : 0;
    return this.isSchedulerResponsive()
      ? parseInt((baseWidth * Number(this.config.schedulerWidth.slice(0, -1))) / 100)
      : this.config.schedulerWidth;
  }

  getResourceTableWidth() {
    let resourceTableConfigWidth = this.getResourceTableConfigWidth();
    let schedulerWidth = this.getSchedulerWidth();
    let resourceTableWidth = this.isResourceViewResponsive()
      ? parseInt((schedulerWidth * Number(resourceTableConfigWidth.slice(0, -1))) / 100)
      : resourceTableConfigWidth;
    if (
      this.isSchedulerResponsive() &&
      this.getContentTableWidth() + resourceTableWidth < schedulerWidth
    )
      resourceTableWidth = schedulerWidth - this.getContentTableWidth();
    return resourceTableWidth;
  }

  getContentCellWidth() {
    let contentCellConfigWidth = this.getContentCellConfigWidth();
    let schedulerWidth = this.getSchedulerWidth();
    return this.isContentViewResponsive()
      ? parseInt((schedulerWidth * Number(contentCellConfigWidth.slice(0, -1))) / 100)
      : contentCellConfigWidth;
  }

  getContentTableWidth() {
    return this.headers.length * this.getContentCellWidth();
  }

  getScrollToSpecialDayjs() {
    if (this.config.scrollToSpecialDayjsEnabled) return this.scrollToSpecialDayjs;
    return false;
  }

  getSlots() {
    return this.isEventPerspective ? this.eventGroups : this.resources;
  }

  getSlotById(slotId) {
    let slots = this.getSlots();
    let slot = undefined;
    slots.forEach((item) => {
      if (item.id === slotId) slot = item;
    });
    return slot;
  }

  getResourceById(resourceId) {
    let resource = undefined;
    this.resources.forEach((item) => {
      if (item.id === resourceId) resource = item;
    });
    return resource;
  }

  getTableHeaderHeight() {
    return this.config.tableHeaderHeight;
  }

  getSchedulerContentDesiredHeight() {
    let height = 0;
    this.renderData.forEach((item) => {
      if (item.render) height += item.rowHeight;
    });
    return height;
  }

  getCellMaxEvents() {
    return this.viewType === ViewType.Week
      ? this.config.weekMaxEvents
      : this.viewType === ViewType.Day
      ? this.config.dayMaxEvents
      : this.viewType === ViewType.Month
      ? this.config.monthMaxEvents
      : this.viewType === ViewType.Year
      ? this.config.yearMaxEvents
      : this.viewType === ViewType.Quarter
      ? this.config.quarterMaxEvents
      : this.config.customMaxEvents;
  }

  getCalendarPopoverLocale() {
    return this.calendarPopoverLocale;
  }

  getSelectedDate() {
    return this.selectDate.format(DATE_FORMAT);
  }

  getViewStartDate() {
    return this.startDate;
  }

  getViewEndDate() {
    return this.endDate;
  }

  getViewDates() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  getDateLabel() {
    let start = this.localeDayjs(new Date(dayjs(this.startDate)));
    let end = this.localeDayjs(new Date(dayjs(this.endDate)));
    let dateLabel = start.format('LL');

    if (start != end) dateLabel = `${start.format('LL')}-${end.format('LL')}`;

    if (!!this.behaviors.getDateLabelFunc)
      dateLabel = this.behaviors.getDateLabelFunc(
        this,
        this.viewType,
        this.startDate,
        this.endDate,
      );

    return dateLabel;
  }

  addEvent(newEvent) {
    this._attachEvent(newEvent);
    if (this.eventGroupsAutoGenerated) this._generateEventGroups();
    this._createRenderData();
  }

  updateEventStart(event, newStart) {
    this._detachEvent(event);
    event.start = newStart;
    this._attachEvent(event);
    this._createRenderData();
  }

  updateEventEnd(event, newEnd) {
    event.end = newEnd;
    this._createRenderData();
  }

  swapEvent(eventSource, eventDest) {
    // Swap group or resource IDs
    if (this.isEventPerspective) {
      [eventSource.groupId, eventDest.groupId] = [eventDest.groupId, eventSource.groupId];
      [eventSource.groupName, eventDest.groupName] = [eventDest.groupName, eventSource.groupName];
    } else {
      [eventSource.resourceId, eventDest.resourceId] = [
        eventDest.resourceId,
        eventSource.resourceId,
      ];
    }

    // Swap start and end times
    [eventSource.start, eventDest.start] = [eventDest.start, eventSource.start];
    [eventSource.end, eventDest.end] = [eventDest.end, eventSource.end];

    // Update the events
    this._detachEvent(eventSource);
    this._detachEvent(eventDest);
    this._attachEvent(eventSource);
    this._attachEvent(eventDest);
    this._createRenderData();
  }

  swapEvent2(eventSource, eventDest) {
    var tempEventSource = Object.assign({}, eventSource);
    var tempEventDest = Object.assign({}, eventDest);
    this._detachEvent(eventSource);
    this._detachEvent(eventDest);
    if (this.isEventPerspective) {
      tempEventSource.groupId = eventDest.groupId;
      tempEventSource.groupName = eventDest.groupName;
      tempEventDest.groupId = eventSource.groupId;
      tempEventDest.groupName = eventSource.groupName;
    } else {
      tempEventSource.resourceId = eventDest.resourceId;
      tempEventDest.resourceId = eventSource.resourceId;
    }
    tempEventSource.end = eventDest.end;
    tempEventSource.start = eventDest.start;
    tempEventDest.end = eventSource.end;
    tempEventDest.start = eventSource.start;
    this._attachEvent(tempEventSource);
    this._attachEvent(tempEventDest);
    this._createRenderData();
  }

  moveEvent(event, newSlotId, newSlotName, newStart, newEnd) {
    this._detachEvent(event);
    if (this.isEventPerspective) {
      event.groupId = newSlotId;
      event.groupName = newSlotName;
    } else event.resourceId = newSlotId;
    event.end = newEnd;
    event.start = newStart;
    this._attachEvent(event);
    this._createRenderData();
  }

  isEventInTimeWindow(eventStart, eventEnd, windowStart, windowEnd) {
    return eventStart < windowEnd && eventEnd > windowStart;
  }

  removeEvent(event) {
    let index = this.events.indexOf(event);
    if (index !== -1) {
      this.events.splice(index, 1);
      this._createRenderData();
    }
  }

  removeEventById(eventId) {
    let index = -1;
    this.events.forEach((item, idx) => {
      if (item.id === eventId) index = idx;
    });
    if (index !== -1) {
      this.events.splice(index, 1);
      this._createRenderData();
    }
  }

  getResourceTableConfigWidth() {
    if (this.showAgenda) return this.config.agendaResourceTableWidth;

    return this.viewType === ViewType.Week
      ? this.config.weekResourceTableWidth
      : this.viewType === ViewType.Day
      ? this.config.dayResourceTableWidth
      : this.viewType === ViewType.Month
      ? this.config.monthResourceTableWidth
      : this.viewType === ViewType.Year
      ? this.config.yearResourceTableWidth
      : this.viewType === ViewType.Quarter
      ? this.config.quarterResourceTableWidth
      : this.config.customResourceTableWidth;
  }

  getContentCellConfigWidth() {
    return this.viewType === ViewType.Week
      ? this.config.weekCellWidth
      : this.viewType === ViewType.Day
      ? this.config.dayCellWidth
      : this.viewType === ViewType.Month
      ? this.config.monthCellWidth
      : this.viewType === ViewType.Year
      ? this.config.yearCellWidth
      : this.viewType === ViewType.Quarter
      ? this.config.quarterCellWidth
      : this.config.customCellWidth;
  }

  _setDocumentWidth(documentWidth) {
    if (documentWidth >= 0) {
      this.documentWidth = documentWidth;
    }
  }

  _detachEvent(event) {
    let index = this.events.indexOf(event);
    if (index !== -1) this.events.splice(index, 1);
  }

  _attachEvent(event) {
    let pos = 0;
    let eventStart = this.localeDayjs(new Date(dayjs(event.start)));
    this.events.forEach((item, index) => {
      let start = this.localeDayjs(new Date(dayjs(item.start)));
      if (eventStart >= start) pos = index + 1;
    });
    this.events.splice(pos, 0, event);
  }

  _handleRecurringEvents() {
    let recurringEvents = this.events.filter((x) => !!x.rrule);
    recurringEvents.forEach((item) => {
      this._detachEvent(item);
    });

    recurringEvents.forEach((item) => {
      let windowStart = this.startDate,
        windowEnd = this.endDate.add(1, 'days'),
        oldStart = this.localeDayjs(new Date(item.start)),
        oldEnd = this.localeDayjs(new Date(item.end)),
        rule = rrulestr(item.rrule),
        oldDtstart = undefined,
        oldUntil = rule.origOptions.until || windowEnd.toDate();
      if (!!rule.origOptions.dtstart) {
        oldDtstart = this.localeDayjs(new Date(rule.origOptions.dtstart));
      }
      //rule.origOptions.dtstart = oldStart.toDate();
      if (windowEnd < oldUntil) {
        rule.origOptions.until = windowEnd.toDate();
      }

      //reload
      rule = rrulestr(rule.toString());
      if (item.exdates || item.exrule) {
        const rruleSet = new RRuleSet();
        rruleSet.rrule(rule);
        if (item.exrule) {
          rruleSet.exrule(rrulestr(item.exrule));
        }
        if (item.exdates) {
          item.exdates.forEach((exdate) => {
            rruleSet.exdate(this.localeDayjs(exdate).toDate());
          });
        }
        rule = rruleSet;
      }

      let all = rule.between(new Date(windowStart), new Date(windowEnd));
      all.forEach((time, index) => {
        const newEvent = {
          ...item,
          recurringEventId: item.id,
          recurringEventStart: item.start,
          recurringEventEnd: item.end,
          id: `${item.id}-${index}`,
          start: rule.origOptions.tzid
            ? this.localeDayjs
                .utc(time)
                .utcOffset(this.localeDayjs(new Date().utcOffset)(), true)
                .format(DATETIME_FORMAT)
            : this.localeDayjs(new Date(time)).format(DATETIME_FORMAT),
          end: rule.origOptions.tzid
            ? this.localeDayjs
                .utc(time)
                .utcOffset(this.localeDayjs(new Date().utcOffset)(), true)
                .add(oldEnd.diff(oldStart), 'ms')
                .add(
                  this.localeDayjs(new Date(oldUntil)).utcOffset() -
                    this.localeDayjs(new Date(item.start)).utcOffset(),
                  'm',
                )
                .format(DATETIME_FORMAT)
            : this.localeDayjs(new Date(time))
                .add(oldEnd.diff(oldStart), 'ms')
                .format(DATETIME_FORMAT),
        };

        let eventStart = this.localeDayjs(newEvent.start),
          eventEnd = this.localeDayjs(newEvent.end);
        if (
          this.isEventInTimeWindow(eventStart, eventEnd, windowStart, windowEnd) &&
          (!oldDtstart || eventStart >= oldDtstart)
        ) {
          this._attachEvent(newEvent);
        }
      });
    });
  }

  _resolveDate(num, date = undefined) {
    if (date != undefined) this.selectDate = dayjs(date);
    if(num==1 || num==-1){
      this.selectDate=this.localeDayjs(this.selectDate).add(num,"year")
      // date=this.localeDayjs(this.selectDate)
      date=this.localeDayjs(this.selectDate)

    }
    // console.log(this.selectDate,date, 'select date');

    if (this.viewType === ViewType.Week) {
      this.startDate =
        date != undefined
          ? this.localeDayjs(date).startOf('week')
          : this.localeDayjs(this.startDate).add(num, 'weeks');
      this.endDate = this.localeDayjs(this.startDate).endOf('week');
    } else if (this.viewType === ViewType.Day) {
      this.startDate =
        date != undefined ? this.selectDate : this.localeDayjs(this.startDate).add(num, 'days');
      this.endDate = this.startDate;
    } else if (this.viewType === ViewType.Month) {
      console.log('received in resolveddate', date, this.viewType, num);

      this.startDate =
        date != undefined
          ? this.localeDayjs(date).startOf('month')
          : this.localeDayjs(this.startDate).add(num, 'months');
      this.endDate = this.localeDayjs(this.startDate).endOf('month');
      console.log(this.startDate, this.endDate, 'start end');
    } else if (this.viewType === ViewType.Quarter) {
      this.startDate =
        date != undefined
          ? this.localeDayjs(date).startOf('quarter')
          : this.localeDayjs(this.startDate).add(num, 'quarters');
      this.endDate = this.localeDayjs(this.startDate).endOf('quarter');
    } else if (this.viewType === ViewType.Year) {
      this.startDate =
        date != undefined
          ? this.localeDayjs(date).startOf('year')
          : this.localeDayjs(this.startDate).add(num, 'years');
      this.endDate = this.localeDayjs(this.startDate).endOf('year');
    } else if (
      this.viewType === ViewType.Custom ||
      this.viewType === ViewType.Custom1 ||
      this.viewType === ViewType.Custom2
    ) {
      if (this.behaviors.getCustomDateFunc != undefined) {
        let customDate = this.behaviors.getCustomDateFunc(this, num, dayjs(date));
        // console.log(date,customDate,"from custom date")
        // console.log(date)
        //changes done here
        // this.startDate = this.localeDayjs(customDate.startDate).add(num,"year");
        this.startDate = this.localeDayjs(customDate.startDate)
        // this.selectDate = customDate.startDate
        console.log(this.selectDate, "from custom next prev click")

        // this.startDate = this.localeDayjs(customDate.startDate).startOf("year").startOf("month").add(num,"year");

        // this.startDate = this.localeDayjs(Date.now())
        
        this.endDate = this.localeDayjs(customDate.endDate)
        // this.endDate = this.localeDayjs(this.startDate).endOf("year")
        // console.log(this.startDate,this.endDate,"this.start ,this.end")

        if (!!customDate.cellUnit) this.cellUnit = customDate.cellUnit;
      } else {
        throw new Error(
          'This is custom view type, set behaviors.getCustomDateFunc func to resolve the time window(startDate and endDate) yourself',
        );
      }
    }
  }

  _createHeaders() {
    let headers = [],
      start = this.localeDayjs(new Date(dayjs(this.startDate))),
      end = this.localeDayjs(new Date(dayjs(this.endDate))),
      header = start;
    // console.log(header, 'header');

    if (this.showAgenda) {
      headers.push({ time: header.format(DATETIME_FORMAT), nonWorkingTime: false });
    } else {
      if (this.cellUnit === CellUnit.Hour) {
        if (start.hour() == 0) {
          start = start.add(this.config.dayStartFrom, 'hours');
        }
        if (end.hour() == 0) {
          end = end.add(this.config.dayStopTo, 'hours');
        }
        header = start;

        let prevHour = -1;
        while (header >= start && header <= end) {
          // prevent doubled hours on time change
          if (header.hour() == prevHour) {
            header = header.add(1, 'hours');
            continue;
          }
          prevHour = header.hour();
          let minuteSteps = this.getMinuteStepsInHour();
          for (let i = 0; i < minuteSteps; i++) {
            let hour = header.hour();
            if (hour >= this.config.dayStartFrom && hour <= this.config.dayStopTo) {
              let time = header.format(DATETIME_FORMAT);
              let nonWorkingTime = this.behaviors.isNonWorkingTimeFunc(this, time);
              headers.push({ time: time, nonWorkingTime: nonWorkingTime });
            }

            header = header.add(this.config.minuteStep, 'minutes');
          }
        }
      } else if (this.cellUnit === CellUnit.Day) {
        while (header >= start && header <= end) {
          let time = header.format(DATETIME_FORMAT);
          let dayOfWeek = header.weekday();
          if (this.config.displayWeekend || (dayOfWeek !== 0 && dayOfWeek !== 6)) {
            let nonWorkingTime = this.behaviors.isNonWorkingTimeFunc(this, time);
            headers.push({ time, nonWorkingTime });
          }

          header = header.add(1, 'days');
        }
      } else if (this.cellUnit === CellUnit.Week) {
        while (header >= start && header <= end) {
          let time = header.format(DATE_FORMAT);
          headers.push({ time });
          header = header.add(1, 'weeks').startOf('week');
        }
      } else if (this.cellUnit === CellUnit.Month) {
        while (header >= start && header <= end) {
          let time = header.format(DATE_FORMAT);
          headers.push({ time });
          header = header.add(1, 'months').startOf('month');
        }
      } else if (this.cellUnit === CellUnit.Year) {
        while (header >= start && header <= end) {
          let time = header.format(DATE_FORMAT);
          headers.push({ time });
          header = header.add(1, 'years').startOf('year');
        }
      }
    }

    this.headers = headers;
  }

  _createInitHeaderEvents(header) {
    let startValue= this.localeDayjs(header.time).startOf("month").format(DATE_FORMAT)
    //  let startValue = start.format(DATE_FORMAT);
       let endValue= this.localeDayjs(header.time).endOf("month").format(DATE_FORMAT)
    // let endValue = this.showAgenda
    //   ? this.viewType === ViewType.Week
    //     ? start.add(1, 'weeks').format(DATETIME_FORMAT)
    //     : this.viewType === ViewType.Day
    //     ? start.add(1, 'days').format(DATETIME_FORMAT)
    //     : this.viewType === ViewType.Custom2
    //     ? start.endOf("month").format(DATE_FORMAT)
    //     : this.viewType === ViewType.Year
    //     ? start.add(1, 'years').format(DATETIME_FORMAT)
    //     : this.viewType === ViewType.Quarter
    //     ? start.add(1, 'quarters').format(DATETIME_FORMAT)
    //     : this.localeDayjs(new Date(this.endDate)).add(1, 'days').format(DATETIME_FORMAT)
    //   : this.cellUnit === CellUnit.Hour
    //   ? start.add(this.config.minuteStep, 'minutes').format(DATETIME_FORMAT)
    //   : this.cellUnit === CellUnit.Year
    //   ? start.add(1, 'years').format(DATE_FORMAT)
    //   : this.cellUnit === CellUnit.Month
    //   ? start.endOf('month').format(DATE_FORMAT)
    //   : this.cellUnit === CellUnit.Week
    //   ? start.add(1, 'weeks').format(DATE_FORMAT)
    //   : start.add(1, 'days').format(DATETIME_FORMAT);


     

    return {
      time: header.time,
      nonWorkingTime: header.nonWorkingTime,
      start: startValue,
      end: endValue,//changes here
      count: 0,
      addMore: 0,
      addMoreIndex: 0,
      events: [, , ,],
    };
  }

  _createHeaderEvent(render, span, eventItem) {
    return {
      render: render,
      span: span,
      eventItem: eventItem,
    };
  }

  _getEventSlotId(event) {
    return this.isEventPerspective ? this._getEventGroupId(event) : event.resourceId;
  }

  _getEventGroupId(event) {
    return !!event.groupId ? event.groupId.toString() : event.id.toString();
  }

  _getEventGroupName(event) {
    return !!event.groupName ? event.groupName : event.title;
  }

  _generateEventGroups() {
    let eventGroups = [];
    let set = new Set();
    this.events.forEach((item) => {
      let groupId = this._getEventGroupId(item);
      let groupName = this._getEventGroupName(item);

      if (!set.has(groupId)) {
        eventGroups.push({
          id: groupId,
          name: groupName,
          state: item,
        });
        set.add(groupId);
      }
    });
    this.eventGroups = eventGroups;
  }

  _createInitRenderData(isEventPerspective, eventGroups, resources, headers) {

    // console.log(headers,"headers")
    let slots = isEventPerspective ? eventGroups : resources;
    let slotTree = [],
      slotMap = new Map();
    slots.forEach((slot) => {
      let headerEvents = headers.map((header) => {
        return this._createInitHeaderEvents(header);
      });
      // console.log(headerEvents,"slots")
      let slotRenderData = {
        slotId: slot.id,
        slotName: slot.name,
        // slotImageUrl:slot.imageUrl,
        slotTitle:slot.title,
        slotImageUrl: slot?.profile_pic || '',
        parentId: slot.parentId,
        groupOnly: slot.groupOnly,
        hasSummary: false,
        rowMaxCount: 0,
        rowHeight:
          this.config.nonAgendaSlotMinHeight !== 0
            ? this.config.nonAgendaSlotMinHeight
            : this.config.eventItemLineHeight + 2,
        headerItems: headerEvents,
        indent: 0,
        hasChildren: false,
        expanded: true,
        render: true,
      };
      let id = slot.id;
      let value = undefined;
      if (slotMap.has(id)) {
        value = slotMap.get(id);
        value.data = slotRenderData;
      } else {
        value = {
          data: slotRenderData,
          children: [],
        };
        slotMap.set(id, value);
      }

      let parentId = slot.parentId;
      if (!parentId || parentId === id) {
        slotTree.push(value);
      } else {
        let parentValue = undefined;
        if (slotMap.has(parentId)) {
          parentValue = slotMap.get(parentId);
        } else {
          parentValue = {
            data: undefined,
            children: [],
          };
          slotMap.set(parentId, parentValue);
        }

        parentValue.children.push(value);
      }
    });

    let slotStack = [];
    let i;
    for (i = slotTree.length - 1; i >= 0; i--) {
      slotStack.push(slotTree[i]);
    }
    let initRenderData = [];
    let currentNode = undefined;
    while (slotStack.length > 0) {
      currentNode = slotStack.pop();
      if (currentNode.data.indent > 0) {
        currentNode.data.render = this.config.defaultExpanded;
      }
      if (currentNode.children.length > 0) {
        currentNode.data.hasChildren = true;
        currentNode.data.expanded = this.config.defaultExpanded;
      }
      initRenderData.push(currentNode.data);
      // console.log(currentNode.data,"inside initRender func")

      for (i = currentNode.children.length - 1; i >= 0; i--) {
        currentNode.children[i].data.indent = currentNode.data.indent + 1;
        slotStack.push(currentNode.children[i]);
      }
    }
    return initRenderData;
  }

  _getSpan(startTime, endTime, headers) {
    if (this.showAgenda) return 1;

    function startOfWeek(date) {
      let day = date.getDay();
      let diff = date.getDate() - day;
      return new Date(date.getFullYear(), date.getMonth(), diff);
    }

    const timeBetween = (date1, date2, timeIn) => {
      if (timeIn === 'days' || timeIn === 'day') {
        if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()) {
          return 1;
        }
      }

      if(timeIn==="month"){

        // let one;
        // switch (timeIn) {
        //   case 'days':
        //   case 'day':
        //     one = 1000 * 60 * 60 * 24;
        //     break;
        //   case 'minutes':
        //   case 'minute':
        //     one = 1000 * 60;
        //     break;
        //   default:
        //     return 0;
        // }
  
        const date1_ms = date1.getMonth();
        const date2_ms = date2.getMonth()+(12*(date2.getFullYear()-date1.getFullYear())+1);
        // console.log(startTime,endTime,date1_ms,date2_ms,"from event setter")
  
        const diff = (date2_ms - date1_ms);
        return diff < 0 ? 0 : diff;

      }

      let one;
      switch (timeIn) {
        case 'days':
        case 'day':
          one = 1000 * 60 * 60 * 24;
          break;
        case 'minutes':
        case 'minute':
          one = 1000 * 60;
          break;
        default:
          return 0;
      }

      const date1_ms = date1.getTime();
      const date2_ms = date2.getTime();

      const diff = (date2_ms - date1_ms) / one;
      return diff < 0 ? 0 : diff;
    };
    // console.log(startTime,endTime,"start.....end")
    let eventStart = new Date(dayjs(startTime)),
      eventEnd = new Date(dayjs(endTime)),
      span = 0,
      windowStart = new Date(dayjs(this.startDate)),
      windowEnd = new Date(dayjs(this.endDate));

      // console.log(eventStart,eventEnd,windowStart,windowEnd,"start.....end")

    // windowStart.setHours(0, 0, 0,0);
    // windowEnd.setHours(11, 0, 0);

    if (this.viewType === ViewType.Day) {
      if (headers.length > 0) {
        const day = new Date(headers[0].time);
        if (day.getDate() > eventStart.getDate() && day.getDate() < eventEnd.getDate()) {
          span = 1440 / this.config.minuteStep;
        } else if (day.getDate() > eventStart.getDate() && day.getDate() === eventEnd.getDate()) {
          span = Math.ceil(timeBetween(day, eventEnd, 'minutes') / this.config.minuteStep);
        } else if (day.getDate() === eventStart.getDate() && day.getDate() < eventEnd.getDate()) {
          day.setHours(23, 59, 59);
          span = Math.ceil(timeBetween(eventStart, day, 'minutes') / this.config.minuteStep);
        } else if (
          (day.getDate() === eventStart.getDate() && day.getDate() === eventEnd.getDate()) ||
          eventEnd.getDate() === eventStart.getDate()
        ) {
          span = Math.ceil(timeBetween(eventStart, eventEnd, 'minutes') / this.config.minuteStep);
        }
      }
    } else if (
      this.viewType === ViewType.Week ||
      this.viewType === ViewType.Month ||
      this.viewType === ViewType.Quarter ||
      this.viewType === ViewType.Year || ViewType.Custom2
    ) {
      const startDate = windowStart < eventStart ? eventStart : windowStart;
      const endDate = windowEnd > eventEnd ? eventEnd : windowEnd;
      // span = Math.ceil(timeBetween(startDate, endDate, 'days'));
      span = Math.ceil(timeBetween(startDate, endDate, 'month'))
    //  console.log(startDate,endDate,"..start,end")      
      
    } else {
      if (this.cellUnit === CellUnit.Day) {
        eventEnd.setHours(23, 59, 59);
        eventStart.setHours(0, 0, 0, 0);
      }

      const timeIn = this.cellUnit === CellUnit.Day ? 'days' : 'minutes';
      const dividedBy = this.cellUnit === CellUnit.Day ? 1 : this.config.minuteStep;

      if (windowStart >= eventStart && eventEnd <= windowEnd) {
        span = Math.ceil(timeBetween(windowStart, eventEnd, timeIn) / dividedBy);
      } else if (windowStart > eventStart && eventEnd > windowEnd) {
        span = Math.ceil(timeBetween(windowStart, windowEnd, timeIn) / dividedBy);
      } else if (windowStart <= eventStart && eventEnd >= windowEnd) {
        span = Math.ceil(timeBetween(eventStart, windowEnd, timeIn) / dividedBy);
      } else {
        span = Math.ceil(timeBetween(eventStart, eventEnd, timeIn) / dividedBy);
      }
    }

    return span;
  }

  _validateResource(resources) {
    if (Object.prototype.toString.call(resources) !== '[object Array]') {
      throw new Error('Resources should be Array object');
    }

    resources.forEach((item, index) => {
      if (item == undefined) {
        console.error(`Resource undefined: ${index}`);
        throw new Error(`Resource undefined: ${index}`);
      }
      if (item.id == undefined || item.name == undefined) {
        console.error('Resource property missed', index, item);
        throw new Error(`Resource property undefined: ${index}`);
      }
    });
  }

  _validateEventGroups(eventGroups) {
    if (Object.prototype.toString.call(eventGroups) !== '[object Array]') {
      throw new Error('Event groups should be Array object');
    }

    eventGroups.forEach((item, index) => {
      if (item == undefined) {
        console.error(`Event group undefined: ${index}`);
        throw new Error(`Event group undefined: ${index}`);
      }
      if (item.id == undefined || item.name == undefined) {
        console.error('Event group property missed', index, item);
        throw new Error(`Event group property undefined: ${index}`);
      }
    });
  }

  _validateEvents(events) {
    if (Object.prototype.toString.call(events) !== '[object Array]') {
      throw new Error('Events should be Array object');
    }

    events.forEach((e, index) => {
      if (e == undefined) {
        console.error(`Event undefined: ${index}`);
        throw new Error(`Event undefined: ${index}`);
      }
      if (
        e.id == undefined ||
        e.resourceId == undefined ||
        e.title == undefined ||
        e.start == undefined ||
        e.end == undefined
      ) {
        console.error('Event property missed', index, e);
        throw new Error(`Event property undefined: ${index}`);
      }
    });
  }

  _validateMinuteStep(minuteStep) {
    if (60 % minuteStep !== 0) {
      console.error(
        'Minute step is not set properly - 60 minutes must be divisible without remainder by this number',
      );
      throw new Error(
        'Minute step is not set properly - 60 minutes must be divisible without remainder by this number',
      );
    }
  }

  _compare(event1, event2) {
    let start1 = this.localeDayjs(event1.start),
      start2 = this.localeDayjs(event2.start);
    if (start1 !== start2) return start1 < start2 ? -1 : 1;

    let end1 = this.localeDayjs(event1.end),
      end2 = this.localeDayjs(event2.end);
    if (end1 !== end2) return end1 < end2 ? -1 : 1;

    return event1.id < event2.id ? -1 : 1;
  }

  _createRenderData() {
    let initRenderData = this._createInitRenderData(
      this.isEventPerspective,
      this.eventGroups,
      this.resources,
      this.headers,
    );
    // console.log(initRenderData,"initRenderData")
    //this.events.sort(this._compare);
    let cellMaxEventsCount = this.getCellMaxEvents();
    const cellMaxEventsCountValue = 30;
    this.events.forEach((item) => {
      // console.log(item,"events and item")

      let resourceEventsList = initRenderData.filter(
        (x) => x.slotId === this._getEventSlotId(item),
      );
      if (resourceEventsList.length > 0) {
        let resourceEvents = resourceEventsList[0];
        // console.log(resourceEventsList[0],"resource event list")

        let span = this._getSpan(item.start, item.end, this.headers);
        let eventStart = new Date(dayjs(item.start)),
          eventEnd = new Date(dayjs(item.end));
        let pos = -1;

        resourceEvents.headerItems.forEach((header, index) => {
          let headerStart = new Date(dayjs(header.start)),
            headerEnd = new Date(dayjs(header.end));
          if (headerEnd > eventStart && headerStart < eventEnd) {
            header.count = header.count + 1;
            if (header.count > resourceEvents.rowMaxCount) {
              resourceEvents.rowMaxCount = header.count;
              let rowsCount =
                cellMaxEventsCount <= cellMaxEventsCountValue &&
                resourceEvents.rowMaxCount > cellMaxEventsCount
                  ? cellMaxEventsCount
                  : resourceEvents.rowMaxCount;
              // let newRowHeight =
              //   rowsCount * this.config.eventItemLineHeight +
              //   (this.config.creatable && this.config.checkConflict === false ? 20 : 2);
               //changes done here
              let newRowHeight =
                rowsCount * 30 +
                (this.config.creatable && this.config.checkConflict === false ? 20 : 2);
              if (newRowHeight > resourceEvents.rowHeight) resourceEvents.rowHeight = newRowHeight;
            }

            if (pos === -1) {
              let tmp = 0;
              while (header.events[tmp] !== undefined) tmp++;

              pos = tmp;
            }
            let render = headerStart <= eventStart || index === 0;
            if (render === false) {
              let previousHeader = resourceEvents.headerItems[index - 1];
              let previousHeaderStart = new Date(dayjs(previousHeader.start)),
                previousHeaderEnd = new Date(dayjs(previousHeader.end));
              if (previousHeaderEnd <= eventStart || previousHeaderStart >= eventEnd) render = true;
            }
            // console.log(`span: ${span}`)
            header.events[pos] = this._createHeaderEvent(render, span, item);
          }
        });
      }
    });

    if (
      cellMaxEventsCount <= cellMaxEventsCountValue ||
      this.behaviors.getSummaryFunc !== undefined
    ) {
      initRenderData.forEach((resourceEvents) => {
        let hasSummary = false;

        resourceEvents.headerItems.forEach((headerItem) => {
          if (cellMaxEventsCount <= cellMaxEventsCountValue) {
            let renderItemsCount = 0,
              addMoreIndex = 0,
              index = 0;
            while (index < cellMaxEventsCount - 1) {
              if (headerItem.events[index] !== undefined) {
                renderItemsCount++;
                addMoreIndex = index + 1;
              }

              index++;
            }

            if (headerItem.events[index] !== undefined) {
              if (renderItemsCount + 1 < headerItem.count) {
                headerItem.addMore = headerItem.count - renderItemsCount;
                headerItem.addMoreIndex = addMoreIndex;
              }
            } else {
              if (renderItemsCount < headerItem.count) {
                headerItem.addMore = headerItem.count - renderItemsCount;
                headerItem.addMoreIndex = addMoreIndex;
              }
            }
          }

          if (this.behaviors.getSummaryFunc !== undefined) {
            let events = [];
            headerItem.events.forEach((e) => {
              if (!!e && !!e.eventItem) events.push(e.eventItem);
            });

            headerItem.summary = this.behaviors.getSummaryFunc(
              this,
              events,
              resourceEvents.slotId,
              resourceEvents.slotName,
              headerItem.start,
              headerItem.end,
            );
            if (!!headerItem.summary && headerItem.summary.text != undefined) hasSummary = true;
          }
        });

        resourceEvents.hasSummary = hasSummary;
        if (hasSummary) {
          let rowsCount =
            cellMaxEventsCount <= cellMaxEventsCountValue &&
            resourceEvents.rowMaxCount > cellMaxEventsCount
              ? cellMaxEventsCount
              : resourceEvents.rowMaxCount;
          let newRowHeight =
            (rowsCount + 1) * this.config.eventItemLineHeight +
            (this.config.creatable && this.config.checkConflict === false ? 20 : 2);
          if (newRowHeight > resourceEvents.rowHeight) resourceEvents.rowHeight = newRowHeight;
        }
      });
    }

    this.renderData = initRenderData;
  }

  _startResizing() {
    this.resizing = true;
  }

  _stopResizing() {
    this.resizing = false;
  }

  _isResizing() {
    return this.resizing;
  }
}
