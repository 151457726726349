import React, { useState } from 'react';
import { ComposedChart, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Bar, Line,ResponsiveContainer } from 'recharts'
import { Table } from 'react-bootstrap';
import Styled from 'styled-components';
import Pagination from '../../../../components/Pagination';


function ExpandableRow(props) {
  const { data} = props;



  // console.log(data);
  return (
    <>
      {data.map((row, index) => (
        <tr  key={index}>
          <td colSpan='8'>
            <Table responsive bordered>
              <tbody>
                <tr className='expandable-tr'>


                  <td >{row.name}</td>
                  <td className="text-center">${row?.revenue}</td>
                  <td className="text-center">{row?.forecastedMargin}%</td>
                  <td className="text-center">{row?.actualMargin}%</td>
                  <td className="text-center">{row?.goal_percentage}%</td>

                  <td className="text-center">{row?.variance}%</td>
                  <td className="text-center">{Number(row?.goalVariance).toFixed(1)}%</td>

                  <td ></td>

                </tr>

              </tbody>
            </Table>
          </td>
        </tr>
      ))}
    </>
  );
}

const Chart = ({chartData,page,setPage,totalPages,perPage,setPerPage,paginationText}) => {
  console.log(chartData)
  const [data, setData] = useState(chartData);
  const [expandedRows,setExpandedRows]=useState([])
  const [expandAllBtn,setExpandAllBtn]=useState("Expand All");


  const toggleExpandedRow = (rowId) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));


    } else {
      setExpandedRows([...expandedRows, rowId]);
    }

  };

  const toggleAllExpandedRow = () => {
    let rows=[]
    if(expandedRows.length>0){
      setExpandAllBtn("Expand All")
      setExpandedRows(rows)
      return
    }
    chartData.forEach((item,i)=>{
     rows.push(item._id)
    })
    setExpandAllBtn("Shrink All")
    setExpandedRows(rows) 
  };

  return (
    <Wrapper>

          <div className='third-row'>
            <button className='btn btn-primary my-2' onClick={()=>toggleAllExpandedRow()}>{expandAllBtn}</button>
            <>
              <Table responsive bordered>
                <thead  >
                  <tr>
                    <th className='text-center'>Account</th>
                    <th className='text-center'>Revenue</th>
                    <th className='text-center'>Forecasted Profitability Margin</th>
                    <th className='text-center'>Actual Profitability  Margin</th>
                    <th className='text-center'>Goal Profitability Margin</th>

                    <th className='text-center'>Actual to Forecast Variance</th>
                    <th className='text-center'>Actual to Goal Variance</th>

                    <th className='text-center'>Month</th>

                  </tr>
                </thead>
                <tbody>
                  {chartData?.map((row,i) => (
                   <>

                    <tr key={i}>
                      <td>
                      <button className='btn btn-link ' onClick={() => toggleExpandedRow(row._id)}>
                        {expandedRows.includes(row._id) ? (
                          <span>&#x25BC;</span>
                        ) : (
                          <span>&#x25B6;</span>
                        )}
                      </button>
                      <span className='fw-bold fw-extra-bold'>{row?.account_name}</span>
                      
                    </td>
                      <td className='text-center' >${row?.revenue}</td>
                      <td className='text-center'>{row?.forecastedMargin}%</td>
                      <td className='text-center'>{row?.actualMargin}%</td>
                      <td className='text-center'>{row?.goalPercentageAccount}%</td>
                      <td className='text-center'>{row?.variance}%</td>
                      <td className='text-center'>{Number(row?.goalVarianceAccount).toFixed(1)}%</td>

                      <td className='text-center'>{row?.month}</td>
   
                    </tr>

{expandedRows.includes(row._id) && (
  <ExpandableRow
    data={row.brands}
  />
)}
</>
                   
                  ))}
                </tbody>
              </Table>
            </>
          </div>
          <Pagination
                total={totalPages}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                paginationText={paginationText}
              />

    </Wrapper>
  );
};

export default Chart;




const Wrapper = Styled.div`
 
//from hours

.third-row{
    margin-top: 15px;
    border-radius: 16px;
    padding:20px;
    background: #fff;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
.overlap{
  margin-left:-12px
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
  .expandable-tr {
    border-width: 0;
    background:grey;
}
.expandable-tr-2 {
    border-width: 0;
    border-top:0;
}
.expandable-tr-2 td {
  padding: 10px 10px !important;
}
  .expandable-tr td{
    padding: 10px 10px !important;
}
  .expandable-row {
    border-width: 0;
}
  tr td {
    width: 100%;
    min-width: 220px;
}
.expandable-row tr td {
     width: 100px;
}
.sno{
  width: 10%;
    min-width: 20px;
}
tr td:last-child {
    // width: 40%;
    // min-width: 120px;
}

#toggle-switch:checked{
  background-color: #008000; /* checked color */
}
#toggle-switch:not(:checked){
  background-color: #908F90; /* Unchecked color */
  color: #fff !important;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e")
;

}
.btn-link{
  --bs-btn-color:#000;
  text-decoration: none;
}

.total-duration-text{
  text-align: center;
}

@media(min-width:1400px){
  .total-duration-text{
    text-align: left;
  }
}



td{
  /* text-align: center; */
  vertical-align: middle;
  padding:3px 10px;
  border-color: #fff;
}
tr th{
padding:10px 10px;
  /* text-align: center; */
  vertical-align: middle;
  border-color: #EAF3FF;
  cursor: pointer;
  font-size: 0.9rem;
  border-right: 1px solid #dcdfe5;
  max-width:300px!important;
}

// tr td:nth-child(2){
//   font-weight:bold;
// }

/* Default style for the arrow icon */
.arrow {
  font-size: 12px; /* Adjust the font size as needed */
  color: #ccc; /* Default color */
  margin-left: 5px;
  float: right;

}

/* Style for ascending order */
.asc .arrow-up{
  color: #7a7c7f;
}



/* Style for descending order */
.desc .arrow-down{
  color: #7a7c7f;
}

.table-responsive{
   height:auto;

  /* table {
    thead{
      position: sticky !important;
      top: -1px;
      z-index: 0;
    }
    tbody{
      overflow-y: auto;
    }
  } */
}

`;


{/* <div className='col-sm-7'>
<div className='section secttion-right'>
  <div className='hSpent-text'>Monthly Margin</div>
  <div className='bar-chart'>
  <ResponsiveContainer width="100%" height={408}>
    <ComposedChart
    margin={{
      top: 5,
      right: 0,
      left: 10,
      bottom: 5,
    }}
  data={data}
  >
<XAxis dataKey="month" />
<YAxis yAxisId={1} orientation="right" />
<YAxis yAxisId={2} />
<Tooltip />
<CartesianGrid stroke="#f5f5f5" />
<Bar yAxisId={1} dataKey="contributionMarginPercentage" fill="#413ea0" />
<Line yAxisId={2} type="monotone" dataKey="contributionMargin" stroke="#ff0000" />
</ComposedChart>
</ResponsiveContainer>
  </div>
</div>
</div> */}
