import React, { useState, useEffect } from 'react';
import Axios from '../../../../utils/api';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import TokenServices from '../../../../utils/token.services';
import { toast } from 'react-toastify';

const FullDataExportCSV = ({ exportLoader, setExportLoader, setisExportClicked, setShowPopup, isExportClicked, ApplyDateFilter, selectedMultiTeamValues, selectedMultiEmployeeValues, selectedMultiAccountsTypeValues, selectedYear, selectedMonth, setLoading, areFiltersFetched, employeeOptions, employeeFetched }) => {
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'employeeName', direction: 'ascending' });

    const fetchData = async () => {
        try {
            const userData = TokenServices.getUser();
            if (userData.role === 'Admin' || (userData.role !== 'Admin' && (selectedMultiTeamValues.length > 0 || selectedMultiAccountsTypeValues.length > 0 || selectedMultiEmployeeValues.length > 0))) {

                setExportLoader(true);
                let updatedEmployeeValue = [...selectedMultiEmployeeValues];
                if (userData && userData.role === 'Employee') {
                    updatedEmployeeValue = [userData._id];
                } else if (userData && userData.role === 'Manager' && selectedMultiEmployeeValues.length === 0 && employeeOptions.length > 0) {
                    updatedEmployeeValue = employeeOptions.map(emp => emp.value);
                }
                const response = await Axios.post(
                    `/admin/utilizationExport`, {
                    team_ids: selectedMultiTeamValues,
                    account_type: selectedMultiAccountsTypeValues,
                    employee_id: updatedEmployeeValue,
                    year: selectedYear,
                    month: selectedMonth,
                });

                const newData = response.data.data?.map((row) => {
                    const totalDuration = getTotalDuration(row);
                    let totalDurationArray = totalDuration.split(":");
                    let durationMinutes = ((totalDurationArray[1] / 60) * 100).toString().padStart(2, "0");

                    const fromDate = moment.tz(row.from, 'America/New_York');
                    const year = fromDate.year();
                    const month = fromDate.month() + 1;

                    const totalWorkingDaysInMonth = getWorkingDaysInMonth(year, month, 'America/New_York');
                    const workingHoursPerDay = 8;
                    const totalWorkingHoursInMonth = totalWorkingDaysInMonth * workingHoursPerDay;

                    const percentageOfScheduledHours = (row.duration / totalWorkingHoursInMonth) * 100;

                    const totalHours = Number(totalDurationArray[0]);
                    const totalMinutes = Number(durationMinutes);

                    const totalActualDurationInHours = totalHours + totalMinutes / 100;
                    const percentageOfActualHours = (totalActualDurationInHours / totalWorkingHoursInMonth) * 100;

                    return {
                        employeeName: `${row?.user_id?.first_Name} ${row?.user_id?.last_Name}`,
                        teamName: `${row?.user_id?.team?.name}`,
                        actualUtilization: percentageOfActualHours ? parseFloat(percentageOfActualHours.toFixed(1)) : 0.0,
                        scheduledUtilization: percentageOfScheduledHours ? parseFloat(percentageOfScheduledHours.toFixed(1)) : 0.0,
                        targetUtilization: row.user_id?.utilization ? row.user_id.utilization.toFixed(1) : 0.0,
                        month: fromDate.format('MMM YYYY'),
                        user_timer: row.user_timers,
                        from: row.from,
                        user_id: row.user_id?._id
                    };
                }).filter(Boolean); // Remove null values from the resulting array;

                setExportLoader(false);
                return sortData(newData);
            } else {
                toast.error('Select at least one filter');
            }
        } catch (error) {
            setExportLoader(false);
            console.error(error);
        }
    };

    const sortData = (data) => {
        if (sortConfig.key !== null) {
            return [...data].sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                if (typeof aValue === 'string' && (sortConfig.key === 'employeeName' || sortConfig.key === 'teamName')) {
                    if (aValue.toLowerCase() < bValue.toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (aValue.toLowerCase() > bValue.toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                }

                if (parseFloat(aValue) < parseFloat(bValue)) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (parseFloat(aValue) > parseFloat(bValue)) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return data;
    };

    const durationToMinutes = (duration) => {
        const [hours, minutes] = duration.split(":").map(Number);
        return hours * 60 + minutes;
    };

    const getTotalDuration = (row) => {
        const rowMonth = moment(row.from).tz('America/New_York').toDate().getMonth() + 1;
        const rowYear = moment(row.from).tz('America/New_York').toDate().getFullYear();

        const totalMinutes = row?.user_timers
            ?.filter((timer) => {
                const timerMonth = moment(timer.date).tz('America/New_York').add(1, 'day').month() + 1;
                const timerYear = moment(timer.date).tz('America/New_York').add(1, 'day').year();
                return timerMonth === rowMonth && timerYear === rowYear;
            })
            .reduce((total, timer) => total + durationToMinutes(timer.duration), 0);

        return `${Math.floor(totalMinutes / 60)}:${(totalMinutes % 60)
            .toString()
            .padStart(2, "0")}`;
    };

    function getWorkingDaysInMonth(year, month) {
        const firstDay = new Date(year, month - 1, 1);
        const lastDay = new Date(year, month, 0);

        let workingDays = 0;

        for (let currentDay = firstDay; currentDay <= lastDay; currentDay.setDate(currentDay.getDate() + 1)) {
            const dayOfWeek = currentDay.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                workingDays++;
            }
        }

        return workingDays;
    }

    const exportToExcel = async () => {
        const sortedData = await fetchData();
        const excelData = [];

        excelData.push([
            'Employee',
            'Team',
            'Actual Utilization %',
            'Scheduled Utilization %',
            'Target Utilization %',
            'Month',
             'Year',
            'Total Available Hours',
            'Scheduled Hours',
            'Target Hours',
            'Actual Hours'
        ]);

        sortedData?.forEach((row) => {
            let actualUtilization = row.actualUtilization / 100;
            let scheduledUtilization = row.scheduledUtilization / 100;
            let targetUtilization = parseFloat(row.targetUtilization) / 100;
            let year = parseInt(row.month?.split(' ')[1]);
            let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(row.month?.split(' ')[0]) + 1;
            let totalAvailableHours = getWorkingDaysInMonth(year, month) * 8;
            let scheduledHours = parseFloat((totalAvailableHours * scheduledUtilization).toFixed(2));
            let targetHours = parseFloat((totalAvailableHours * targetUtilization).toFixed(2));
            let actualHours = parseFloat((totalAvailableHours * actualUtilization).toFixed(2));
            excelData.push([
                `${row.employeeName}`,
                `${row.teamName}`,
                actualUtilization,
                scheduledUtilization,
                targetUtilization,
                `${new Date(row.month).getMonth()+1}`,
                new Date(row.month).getFullYear(),
                totalAvailableHours,
                scheduledHours,
                targetHours,
                actualHours
            ]);
        });

        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

        let expFileName = 'utilization data';
        if (selectedMonth.length > 0) {
            let lastMonthKey = selectedMonth.length - 1;
            expFileName = selectedMonth[0] + ' to ' + selectedMonth[lastMonthKey];
        }

        XLSX.writeFile(wb, expFileName + '.xlsx');
    }

    useEffect(() => {
        const handleExport = async () => {
            if (isExportClicked) {
                setShowPopup(false);
                setData([]);
                if (areFiltersFetched && employeeFetched) {
                    await exportToExcel();
                }
                setisExportClicked(false);
                
            }
        };
        handleExport();
    }, [isExportClicked]);

    return (
        <></>
    );
}

export default FullDataExportCSV;
