import React, { useState } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';
import styled from 'styled-components';
import Axios from '../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';

const EditableCell = ({
  initialValue,
  onSave,
  checkmonth,
  brand_id,
  month,
  year,
  setLoading,
  setRefreshPage,
  switchValue,
  refreshPage,
}) => {
  // console.log(brand_id);
  // console.log(month);
  // console.log(year);
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const currentYear = new Date().getFullYear();

  const user = useSelector((state) => state?.user?.user); // Access the user data from the redux store

  const handleEdit = () => {
    if (user.role == 'Lead' && switchValue == 'budget') {
      return true;
    }
    if (user.role == 'Lead' && !checkmonth) {
      return true;
    }
    if (value == '0') {
      setValue('');
    }
    setIsEditing(true);
  };

  const handleSave = async () => {
    setLoading(true);
    // Check if the value is empty or not a string
    if (typeof value !== 'string' || value.trim() === '') {
      // setIsEditing(false);
      setLoading(false);
      return;
    }

    // Parse the value string to extract numeric values
    const pastedValues = value.split(';').map((item) => Number(item.trim()));
    console.log(pastedValues);

    if (pastedValues.length > 0) {
      const forecastData = {
        year: year,
        [month]: pastedValues[0],
      };

      const months = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ];

      // Start from the index of the provided month and set the forecast data accordingly
      let index = months.indexOf(month);
      for (let i = 1; i < pastedValues.length; i++) {
        index++;
        if (index >= 12) {
          break; // Prevent index overflow
        }
        forecastData[months[index]] = pastedValues[i];
      }

      console.log(forecastData);

      // Prepare the data to be sent in the PUT request

      try {
        if (switchValue == 'forecast') {
          var apiCall = 'addForecast';
          var data = {
            brandId: brand_id,
            forecastData: forecastData,
          };
        } else {
          var apiCall = 'addBudget';
          var data = {
            brandId: brand_id,
            budgetData: forecastData,
          };
        }
        // Send the PUT request to the API endpoint
        Axios.put(`/admin/${apiCall}`, data).then((response) => {
          console.log(response.message);
          toast.success(response?.data?.message);
          setIsEditing(false);
          setRefreshPage(!refreshPage);
          setLoading(false);
        });
      } catch (error) {
        // Handle error
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An error occurred.');
        }
        setLoading(false);
        console.error(error);
      }

      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setValue(initialValue);
    setIsEditing(false);
  };

  const handleChange = (event) => {
    // Get the input value and remove any non-numeric characters except commas
    const inputValue = event.target.value.replace(/[^0-9;-]/g, '');
    setValue(inputValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text').toString();

    // const pastedArray=pastedText.split("\n");
    // pastedArray.forEach((item)=>{
    //  const item1= item.replace(",","")
    //   console.log(item1)
    // })

    // Split the pasted text into an array based on different delimiters
    const pastedValues = pastedText.split(/\t|\r\n|\n|\r/);

    // Convert the pasted values to numbers
    const numericValues = pastedValues.map((value) => Number(value.replace(/[$,]/g, '')));
    if (numericValues.includes(NaN)) {
      toast.error('Please enter valid value');
      return true;
    } else {
      setValue(numericValues.join(';'));
    }

    // console.log(numericValues);
  };

  return (
    <TableCell>
      {isEditing ? (
        <EditableCellWrapper>
          <span className='dollar'>$</span>
          <input
            className='editableInput'
            type='text'
            value={value}
            onChange={handleChange}
            onPaste={handlePaste}
            maxLength='12'
          />
          <Buttons>
            <SaveButton onClick={handleSave}>
              <MdCheck />
            </SaveButton>
            <CancelButton onClick={handleCancel}>
              <MdClose />
            </CancelButton>
          </Buttons>
        </EditableCellWrapper>
      ) : (
        <EditableCellWrapper shouldApplyBackground={!checkmonth || year < currentYear} onClick={handleEdit}>
          <span className='dollar'>$</span>
          <EditButton>
            <span>{value}</span>
          </EditButton>
        </EditableCellWrapper>
      )}
    </TableCell>
  );
};

export default EditableCell;

const TableCell = styled.td`
  padding: 8px;
`;

const EditableCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  background-color: ${(props) => (props.shouldApplyBackground ? '#E9E9E9' : 'transparent')};
  cursor: pointer;
  /* justify-content: center; */
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute !important;
  right: 0 !important;
  background: rgba(0, 0, 0, 0.9);
`;

const Button = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const SaveButton = styled(Button)`
  color: green;
  font-size: 19px;
`;

const CancelButton = styled(Button)`
  color: red;
  font-size: 19px;
`;

const EditButton = styled(Button)`
  text-decoration: none;
`;
