import React, { useState, useEffect } from 'react';
import axios from '../../../utils/api';
import Loader from '../../../components/LoaderWave';

import Styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineFileDownload } from 'react-icons/md';
import { IoMdShareAlt } from 'react-icons/io';
import ReactSelect from 'react-select';
import ChartRow1 from './ChartRow1'
import ChartRow2 from './ChartRow2'
import ChartRow3 from './ChartRow3';

const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

// Custom component to render checkboxes for Accounts
const CustomCheckboxOption = (props) => {
  // console.log('CustomCheckboxOption props', props);
  return (<div className='custom-option'>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
        id={`account-checkbox-${props.data.value}`}
      />
      <label className='checkbox-label' htmlFor={`account-checkbox-${props.data.value}`}>
        {props.label}
      </label>
    </div>
    {props.data.brands && props.isSelected && (

      <div className='brand-checkboxes'>
        {props.data.brands.map((brand) => (
          <div key={brand._id} className='checkbox-container'>
            <input
              type='checkbox'
              checked={brand.isSelected}
              onChange={() => props.selectBrandOption(brand)}
              id={`brand-checkbox-${brand._id}`}
            />
            <label className='checkbox-label' htmlFor={`brand-checkbox-${brand._id}`}>
              {brand.brand_name}
            </label>
          </div>
        ))}
      </div>
    )}
  </div>
  )
}

function Dashboard() {
  const [runAPI, setRunAPI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [accountsByYear, setAccountsByYear] = useState([]);
  const [forecastSumByYear, setforecastSumByYear] = useState();
  const [actualforecastSumByYear, setactualforecastSumByYear] = useState();
  const [budgetSumByYear, setbudgetSumByYear] = useState();
  const [YoYData, setYoYData] = useState({});
  const [ClientHours, setClientHours] = useState({});
  const [quaterlyChartdata, setQuaterlyChartdata] = useState([]);
  const [monthlyMarginData, setmonthlyMarginData] = useState([]);
  const [quaterlyMarginData, setquaterlyMarginData] = useState([]);
  const [revenueOverviewData, setRevenueOverviewData] = useState({});
  const [revenueYoYData, setRevenueYoYData] = useState([]);
  const [showComponent, setshowComponent] = useState(false);

  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedMultiAccountsOptions, setSelectedMultiAccountsOptions] = useState([]);
  const [selectedMultiAccountsValues, setSelectedMultiAccountsValues] = useState([]);
  const [selectedYear, setSelectedYear] = useState({ value: new Date().getFullYear(), label: new Date().getFullYear() });
  const [TeamOptions, setTeamOptions] = useState([]);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [PodOption, setPodOption] = useState([]);
  const [selectedMultiPodOptions, setSelectedMultiPodOptions] = useState([]);
  const [selectedMultiPodValues, setselectedMultiPodValues] = useState([]);
  const [selectedBrandData, setSelectedBrandData] = useState([]);

  const handleMultiSelectAccountsChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiAccountsOptions(selectedOptions);
    setSelectedMultiAccountsValues(selectedValues);
    console.log('selectedMultiAccountsValues', selectedMultiAccountsValues);
  };

  const handleMultiSelectBrandChange = (selectedBrand) => {
    const brandIndex = selectedBrandData.findIndex((brand) => brand.id === selectedBrand._id);

    if (brandIndex === -1) {
      // If not in selectedBrandData, add it
      setSelectedBrandData([...selectedBrandData, { id: selectedBrand._id, name: selectedBrand.brand_name }]);
    } else {
      // If already in selectedBrandData, remove it
      const updatedBrandData = [...selectedBrandData];
      updatedBrandData.splice(brandIndex, 1);
      setSelectedBrandData(updatedBrandData);
    }
  };

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
    console.log('selectedMultiTeamValues', selectedMultiTeamValues);
  };

  const handleMultiSelectPodChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiPodOptions(selectedOptions);
    setselectedMultiPodValues(selectedValues);
    console.log('selectedMultiPodValues', selectedMultiTeamValues);
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
  };

  const getQuaterlyRevenueChartData = (data) => {
    let forcastSum = 0;
    let budgetSum = 0;
    let quaterlyData = [];
    let count = 1;
    data.forEach((item, index) => {
      forcastSum += item.forecast;
      budgetSum += item.budget;

      if ((index + 1) % 3 === 0) {
        quaterlyData.push({
          month: `Q${count}`,
          forecast: forcastSum,
          budget: budgetSum
        })

        forcastSum = 0;
        budgetSum = 0;
        count++;
      }
    });
    setQuaterlyChartdata(quaterlyData);
  }

  const getQuaterlyMonthlyMarginChartData = (Revenue, Expense) => {
    let revenueSum = 0;
    let expenseSum = 0;
    let quaterlyData = [];
    let count = 1;
    Revenue.forEach((revenue, index) => {
      revenueSum += revenue;
      expenseSum += Expense[index];

      if ((index + 1) % 3 === 0) {
        quaterlyData.push({
          month: `Q${count}`,
          ContributionMargin: revenueSum ? Number((((revenueSum - expenseSum) / revenueSum) * 100).toFixed(1)) : 0,
          Profit: Number((revenueSum - expenseSum).toFixed(1))
        })

        revenueSum = 0;
        expenseSum = 0;
        count++;
      }
    });
    console.log('quayer', quaterlyData);
    setquaterlyMarginData(quaterlyData);
  }

  // Fetch the revenue data from the API
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/admin/getForecastandBudgetSUM', {
        budgetYear: selectedYear.value,
        forecastYear: selectedYear.value,
        team_ids: selectedMultiTeamValues,
        pod_ids: selectedMultiPodValues,
        account_id: selectedMultiAccountsValues,
        brand_id: selectedBrandData.map((item) => item.id)
      });
      const { months, forecast, budget } = response.data;

      // Create the chart data array
      const data = months.map((month, index) => ({
        month,
        forecast: forecast[index],
        budget: budget[index],
      }));
      getQuaterlyRevenueChartData(data);

      setChartData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setLoading(false);
    }
  };

  const fetchMonthlyData = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/admin/getMonthlyMarginByYear', {
        year: selectedYear.value,
        team_ids: selectedMultiTeamValues,
        pod_ids: selectedMultiPodValues,
        account_id: selectedMultiAccountsValues,
        brand_id: selectedBrandData.map((item) => item.id)
      });
      const { monthlyProfitData, Revenue, Expense } = response.data;
      getQuaterlyMonthlyMarginChartData(Revenue, Expense);
      setmonthlyMarginData(monthlyProfitData);
      setLoading(false);
      setRunAPI(false)
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setLoading(false);
      setRunAPI(false)
    }
  }

  // Fetch the revenue data from the API
  const fetchAccountsByYear = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/admin/getTotalAccountsByYear', {
        year: selectedYear.value,
        team_ids: selectedMultiTeamValues,
        pod_ids: selectedMultiPodValues,
        account_id: selectedMultiAccountsValues
      });
      // console.log('dd',response.data);
      const { totalAccountCount, accountdAddedThisMonthCount } = response.data;

      setAccountsByYear(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setLoading(false);
    }
  };

  // Fetch the fetchForecastSumByYear data from the API
  const fetchForecastSumByYear = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/admin/getAllForecastSumByYear', {
        year: selectedYear.value,
        team_ids: selectedMultiTeamValues,
        pod_ids: selectedMultiPodValues,
        account_id: selectedMultiAccountsValues,
        brand_id: selectedBrandData.map((item) => item.id)
      });
      // console.log('dd',response.data);
      const { forecastSumByYear, actualForecastSum, budgetSumByYear, revenueOverviewData } = response.data;
      // console.log('forecastSumByYear',forecastSumByYear);

      setforecastSumByYear(forecastSumByYear);
      // setactualforecastSumByYear(actualForecastSum);
      setbudgetSumByYear(budgetSumByYear);
      setRevenueOverviewData(revenueOverviewData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setLoading(false);
    }
  };

  // Fetch the fetchForecastSumByYear data from the API
  const fetchBudgetSumByYear = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/admin/getAllBudgetSumByYear', {
        year: selectedYear.value,
      });
      // console.log('dd',response.data);
      const { budgetSumByYear } = response.data;
      // console.log('forecastSumByYear',forecastSumByYear);

      setbudgetSumByYear(budgetSumByYear);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setLoading(false);
    }
  };

  // Fetch the fetchForecastSumByYear data from the API
  const fetchForecastYoY = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/admin/getAllRevenueYoY', {
        year: selectedYear.value,
        team_ids: selectedMultiTeamValues,
        pod_ids: selectedMultiPodValues,
        account_id: selectedMultiAccountsValues,
        brand_id: selectedBrandData.map((item) => item.id)
      });

      const { forecastSumByCurrentYear, forecastSumByPreviousYear, diff, revenueYoYData } = response.data;

      setYoYData({ forecastSumByCurrentYear, forecastSumByPreviousYear, diff });
      setRevenueYoYData(revenueYoYData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setLoading(false);
    }
  };

  const fetchClientHourByYear = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/admin/getTotalClientHoursByYear', {
        year: selectedYear.value,
        team_ids: selectedMultiTeamValues,
        pod_ids: selectedMultiPodValues,
        account_id: selectedMultiAccountsValues,
        brand_id: selectedBrandData.map((item) => item.id)
      });

      const { totalScheduledHours, totalClientHours, diff } = response.data;

      setClientHours({ totalScheduledHours, totalClientHours, diff });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedYear && runAPI) {
      fetchData();
      fetchAccountsByYear();
      fetchForecastSumByYear();
      // fetchBudgetSumByYear();
      fetchForecastYoY();
      fetchClientHourByYear();
      fetchMonthlyData();
      setshowComponent(true);
    }
  }, [selectedYear, runAPI, refreshPage, selectedMultiPodOptions, selectedMultiTeamValues, selectedBrandData]);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 3 }, (_, index) => ({
    value: currentYear + index,
    label: currentYear + index,
  }));

  useEffect(() => {
    setYearOptions(years);
  }, []);

  useEffect(() => {
    axios
      .post('/admin/accounts?page=&limit=', {
        account_id: '',
        team_ids: '',
        SelectedDateFormattedData: '',
      })
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        const modifyData = response.data.data.map((item) => {
          for (let i = 0; i < item.brands.length; i++) {
            item.brands[i].isChecked = false
          }
          return item
        })
        setAccountOptions(
          modifyData
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/teams_wopaging')
      .then((response) => {
        setTeamOptions(
          response?.data?.data.map((item) => ({ value: item?._id, label: item?.name })),
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/pods_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response?.data?.data);
        setPodOption(response?.data?.data.map((item) => ({ value: item?._id, label: item?.name })));
      })
      .catch((error) => {
        console.log(error);
      });

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 3 }, (_, index) => ({
      value: currentYear + index,
      label: currentYear + index,
    }));
    setYearOptions(years);
    // setSelectedYear({ value: currentYear, label: currentYear });

  }, []);

  useEffect(() => {
    const updatedAccountOptions = accountOptions.map((account) => ({
      ...account,
      brands: account.brands.map((brand) => ({
        ...brand,
        isSelected: selectedBrandData.some((selectedBrand) => selectedBrand.id === brand._id),
      })),
    }));

    setAccountOptions(updatedAccountOptions);
  }, [selectedBrandData]);

  useEffect(() => {
    if (selectedMultiAccountsValues.length <= 0) {
      if (selectedBrandData.length > 0) {
        setSelectedBrandData([]);
      }
    } else {
      const deselectedAccountIds = accountOptions.filter((account) => !selectedMultiAccountsValues.includes(account._id));
      let allDeselectedBrands = [];
      deselectedAccountIds.forEach(account => {
        account?.brands.forEach(brand => {
          allDeselectedBrands.push(brand._id);
        });
      });
      setSelectedBrandData((prev) => prev.filter((brand) => !allDeselectedBrands.includes(brand.id)));
    }
  }, [selectedMultiAccountsValues]);


  return (
    <Wrapper>
      {loading && <Loader />}

      <div className='HistoryOfRequest'>
        <section className='bg'>
          <div className='row flex-nowrap'>
            <div className='col py-3 px-3 content-area'>
              <div className='row-1'>
                <div className='filter-right-section'>
                  <div className='row'>
                    <div className='col-lg-3 col-md-6 col-xs-12 px-2'>
                      <div className='multi-select-container'>
                        <label>
                          Account Name
                          <ReactSelect
                            id='multiSelectAccounts'
                            isMulti
                            options={accountOptions.map((account) => ({
                              value: account._id,
                              label: account.name,
                              brands: account.brands, // Include the brands as part of the option
                            }))}
                            value={selectedMultiAccountsOptions}
                            onChange={handleMultiSelectAccountsChange}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            filterOption={(option, inputValue) => {
                              const lowercaseInput = inputValue.toLowerCase();

                              // Check if the account label matches the input value
                              const accountLabel = option?.label?.toLowerCase();
                              if (accountLabel?.includes(lowercaseInput)) {
                                return true;
                              }

                              // Check if any of the brand names within this account match the input value
                              const brandLabels = option?.data?.brands?.map((brand) => brand.brand_name.toLowerCase());
                              return brandLabels?.some((label) => label?.includes(lowercaseInput));
                            }}
                            components={{
                              Option: (props) => <CustomCheckboxOption {...props} selectBrandOption={handleMultiSelectBrandChange} />,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-xs-12 px-2'>
                      <div className='multi-select-container'>
                        <label>
                          Team
                          <ReactSelect
                            id='multiSelectTeam'
                            isMulti
                            options={TeamOptions}
                            value={selectedMultiTeamOptions}
                            onChange={handleMultiSelectTeamChange}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option: CheckboxOption }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-xs-12 px-2'>
                      <div className='multi-select-container'>
                        <label>
                          Pods
                          <ReactSelect
                            id='multiSelectPod'
                            isMulti
                            options={PodOption}
                            value={selectedMultiPodOptions}
                            onChange={handleMultiSelectPodChange}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option: CheckboxOption }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-xs-12 px-2'>
                      <div className='year-select-container'>
                        <label>
                          Year
                          <ReactSelect
                            id='yearSelect'
                            options={yearOptions}
                            value={selectedYear}
                            onChange={handleYearChange}
                          />
                        </label>
                      </div>
                    </div>

                  </div>
                </div>

                <div>
                  {/* <div>Showing data for year, {selectedYear?.value}</div> */}
                  <div>
                    {loading ? (<div className='btn-container btn-run-container'>
                      <input type='button' value='Running...' disabled={loading} />
                    </div>) : (<div className='btn-container btn-run-container' onClick={() => setRunAPI(!runAPI)}>
                      <input type='button' value='Run' />
                    </div>)}
                  </div>
                </div>


              </div>
              {/* chart component */}
                <ChartRow1 selectedYear={selectedYear} revenueYoYData={revenueYoYData} revenueOverviewData={revenueOverviewData} accountsByYear={accountsByYear} forecastSumByYear={forecastSumByYear} actualForecastSum={actualforecastSumByYear} budgetSumByYear={budgetSumByYear} YoYData={YoYData} ClientHours={ClientHours} />
              

              
                <ChartRow2
                  budgetYear={selectedYear}
                  forecastYear={selectedYear} quaterlyData={quaterlyChartdata} quaterlyMarginData={quaterlyMarginData} />
              

              
                <ChartRow3
                  data={chartData}
                  budgetYear={selectedYear}
                  forecastYear={selectedYear}
                  setRefreshPage={setRefreshPage}
                  refreshPage={refreshPage}
                  monthlyMarginData={monthlyMarginData}
                />
          

            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

export default Dashboard;

const Wrapper = Styled.div`
    
    .bg{
      background: #e9eaed;
      /* padding: 20px; */
    }
    .bg .row{
    --bs-gutter-x: 0px !important;
    }
    .row-1{
display: flex;
padding: 15px;
justify-content: space-between;
align-items: center;
border-radius: 16px;
background: var(--white, #FFF); 
    }
    .row-1-title{
color: var(--black-text, #201E20);
font-size: 24px;
    }

    .filter-right-section{
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  width: 200px!important;
  border: 1px solid #636263;
  border-radius: 8px;
  max-height: 2rem !important;
  overflow-y: auto;
}
.react-icon {
    height: 21px;
    width: 21px;
  }
 
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}
.custom-option {
  display: flex;
  flex-direction: column;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}

.brand-checkboxes {
  margin-left: 20px; /* Indent the brand checkboxes */
  .checkbox-container{
    input[type="checkbox"]{
      width: 12px;
      height: 12px;
      cursor: pointer;
    }

    .checkbox-label{
      // font-weight: bold;
      font-size: 14px;
      color: #333;
    }
  }
}

@media screen and (max-width: 700px) {
.year-info{
display:none;
}
}

.btn-run-container input[type="button"] {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid rgb(41, 132, 253);
    background: rgb(41, 132, 253);
    border-radius: 8px;
    padding: 4px;
    min-width: 50px;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
`;