import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import SessionTimeoutDialog from "./SessionTimeoutDialog"
import { useSelector } from 'react-redux';

const timeout = 28_800_000
const promptBeforeIdle = 10_000

export default function App({logout}) {
  const [state, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const [showSessionTimeoutModal,setShowSessionTimeoutModal]=useState(false)

  const user = useSelector((state) => state?.user?.user);

  const onIdle = () => {
     logout()  }

  const onActive = () => {
    setShowSessionTimeoutModal(false)
    setState('Active')
  }

  const onAction = () => {
   
    console.log("abc")
    setCount(count + 1)
  }

  const onPrompt = () => {
    setState('Prompted')
    setShowSessionTimeoutModal(true)
  }

  const { getRemainingTime,activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

//   useEffect(()=>{
//    if(remaining<10){
//     setShowSessionTimeoutModal(true)
//    }
//   },[remaining])

  return (
    <>
     <SessionTimeoutDialog show={showSessionTimeoutModal} countdown={remaining} onLogout={logout} onContinue={activate} />
    </>
  )
}