import React, { useState, useEffect } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import Styled from 'styled-components';
import ForecastBudgetTable from './ForecastBudgetTable';
import ReactSelect from 'react-select';
import axios from '../../../utils/api';
import Loader from '../../../components/LoaderWave';
import * as XLSX from 'xlsx'; // Use * as XLSX to import all exports

const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

// Custom component to render checkboxes for Accounts
const CustomCheckboxOption = (props) => {
  // console.log('CustomCheckboxOption props', props);
  return (<div className='custom-option'>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
        id={`account-checkbox-${props.data.value}`}
      />
      <label className='checkbox-label' htmlFor={`account-checkbox-${props.data.value}`}>
        {props.label}
      </label>
    </div>
    {props.data.brands && props.isSelected && (

      <div className='brand-checkboxes'>
        {props.data.brands.map((brand) => (
          <div key={brand._id} className='checkbox-container'>
            <input
              type='checkbox'
              checked={brand.isSelected}
              onChange={() => props.selectBrandOption(brand)}
              id={`brand-checkbox-${brand._id}`}
            />
            <label className='checkbox-label' htmlFor={`brand-checkbox-${brand._id}`}>
              {brand.brand_name}
            </label>
          </div>
        ))}
      </div>
    )}
  </div>
  )
}

const ForecastBudget = ({ setRefreshPage, refreshPage }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [switchValue, setSwitchValue] = useState('forecast');
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedMultiAccountsOptions, setSelectedMultiAccountsOptions] = useState([]);
  const [selectedMultiAccountsValues, setSelectedMultiAccountsValues] = useState([]);
  const [selectedYear, setSelectedYear] = useState({ value: new Date().getFullYear() });
  const [yearOptions, setYearOptions] = useState([]);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [PodOption, setPodOption] = useState([]);
  const [selectedMultiPodOptions, setSelectedMultiPodOptions] = useState([]);
  const [selectedMultiPodValues, setselectedMultiPodValues] = useState([]);
  const [selectedBrandData, setSelectedBrandData] = useState([]);

  const handleSwitchChange = (e) => {
    setSwitchValue(e.target.value);
  };

  const handleMultiSelectAccountsChange = (selectedOptions) => {
    setSwitchValue('forecast')
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiAccountsOptions(selectedOptions);
    setSelectedMultiAccountsValues(selectedValues);
    console.log('selectedMultiAccountsValues', selectedMultiAccountsValues);
  };

  const handleMultiSelectBrandChange = (selectedBrand) => {
    setSwitchValue('forecast')
    const brandIndex = selectedBrandData.findIndex((brand) => brand.id === selectedBrand._id);

    if (brandIndex === -1) {
      // If not in selectedBrandData, add it
      setSelectedBrandData([...selectedBrandData, { id: selectedBrand._id, name: selectedBrand.brand_name }]);
    } else {
      // If already in selectedBrandData, remove it
      const updatedBrandData = [...selectedBrandData];
      updatedBrandData.splice(brandIndex, 1);
      setSelectedBrandData(updatedBrandData);
    }
  };

  const handleMultiSelectTeamChange = (selectedOptions) => {
    setSwitchValue('forecast')
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
    console.log('selectedMultiTeamValues', selectedMultiTeamValues);
  };

  const handleMultiSelectPodChange = (selectedOptions) => {
    setSwitchValue('forecast')
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiPodOptions(selectedOptions);
    setselectedMultiPodValues(selectedValues);
    console.log('selectedMultiPodValues', selectedMultiTeamValues);
  };

  const handleYearChange = (selectedOption) => {
    setSwitchValue('forecast')
    setSelectedYear(selectedOption);
  };

  const exportToExcel = () => {
    if (switchValue === 'budget') {
      var selectedRevenue = 'budget';
    } else {
      var selectedRevenue = 'forecast';
    }
    const filename = `${selectedRevenue}_${selectedYear?.value}_data.xlsx`;
    const ws_data = data?.flatMap((item) =>
      item?.brands?.map((brand) => ({
        Account: item.name,
        Brand: brand.brand_name,
        Jan: brand?.forecast?.jan || brand?.budget?.jan || 0,
        Feb: brand?.forecast?.feb || brand?.budget?.feb || 0,
        Mar: brand?.forecast?.mar || brand?.budget?.mar || 0,
        Apr: brand?.forecast?.apr || brand?.budget?.apr || 0,
        May: brand?.forecast?.may || brand?.budget?.may || 0,
        Jun: brand?.forecast?.jun || brand?.budget?.jun || 0,
        Jul: brand?.forecast?.jul || brand?.budget?.jul || 0,
        Aug: brand?.forecast?.aug || brand?.budget?.aug || 0,
        Sep: brand?.forecast?.sep || brand?.budget?.sep || 0,
        Oct: brand?.forecast?.oct || brand?.budget?.oct || 0,
        Nov: brand?.forecast?.nov || brand?.budget?.nov || 0,
        Dec: brand?.forecast?.dec || brand?.budget?.dec || 0,
      })),
    );
    const ws = XLSX.utils.json_to_sheet(ws_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${selectedRevenue}_${selectedYear?.value}_data`);
    XLSX.writeFile(wb, filename);
  };

  useEffect(() => {
    axios
      .post('/admin/accounts?page=&limit=', {
        account_id: '',
        team_ids: '',
        SelectedDateFormattedData: '',
      })
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        const modifyData = response.data.data.map((item) => {
          for (let i = 0; i < item.brands.length; i++) {
            item.brands[i].isChecked = false
          }
          return item
        })
        setAccountOptions(
          modifyData
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/teams_wopaging')
      .then((response) => {
        setTeamOptions(
          response?.data?.data.map((item) => ({ value: item?._id, label: item?.name })),
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/pods_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response?.data?.data);
        setPodOption(response?.data?.data.map((item) => ({ value: item?._id, label: item?.name })));
      })
      .catch((error) => {
        console.log(error);
      });

    const currentYear = new Date().getFullYear() + 3;
    const years = Array.from({ length: 15 }, (_, index) => ({
      value: currentYear - index,
      label: currentYear - index,
    }));
    setYearOptions(years);
    setSelectedYear({ value: new Date().getFullYear(), label: new Date().getFullYear() });
  }, []);
  // console.log(selectedYear);

  // console.log(switchValue);

  useEffect(() => {
    const updatedAccountOptions = accountOptions.map((account) => ({
      ...account,
      brands: account.brands.map((brand) => ({
        ...brand,
        isSelected: selectedBrandData.some((selectedBrand) => selectedBrand.id === brand._id),
      })),
    }));

    setAccountOptions(updatedAccountOptions);
  }, [selectedBrandData]);

  useEffect(() => {
    if(selectedMultiAccountsValues.length <= 0){
      setSelectedBrandData([]);
    }else{
      const deselectedAccountIds = accountOptions.filter((account) => !selectedMultiAccountsValues.includes(account._id));
      let allDeselectedBrands = [];
      deselectedAccountIds.forEach(account => {
        account?.brands.forEach(brand => {
          allDeselectedBrands.push(brand._id);
        });
      });
      setSelectedBrandData((prev) => prev.filter((brand) => !allDeselectedBrands.includes(brand.id)));
    }
  }, [selectedMultiAccountsValues]);

  return (
    <Wrapper>
      {loading && <Loader />}
      <div className='section'>
        <div className='filter-section'>
          <div className='filter-left-section'>
            <label>
              <input
                type='radio'
                value='forecast'
                checked={switchValue === 'forecast'}
                onChange={handleSwitchChange}
              />
              <span className={switchValue === 'forecast' ? 'active' : ''}>Forecast</span>
            </label>
            <label>
              <input
                type='radio'
                value='budget'
                checked={switchValue === 'budget'}
                onChange={handleSwitchChange}
              />
              <span className={switchValue === 'budget' ? 'active' : ''}>Budget</span>
            </label>
          </div>
          <div className='filter-right-section'>
            <div className='multi-select-container'>
              <label>
                Account Name
                <ReactSelect
                  id='multiSelectAccounts'
                  isMulti
                  options={accountOptions.map((account) => ({
                    value: account._id,
                    label: account.name,
                    brands: account.brands, // Include the brands as part of the option
                  }))}
                  value={selectedMultiAccountsOptions}
                  onChange={handleMultiSelectAccountsChange}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  filterOption={(option, inputValue) => {
                    const lowercaseInput = inputValue.toLowerCase();

                    // Check if the account label matches the input value
                    const accountLabel = option?.label?.toLowerCase();
                    if (accountLabel?.includes(lowercaseInput)) {
                      return true;
                    }

                    // Check if any of the brand names within this account match the input value
                    const brandLabels = option?.data?.brands?.map((brand) => brand.brand_name.toLowerCase());
                    return brandLabels?.some((label) => label?.includes(lowercaseInput));
                  }}
                  components={{
                    Option: (props) => <CustomCheckboxOption {...props} selectBrandOption={handleMultiSelectBrandChange} />,
                  }}
                />
              </label>
            </div>
            <div className='multi-select-container'>
              <label>
                Pods
                <ReactSelect
                  id='multiSelectPod'
                  isMulti
                  options={PodOption}
                  value={selectedMultiPodOptions}
                  onChange={handleMultiSelectPodChange}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option: CheckboxOption }}
                />
              </label>
            </div>
            <div className='multi-select-container'>
              <label>
                Team
                <ReactSelect
                  id='multiSelectTeam'
                  isMulti
                  options={TeamOptions}
                  value={selectedMultiTeamOptions}
                  onChange={handleMultiSelectTeamChange}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option: CheckboxOption }}
                />
              </label>
            </div>

            <div className='year-select-container'>
              <label>
                Year
                <ReactSelect
                  id='yearSelect'
                  options={yearOptions}
                  value={selectedYear}
                  onChange={handleYearChange}
                />
              </label>
            </div>
            <div className='btn-container'>
              <div className='icon-container'>
                <MdOutlineFileDownload className='react-icon' />
              </div>
              <input type='button' value='Export' onClick={exportToExcel} />
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <ForecastBudgetTable
          selectedYear={selectedYear}
          setLoading={setLoading}
          selectedMultiTeamValues={selectedMultiTeamValues}
          selectedMultiAccountsValues={selectedMultiAccountsValues}
          selectedMultiPodValues={selectedMultiPodValues}
          selectedBrandData={selectedBrandData}
          switchValue={switchValue}
          setRefreshPage={setRefreshPage}
          refreshPage={refreshPage}
          data={data}
          setData={setData}
        />
      </div>
      <div style={{ height: 1 }}></div>
    </Wrapper>
  );
};

export default ForecastBudget;

const Wrapper = Styled.div`
  .section {
    border-radius: 16px;
    background: #FFF;
    margin: 20px 20px 20px 20px;
  }
  
  .filter-section {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  
  .filter-left-section {
    display: flex;
    padding: 4px 2px;
justify-content: center;
align-items: center;
border-radius: 36px;
border: 1px solid var(--blue-primary, #2984FD);
background: #FFF;
  }
  .filter-left-section .active {
    background-color: #2984FD;
    color: #FFF;
  }
  
  .filter-left-section input[type="radio"] {
    display: none;
  }
  
  .filter-left-section span {
    padding: 5px 10px;
    border-radius: 36px;    
    cursor: pointer;
  }
  
  .filter-left-section span:hover {
    background-color: #176ab5;
  }
  
  .btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 24px;

  }
  
  .btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 5px;
  }
  
  .react-icon {
    height: 22px;
    width: 22px;
  }

  .checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}

.custom-option {
  display: flex;
  flex-direction: column;
}

.brand-checkboxes {
  margin-left: 20px; /* Indent the brand checkboxes */
  .checkbox-container{
    input[type="checkbox"]{
      width: 12px;
      height: 12px;
      cursor: pointer;
    }

    .checkbox-label{
      // font-weight: bold;
      font-size: 14px;
      color: #333;
    }
  }
}

.filter-right-section{
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 2;
    flex-wrap: wrap;
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  width: 160px!important;
  border: 1px solid #636263;
border-radius: 8px;
max-height: 2rem !important;
  overflow: auto !important;
}

.table-responsive{
  height:auto;

  table {
    thead{
     z-index:1;
      
    }
    tbody{
      overflow-y: auto;
    }
  }
}
`;
