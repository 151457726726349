import { configureStore } from '@reduxjs/toolkit';
import timerReducer from './slices/timerSlice';
import sessionReducer from './slices/sessionSlice';
import appInfoReducer from './slices/appInfoSlice';
import userReducer from './slices/userSlice';
import notificationReducer from './slices/notificationsSlice';
export const store = configureStore({
  reducer: {
    timer: timerReducer,
    appInfo: appInfoReducer,
    user: userReducer,
    notifications: notificationReducer,
    session:sessionReducer
  },
});
