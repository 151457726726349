import TokenServices from '../../utils/token.services';
import { createSlice } from '@reduxjs/toolkit';
import * as types from '../actions/types';
// const initialState = {
//   user: JSON.parse(localStorage?.getItem('user')) || {},
//   token: JSON.parse(localStorage?.getItem('token')) || '',
// };
const initialState = {
  user:TokenServices.getUser() ||{},
  token: TokenServices.getAccessToken() || '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateHeaderTitle: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.headerTitle = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    clearUserAndToken: (state, action) => {
      state.user = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateHeaderTitle, updateUser, updateToken, clearUserAndToken } = userSlice.actions;

export default userSlice.reducer;
