import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import axios from '../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { DatePicker } from "antd";

const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

function SheduleShareModal({ routePath, selectedMultiEmployeeValues, show, setShow, refreshPage, setRefreshPage, prevModalShow }) {
console.log(routePath,'2');

  const [errors, setErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState('Day');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedWeekDay, setSelectedWeekDay] = useState([]);

  const [selectedTimeHour, setSelectedTimeHour] = useState(null);
  const [selectedTimeMinute, setSelectedTimeMinute] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedOption('Day');
    setSelectedDate(null);
    setSelectedWeekDay([]);
    setSelectedTimeHour(null);
    setSelectedTimeMinute(null);
    setSelectedPeriod(null);
    setSelectedDateRange(null)
    setErrors({});
  }

  const handleWeekDayChange = (value) => {
    setSelectedWeekDay(value);
  };

  const handleTimeHourChange = (value) => {
    setSelectedTimeHour(value);
  };

  const handleTimeMinuteChange = (value) => {
    setSelectedTimeMinute(value);
  };

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
  };

  const handleShedule = async (e) => {
    e.preventDefault();
    const errors = {};
    if (selectedOption == 'Week' && selectedWeekDay.length == 0) {
      errors.day = 'Please select the Day';
    }
    if (selectedOption == 'Month' && selectedDate == null) {
      errors.date = 'Please select the Date';
    }
    if (selectedTimeHour == null) {
      errors.hour = 'Please select the Hour';
    }
    if (selectedTimeMinute == null) {
      errors.minute = 'Please select the Minute';
    }
    if (selectedPeriod == null) {
      errors.period = 'Please select the Period';
    }
    if (selectedDateRange == null) {
      errors.dateRange = 'Please select the Date Range';
    }
    setErrors(errors);

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }
    // Prepare data to be sent in the request
    const requestData = {
      selectedOption,
      selectedWeekDay,
      selectedDate,
      selectedTimeHour,
      selectedTimeMinute,
      selectedPeriod,
      selectedDateRange,
      selectedMultiEmployeeValues,
      routePath
    };
    try {
      const response = await axios.post('/admin/submitShareSchedule', requestData);
      console.log(response.data);

      toast.success(response.data.message);
      handleClose();
      prevModalShow(false)
    } catch (error) {
      console.error('Error submitting data:', error);
      // Show error message if needed
      toast.error(error.response.data.message);
      // toast.error('Failed to submit data. Please try again later.');
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Wrapper>
          <Modal.Header closeButton>
            <Modal.Title>Shedule Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='input-container d-flex align-items-end w-100'>
                <div className='me-2 mb-2'>Every</div>
                <div className='w-100'>
                  <Select
                    defaultValue={{ value: 'Day', label: 'Day' }}
                    value={{ value: selectedOption, label: selectedOption }}
                    onChange={(option) => setSelectedOption(option.value)}
                    options={[
                      { value: 'Day', label: 'Day' },
                      { value: 'Week', label: 'Week' },
                      { value: 'Month', label: 'Month' },
                    ]}
                  />
                </div>
                {selectedOption !== 'Day' && (
                  <>
                    <div className='mx-2 mb-2'>on</div>
                    <div className='w-100'>
                      {selectedOption === 'Week' ? (
                        <>
                          <Select
                            className={`shadow-none ${errors.day ? 'is-invalid' : ''}`}
                            id='weekDays' // Make sure this id matches the htmlFor attribute of the label
                            defaultValue={selectedWeekDay.map((day) => ({ value: day, label: day }))}
                            value={selectedWeekDay.map((day) => ({ value: day, label: day }))}
                            onChange={(selectedOptions) => setSelectedWeekDay(selectedOptions.map((option) => option.value))}
                            options={[
                              { value: 'Monday', label: 'Monday' },
                              { value: 'Tuesday', label: 'Tuesday' },
                              { value: 'Wednesday', label: 'Wednesday' },
                              { value: 'Thursday', label: 'Thursday' },
                              { value: 'Friday', label: 'Friday' },
                              { value: 'Saturday', label: 'Saturday' },
                              { value: 'Sunday', label: 'Sunday' },
                            ]}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            components={{ Option: CheckboxOption }}
                          />
                          {errors.day && <div className='invalid-feedback'>{errors.day}</div>}
                        </>
                      ) : (
                        <div className='w-100'>
                          <Select
                            className={`shadow-none ${errors.date ? 'is-invalid' : ''}`}
                            id='selectedDate' // Make sure this id matches the htmlFor attribute of the label
                            defaultValue=''
                            value={{ value: selectedDate, label: selectedDate }}
                            // onChange={handleDateChange}
                            onChange={(option) => { setSelectedDate(option.value) }}
                            options={[...Array(30).keys()].map((day) => ({ value: day + 1, label: day + 1 }))}
                          />
                          {errors.date && <div className='invalid-feedback'>{errors.date}</div>}
                        </div>
                      )}
                      {/* <div id="date-popup" /> */}
                    </div>
                  </>
                )}
              </div>

              <div className='input-container d-flex align-items-end'>
                <div className='me-2 my-2 mt-4'>Time</div>
                <div className='d-flex'>
                  <Select
                    className={`shadow-none ${errors.hour ? 'is-invalid' : ''}`}
                    defaultValue='' value={selectedTimeHour} onChange={handleTimeHourChange}
                    options={[...Array(12).keys()].map((hour) => ({ value: hour.toString().padStart(2, '0'), label: hour.toString().padStart(2, '0') }))}
                  />
                  {errors.hour && <div className='invalid-feedback hour'>{errors.hour}</div>}
                  <span className='mx-2 my-1'>:</span>
                  <Select
                    className={`shadow-none ${errors.minute ? 'is-invalid' : ''}`}
                    defaultValue='' value={selectedTimeMinute} onChange={handleTimeMinuteChange}
                    options={[0, 15, 30, 45,].map((minute) => ({ value: minute.toString().padStart(2, '0'), label: minute.toString().padStart(2, '0') }))}
                  />
                  {errors.minute && <div className='invalid-feedback minute'>{errors.minute}</div>}
                  <span className='mx-2 my-1'>-</span>
                  <Select
                    className={`shadow-none ${errors.period ? 'is-invalid' : ''}`}
                    defaultValue='' value={selectedPeriod} onChange={handlePeriodChange}
                    options={[{ value: 'AM', label: 'AM' }, { value: 'PM', label: 'PM' },]} />
                  {errors.period && <div className='invalid-feedback period'>{errors.period}</div>}
                </div>
              </div>

              <div className='input-container d-flex align-items-end'>
                <div className='me-2 my-2 mt-4'>Select Date Range</div>
                <div className='d-flex'>
                  <div id="date-popup" />
                  <DatePicker.RangePicker
                    className={`shadow-none ${errors.dateRange ? 'is-invalid' : ''}`}
                    format="DD-MMM-YYYY"
                    picker='day'
                    mode={['day', 'day']}
                    showTime={true}
                    allowClear={true}
                    onChange={handleDateRangeChange}
                    popupStyle={{
                      position: "absolute",
                      top: -200
                    }}
                    getPopupContainer={() => document.getElementById("date-popup")}
                  />
                  {errors.dateRange && <div className='invalid-feedback dateRange'>{errors.dateRange}</div>}
                </div>
              </div>

              <div className='d-flex justify-content-center mt-4'>
                <div className='mt-2 mx-2'>
                  <button
                    className='btn btn-primary employee-cancel-button'
                    type='button'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
                <div className='mt-2 mx-2'>
                  <button className='btn btn-primary employee-save-button' type='submit' onClick={handleShedule}>
                    Schedule Share
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>

        </Wrapper>
      </Modal>
    </>
  );
}

export default SheduleShareModal;
const Wrapper = styled.div`
  /* .modal-header{
        background: #EAF3FF;
    }
    .input-container{
        width:100%;
        margin-bottom:10px !important;

    } */
  .modal-title {
    font-weight: 500;
    font-size: 16px;
  }
  .modal-header .btn-close {
    width: 24px;
    height: 24px;
  }
  .modal-header {
    background: #eaf3ff;
    border-radius: 16px 16px 0px 0px;
  }

  .employee-cancel-button {
    background: #ffffff;
    border: none;
    color: #2984fd;
  }
  .employee-save-button {
    background: #2984fd;
    border-radius: 8px;
    border-color: #2984fd;
  }




  
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
  width:20px
}


.ant-picker{
    min-width: 200px;
    max-width: 320px !important;
    border: 1px solid #636263;
    border-radius: 8px;
    height: 2.4rem;
    margin-top: 1.3rem;
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  // width: 160px!important;
  border: 1px solid #636263;
border-radius: 8px;
min-width: 126px;
}
.css-13cymwt-control,.css-t3ipsp-control{
  max-height: 38px !important;
  overflow: auto !important;
}
.ant-picker-time-panel{
  display:none
}

.invalid-feedback {
    position: absolute;
    margin-top: 0;
}
.hour{
    top: 120px !important;
    left: 55px !important;
}
.minute{
    top: 120px !important;
    left: 200px !important;
}
.period {
    top: 120px !important;
    left: 350px !important;
}
.dateRange{
  top: 189px !important;
}
.align-items-end {
    align-items: flex-end!important;
    margin-bottom: 10px;
}
`;
