import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import { Table, Image, Button, ButtonGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from '../../../../components/Pagination';
import { BiArchiveIn } from 'react-icons/bi';
import axios from '../../../../utils/api';
import ArchiveTitleModal from '../../../../components/modals/ArchiveModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/LoaderWave';

const EmployeeTabBody = ({ refreshPage, setRefreshPage, search }) => {
  const [loading, setLoading] = useState(false);
  const [showArchiveTitleModal, setShowArchiveTitleModal] = useState(false);
  const [slectedtitleDatas, setslectedtitleDatas] = useState(null);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const renderTooltip = (text) => <Tooltip>{text}</Tooltip>;

  const fetchData = async () => {
    // console.log('search',search);
    try {
      setLoading(true);
      const response = await axios.get(`/admin/archiveTitles?keyword=${search}`, {
        params: { page, perPage },
      });
      setData(response.data.data);
      setTotalPages(Number(response.data.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleCloseArchiveTitleModal = () => setShowArchiveTitleModal(false);
  const handleShowArchiveTitleModal = (title) => {
    setslectedtitleDatas(title);
    setShowArchiveTitleModal(true);
  };
  const handleConfirmArchive = () => {
    handleTitleArchive(slectedtitleDatas);
  };
  const handleTitleArchive = async (slectedtitleDatas) => {
    try {
      const response = await axios.delete(`/admin/UnarchiveTitle?_id=${slectedtitleDatas._id}`);
      console.log(response.data); // do something with the response
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseArchiveTitleModal();
      setslectedtitleDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [page, perPage, refreshPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Wrapper>
      {loading && <Loader />}
      <div className='third-row'>
        <ArchiveTitleModal
          show={showArchiveTitleModal}
          setShow={handleCloseArchiveTitleModal}
          onYes={handleConfirmArchive}
          message='Are you sure you want to unarchive this title?'
        />
        <>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Title Name</th>

                <th>Hourly Rate</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, i) => (
                <tr key={row._id}>
                  <td>{(page - 1) * 10 + i + 1}</td>

                  <td>{row.name}</td>
                  <td>${row.hourly_rate}</td>
                  <td>
                    <ButtonGroup className='action-btn'>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Unarchive')}>
                        <Button
                          className='archive-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleShowArchiveTitleModal(row)}
                        >
                          <BiArchiveIn style={{ color: '#008000' }} />
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            total={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </>
      </div>
    </Wrapper>
  );
};

const Wrapper = Styled.div`

.third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
.avatar-container {
  display: flex;
  align-items: center;
}

.avatar-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.action-btn {
    background: none;
    border: none;
    border-radius: 0px !important;
}
.edit-btn{
    margin-right:10px;
    background: #D4E6FF;
    border:1px solid #D4E6FF;
    border-radius: 8px;
}
.archive-btn{
    margin-right:10px;
    background: #CCE6CC;
    border:1px solid #CCE6CC;
    border-radius: 8px;
}
.delete-btn{
    background: #FFDBDC;
    border:1px solid #FFDBDC;
    border-radius: 8px;
}

.btn-group .button:only-child {
    border-radius: 6px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
.active{
  background:none;
}
    `;

export default EmployeeTabBody;
