import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
function MessageModal({ show, setShow, message, color }) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className='fw-bold'>
        <div className='d-flex justify-content-end cursor-pointer'>
          <MdClose className='mt-2 me-2' onClick={handleClose} />
        </div>
        <div
          style={{
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px'
          }}
        >
          <div style={{ color: color ? color : '#000' }}>{message}</div>
        </div>
        <div className='d-flex justify-content-center mb-3'>
          <button className='btn btn-success px-4' onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal>
    </>
  );
}

export default MessageModal;
