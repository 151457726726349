import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import {
  Table,
  Image,
  Button,
  ButtonGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Select from 'react-select';
import EditAccountsModal from './EditAccountsModal';
import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../../../utils/api';
import Loader from '../../../../components/LoaderWave';
import Axios from '../../../../utils/api';
import Pagination from '../../../../components/Pagination';
import DeleteModal from '../../../../components/modals/DeleteModal';
import ArchiveModal from '../../../../components/modals/ArchiveModal';

function ExpandableRow(props) {
  const { data, refreshPage, setRefreshPage } = props;
  // console.log(data);
  return (
    <>
      {data.map((item, index) => (
        <tr className='expandable-row' key={index}>
          <td colSpan='8'>
            <Table responsive>
              <tbody>
                <tr className='expandable-tr'>
                  <td></td>
                  <td>{item?.brand_name}</td>
                  <td>
                    {/* {item?.status} */}
                    {item.status === 'prospect' ? (
                      <td className='text-capitalize' style={{ color: '#DB517B' }}>
                        {item.status}
                      </td>
                    ) : (
                      <td className='text-capitalize' style={{ color: '#4E6DF2' }}>
                        {item.status}
                      </td>
                    )}
                  </td>
                  <td>{item?.pod_id?.name}</td>
                  <td>
                    {item?.lead_id?.first_Name} {item?.lead_id?.last_Name}
                  </td>
                  {/* <td>{item.accounts_name.join(', ')}</td> */}
                  <td>
                    {item?.team_id?.map((team, index) => {
                      console.log(item.team_id.length);
                      if (item?.team_id?.length - 1 == index) {
                        return team?.name;
                      } else {
                        return team?.name + ', ';
                      }
                    })}
                  </td>
                  {/* <td><SwitchButton className={`toggle-switch-${index}`} id={`toggle-switch`} 
                initialValue={item?.status=='active'?true:false} brand_id={item?._id}
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
                /></td>             */}
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </td>
        </tr>
      ))}
    </>
  );
}

function AcStageSwitchButton(props) {
  const { refreshPage, setRefreshPage } = props;
  const [isChecked, setIsChecked] = useState(props?.initialValue);
  const [acc_id, setBrandId] = useState(props?.acc_id);

  const handleSwitchChange = async () => {
    try {
      const response = await axios.post(`/admin/changeStageStatus`, {
        _id: acc_id,
        status: !isChecked ? 'active' : 'prospect',
      });
      console.log(response.data); // do something with the response
      toast.success('Account status changed successfully');
      setIsChecked(!isChecked);
      setRefreshPage(!refreshPage);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form.Group>
      <div className='custom-switch'>
        <Form.Check
          type='switch'
          id={props.id}
          label={props.label}
          checked={isChecked}
          onChange={handleSwitchChange}
        />
      </div>
    </Form.Group>
  );
}

function SwitchButton(props) {
  const { refreshPage, setRefreshPage } = props;
  const [isChecked, setIsChecked] = useState(props.initialValue);
  const [brandId, setBrandId] = useState(props.brand_id);
  const handleSwitchChange = async () => {
    try {
      const response = await axios.post(`/admin/changeBrandStatus`, {
        _id: brandId,
        status: !isChecked ? 'active' : 'prospect',
      });
      console.log(response.data); // do something with the response
      toast.success('Brand status changed successfully');
      setIsChecked(!isChecked);
      setRefreshPage(!refreshPage);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form.Group>
      <div className='custom-switch'>
        <Form.Check
          type='switch'
          id={props.id}
          label={props.label}
          checked={isChecked}
          onChange={handleSwitchChange}
        />
      </div>
    </Form.Group>
  );
}

const AccountsTabBody = ({
  SelectedDateFormattedData,
  selectedMultiTeamValues,
  selectedMultiAccountsValues,
  selectedBrandData,
  refreshPage,
  setRefreshPage,
  TeamOptions,
  PodOption,
  LeadOptions,
}) => {
  console.log(selectedMultiAccountsValues,'selectedMultiAccountsValues');
  console.log(selectedBrandData,'selectedBrandData');
  const renderTooltip = (text) => <Tooltip>{text}</Tooltip>;
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [User, setUser] = useState(null);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [slectedDatas, setslectedDatas] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const sortData = () => {
    const sortedData = [...data]; // Make a copy of your data
    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });
    return sortedData;
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      // Toggle the sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set a new sorting field and default to ascending order
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (field) => {
    if (field === sortBy) {
      return <span className={`arrow ${sortOrder === 'asc' ? 'asc' : 'desc'}`}><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
    }
    return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>; // Add a default icon
  };

  const toggleExpandedRow = (rowId) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  const handleMultiSelectTeamChange = (selectedMultiTeamOptions) => {
    setSelectedMultiTeamOptions(selectedMultiTeamOptions);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseArchiveModal = () => setShowArchiveModal(false);
  const handleShowDeleteModal = (account) => {
    setslectedDatas(account);
    setShowDeleteModal(true);
  };
  const handleShowArchiveModal = (account) => {
    setslectedDatas(account);
    setShowArchiveModal(true);
  };
  const handleConfirmDelete = () => {
    handleDelete(slectedDatas);
  };
  const handleConfirmArchive = () => {
    handleArchive(slectedDatas);
  };
  const handleDelete = async (slectedDatas) => {
    try {
      const response = await axios.delete(`/admin/account?_id=${slectedDatas._id}`);
      console.log(response.data); // do something with the response
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseDeleteModal();
      setslectedDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };
  const handleArchive = async (slectedDatas) => {
    try {
      const response = await axios.delete(`/admin/archiveAccount?_id=${slectedDatas._id}`);
      console.log(response.data); // do something with the response
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseArchiveModal();
      setslectedDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };

  const fetchData = async () => {
    console.log('inside fetchData ', SelectedDateFormattedData);
    try {
      setLoading(true);
      // const response = await Axios.post(`/admin/accounts?page=${page}&limit=${perPage}&keyword=${search}`, {
      //   account_names: selectedMultiTeamNames
      // }
      const response = await Axios.post(`/admin/accounts?page=${page}&limit=${perPage}`, {
        account_id: selectedMultiAccountsValues,
        team_ids: selectedMultiTeamValues,
        SelectedDateFormattedData: SelectedDateFormattedData,
      });
      console.log('accounts',response.data.data);
          // Filter the data based on selectedBrandData
          const filteredData = response.data.data.map(account => {
            if (selectedBrandData.length > 0) {
              // Check if the brand ID exists in selectedBrandData
              const filteredBrands = account.brands.filter(brand => selectedBrandData.some(selectedBrand => selectedBrand.id.toString() === brand._id.toString()));
              // Update the account's brands with the filtered ones or keep all brands if none match
              account.brands = filteredBrands.length > 0 ? filteredBrands : account.brands;
            }
            return account;
          });
      
          setData(filteredData);
      // setData(response.data.data);
      setTotalPages(Number(response.data.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [
    page,
    perPage,
    refreshPage,
    selectedMultiTeamValues,
    selectedMultiAccountsValues,
    SelectedDateFormattedData,
    selectedBrandData
  ]);

  return (
    <Wrapper>
      {loading && <Loader />}
      <DeleteModal
        show={showDeleteModal}
        setShow={handleCloseDeleteModal}
        onYes={handleConfirmDelete}
        message='Are you sure you want to Delete this account?'
      />
      <ArchiveModal
        show={showArchiveModal}
        setShow={handleCloseArchiveModal}
        onYes={handleConfirmArchive}
        message='Are you sure you want to Archive this account?'
      />
      {showEditTeamModal && (
        <EditAccountsModal
          showModal={showEditTeamModal}
          setShowModal={setShowEditTeamModal}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          selectedAccount={User}
          TeamOptions={TeamOptions}
          PodOption={PodOption}
          LeadOptions={LeadOptions}
        />
      )}
      <div className='third-row'>
        <>
          <Table responsive bordered size='sm'>
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => handleSort('name')}>Account Name {renderSortIcon('name')}</th>
                <th onClick={() => handleSort('account_status')}>Account Stage {renderSortIcon('account_status')}</th>
                <th>Pod</th>
                <th>Leads</th>
                <th>Teams</th>
                {/* <th>Status</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortData()?.map((row, index) => (
                <>
                  <tr key={row._id}>
                    <td className='sno'>{perPage * (page - 1) + index + 1}</td>
                    <td>
                      <button className='btn btn-link' onClick={() => toggleExpandedRow(row._id)}>
                        {expandedRows.includes(row._id) ? (
                          <span>&#x25BC;</span>
                        ) : (
                          <span>&#x25B6;</span>
                        )}
                      </button>
                      {row.name}
                    </td>
                    {row.account_status === 'prospect' ? (
                      <td className='text-capitalize' style={{ color: '#DB517B' }}>
                        {row.account_status}
                      </td>
                    ) : (
                      <td className='text-capitalize' style={{ color: '#4E6DF2' }}>
                        {row.account_status}
                      </td>
                    )}

                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td>
  <AcStageSwitchButton
    className={`toggle-switch-${index}`}
    id={`toggle-switch`}
    initialValue={row?.account_status == "active" ? true : false}
    acc_id={row?._id}
    refreshPage={refreshPage}
    setRefreshPage={setRefreshPage}
  />
</td> */}
                    <td>
                      <ButtonGroup className='action-btn'>
                        <OverlayTrigger placement='top' overlay={renderTooltip('Edit')}>
                          <Button
                            className='edit-btn'
                            variant='outline-secondary'
                            style={{ borderRadius: '8px' }}
                            onClick={() => {
                              setShowEditTeamModal(true);
                              setUser(row);
                            }}
                          >
                            <FaEdit style={{ color: '#2984FD' }} />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' overlay={renderTooltip('Archive')}>
                          <Button
                            className='archive-btn'
                            variant='outline-secondary'
                            style={{ borderRadius: '8px' }}
                            onClick={() => handleShowArchiveModal(row)}
                          >
                            <BiArchiveIn style={{ color: '#008000' }} />
                          </Button>
                        </OverlayTrigger>
                        {/* <OverlayTrigger placement="top" overlay={renderTooltip('Delete')}>
            <Button className='delete-btn' variant="outline-secondary" style={{borderRadius: '8px'}} onClick={() => handleShowDeleteModal(row)}><FaTrashAlt style={{color:'#FF4D4F'}}/></Button>
            </OverlayTrigger> */}
                      </ButtonGroup>
                    </td>
                  </tr>
                  {expandedRows.includes(row._id) && (
                    <ExpandableRow
                      data={row.brands}
                      refreshPage={refreshPage}
                      setRefreshPage={setRefreshPage}
                    />
                  )}
                </>
              ))}
            </tbody>
          </Table>
          <Pagination
            total={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </>
      </div>
    </Wrapper>
  );
};

const Wrapper = Styled.div`

.third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-image {
  width: 30px;
  height: 30px;
  border: 2px solid #FFFFFF;
  /* display:flex; */
  /* flex-direction:row; */
  justify-content:center;
  text-align:center;
}
.overlap{
  margin-left:-12px
}

.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.action-btn {
    background: none;
    border: none;
    border-radius: 0px !important;
}
.edit-btn{
    margin-right:10px;
    background: #D4E6FF;
    border:1px solid #D4E6FF;
    border-radius: 8px;
}
.archive-btn{
    margin-right:10px;
    background: #CCE6CC;
    border:1px solid #CCE6CC;
    border-radius: 8px;
}
.delete-btn{
    background: #FFDBDC;
    border:1px solid #FFDBDC;
    border-radius: 8px;
}

.btn-group .button:only-child {
    border-radius: 6px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
  .expandable-tr {
    border-width: 0;
}
  .expandable-tr td{
    padding: 0px 10px !important;
}
  .expandable-row {
    border-width: 0;
}
  tr td {
    width: 100%;
    min-width: 220px;
}
.expandable-row tr td:first-child {
    width: 16%;
    min-width: 16px;
}
.sno{
  width: 10%;
    min-width: 20px;
}
tr td:last-child {
    width: 40%;
    min-width: 120px;
}

#toggle-switch:checked{
  background-color: #008000; /* checked color */
}
#toggle-switch:not(:checked){
  background-color: #908F90; /* Unchecked color */
  color: #fff !important;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e")
;

}
.btn-link{
  --bs-btn-color:#000;
  text-decoration: none;
}

.table-responsive{
  height:auto;

  table {
    thead{
      position: sticky !important;
      top: -1px;
      z-index: 1;
    }
    tbody{
      overflow-y: auto;
    }
  }
}
    `;

export default AccountsTabBody;
