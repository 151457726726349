import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Sidebar } from "./Sidebar";
import { BiSearch } from 'react-icons/bi';
import { BiArchiveIn } from 'react-icons/bi';
import { MdMoreVert } from 'react-icons/md';
import axios from '../../../../utils/api';
import ArchivePodModal from '../../../../components/modals/ArchiveModal';
import Loader from '../../../../components/LoaderWave';
function Pod() {
  const [loading, setLoading] = useState(false);
  const [podItems, setPodItems] = useState([]);
  const [activePodItem, setActivePodItem] = useState('');
  const [showpodFilter, setShowPodFilter] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [showArchivePodModal, setShowArchivePodModal] = useState(false);
  const [slectedpodDatas, setslectedpodDatas] = useState(null);
  const [search, setSearch] = useState('');

  const fetchData = async () => {
    // console.log('pod search',search);
    try {
      setLoading(true);
      const response = await axios.get(`/admin/archivepods?keyword=${search}`);
      setPodItems(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleCloseArchivePodModal = () => setShowArchivePodModal(false);
  const handleShowArchivePodModal = (pod) => {
    setslectedpodDatas(pod);
    setShowArchivePodModal(true);
  };
  const handleConfirmArchive = () => {
    handlePodArchive(slectedpodDatas);
  };
  const handlePodArchive = async (slectedpodDatas) => {
    try {
      const response = await axios.delete(`/admin/Unarchivepod?_id=${slectedpodDatas._id}`);
      // console.log(response.data);
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseArchivePodModal();
      setslectedpodDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [refreshPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Wrapper>
      {loading && <Loader />}
      <ArchivePodModal
        show={showArchivePodModal}
        setShow={handleCloseArchivePodModal}
        onYes={handleConfirmArchive}
        message='Are you sure you want to unarchive this pod?'
      />
      <div className='tabs-ride-container'>
        <label htmlFor='search-input'>
          Search By Pods
          <div className='search-input-container'>
            <div className='search-icon-container'>
              <BiSearch className='react-icon' />
            </div>
            <input
              type='text'
              id='search-input'
              value={search}
              onChange={handleSearchChange}
              placeholder='Type in a keyword or name'
            />
          </div>
        </label>
      </div>
      <div className='container-main'>
        {podItems.map((item, i) => {
          return (
            <div className='pod-container' key={i}>
              <span className='ms-2'>{item.name}</span>
              <div className='pod-icon'>
                <MdMoreVert
                  className='me-2'
                  onClick={() => {
                    if (i === activePodItem) {
                      setShowPodFilter(!showpodFilter);
                      setActivePodItem(i);
                      return;
                    }
                    setShowPodFilter(true);
                    setActivePodItem(i);
                  }}
                />
                {showpodFilter && activePodItem === i && (
                  <ul className='pod-filter' onClick={() => setShowPodFilter(false)}>
                    <li
                      className='d-flex pod-filter-item'
                      onClick={() => handleShowArchivePodModal(item)}
                    >
                      <BiArchiveIn className='m-1' /> <span className='m-2'>Unarchive</span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

export default Pod;

const Wrapper = styled.div`
  background: #dee7f2;
  .container-main {
    background: #fff;
    width: 98%;
    margin: 0px auto;
    padding: 10px;
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .pod-container {
    border: 1px solid black;
    min-width: 200px;
    border-radius: 10px;
    height: 35px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .pod-icon {
    position: relative;
    cursor: pointer;
  }
  .pod-filter {
    position: absolute;
    min-width: 150px;
    background: white;
    top: 10px;
    right: 40px;
    border-radius: 10px;
    padding: 0px;
  }
  .pod-filter-item {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #d2d2d2;
  }

  .pod-filter-item:last-child {
    border-bottom: none;
  }
  .pod-filter-item:hover {
    background: #eaf3ff;
  }
`;
