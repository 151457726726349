import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import { Sidebar } from '../Sidebar';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineFileDownload } from 'react-icons/md';
import { IoMdShareAlt } from 'react-icons/io';
import ReactSelect from 'react-select';
import axios from '../../../../utils/api';
import Loader from '../../../../components/LoaderWave';
import Chart from './Chart';
import Htable from './Htable';
import DateRangeModal from './calendar/DateRangeModal';
import { startOfWeek, endOfWeek, addDays } from 'date-fns';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx'
import { es, ru } from 'date-fns/locale'
import { formatInTimeZone } from 'date-fns-tz'
import ShareDataModal from '../ShareDataModal';
import TokenServices from '../../../../utils/token.services';
import { toast } from 'react-toastify';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);
// Custom component to render checkboxes for Accounts
const CustomCheckboxOption = (props) => {
  // console.log('CustomCheckboxOption props', props);
  return (<div className='custom-option'>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
        id={`account-checkbox-${props.data.value}`}
      />
      <label className='checkbox-label' htmlFor={`account-checkbox-${props.data.value}`}>
        {props.label}
      </label>
    </div>
    {props.data.brands && props.isSelected && (

      <div className='brand-checkboxes'>
        {props.data.brands.map((brand) => (
          <div key={brand._id} className='checkbox-container'>
            <input
              type='checkbox'
              checked={brand.isSelected}
              onChange={() => props.selectBrandOption(brand)}
              id={`brand-checkbox-${brand._id}`}
            />
            <label className='checkbox-label' htmlFor={`brand-checkbox-${brand._id}`}>
              {brand.brand_name}
            </label>
          </div>
        ))}
      </div>
    )}
  </div>
  )
}

const taskTypeOption = [{ value: "Internal", label: "Internal" }, { value: "Client Related", label: "Client Related" }, { value: "", label: "All" }].sort((a, b) => {
  return a.label > b.label ? 1 : -1;
})

const Report = () => {
  const [runAPI, setRunAPI] = useState(false);
  const [exportObject,setExportObject]=useState(null)
  const [showShareDataModal, setShowShareDataModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [areFiltersFetched, setAreFiltersFetched] = useState(false)
  const [employeeFetched, setEmployeeFetched] = useState(false);
  const [tableData, setTableData] = useState([])
  const [taskType, setTaskType] = useState({ label: "All", value: "" })
  const [isTaskTypeSelected, setIsTaskTypeSelected] = useState(false)

  const [refreshPage, setRefreshPage] = useState(false);
  const [data, setData] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedMultiEmployeeOptions, setSelectedMultiEmployeeOptions] = useState([]);
  const [selectedMultiEmployeeValues, setSelectedMultiEmployeeValues] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedMultiAccountsOptions, setSelectedMultiAccountsOptions] = useState([]);
  const [selectedMultiAccountsValues, setSelectedMultiAccountsValues] = useState([]);
  const [isAccountValueGotChanged, setIsAccountValueGotChanged] = useState(false)

  const [selectedYear, setSelectedYear] = useState({ value: new Date().getFullYear() });
  // const [yearOptions, setYearOptions] = useState([]);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [PodOption, setPodOption] = useState([]);
  const [selectedMultiPodOptions, setSelectedMultiPodOptions] = useState([]);
  const [selectedMultiPodValues, setselectedMultiPodValues] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const [selectedBrandData, setSelectedBrandData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [chartData, setChartData] = useState({
    months: [],
    hours: [],
  });
  const [filterDisabled, setFilterDisabled] = useState({ pod: false, team: false, account: false, employee: false, date: false })
  const [donughtData, setDonughtData] = []
  // const [chartData, setChartData] = useState({
  //   months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  //   hours: [50, 50, 50, 270, 277, 330, 147, 148, 141, 153, 50, 50],
  // });
  const [showModal, setShowModal] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(dayjs.tz(new Date(), "Asia/Tokyo")),
    endDate: addDays(new Date(dayjs.tz(new Date(), "Asia/Tokyo")), 6), // Set end date as the end of the current week
    key: 'selection',
  });
  const handleDateSelection = (range) => {
    console.log(range, "range.....")

    setSelectedRange(range);

     syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, taskType, range)
  };

  const handleDateSelection2 = (range) => {
    console.log(range, "range.....")

    setSelectedRange(range);
    
    // syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, taskType, range)
  };

  const handleShowModal = () => {
    setShowShareDataModal(true);
  };

  const handleMultiSelectAccountsChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiAccountsOptions(selectedOptions);
    setSelectedMultiAccountsValues(selectedValues);
    setIsAccountValueGotChanged(true)
    console.log('selectedMultiAccountsValues', selectedMultiAccountsValues);
    syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedOptions, taskType, selectedRange)
  };
  const handleMultiSelectBrandChange = (selectedBrand) => {
    const brandIndex = selectedBrandData.findIndex((brand) => brand.id === selectedBrand._id);

    if (brandIndex === -1) {
      // If not in selectedBrandData, add it
      setSelectedBrandData([...selectedBrandData, { id: selectedBrand._id, name: selectedBrand.brand_name }]);
    } else {
      // If already in selectedBrandData, remove it
      const updatedBrandData = [...selectedBrandData];
      updatedBrandData.splice(brandIndex, 1);
      setSelectedBrandData(updatedBrandData);
    }
  };

  const handleMultiSelectEmployeeChange = (selectedOptions) => {
    console.log(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiEmployeeOptions(selectedOptions);
    setSelectedMultiEmployeeValues(selectedValues);
    console.log('selectedMultiEmployeeValues', selectedMultiEmployeeValues);
    syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedOptions, selectedMultiAccountsOptions, taskType, selectedRange)
  };

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
    console.log('selectedMultiTeamValues', selectedMultiTeamValues);
    syncFilter(selectedMultiPodOptions, selectedOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, taskType, selectedRange)
  };

  const handleMultiSelectPodChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiPodOptions(selectedOptions);
    setselectedMultiPodValues(selectedValues);
    syncFilter(selectedOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, taskType, selectedRange)
    console.log('selectedMultiPodValues', selectedMultiTeamValues);
  };

  // const handleYearChange = (selectedOption) => {
  //   setSelectedYear(selectedOption);
  // };

  const handleActionClick = () => {
    setShowPopup(!showPopup);
  };

  const exportData = () => {
    setShowPopup(false);
    // Perform exportData action here
  };

  const shareData = () => {
    setShowPopup(false);
    handleShowModal();
  };


  // const exportDataArrange=(data,groupBy)=>{
  //   const exportData = []
    
  //   if(groupBy=="Account"||groupBy=="Employee"){
  //     data?.forEach((item, i) => {
  //       item.brand.forEach((item2, i2) => {
  //         item2.employees.forEach((item3, i3) => {
  //           item3.tasks.forEach((item4, i4) => {
  //             const hours = Number(item4.duration.slice(0, item4.duration.indexOf(":")))
  //             const minutes = Number(item4.duration.slice(item4.duration.indexOf(":") + 1, item4.duration.length)) / 60
  //             const totalDuration = parseFloat(hours + minutes).toFixed(2)
  //             exportData.push({ Employee: item3.name, Account: item.name, Brand: item2.brand_name, Team: item.team, Pod: item.pod, Tasktype: item4.task_type, Task: item4.name[0], duration: totalDuration, date: dayjs(item4.date).format("MM-DD-YYYY"), employeeTeam: (item3.team), title: item3.title })
  
  //           })
  //         })
  //       })
  //     })
  //   }
    
  //   return exportData
  // }

  const exportHoursData = () => {
    // Prepare data for Excel export


    // Create a new workbook
    let groupBy=exportObject?.groupBy
    console.log(groupBy,"groupby")
    // const exportData=exportDataArrange(exportObject?.exportData,exportObject?.groupBy)
    
    let { startDate, endDate } = selectedRange
    startDate = dayjs(startDate).add(1, "D").format("MM-DD-YYYY").toString()
    endDate = dayjs(endDate).format("MM-DD-YYYY").toString()
    const wb = XLSX.utils.book_new();

    let exportData=chartData?.exportData.map((value) => {
      return {
        Employee: value.Employee,
        Title: value.title,
        Account: value.Account,
        Brand: value.Brand,
        Team: value.employeeTeam,
        Pod: value.Pod,
        "Task Type": value.Tasktype,
        Task: value.Task,
        Description: value.description,
        Duration: parseFloat(value.duration),
        Date: value.date,
      }
    })
    if(groupBy=="Employee"){
    exportData.sort((a,b)=>{
      if(a.Employee.toLowerCase()<b.Employee.toLowerCase()){
        return -1
      }
    }) 
    console.log(exportData) 
    
    }
     if(groupBy=="Task"){
    exportData.sort((a,b)=>{
      if(a.Task.toLowerCase()<b.Task.toLowerCase()){
        return -1
      }
    }) 
    console.log(exportData) 
    
    }

    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'HourData');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, `hourData(${startDate + "~" + endDate}).xlsx`);
  };

  const Popup = ({ message, exportData, shareData }) => {
    return (
      <div className='popup-container'>
        <div className='popup-message' >
          <span className='export-txt'>Export data - </span>Download the data to your computer or
          local storage.{' '}
        </div>
        <div className='popup-message'>
          <span className='share-txt'>Share data - </span>Share the data with anyone within the app.{' '}
        </div>
        <div className='popup-buttons'>
          <button onClick={exportHoursData}>
            <div className='icon-container'>
              <MdOutlineFileDownload className='react-icon' />
            </div>
            Export Data
          </button>
          <button onClick={shareData}>
            <div className='icon-container'>
              <IoMdShareAlt className='react-icon' />
            </div>
            Share Data
          </button>
        </div>
      </div>
    );
  };


  const populateFilter = async () => {
    const userData = TokenServices.getUser();
    const user_id = userData ? userData._id : '';
    axios.get(`/admin/getFilter?user_id=${user_id}`).then((res) => {
      console.log('data', res);
      const { hoursFilter } = res?.data?.data;
      if (hoursFilter&& userData.role!=="Employee") {
        setSelectedMultiPodOptions(hoursFilter?.pods || [])
        setselectedMultiPodValues(hoursFilter?.pods.map((option) => option.value) || [])
        if(userData.role!=="Manager"){
          setSelectedMultiTeamOptions(hoursFilter?.teams || [])
          setselectedMultiTeamValues(hoursFilter?.teams.map((option) => option.value) || [])
        }
       
        setSelectedMultiEmployeeOptions(hoursFilter?.users || [])
        setSelectedMultiEmployeeValues(hoursFilter?.users.map((option) => option.value) || [])
        setSelectedMultiAccountsOptions(hoursFilter?.accounts || [])
        setSelectedMultiAccountsValues(hoursFilter?.accounts.map((option) => option.value) || [])
        setTaskType(hoursFilter?.taskType)
        if (hoursFilter.dateRange) {
          setSelectedRange(hoursFilter?.dateRange)
          handleDateSelection2(hoursFilter?.dateRange)

        }
        setAreFiltersFetched(true)
      }
      else {
        setAreFiltersFetched(true)
      }
    }).catch((error) => {
      setAreFiltersFetched(true)
      console.log('error', error);
    })

    if (userData && userData.role === 'Employee') {
      setFilterDisabled({ pod: true, team: true, account:true, employee: true, date: false })
    }
    if (userData && userData.role === 'Manager') {
      setFilterDisabled({ ...filterDisabled, team: true });
    }

  }

  useEffect(() => {
    const userData = TokenServices.getUser();
   
     populateFilter().then((response)=>console.log("filter got populated"))

     let userId = userData ? userData._id : '';
     let role = userData ? userData.role : '';
     let teamsToExclude=[]
     let employeeResponse=[]
     Promise.all([
      axios
      .post('/admin/accounts?page=&limit=', {
        account_id: '',
        team_ids: '',
        SelectedDateFormattedData: '',
      }), 
      axios
      .get(`/admin/resources_wopaging?user_id=${userId}&role=${role}`),
      axios
      .get('/admin/teams_wopaging'),
      axios
      .get('/admin/pods_wopaging')


    ])
    .then((response) => {

      const modifyData = response[0].data.data.map((item) => {
        for (let i = 0; i < item.brands.length; i++) {
          item.brands[i].isChecked = false
        }
        return item
      })
      setAccountOptions(
        modifyData
      );
      //
      employeeResponse=response[1]?.data?.data

      //
      let modifyResponse=response[2]?.data?.data.filter((item)=>{
        if(item.includeInInsights){
         return item
        }
        else {
         teamsToExclude.push(item._id)
        }
      })

      

      employeeResponse=employeeResponse.filter((item)=>{
        if(teamsToExclude.indexOf(item.team)<0){
          return item
        }
      })
      console.log(employeeResponse)

      setEmployeeOptions(
        employeeResponse?.map((item, index) => ({
          value: item?._id,
          label: item?.first_Name + ' ' + item?.last_Name,
          key: index,
        })).sort((a, b) => {
          return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
        }),
      );
      setEmployeeFetched(true)


      setTeamOptions(
        modifyResponse?.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
          return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
        })
      );

      //
      setPodOption(response[3]?.data?.data.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      }));
      

    }).catch((error)=>console.log(error));


    // axios
    //   .post('/admin/accounts?page=&limit=', {
    //     account_id: '',
    //     team_ids: '',
    //     SelectedDateFormattedData: '',
    //   })
    //   .then((response) => {
    //     // console.log("resources_wopaging",response.data.data);
    //     const modifyData = response.data.data.map((item) => {
    //       for (let i = 0; i < item.brands.length; i++) {
    //         item.brands[i].isChecked = false
    //       }
    //       return item
    //     })
    //     setAccountOptions(
    //       modifyData
    //     );
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

   

    // axios
    //   .get(`/admin/resources_wopaging?user_id=${userId}&role=${role}`)
    //   .then((response) => {
    //     // console.log('response?.data?.data', response?.data?.data);
    //     employeeResponse=response?.data?.data
    //           })
    //   .catch((error) => {
    //     setEmployeeFetched(true)
    //     console.log(error);
    //   });

    // axios
    //   .get('/admin/teams_wopaging')
    //   .then((response) => {
    //     let modifyResponse=response?.data?.data.filter((item)=>{
    //       if(item.includeInInsights){
    //        return item
    //       }
    //       else {
    //        teamsToExclude.push(item._id)
    //       }
    //     })

        

    //     employeeResponse=employeeResponse.filter((item)=>{
    //       if(teamsToExclude.indexOf(item.team)<0){
    //         return item
    //       }
    //     })
    //     console.log(employeeResponse)

    //     setEmployeeOptions(
    //       employeeResponse?.map((item, index) => ({
    //         value: item?._id,
    //         label: item?.first_Name + ' ' + item?.last_Name,
    //         key: index,
    //       })).sort((a, b) => {
    //         return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    //       }),
    //     );
    //     setEmployeeFetched(true)


    //     setTeamOptions(
    //       modifyResponse?.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
    //         return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    //       })
    //     );

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // axios
    //   .get('/admin/pods_wopaging')
    //   .then((response) => {
    //     // console.log("resources_wopaging",response?.data?.data);
    //     setPodOption(response?.data?.data.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
    //       return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    //     }));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });



  }, []);

  const fetchData = async () => {
    const userData = TokenServices.getUser();
    let updatedEmployeeValue = [...selectedMultiEmployeeValues];
    if (userData && userData.role === 'Employee') {
      updatedEmployeeValue = [userData._id];
    } else if (userData && userData.role === 'Manager' && selectedMultiEmployeeValues.length === 0 && employeeOptions.length > 0) {
      updatedEmployeeValue = employeeOptions.map(emp => emp.value);
    }
    console.log(selectedRange, "selected range");
    let { startDate, endDate } = selectedRange

    startDate = dayjs(startDate).format("YYYY-MM-DD")
    endDate = dayjs(endDate).format("YYYY-MM-DD")
    try {
      setLoading(true);

      const response = await axios.post(
        `/admin/report?page=${page}&limit=${perPage}`, {
        employee_id: updatedEmployeeValue,
        account_id: selectedMultiAccountsValues,
        brand_id: selectedMultiAccountsValues.length > 0 ? selectedBrandData : [],
        pod_ids: selectedMultiPodValues,
        team_ids: selectedMultiTeamValues,
        date_range: { startDate, endDate },
        task_type: taskType.value
      });
      const chartAPIData = response?.data?.data?.chartData;
      // chartAPIData.donught_data=response?.data?.data?.donughtData
      const donught_data = response?.data?.data?.donughtData;
      // setDonughtData(donught_data)

      if (chartAPIData) {
        // Extract keys (years) from chartAPIData
        const years = Object.keys(chartAPIData);

        // Extract values (hours) from chartData as integers
        const hoursData = Object.values(chartAPIData).map((hoursString) => {
          const [hours, minutes] = hoursString.split(':');
          return Number(parseInt(hours) + '.' + parseInt(minutes));
        });

        // Update chartState
        setChartData({
          months: years,
          hours: hoursData,
          donught_data: selectedMultiTeamValues.length > 0 ? (response?.data?.data?.taskTeamData) : (response?.data?.data?.donughtData),
          exportData: response?.data?.data?.exportData,
          totalDuration: response?.data?.data?.totalDuration,

        });
      }

      let sortedData = response?.data?.data;
      sortedData.timerData = sortedData?.timerData.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setTableData(sortedData)
      setRefreshPage(!refreshPage)
      // setData(response.data.data);
      // setTotalPages(Number(response.data.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const syncFilter = async (selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, taskType, selectedRange) => {
    const userData = TokenServices.getUser();
    const user_id = userData ? userData._id : '';
    const modifyPods = selectedMultiPodOptions
    const modifyTeams = selectedMultiTeamOptions
    const modifyUsers = selectedMultiEmployeeOptions
    const modifyAccount = selectedMultiAccountsOptions
    const modifyTaskType = taskType
    const modifyRange = selectedRange

    if (user_id) {
      const response = await axios.post("/admin/setInsightFilter", {
        user_id,
        filter: {
          pods: modifyPods,
          teams: modifyTeams,
          users: modifyUsers,
          accounts: modifyAccount,
          taskType: modifyTaskType,
          dateRange: modifyRange
        },
        page: 'hours'
      })
    }
  }

  useEffect(() => {
    // setData([]);
    if (areFiltersFetched && employeeFetched) {
      const userData = TokenServices.getUser();
      if (userData.role === 'Admin' || userData.role==='Employee' || (userData.role !== 'Admin' && (selectedMultiPodValues.length > 0 || selectedMultiTeamValues.length > 0 || selectedMultiAccountsValues.length > 0 || selectedMultiEmployeeValues.length > 0))) {
        fetchData();
      }else{
        toast.error('Select at least one filter');
      }
    }

  }, [runAPI]);


  // Update accountOptions based on selectedBrandData whenever needed
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !event.target.closest('.btn-action-container')
      ) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const updatedAccountOptions = accountOptions.map((account) => ({
      ...account,
      brands: account.brands.map((brand) => ({
        ...brand,
        isSelected: selectedBrandData.some((selectedBrand) => selectedBrand.id === brand._id),
      })),
    }));

    setAccountOptions(updatedAccountOptions);
  }, [selectedBrandData]);

  useEffect(() => {
    if (selectedMultiAccountsValues.length <= 0) {
      if (isAccountValueGotChanged === true) {
        setSelectedBrandData([]);
      }
      setIsAccountValueGotChanged(false)

    } else {
      const deselectedAccountIds = accountOptions.filter((account) => !selectedMultiAccountsValues.includes(account._id));
      let allDeselectedBrands = [];
      deselectedAccountIds.forEach(account => {
        account?.brands.forEach(brand => {
          allDeselectedBrands.push(brand._id);
        });
      });
      setSelectedBrandData((prev) => prev.filter((brand) => !allDeselectedBrands.includes(brand.id)));
    }
  }, [selectedMultiAccountsValues]);
  return (
    <Wrapper>
      {loading && <Loader />}
      <ShareDataModal
        show={showShareDataModal}
        setShow={setShowShareDataModal}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
      />
      <div className='report-container'>
        <div className='HistoryOfRequest'>
          <section className='bg'>
            <div className='row flex-nowrap'>
              <div className='col-auto sidebar-container'>
                <Sidebar />
              </div>
              <div className='col py-3 content-area'>
                <div className='row'>
                  <div className='action-row'>
                    <div className='right-btn-section'>
                      {loading ? (<div className='btn-container btn-run-container'>
                        <input type='button' value='Running...' disabled={loading} />
                      </div>) : (<div className='btn-container btn-run-container' onClick={() => setRunAPI(!runAPI)}>
                        <input type='button' value='Run' />
                      </div>)}

                      <div className='btn-container btn-action-container ' onClick={handleActionClick}>
                        <input type='button' value='Action' />
                        <div className='icon-container'>
                          <IoIosArrowDown className='react-icon' />
                        </div>
                      </div>
                      {/* Conditional rendering of the popup */}
                      {showPopup && (
                        <div ref={popupRef} className='popup-div'>
                          <Popup exportData={exportData} shareData={shareData} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row-1'>
                  {/* <div>
                  <h1 className='row-1-title'>Hours</h1>
                </div> */}

                  <div className='filter-right-section'>
                    <div className='row'>
                      <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                        <div className='multi-select-container'>
                          <label>
                            Task Type
                            <ReactSelect
                              id='multiSelectPod'

                              options={taskTypeOption}
                              value={taskType}
                              onChange={(e) => {
                                syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, e, selectedRange)
                                setTaskType(e)
                                setIsTaskTypeSelected(!isTaskTypeSelected)
                              }}
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              components={{ Option: CheckboxOption }}
                            // components={{ Option: CheckboxOption }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                        <div className='multi-select-container'>
                          <label>
                            Pods
                            <ReactSelect
                              id='multiSelectPod'
                              isMulti
                              options={PodOption}
                              value={selectedMultiPodOptions}
                              onChange={handleMultiSelectPodChange}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{ Option: CheckboxOption }}
                              isDisabled={filterDisabled.pod}
                            />
                          </label>
                        </div>
                      </div>
                      <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                        <div className='multi-select-container'>
                          <label>
                            Team
                            <ReactSelect
                              id='multiSelectTeam'
                              isMulti
                              options={TeamOptions}
                              value={selectedMultiTeamOptions}
                              onChange={handleMultiSelectTeamChange}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{ Option: CheckboxOption }}
                              isDisabled={filterDisabled.team}
                            />
                          </label>
                        </div>
                      </div>
                      <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                        <div className='multi-select-container'>
                          <label>
                            Accounts
                            <ReactSelect
                              id='multiSelectAccounts'
                              isMulti
                              options={accountOptions.map((account) => ({
                                value: account._id,
                                label: account.name,
                                brands: account.brands, // Include the brands as part of the option
                              }))}
                              value={selectedMultiAccountsOptions}
                              onChange={handleMultiSelectAccountsChange}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              filterOption={(option, inputValue) => {
                                const lowercaseInput = inputValue.toLowerCase();

                                // Check if the account label matches the input value
                                const accountLabel = option?.label?.toLowerCase();
                                if (accountLabel?.includes(lowercaseInput)) {
                                  return true;
                                }

                                // Check if any of the brand names within this account match the input value
                                const brandLabels = option?.data?.brands?.map((brand) => brand.brand_name.toLowerCase());
                                return brandLabels?.some((label) => label?.includes(lowercaseInput));
                              }}
                              components={{
                                Option: (props) => <CustomCheckboxOption {...props} selectBrandOption={handleMultiSelectBrandChange} />,
                              }}
                              isDisabled={filterDisabled.account}
                            />
                          </label>
                        </div>
                      </div>
                      <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                        <div className='multi-select-container'>
                          <label>
                            Employee
                            <ReactSelect
                              id='multiSelectAccounts'
                              isMulti
                              options={employeeOptions}
                              value={selectedMultiEmployeeOptions}
                              onChange={handleMultiSelectEmployeeChange}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{ Option: CheckboxOption }}
                              isDisabled={filterDisabled.employee}
                            />
                          </label>
                        </div>
                      </div>
                      <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                        <div className='multi-select-container'>
                          <label>Date Range</label>
                          <input
                            type="text"
                            value={`${selectedRange.startDate ? dayjs(selectedRange.startDate).format('MM/DD/YYYY') : 'Start Date'} - ${selectedRange.endDate ? dayjs(selectedRange.endDate).format('MM/DD/YYYY') : 'End Date'}`}
                            onClick={() => setShowModal(true)}
                            readOnly
                            className='date-text-field' disabled={filterDisabled.date}
                          />
                          <DateRangeModal
                            showModal={showModal}
                            setShowModal={setShowModal}
                            handleDateSelection={handleDateSelection}
                            selectedRange={selectedRange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                {/* chart component */}
                <Chart chartData={chartData} selectedMultiTeamValues={selectedMultiTeamValues} />
                {/* <Chart chartData={chartData} hoursYear={selectedYear?.value} /> */}

                <Htable tableData={tableData} setTableData={setTableData} refreshPage={refreshPage} setRefreshPage setExportObject={setExportObject} />
              </div>
            </div>
          </section>
        </div>
      </div>

    </Wrapper>
  );
};

export default Report;

const Wrapper = Styled.div`

.report-container{
   overflow:auto;
  }
   

  .HistoryOfRequest{
    
    min-width:1000px;
    
    
  }
    
    .bg{
      background: #e9eaed;
      /* padding: 20px; */
    }
    .profile-body{
background:#fff;
margin-top:14px;
    }
    .bg .row{
    --bs-gutter-x: 0px !important;
    }
    .sidebar-container{
      background: #FFFFFF;
border-radius: 16px;
margin:15px
    }
    .content-area{
      margin:0px 10px 0px 0px;
      width: 70%;
    }
    .action-row {
    display: flex;
    padding: 0px 10px;
    border-radius: 20px 20px 0px 0px;
    background: var(--white, #FFF);
	justify-content: flex-end;
}
  .right-btn-section{
    display: flex;
    gap: 20px;
    margin-top: -10px;
  }
    .row-1{
display: flex;
padding: 0px 10px 10px 10px;
border-radius: 0px 0px 20px 20px;
background: var(--white, #FFF); 
    }
    .row-1-title{
color: var(--black-text, #201E20);
font-size: 24px;
    }

    .filter-right-section{
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .row{
      @media(max-width: 1370px){
        > div{
          width: unset !important;
        }
      }
    }
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  width: 160px!important;
  border: 1px solid #636263;
border-radius: 8px;
  max-height: 2rem !important;
  overflow: auto !important;
}
.css-16xfy0z-control{
  width: 160px;
  border-radius: 8px;
}
.react-icon {
    height: 21px;
    width: 21px;
  }
  .btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 24px;

  }
  
  .btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 5px;
  }
  .popup-div {
  position: absolute;
  top: 175px;
    right: 35px;
  border-radius: 16px;
background: #FFF;
box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.50);
  padding: 20px;
  z-index: 9999;
  gap: 20px;
}

.popup-message {
  font-size: 16px;
  margin-bottom: 20px;
}
span.share-txt,span.export-txt {
    font-weight: 600;
}

.popup-buttons {
  display: flex;
align-items: flex-start;
gap: 20px;
align-self: stretch;
}

.popup-buttons button {
  display: flex;
padding: 10px ;
justify-content: center;
align-items: center;
gap: 16px;
flex: 1 0 0;
border-radius: 8px;
background: #2984FD;
  cursor: pointer;
  border: none;
  color: #FFF;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}
.date-text-field{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 180px;
  height: 38px;
  border: 1px solid rgb(99, 98, 99);
  border-radius: 8px;
  padding: 5px;
}

.custom-option {
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type='checkbox'] {
  margin-right: 8px; /* Add some spacing between the checkbox and label */
}

.brand-checkboxes {
  margin-left: 20px; /* Indent the brand checkboxes */
}


/* Style the labels to make them more visually appealing */
.checkbox-label {
  font-weight: bold;
  font-size: 14px;
  color: #333; /* Change the color to your desired text color */
}

/* Style the checkboxes */
.checkbox-container input[type='checkbox'] {
  cursor: pointer;
}

/* Style the selected checkboxes */
// .checkbox-container input[type='checkbox']:checked::before {
//   content: '✔'; /* You can use any symbol you like, or replace with a checkmark image */
//   font-size: 12px;
//   color: #007bff; /* Change the color to your desired selected color */
// }

.date-text-field{
    min-width: 183px;
    max-width: 200px !important;
}


`;
