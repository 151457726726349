import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Area
} from 'recharts';
import Styled from 'styled-components';
import { MdOutlineFileDownload } from 'react-icons/md';
import { BiFilterAlt } from 'react-icons/bi';
import ReactSelect from 'react-select';
import * as XLSX from 'xlsx';

const ChartRow3 = ({
  data,
  budgetYear,
  forecastYear,
  monthlyMarginData
}) => {
  // console.log('forecastYear',forecastYear);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const chartData = data?.map((item) => ({
    month: item.month,
    [`${forecastYear.value} Forecast`]: item.forecast,
    [`${budgetYear.value} Budget`]: item.budget,
  }));

  const data1 = monthlyMarginData;

  const exportChart = () => {
    const sheetName = 'Monthly Forecast And Budget';
    const sheetData = chartData?.map((item) => ({
      Month: item.month,
      [`${forecastYear?.value} Forecast`]: item[`${forecastYear?.value} Forecast`],
      [`${budgetYear?.value} Budget`]: item[`${budgetYear?.value} Budget`],
    }));

    const worksheet = XLSX.utils.json_to_sheet(sheetData, {
      header: ['Month', `${forecastYear?.value} Forecast`, `${budgetYear?.value} Budget`],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const fileName = 'monthly-forecast-and-budget.xlsx';
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    if (navigator.msSaveBlob) {
      // For IE browser
      navigator.msSaveBlob(data, fileName);
    } else {
      // For other browsers
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  };

  const exportMargin_hours = () => {
    // Prepare data for Excel export
    const exportData = data1.map((item) => {
      return {
        Month: item.month,
        Profit: item.Profit,
        ContributionMargin: item.ContributionMargin
      }
    });

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'MonthlyMarginHours');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, 'monthly_profitability.xlsx');
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>{`${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} className='label'>{`${entry.name} : $${entry.value?.toLocaleString()}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomTooltip1 = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>{`${label}`}</p>
          {payload.map((entry, index) => {
            if (index !== 1) {
              return <p key={index} className='label'>{`${entry.name} : ${(entry.name !== 'ContributionMargin' ? '$' : '')}${entry.value?.toLocaleString() + (entry.name === 'ContributionMargin' ? '%' : '')}`}</p>
            }
          })}
        </div>
      );
    }
    return null;
  };

  const wrapperStyle = {
    fontWeight: 'bold',
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper>
      <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <div className='section mt-3 me-2'>
            <div className='chart-filter-section'>
              <div className='chart-header'>
                <h3 className='fw-bold'>Monthly Revenue Forecast vs. Budget</h3>
              </div>
              <div className='chart-filter-right'>
                <div className='add-task-btn-container' onClick={exportChart}>
                  <div className='search-icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                  <input type='button' value='Export' />
                </div>
              </div>
            </div>

            <ResponsiveContainer width='100%' height={300}>
              <BarChart
                margin={{
                  top: 10,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
                data={chartData}
              >
                <CartesianGrid strokeDasharray='3 3' />
                {windowWidth < 1200 ? (
                  <XAxis dataKey='month' interval={0} angle={-45} textAnchor="end" />
                ) : (
                  <XAxis dataKey='month' />
                )}
                <YAxis tickFormatter={(value) => `$${value.toLocaleString()}`} />
                <Tooltip
                  content={<CustomTooltip />}
                  wrapperStyle={wrapperStyle}
                  cursor={{ fill: '#787878d0' }}
                />
                <Legend />
                <Bar dataKey={`${forecastYear?.value} Forecast`} fill='#2984FD' />
                <Bar dataKey={`${budgetYear?.value} Budget`} fill='#c6c6c6' />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>

          <div className="section mt-3 me-2 mb-3">
            <div className='chart-filter-section'>
              <div className='chart-header'>
                <h3 className='fw-bold'>Monthly Profitability</h3>
              </div>
              <div className='chart-filter-right'>
                <div className='add-task-btn-container' onClick={exportMargin_hours}>
                  <div className='search-icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                  <input type='button' value='Export' />
                </div>
              </div>
            </div>
            <div className='bar-chart'>
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                  margin={{
                    top: 5,
                    right: 0,
                    left: 30,
                    bottom: 5,
                  }}
                  data={data1}
                >
                  {windowWidth < 1200 ? (
                    <XAxis dataKey='month' interval={0} angle={-45} textAnchor="end" />
                  ) : (
                    <XAxis dataKey='month' />
                  )}
                  <YAxis yAxisId={2} orientation="right" tickLine={false} tickFormatter={(value) => `${value}%`} />
                  {/* <YAxis yAxisId={1} orientation="right" label={{ value: 'ContributionMargin%', angle: -90 ,position: 'left' ,offset:-40}} /> */}
                  <YAxis yAxisId={1} tickFormatter={(value) => `$${value.toLocaleString()}`} />
                  {/* <YAxis yAxisId={2} label={{ value: 'ContributionMargin', angle: -90  ,position: 'left',offset:0}} /> */}
                  <Tooltip
                    content={<CustomTooltip1 />}
                    wrapperStyle={wrapperStyle}
                    cursor={{ fill: '#787878d0' }}
                  />
                  {/* <Legend /> */}
                  <CartesianGrid stroke="#f5f5f5" />
                  {/* <Bar yAxisId={1} dataKey="hours" barSize={35} fill="#413ea0" /> */}
                  <Bar yAxisId={1} dataKey="Profit" fill="#2984FD" />
                  <Line yAxisId={2} type="monotone" dataKey="ContributionMargin" unit={'%'} strokeWidth={3} stroke="#E7F3AC" />
                  <Area yAxisId={2} type="monotone" dataKey="ContributionMargin" stroke="none" fill="#E7F3AC" tooltipType='none' fillOpacity={0.3} />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

    </Wrapper>
  );
};

export default ChartRow3;

const Wrapper = Styled.div`
   .section {
    border-radius: 16px;
    background: #FFF;
    /* margin: 0 10px 15px 0; */
    padding: 20px;
  }

  .chart-filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chart-header h3 {
    color: var(--black-text, #201E20);
    font-size: 18px;
font-style: normal;
font-weight: 500;
  }

  .chart-filter-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .add-task-btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 2px;
    color: #fff;
    cursor: pointer;
  }

  .add-task-btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 5px;
  }

  .react-icon {
    height: 22px;
    width: 22px;
  }

  .recharts-legend-item-text {
    color: var(--black-text, #201E20) !important;
  }
  .css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  /* min-width: 160px!important; */
  border: 1px solid #636263;
border-radius: 8px;
}
  .custom-tooltip {
    margin-left: -55px;
    background: white;
     padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(74, 5, 5, 0.15);
  }

  .custom-tooltip p {
    /* margin-left: -55px; */
  }

  .custom-tooltip .label {
    font-weight: bold;
  }
  .filter-icon-container {
    display: flex;
    padding: 12px 0px;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
}
  span.vs {
    margin-top: 20px;
}

.recharts-default-tooltip {
  margin-left: -55px;
  background: white;
  padding: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(74, 5, 5, 0.15);
}

.recharts-default-tooltip p, li.recharts-tooltip-item {
  font-weight: bold;
  font-size: 14px;
}
`;
