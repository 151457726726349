import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import { Sidebar } from '../Sidebar';
import { MdOutlineFileDownload } from 'react-icons/md';
import ReactSelect from 'react-select';
import axios from '../../../../utils/api';
import Loader from '../../../../components/LoaderWave';
import Chart from './Chart';
import dayjs from "dayjs"
import * as XLSX from 'xlsx'
import TokenServices from '../../../../utils/token.services';
import DateRangeModal from '../../insights/hours/calendar/DateRangeModal';
import { addDays } from 'date-fns';
import Pagination from '../../../../components/Pagination';

const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

const LoginHistory = () => {
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(dayjs.tz(new Date(), "Asia/Tokyo")),
    endDate: addDays(new Date(dayjs.tz(new Date(), "Asia/Tokyo")), 6), // Set end date as the end of the current week
    key: 'selection',
  });
  const [selectedYear, setSelectedYear] = useState([dayjs().format('YYYY')]);
  const [selectedMonth, setSelectedMonth] = useState([dayjs().format('MM')]);
  const [selectedMultiEmployeeOptions, setSelectedMultiEmployeeOptions] = useState([]);
  const [selectedMultiEmployeeValues, setSelectedMultiEmployeeValues] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [areFiltersFetched, setAreFiltersFetched] = useState(false)
  const [locationOptions, setlocationOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [selectedMultiLocationOptions, setSelectedMultiLocationOptions] = useState([]);
  const [selectedMultiLocationValues, setSelectedMultiLocationValues] = useState([]);
  const [selectedMultiDeviceOptions, setSelectedMultiDeviceOptions] = useState([]);
  const [selectedMultiDeviceValues, setSelectedMultiDeviceValues] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);

  const handleMultiSelectEmployeeChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiEmployeeOptions(selectedOptions);
    setSelectedMultiEmployeeValues(selectedValues);
    syncFilter(selectedOptions, selectedMultiLocationOptions, selectedMultiDeviceOptions, selectedRange)
  };

  const handleMultiSelectLocationChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiLocationOptions(selectedOptions);
    setSelectedMultiLocationValues(selectedValues);
    syncFilter(selectedMultiEmployeeOptions, selectedOptions, selectedMultiDeviceOptions, selectedRange)
  };

  const handleMultiSelectDeviceChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiDeviceOptions(selectedOptions);
    setSelectedMultiDeviceValues(selectedValues);
    syncFilter(selectedMultiEmployeeOptions, selectedMultiLocationOptions, selectedOptions, selectedRange)
  };

  const handleDateSelection = (range) => {
    setSelectedRange(range);
    syncFilter(selectedMultiEmployeeOptions, selectedMultiLocationOptions, selectedMultiDeviceOptions, range)
  };

  const syncFilter = async (selectedMultiEmployeeOptions, selectedMultiLocationOptions, selectedMultiDeviceOptions, selectedRange) => {
    const userData = TokenServices.getUser();
    const user_id = userData ? userData._id : '';

    if (user_id) {
      const response = await axios.post("/admin/setInsightFilter", {
        user_id,
        filter: {
          users: selectedMultiEmployeeOptions,
          locations: selectedMultiLocationOptions,
          devices: selectedMultiDeviceOptions,
          dateRange: selectedRange
        },
        page: 'loginHistory'
      })
    }
  }

  useEffect(() => {
    const userData = TokenServices.getUser();
    let userId = userData ? userData._id : '';
    let role = userData ? userData.role : '';
    axios
      .get(`/admin/resources_wopaging?user_id=${userId}&role=${role}`)
      .then((response) => {
        setEmployeeOptions(
          response?.data?.data.map((item, index) => ({
            value: item?._id,
            label: item?.first_Name + ' ' + item?.last_Name,
            key: index,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/admin/getLoginHistoryFilter`)
      .then((response) => {
        if (response.data.success) {
          setlocationOptions(response?.data?.locations?.map((item) => {
            return { label: item, value: item }
          }))
          setDeviceOptions(response?.data?.devices?.map((item) => {
            return { label: item, value: item }
          }))
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios.get(`/admin/getFilter?user_id=${userId}`).then((res) => {
      const { loginHistoryFilter } = res?.data?.data;
      if (loginHistoryFilter) {
        setSelectedMultiEmployeeOptions(loginHistoryFilter?.users || [])
        setSelectedMultiEmployeeValues(loginHistoryFilter?.users.map((option) => option.value) || [])
        setSelectedMultiLocationOptions(loginHistoryFilter?.locations || [])
        setSelectedMultiLocationValues(loginHistoryFilter?.locations.map((option) => option.value) || [])
        setSelectedMultiDeviceOptions(loginHistoryFilter?.devices || [])
        setSelectedMultiDeviceValues(loginHistoryFilter?.devices.map((option) => option.value) || [])
        if (loginHistoryFilter.dateRange) {
          setSelectedRange(loginHistoryFilter?.dateRange)
        }
        setAreFiltersFetched(true)
      } else {
        setAreFiltersFetched(true)
      }
    }).catch((error) => {
      setAreFiltersFetched(true)
      console.log('error', error);
    })
  }, []);

  const fetchData = () => {
    setLoading(true)
    axios
      .post(`/admin/getLoginHistory?page=${page}&limit=${perPage}`, {
        employee_id: selectedMultiEmployeeValues,
        location: selectedMultiLocationValues,
        device: selectedMultiDeviceValues,
        dateRange: selectedRange
      })
      .then((response) => {
        setData(response.data.data)
        setTotalPages(Number(response.data.total_pages));
        if (page > Number(response.data.total_pages)) {
          setPage(1);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  useEffect(() => {
    if (areFiltersFetched) {
      fetchData()
    }
  }, [selectedMultiEmployeeValues, selectedMultiLocationValues, selectedMultiDeviceValues, selectedRange, areFiltersFetched])


  const handleLoginHistory = () => {
    let { startDate, endDate } = selectedRange
    startDate = dayjs(startDate).add(1, "D").format("MM-DD-YYYY").toString()
    endDate = dayjs(endDate).format("MM-DD-YYYY").toString()
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data.map((value) => {
      return {
        "Employee Name": value.name,
        "Employee Email": value.email,
        "Location": value.location,
        "Device": value.device,
        "Login Date": dayjs(new Date(value.createdAt)).format('MM/DD/YYYY'),
        "Login Time": dayjs(new Date(value.createdAt)).format('HH:mm:ss')
      }
    }));

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'LoginHistory');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, `loginHistory(${startDate + "~" + endDate}).xlsx`);
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [page, perPage]);

  return (
    <Wrapper>
      {loading && <Loader />}
      <div className='HistoryOfRequest'>
        <section className='bg'>
          <div className='row flex-nowrap'>
            <div className='col-auto sidebar-container'>
              <Sidebar />
            </div>
            <div className='col py-3 content-area'>
              <div className='row-1'>
                <div className='filter-right-section'>
                  <div className='multi-select-container'>
                    <label>
                      Employee Name
                      <ReactSelect
                        id='multiSelectAccounts'
                        isMulti
                        options={employeeOptions}
                        value={selectedMultiEmployeeOptions}
                        onChange={handleMultiSelectEmployeeChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CheckboxOption }}
                      />
                    </label>
                  </div>

                  <div className='multi-select-container'>
                    <label>
                      Location
                      <ReactSelect
                        id='multiSelectAccounts'
                        isMulti
                        options={locationOptions}
                        value={selectedMultiLocationOptions}
                        onChange={handleMultiSelectLocationChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CheckboxOption }}
                      />
                    </label>
                  </div>

                  <div className='multi-select-container'>
                    <label>
                      Device
                      <ReactSelect
                        id='multiSelectAccounts'
                        isMulti
                        options={deviceOptions}
                        value={selectedMultiDeviceOptions}
                        onChange={handleMultiSelectDeviceChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CheckboxOption }}
                      />
                    </label>
                  </div>

                  <div className='multi-select-container'>
                    <label>Date Range</label>
                    <input
                      type="text"
                      value={`${selectedRange.startDate ? dayjs(selectedRange.startDate).format('MM/DD/YYYY') : 'Start Date'} - ${selectedRange.endDate ? dayjs(selectedRange.endDate).format('MM/DD/YYYY') : 'End Date'}`}
                      onClick={() => setShowModal(true)}
                      readOnly
                      className='date-text-field'
                    />
                    <DateRangeModal
                      showModal={showModal}
                      setShowModal={setShowModal}
                      handleDateSelection={handleDateSelection}
                      selectedRange={selectedRange}
                    />
                  </div>
                </div>
                <div className='btn-container btn-action-container' onClick={handleLoginHistory}>
                  <input type='button' value='Export' />
                  <div className='icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                </div>
              </div>
              {/* chart component */}
              <Chart chartData={data} />

              <Pagination
                total={totalPages}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default LoginHistory;

const Wrapper = Styled.div`
    
    .bg{
      background: #e9eaed;
      /* padding: 20px; */
    }
    .profile-body{
background:#fff;
margin-top:14px;
    }
    .bg .row{
    --bs-gutter-x: 0px !important;
    }
    .sidebar-container{
      background: #FFFFFF;
border-radius: 16px;
margin:15px
    }
    .content-area{
      margin:0px 10px 0px 0px;
      width: 70%;
    }
    .row-1{
display: flex;
padding: 20px;
justify-content: space-between;
align-items: center;
border-radius: 16px;
background: var(--white, #FFF); 
    }
    .row-1-title{
color: var(--black-text, #201E20);
font-size: 24px;
    }

    .filter-right-section{
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 2;
    flex-wrap: wrap;

    @media(max-width: 720px){
      flex-direction: column;
      gap: 10px;
      align-items: start;
    }
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  width: 180px!important;
  border: 1px solid #636263;
border-radius: 8px;
max-height: 38px !important;
  overflow: auto !important;
}
.css-16xfy0z-control{
  width: 180px;
  border-radius: 8px;
}
.date-range{
  display: flex;
  flex-direction: column;
}
.ant-picker{
    min-width: 200px;
    max-width: 220px !important;
    border: 1px solid #636263;
    border-radius: 8px;
    height: 2.4rem;
    // margin-top: 1.3rem;
}
.react-icon {
    height: 21px;
    width: 21px;
  }
  .btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 24px;

  }
  
  .btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 5px;
  }
  .popup-div {
  position: absolute;
  top: 175px;
    right: 35px;
  border-radius: 16px;
background: #FFF;
box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.50);
  padding: 20px;
  z-index: 9999;
  gap: 20px;
}

.popup-message {
  font-size: 16px;
  margin-bottom: 20px;
}
span.share-txt,span.export-txt {
    font-weight: 600;
}

.custom-option {
  display: flex;
  flex-direction: column;
}
.brand-checkboxes {
  margin-left: 20px; /* Indent the brand checkboxes */
}

.popup-buttons {
  display: flex;
align-items: flex-start;
gap: 20px;
align-self: stretch;
}

.popup-buttons button {
  display: flex;
padding: 10px ;
justify-content: center;
align-items: center;
gap: 16px;
flex: 1 0 0;
border-radius: 8px;
background: #2984FD;
  cursor: pointer;
  border: none;
  color: #FFF;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.date-text-field{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 38px;
  border: 1px solid rgb(99, 98, 99);
  border-radius: 8px;
  padding: 5px;
  min-width: 183px;
  max-width: 200px !important;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}
thead tr:first-child th { position: sticky; top: 0px; }

`;