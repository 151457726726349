import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateHeader } from '../redux/slices/appInfoSlice';
import { useSelector } from 'react-redux';
function PrivateRoute({ children }) {
  const user = useSelector((state) => state?.user?.user);
  const currentPath=useLocation()
  console.log(currentPath.pathname,"///path")
  console.log(user,'userffer');
  const [loggedIn, setLoggedIn] = useState(user._id ? true : false);
  console.log(loggedIn,'loggedIn');
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log(state);
  useEffect(()=>{
    if(user.role!=="Admin"){
    if(currentPath.pathname==="/setupResources" || currentPath.pathname==="/archive" || currentPath.pathname==="/loginhistory"){
      setLoggedIn(false)
    }
    

    }

    if(user.role==="Contractor"){
      if(currentPath.pathname=="/timer"){
        setLoggedIn(true)
      }
      else if(currentPath.pathname=="/profile"){
        setLoggedIn(true)
      }
      else if(currentPath.pathname=="/timerSettings"){
        setLoggedIn(true)
      }
      else if(currentPath.pathname=="/Password"){
        setLoggedIn(true)
      }
     
      else{
        setLoggedIn(false)
      }
    }

    if(user.role==="Employee"){
      if(currentPath.pathname ==="/scheduler"){
        setLoggedIn(false)
      }
    }

  },[currentPath])
  useEffect(() => {
    if (state?.headerTitle) {
      console.log(state.headerTitle);
      dispatch(updateHeader(state?.headerTitle));
    }
  }, [state]);
  return <>{loggedIn ? children : <Navigate to='/' replace='true' />}</>;
}

export default PrivateRoute;
