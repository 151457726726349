import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import {
  Table,
  Image,
  Button,
  ButtonGroup,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Axios from '../../../utils/api';
import { MdCheck, MdClose } from 'react-icons/md';
import EditableCell from './EditableCell';
import Pagination from '../../../components/Pagination';

function ExpandableRow(props) {
  const {
    data,
    refreshPage,
    setRefreshPage,
    currentMonthIndex,
    selectedYear,
    setLoading,
    switchValue,
  } = props;

  return (
    <>
      {data.map((item, index) => (
        <tr className='expandable-row' key={index}>
          <td colSpan='14'>
            <Table responsive>
              <tbody>
                <tr className='expandable-tr'>
                  {/* <td></td> */}
                  <td>{item?.brand_name}</td>
                  <EditableCell
                    initialValue={item?.forecast?.jan || item?.budget?.jan || 0}
                    checkmonth={currentMonthIndex <= 0}
                    brand_id={item?._id}
                    month={'jan'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.feb || item?.budget?.feb || 0}
                    checkmonth={currentMonthIndex <= 1}
                    brand_id={item?._id}
                    month={'feb'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.mar || item?.budget?.mar || 0}
                    checkmonth={currentMonthIndex <= 2}
                    brand_id={item?._id}
                    month={'mar'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.apr || item?.budget?.apr || 0}
                    checkmonth={currentMonthIndex <= 3}
                    brand_id={item?._id}
                    month={'apr'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.may || item?.budget?.may || 0}
                    checkmonth={currentMonthIndex <= 4}
                    brand_id={item?._id}
                    month={'may'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.jun || item?.budget?.jun || 0}
                    checkmonth={currentMonthIndex <= 5}
                    brand_id={item?._id}
                    month={'jun'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.jul || item?.budget?.jul || 0}
                    checkmonth={currentMonthIndex <= 6}
                    brand_id={item?._id}
                    month={'jul'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.aug || item?.budget?.aug || 0}
                    checkmonth={currentMonthIndex <= 7}
                    brand_id={item?._id}
                    month={'aug'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.sep || item?.budget?.sep || 0}
                    checkmonth={currentMonthIndex <= 8}
                    brand_id={item?._id}
                    month={'sep'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.oct || item?.budget?.oct || 0}
                    checkmonth={currentMonthIndex <= 9}
                    brand_id={item?._id}
                    month={'oct'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.nov || item?.budget?.nov || 0}
                    checkmonth={currentMonthIndex <= 10}
                    brand_id={item?._id}
                    month={'nov'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                  <EditableCell
                    initialValue={item?.forecast?.dec || item?.budget?.dec || 0}
                    checkmonth={currentMonthIndex <= 11}
                    brand_id={item?._id}
                    month={'dec'}
                    year={selectedYear.value}
                    setLoading={setLoading}
                    setRefreshPage={setRefreshPage}
                    switchValue={switchValue}
                    refreshPage={refreshPage}
                  />
                </tr>
              </tbody>
            </Table>
          </td>
        </tr>
      ))}
    </>
  );
}

const ForecastBudgetTable = ({
  data,
  setData,
  switchValue,
  selectedYear,
  setLoading,
  selectedMultiTeamValues,
  selectedMultiAccountsValues,
  selectedMultiPodValues,
  selectedBrandData,
  setRefreshPage,
  refreshPage,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  // console.log(currentMonth);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  // console.log(switchValue);

  const toggleExpandedRow = (rowId) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  useEffect(() => {
    setExpandedRows(data.map(row => row._id));
  }, [data])

  const getCurrentMonthIndex = () => {
    const currentDate = new Date();
    return currentDate.getMonth();
  };
  const currentMonthIndex = getCurrentMonthIndex();

  const fetchData = async () => {
    if (switchValue == 'forecast') {
      var apiCall = 'getAllForecasttAccounts';
    } else {
      var apiCall = 'getAllBudgettAccounts';
    }
    try {
      setLoading(true);
      const response = await Axios.post(`/admin/${apiCall}?page=${page}&limit=${perPage}`, {
        account_id: selectedMultiAccountsValues,
        team_ids: selectedMultiTeamValues,
        pod_ids: selectedMultiPodValues,
        brand_id: selectedBrandData.map((item)=>item.id),
        year: selectedYear?.value,
      });
      console.log(response.data.data)
      setData(response.data.data);
      setTotalPages(Number(response.data.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    setData([]);
    fetchData();
  }, [
    page,
    perPage,
    selectedMultiTeamValues,
    selectedMultiPodValues,
    selectedBrandData,
    selectedYear,
    switchValue,
    refreshPage,
  ]);

  return (
    <Wrapper>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Account Name</th>
            {months.map((month, index) => (
              <th key={month} className={index + 1 <= currentMonth ? 'past-month' : ''}>
                {month} {selectedYear?.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, index) => (
            <>
              <tr key={row._id}>
                <td className='sno'>{perPage * (page - 1) + index + 1}</td>
                <td>
                  <button className='btn btn-link' onClick={() => toggleExpandedRow(row._id)}>
                    {expandedRows.includes(row._id) ? <span>&#x25BC;</span> : <span>&#x25B6;</span>}
                  </button>
                  {row.name}
                </td>
                <td className={`${currentMonthIndex <= 0 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.jan || item?.budget?.jan || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 1 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.feb || item?.budget?.feb || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 2 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.mar || item?.budget?.mar || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 3 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.apr || item?.budget?.apr || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 4 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.may || item?.budget?.may || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 5 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.jun || item?.budget?.jun || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 6 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.jul || item?.budget?.jul || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 7 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.aug || item?.budget?.aug || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 8 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.sep || item?.budget?.sep || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 9 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.oct || item?.budget?.oct || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 10 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.nov || item?.budget?.nov || 0),
                    0,
                  )}
                </td>
                <td className={`${currentMonthIndex <= 11 ? 'after-month' : 'before-month'} ${currentYear > selectedYear?.value ? 'before-month' : ''}`}>
                  $
                  {row?.brands?.reduce(
                    (sum, item) => sum + (item?.forecast?.dec || item?.budget?.dec || 0),
                    0,
                  )}
                </td>
              </tr>
              {expandedRows.includes(row._id) && (
                <ExpandableRow
                  data={row.brands}
                  currentMonthIndex={currentMonthIndex}
                  selectedYear={selectedYear}
                  setLoading={setLoading}
                  switchValue={switchValue}
                  setRefreshPage={setRefreshPage}
                  refreshPage={refreshPage}
                />
              )}
            </>
          ))}
        </tbody>
      </Table>
      {/* <Pagination
        total={totalPages}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      /> */}
    </Wrapper>
  );
};

export default ForecastBudgetTable;

const Wrapper = Styled.div`

td{
    /* text-align: center; */
    vertical-align: middle;
    padding:0px 0px;
    border-color: #fff;
  }
th{
    padding: 10px 0px 10px 5px;
    /* text-align: center; */
    vertical-align: middle;
    border-color: #EAF3FF;
  }
  .third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff ;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff ;
}
.table-bordered>:not(caption)>*>* {
    border-width: 0 0px;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}

.expandable-tr {
    border-width: 0;
}
  .expandable-tr td{
    padding: 0px 0px !important;
}
  .expandable-row {
    border-width: 0;
}
  tr td {
    width: 100%;
    min-width: 190px;
}
.expandable-row tr td:first-child {
     width: 100%;
    min-width: 16px; 
    padding-left: 50px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 210px;
}
.sno{
  width: 10%;
    min-width: 20px;
    padding-left: 5px;
}
// tr td:last-child {
//     width: 40%;
//     min-width: 120px;
// }
.btn-link{
  --bs-btn-color:#000;
  text-decoration: none;
  padding: 6px;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.dollar{
   margin-right: -7px;
}
.editableInput{
    width:100%;
}

td.before-month {
    background-color: rgb(233, 233, 233) !important;
}


.table-responsive{
  height:auto;

  table {
    thead{
     z-index:1;
    }
    tbody{
      overflow-y: auto;
    }
  }
}
/* td {
  text-align: center;
}

th {
  text-align: center;
} */
    `;
