import { createSlice } from '@reduxjs/toolkit';



const initialState = {
  isTimerStarted:localStorage.getItem("is_timer_started")?true:false,
  timerDuration:localStorage.getItem("timer_duration")?localStorage.getItem("timer_duration"):"",
  timerDurationStartingMilliseconds:localStorage.getItem("timer_duration_milliseconds")?localStorage.getItem("timer_duration_milliseconds"):'',
};

export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    updateIsTimerStarted: (state, action) => {
      state.isTimerStarted = action.payload;
      localStorage.setItem("is_timer_started",action.payload)
    },
    updateTimerDuration: (state, action) => {
      state.timerDuration = action.payload;
      if(localStorage.getItem("is_timer_started")){
        localStorage.setItem("timer_duration",action.payload)
      }
      

    },
    updateTimerDurationStartingMilliseconds: (state, action) => {
      state.timerDurationStartingMilliseconds = action.payload;
      localStorage.setItem("timer_duration_milliseconds",action.payload)

    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateIsTimerStarted,
  updateTimerDuration,
  updateTimerDurationStartingMilliseconds,
} = timerSlice.actions;

export default timerSlice.reducer;
