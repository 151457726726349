import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Styled from 'styled-components';
import axios from '../../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { HiOutlineUser } from 'react-icons/hi';
import { HiOutlineMail } from 'react-icons/hi';
import { BiUserCircle } from 'react-icons/bi';
import { AiOutlineIdcard } from 'react-icons/ai';
import { BiUserCheck } from 'react-icons/bi';
import { HiOutlineUserGroup } from 'react-icons/hi';

function EditTeamsModal({
  showModal,
  setShowModal,
  refreshPage,
  setRefreshPage,
  editingUser,
  TeamManagerOption,
  ResourceOptions,
}) {
  // console.log('editing User',editingUser);
  const [errors, setErrors] = useState({});
  const [TeamName, setTeamName] = useState(editingUser?.name || '');
  const [includeInInsights,setIncludeInInsights]=useState(editingUser?.includeInInsights)
  const [SelectedTeamManagerOption, setSelectedTeamManagerOption] = useState({
    value: editingUser?.manager_id?._id,
    label: `${editingUser?.manager_id?.first_Name} ${editingUser?.manager_id?.last_Name}`,
  });
  const preSelectedOptions = editingUser?.resource_id?.map((row, index) => ({
    value: row?._id,
    label: `${row?.first_Name} ${row?.last_Name}`,
  }));
  const preSelectedValues = editingUser?.resource_id?.map((row, index) => [row?._id]);
  const [SelectedResourceOptions, setSelectedResourceOptions] = useState(preSelectedOptions);
  const [SelectedResourceValues, setSelectedResourceValues] = useState(preSelectedValues);

  const handleCloseModal = () => {
    setShowModal(false);
    setErrors({});
  };

  const handleTeamManagerSelect = (SelectedTeamManagerOption) => {
    setSelectedTeamManagerOption(SelectedTeamManagerOption);
  };
  const handleResourceSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedResourceOptions(selectedOptions);
    setSelectedResourceValues(selectedValues);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    console.log(SelectedResourceValues.length);
    if (!TeamName.trim()) {
      errors.name = 'Please enter the team name';
    }

    if (!SelectedTeamManagerOption.value) {
      errors.TeamManager = 'Please select Team Manager';
    }

    if (SelectedResourceValues.length <= 0) {
      errors.Resource = 'Please select people or resources';
    }

    setErrors(errors);
    // Check if any errors exist
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }

    const details = {
      name: TeamName,
      _id: editingUser._id,
      manager_id: SelectedTeamManagerOption.value && SelectedTeamManagerOption.value,
      resource_ids: SelectedResourceValues && SelectedResourceValues,
      includeInInsights
    };
    console.log('details', details);
    axios
      .put('/admin/team', details)
      .then((response) => {
        console.log(response);
        toast.success(response.data.message);
        setTimeout(() => {
          setShowModal(false);
          setRefreshPage(!refreshPage);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>Edit Team</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddSubmit}>
            <div class='row'>
              <div class='form-border'>
                <div class=''>
                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Team Name</label>
                      <div class='input-group mb-1'>
                        <span class='input-group-text'>
                          <HiOutlineUserGroup />
                        </span>
                        <input
                          type='text'
                          className={`form-control shadow-none ${errors.name ? 'is-invalid' : ''}`}
                          placeholder='Enter Team name'
                          value={TeamName}
                          onChange={(event) => setTeamName(event.target.value)}
                        />
                        {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-md-12 mt-3'>
                      <label class='form-label'>Team Manager Name</label>
                      <div class='input-group mb-1'>
                        <span class='input-group-text'>
                          <HiOutlineUser />
                        </span>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.TeamManager ? 'is-invalid' : ''
                          }`}
                          options={TeamManagerOption}
                          value={SelectedTeamManagerOption}
                          // value={
                          //     editingUser?.Manager_Name
                          //       ? { value: editingUser.Manager_Name, label: editingUser.Manager_Name }
                          //       : null
                          //   }
                          onChange={handleTeamManagerSelect}
                        />
                        {errors.TeamManager && (
                          <div className='invalid-feedback'>{errors.TeamManager}</div>
                        )}
                      </div>
                    </div>
                    <div class='col-md-12 mt-3'>
                      <label class='form-label'>People or Resources</label>
                      <div class='input-group mb-1'>
                        <span class='input-group-text'>
                          <HiOutlineUser />
                        </span>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.Resource ? 'is-invalid' : ''
                          }`}
                          id='TeamSelect'
                          options={ResourceOptions}
                          value={SelectedResourceOptions}
                          onChange={handleResourceSelect}
                          isMulti={true}
                        />
                        {errors.Resource && (
                          <div className='invalid-feedback'>{errors.Resource}</div>
                        )}
                      </div>
                    </div>

                    <div class='col-md-12 mt-3'>
                     
                     <div class='input-group mb-1'>
                      
                     <label class='form-label'>Include In Insights</label>               
                        <input type='checkbox' className='ms-3' defaultChecked={includeInInsights}
       onChange={() => setIncludeInInsights((state) => !state)}/>               
                                        </div>
                     
                   </div>
                  </div>

                  <div class='d-flex justify-content-center'>
                    <div class='mt-2 mx-2'>
                      <button
                        class='btn btn-primary employee-cancel-button'
                        type='button'
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                    </div>
                    <div class='mt-2 mx-2'>
                      <button class='btn btn-primary employee-save-button' type='submit'>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default EditTeamsModal;

const Wrapper = Styled.div`

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;
}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

.select-form-control {
    width:90%;
}
.select-form-control  div{
    border-radius: 8px;
}

.invalid-feedback{
  margin-left: 43px;
}

`;
