import React, { useState, useEffect,useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Styled from 'styled-components';

import { BiUserCheck, BiSolidTimeFive } from 'react-icons/bi';
import { MdAccessTimeFilled } from 'react-icons/md';
import { GiNetworkBars } from 'react-icons/gi';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import {FaPercentage,FaHourglass} from "react-icons/fa"
import { BsRepeat } from 'react-icons/bs';
import axios from '../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import WarningModal from "./WarningModal"
import UtilizationModal from './UtilizationModal';
import dayjs from "dayjs"

const initialError = { selectedTaskActivity: '', timeSpent: '', selectedBrand: '',hourPercentage:"",hours:"" };

const { RangePicker } = DatePicker;
function AddScheduler({
  showModal,
  setShowModal,
  refreshPage,
  setRefreshPage,
  data
}) {

console.log(data,"....data")

// const getAvailableHours=()=>{
//   let from= data.start
//   let to= data.end
//   from=dayjs(from).startOf("month").format("YYYY-MM-DD")
//       to=dayjs(to).endOf("month").format("YYYY-MM-DD")
//       console.log(from,to,"from to")

//       const totalMonths=dayjs(to).diff(from, 'month')+1
//       console.log(totalMonths)
      
//       const monthWiseAvailableHours={} 
//       for(let i=0;i<totalMonths;i++){
//           let initialMonth= dayjs(from).add(i,"month").format("MMM-YYYY")   
//        let month= dayjs(from).endOf("month").add(i,"month")
//        const totalDays=getDaysInMonth(month.$M+1,month.$y)
//       //  console.log(month,totalDays,"totalDays")

//        const totalHoursCurrentMonth= totalDays.length*8
       
//        if(!monthWiseAvailableHours.hasOwnProperty(month)){
//         monthWiseAvailableHours[initialMonth]=totalHoursCurrentMonth
//        } 
//       }
// }

// function getDaysInMonth(month, year) {
//   month--; // lets fix the month once, here and be done with it
//   var date = new Date(year, month, 1);
//   var days = [];
//    while(date.getMonth() === month) {

//       // Exclude weekends
//       var tmpDate = new Date(date);            
//       var weekDay = tmpDate.getDay(); // week day
//       var day = tmpDate.getDate(); // day

//       if (weekDay%6) { // exclude 0=Sunday and 6=Saturday
//           days.push(day);
//       }

//       date.setDate(date.getDate() + 1);
//   }

//   return days;
// }  

//   const user=useSelector((state)=>state.user.user)
  const [from, setFrom] = useState(new Date(dayjs(data?.start)));
  const [to, setTo] = useState(new Date(dayjs(data?.end)));
  const [hours,setHours]=useState(Number(data?.title?.substring(0,data?.title?.indexOf("Hrs"))))
  // const [hoursPercentage,setHoursPercentage]=useState(Math.ceil((Number(data?.title?.substring(0,data?.title?.indexOf("Hrs")))/180)*100))
  const [hoursPercentage,setHoursPercentage]=useState(data?.hour_percentage!=="0"?data?.hour_percentage:"")
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(initialError);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [resourceOptions,setResourceOptions]=useState([])
  const [selectedResources,setSelectedResources]=useState({label:"",value:""})
  const [brandOptions,setBrandOptions]=useState([])
  const [selectedBrand,setSelectedBrand]=useState({value:data?.brand_id,label:data?.brand})
  const [selectedAccount,setSelectedAccount]=useState({value:data?.account,label:data?.account_name})
  const imageAddress="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzHQv_th9wq3ivQ1CVk7UZRxhbPq64oQrg5Q&usqp=CAU"

  const [scheduler,setScheduler]=useState({label:data?.scheduler,value:data?.scheduler_id,image:data?.image})
  const [schedulerOptions,setSchedulerOptions]=useState([])
  const [utilisation,setUtilisation]=useState(10)
  const [showWarningModal,setShowWarningModal]=useState(false)
  const [warningModalData,setWarningModalData]=useState([])
  const [ignoreWarningMessage,setIgnoreWarningMessage]=useState(false)
  const [isHourDisabled,setIsHourDisabled]=useState(false)
  const [showUtilizationModal,setShowUtilizationModal]=useState(false)
  const [percentageHoursCount,setPercentageHoursCount]=useState(0)
  const [profileColor, setprofileColor] = useState(sessionStorage.getItem('color'));
  
  

  console.log(hours,hoursPercentage,"h hpercentage")

  // const percentageHourOnClick = () => {
  //   setEditing(!isEditing);
  // };
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // const handleTeamSelect = (SelectedTeamOptions) => {
  //   setSelectedTeamOptions(SelectedTeamOptions);
  // };
  const handleSelect = (selectedOptions,name) => {
    console.log(selectedOptions)
    setError(initialError)
    if(name=="resources"){
      
           if(selectedOptions.length===0){
        setError((prev)=>({...prev,selectedResources:"Please select resource"}))
        
      }
      
      setSelectedResources(selectedOptions)
    }
    if(name==="brand"){
      console.log(selectedOptions,"from select")
      if(selectedOptions.length===0){
        setError((prev)=>({...prev,selectedBrand:"Brand can't be empty"}))
        
      }
 
      setSelectedBrand(selectedOptions)
      setSelectedAccount({label:selectedOptions?.account?.name,value:selectedOptions?.account?._id})
    }
  };

  const validateFields=()=>{
    let errorCount=0
    if(!selectedBrand){
      setError((prev)=>({...prev,selectedBrand:"Brand can't be empty"}))
     errorCount++ 
    }
    if(![selectedResources].length>0){
      setError((prev)=>({...prev,selectedResources:"Please select resource"}))
      errorCount++
    }
    if(!hours){
      if(hoursPercentage<=0){
        setError((prev)=>({...prev,hourPercentage:"% Hour should be greater than 0"}))
        errorCount++

      }
      if(hoursPercentage>100){
        setError((prev)=>({...prev,hourPercentage:"% Hour shouldn't be greater than 100%"}))
        errorCount++

      }

    }
    else{
      if(hours<=0){
        setError((prev)=>({...prev,hours:"Hours should be greater than 0"}))
        errorCount++

      }
      if(hours>250){
        setError((prev)=>({...prev,hours:"Hours shouldn't be greater than 250"}))
        errorCount++

      }

    }
    
    
    if(errorCount>0){
      return true
    }
  }



  const hourChangeHandler=(e,name)=>{
    setError(initialError)
    if(name==="hourPercentage"){

      if(e.target.value<0){
        return
      }
    
      setHoursPercentage(Math.floor(e.target.value).toFixed(0))
    
      if(e.target.value<=0){
        setError((prev)=>({...prev,hourPercentage:"% Hour should be greater than 0"}))
        

      }
      if(e.target.value>100){
        setError((prev)=>({...prev,hourPercentage:"% Hour shouldn't be greater than 100%"}))
        
      }
      
    }
    if(name==="hours"){

      if(e.target.value<0){
        return
      }
     
      if(e.target.value>=0){
        setHours(Math.floor(e.target.value).toFixed(0))
      }
      
       

      if(e.target.value<=0){
        setError((prev)=>({...prev,hours:"Hours should be greater than 0"}))
        

      }
      if(e.target.value>250){
        setError((prev)=>({...prev,hours:"Hours shouldn't be greater than 250"}))
        
      }
     
    }
    // setHours(Math.floor((e.target.value*180)/100))
  }

  const handleEditSubmit= (e)=>{
    e.preventDefault()
     setError(initialError)
    if(validateFields()){
      return
    }
    const resource_id= selectedResources?.value
    const brand_id=selectedBrand?.value 
    const scheduler_id=scheduler?.value|| "64662107e979d6dde0a9e089"
    const account_id= selectedAccount?.value
    const modifyTo=dayjs(to).format("YYYY-MM-DD")
    const modifyFrom= dayjs(from).format("YYYY-MM-DD")
    const dataToSend={id:data?.eventId,to:modifyTo,from:modifyFrom,duration:hours?hours:"",resource_id,
    brand_id,scheduler_id,account_id,ignore_warning:ignoreWarningMessage,hour_percentage:hoursPercentage}

    axios
        .patch('/admin/scheduler', dataToSend)
        .then((response) => {
          // console.log(response);
          toast.success(response.data.message);
          setTimeout(() => {
            setShowModal(false);
            setRefreshPage(!refreshPage);
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.message==="warning"){
            setShowWarningModal(true)
            setWarningModalData(error.response.data.data)
          }
          //toast.error(error.response.data.message);
        });
    
  }

  const dateSelectionHandler = (e, name) => {
    if (e.target.name === 'from') {
      setFrom(e.target.value);
    }
    if (e.target.name === 'to') {
      setTo(e.target.value);
    }
  };
  const handleClick = () => {
    if(percentageHoursCount===0){
      setHoursPercentage("")
      setHours("")
      setPercentageHoursCount(1)
    }
    
    
  };

  const onHoursPercentageChange=(e)=>{
    setHoursPercentage(e.target.value)
    setHours("")
    // setHours(Math.floor((e.target.value*180)/100))
  }

  // useEffect(() => {
  //   console.log('abc');
  //   const element = document.querySelector('.picker-popup');
  //   if (element) {
  //     console.log('inside element', element);
  //     element.style['z-index'] = 9999;
  //     document.querySelector('.ant-picker-footer').style['display'] = 'none';
  //   }
  // }, [pickerStyle]);

  useEffect(() => {
    if (hoursPercentage>0) {
      // setHours(Math.ceil((hoursPercentage*180)/100))
      setIsHourDisabled(true)
      setHours("")
    }
    else{
      setIsHourDisabled(false)
    }
  }, [hoursPercentage]);

  useEffect(() => {
    axios
      .get('/admin/resources_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        const imageAddress="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzHQv_th9wq3ivQ1CVk7UZRxhbPq64oQrg5Q&usqp=CAU"
        const modifyResponse= response.data.data.map((item) => ({
          value: item._id,
          label: item.first_Name + ' ' + item.last_Name,
          image:item?.profile_pic || ''
        })).sort((a,b)=>{
          return a.label > b.label ? 1 : -1;
        })
        setResourceOptions(modifyResponse);
        setSchedulerOptions(modifyResponse)
        setSelectedResources({label:data?.resource,value:data?.resourceId,image:data?.image})
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get('/admin/getAllBrandsWop')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setBrandOptions(
          response.data.data.map((item) => ({
            value: item?._id,
            label: item?.brand_name,
            account:item?.account_id
          })).filter((item)=> item.label !== 'Internal').sort((a,b)=> a.label > b.label ? 1 : -1),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refreshPage]);


  // useEffect(()=>{
  //   setError(initialError)
  //   validateFields()
  // },[selectedResources,selectedBrand,hours,hoursPercentage])

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      {showWarningModal && (
        <WarningModal showModal={showWarningModal} setShowModal={setShowWarningModal}
         data={warningModalData} ignoreWarningMessage={ignoreWarningMessage} 
         setIgnoreWarningMessage={setIgnoreWarningMessage} />
      )}
      {showUtilizationModal && (
        <UtilizationModal showModal={showUtilizationModal} setShowModal={setShowUtilizationModal}
        users={[selectedResources].map((item)=>item.value)} from={from} to={to}   />
      )}

      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>Edit Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEditSubmit}>
            <div class='row'>
              <div class='form-border'>
                <div class=''>
                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>People or Resources</label>
                      <div class='input-group mb-1'>
                        <span class='input-group-text'>
                          <BiUserCheck />
                        </span>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          options={resourceOptions}
                          value={selectedResources}
                          onChange={(e)=>handleSelect(e,"resources")}
                          formatOptionLabel={item => (
                            <div className="user-option">
                              {item?.image ? (
                                <img src={item.image} alt="profile" />
                              ) : (
                                <div className='profile-image' style={{background: profileColor}}>
                                  <span>{item.label.toUpperCase()[0]}</span>
                                </div>
                              )}
                              <span>{item.label}</span>
                            </div>
                          )}
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div>

                  {/* <div class='row d-flex mt-2'>
                    <div class='col-md-12'>
                      <div class='input-group mb-1'>
                        <span class='input-group-text'>
                          <MdAccessTimeFilled />
                        </span>
                        <TimePicker
                          minuteStep={15}
                          hourStep={1}
                          format='HH:mm'
                          onClick={() => setPickerStyle(!pickerStyle)}
                          showNow={false}
                          value={timeSpent}
                          onSelect={(time) => {
                            setTimeSpent(time);
                            console.log(time);
                          }}
                          popupClassName='picker-popup'
                        />
                        {error.timeSpent && <div className='text-danger'>{error.timeSpent}</div>}
                        {errors.team && <div className="invalid-feedback">{errors.team}</div>}
                      </div>
                    </div>
                  </div> */}

                 <div className=' d-flex'>
                    <div className=''>
                      <label className='form-label'>From</label>
                      {/* <div className='input-group mb-1 mx-4'>
                        <input type="month"
                          value={from}
                          onChange={(e) => setFrom(e.target.value)}
                          className='form-control shadow-none date-inputs'
                          min="1900-01" max="2099-01"

                        />
                      </div> */}
                      <div className='input-group mb-1 mx-4' >
                      <DatePicker
                        showIcon
                        selected={from}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        className='form-control shadow-none date-inputs'

                        onChange={(date) => setFrom(new Date(date))}
                      />
                      </div>
                    </div>
                    <div className=''>
                      <label className='form-label'>To</label>
                      <div className='input-group mb-1 mx-4'>
                      <DatePicker
                        showIcon
                        selected={to}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        className='form-control shadow-none date-inputs'

                        onChange={(date) => setTo(new Date(date))}
                      />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                     <button className='btn btn-primary mx-4 mt-2' type="button" onClick={()=>setShowUtilizationModal(true)}>
                      Remaining hours</button>
                    </div>
                  </div>

                 
                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='form-label my-2'>Allocation%</label>
                      <div className='input-group mb-1'>
                        <span className='input-group-text'>
                          <FaHourglass />
                        </span>
                        <input type="number" step={1} className='form-control shadow-none remove-arrow' placeholder=""
                         value={hoursPercentage||""} onChange={(e)=>hourChangeHandler(e,"hourPercentage")}
                         onClick={handleClick}
                        />

                        </div>
                        {error.hourPercentage && <div className='ms-4 text-danger'>{error.hourPercentage}</div>}


                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='form-label'>Hours</label>
                      <div className='input-group mb-1'>
                        <span className='input-group-text'>
                          <FaHourglass />
                        </span>
                        <input type="number" className='form-control shadow-none remove-arrow' placeholder=""
                         value={hours}   onChange={(e)=>hourChangeHandler(e,"hours")} disabled={isHourDisabled}
                        />
                    
                        </div>
                        {error.hours && <div className=' ms-4 text-danger'>{error.hours}</div>}

                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='form-label'>Brand</label>
                      <div className='input-group mb-1'>
                        <span className='input-group-text'>
                          <AiOutlineUserSwitch />
                        </span>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.taskType ? 'is-invalid' : ''
                          }`}
                          options={brandOptions}
                          value={selectedBrand}
                          onChange={(e) => handleSelect(e,"brand")}
                        />
                        {errors.taskType && (
                          <div className='invalid-feedback'>{errors.taskType}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='form-label'>Parent Account</label>
                      <div className='input-group mb-1'>
                        <span className='input-group-text'>
                          <AiOutlineUserSwitch />
                        </span>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.taskType ? 'is-invalid' : ''
                          }`}
                          isDisabled={true}
                          value={selectedAccount}
                          
                        />
                        {errors.taskType && (
                          <div className='invalid-feedback'>{errors.taskType}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='form-label'>Scheduler</label>
                      <div className='input-group mb-1'>
                        <span className='input-group-text'>
                          <AiOutlineUserSwitch />
                        </span>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.taskType ? 'is-invalid' : ''
                          }`}
                          options={schedulerOptions}
                          value={scheduler}
                          formatOptionLabel={item => (
                            <div className="user-option">
                              {item?.image ? (
                                <img src={item.image} alt="profile" />
                              ) : (
                                <div className='profile-image' style={{background: profileColor}}>
                                  <span>{item.label.toUpperCase()[0]}</span>
                                </div>
                              )}
                              <span>{item.label}</span>
                            </div>
                          )}
                          onChange={(selectedOption) => setScheduler(selectedOption)}
                        />
                        {errors.taskType && (
                          <div className='invalid-feedback'>{errors.taskType}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class='d-flex justify-content-center'>
                    <div class='mt-2 mx-2'>
                      <button
                        class='btn btn-primary employee-cancel-button'
                        type='button'
                        onClick={() => handleCloseModal()}
                      >
                        Cancel
                      </button>
                    </div>
                    <div class='mt-2 mx-2'>
                      <button class='btn btn-primary employee-save-button' type='submit'>
                        Edit Booking
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default AddScheduler;

const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

.date-inputs{
    max-width:200px;
}
.repeat-icon{
    color:blue;
    font-size:20px;
    margin-right:20px;
    font-weight:bold;
}
.repeat-text{
    cursor:pointer;
    font-weight:bold;
    text-decoration:none;
}

.ant-picker-body{
    display:block;
}

.user-option>img{
  width:30px;
  height:30px;
  border-radius:50%;
  background-size:cover;
}

.user-option{
  display: flex;
  align-items: center;
  gap: 10px;
  .profile-image{
    width:30px;
    height:30px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    border-radius:50%;
    background-size:cover;
  }
}

.remove-arrow::-webkit-inner-spin-button, .remove-arrow::-webkit-outer-spin-button{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

`;
