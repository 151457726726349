import React from 'react';
import { Table } from 'react-bootstrap';
import Styled from 'styled-components';
import dayjs from 'dayjs';

const Chart = ({ chartData }) => {
    return (
        <Wrapper>

            <div className='third-row'>
                <>
                    <Table responsive bordered>
                        <thead  >
                            <tr>
                                <th className='text-center'>Employee Name</th>
                                <th className='text-center'>Employee Email</th>
                                <th className='text-center'>Location</th>
                                <th className='text-center'>Device</th>
                                <th className='text-center'>Login Date</th>
                                <th className='text-center'>Login Time</th>

                            </tr>
                        </thead>
                        <tbody>
                            {chartData?.map((row, i) => (
                                <>
                                    <tr key={i}>
                                        <td className='text-center'>{row.name}</td>
                                        <td className='text-center'>{row.email}</td>
                                        <td className='text-center'>{row.location}</td>
                                        <td className='text-center'>{row.device}</td>
                                        <td className='text-center'>{dayjs(new Date(row.createdAt)).format('MM/DD/YYYY')}</td>
                                        <td className='text-center'>{dayjs(new Date(row.createdAt)).format('HH:mm:ss')}</td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </Table>
                </>
            </div>
        </Wrapper>
    );
};

export default Chart;

const Wrapper = Styled.div`
 
//from hours

.third-row{
    margin-top: 15px;
    border-radius: 16px;
    padding:20px;
    background: #fff;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
.overlap{
  margin-left:-12px
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
  .expandable-tr {
    border-width: 0;
    background:grey;
}
.expandable-tr-2 {
    border-width: 0;
    border-top:0;
}
.expandable-tr-2 td {
  padding: 10px 10px !important;
}
  .expandable-tr td{
    padding: 10px 10px !important;
}
  .expandable-row {
    border-width: 0;
}
//   tr td {
//     width: 100%;
//     min-width: 220px;
// }
.expandable-row tr td {
     width: 100px;
}
.sno{
  width: 10%;
    min-width: 20px;
}
tr td:last-child {
    // width: 40%;
    // min-width: 120px;
}

#toggle-switch:checked{
  background-color: #008000; /* checked color */
}
#toggle-switch:not(:checked){
  background-color: #908F90; /* Unchecked color */
  color: #fff !important;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e")
;

}
.btn-link{
  --bs-btn-color:#000;
  text-decoration: none;
}

.total-duration-text{
  text-align: center;
}

@media(min-width:1400px){
  .total-duration-text{
    text-align: left;
  }
}



td{
  /* text-align: center; */
  vertical-align: middle;
  padding:3px 10px;
  border-color: #fff;
}
tr th{
padding:10px 10px;
  /* text-align: center; */
  vertical-align: middle;
  border-color: #EAF3FF;
  cursor: pointer;
  font-size: 0.9rem;
  border-right: 1px solid #dcdfe5;
  max-width:300px!important;
}

// tr td:nth-child(2){
//   font-weight:bold;
// }

/* Default style for the arrow icon */
.arrow {
  font-size: 12px; /* Adjust the font size as needed */
  color: #ccc; /* Default color */
  margin-left: 5px;
  float: right;

}

/* Style for ascending order */
.asc .arrow-up{
  color: #7a7c7f;
}



/* Style for descending order */
.desc .arrow-down{
  color: #7a7c7f;
}

.table-responsive{
   height:auto;

  /* table {
    thead{
      position: sticky !important;
      top: -1px;
      z-index: 0;
    }
    tbody{
      overflow-y: auto;
    }
  } */
}

`;


{/* <div className='col-sm-7'>
<div className='section secttion-right'>
  <div className='hSpent-text'>Monthly Margin</div>
  <div className='bar-chart'>
  <ResponsiveContainer width="100%" height={408}>
    <ComposedChart
    margin={{
      top: 5,
      right: 0,
      left: 10,
      bottom: 5,
    }}
  data={data}
  >
<XAxis dataKey="month" />
<YAxis yAxisId={1} orientation="right" />
<YAxis yAxisId={2} />
<Tooltip />
<CartesianGrid stroke="#f5f5f5" />
<Bar yAxisId={1} dataKey="contributionMarginPercentage" fill="#413ea0" />
<Line yAxisId={2} type="monotone" dataKey="contributionMargin" stroke="#ff0000" />
</ComposedChart>
</ResponsiveContainer>
  </div>
</div>
</div> */}
