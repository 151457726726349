//pushed merge vala
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import Styled from 'styled-components';
import Pagination from '../../../../components/Pagination';
// import Pagination from './Pagination';
import Axios from '../../../../utils/api';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import TokenServices from '../../../../utils/token.services';
import { toast } from 'react-toastify';

const TableComp = ({ runAPI, setisExportClicked, setShowPopup, isExportClicked, ApplyDateFilter, selectedMultiPodValues, selectedMultiTeamValues, selectedMultiAccountsValues, selectedBrandData, selectedMultiEmployeeValues, selectedYear, selectedMonth, setLoading, areFiltersFetched, employeeOptions, employeeFetched }) => {
  // Set the default time zone for the application
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [cumulativeTotals, setCumulativeTotals] = useState([{
    grandTotalUserTimersSubtotal: 0,
    grandTotalAccountSubtotal: 0,
    variance: 0,
    currentPage: 0
  }]);
  // console.log(isExportClicked);
  // const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(6);
  const [paginationText, setPaginationText] = useState('Show accounts per page:');
  // const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [previouSum, setPreviouSum] = useState(0);


  // getAllSchedule
  const fetchData = async (currentPage) => {
    try {
      setLoading(true);
      const userData = TokenServices.getUser();
      let updatedEmployeeValue = [...selectedMultiEmployeeValues];
      if (userData && userData.role === 'Employee') {
        updatedEmployeeValue = [userData._id];
      } else if (userData && userData.role === 'Manager' && selectedMultiEmployeeValues.length === 0 && employeeOptions.length > 0) {
        updatedEmployeeValue = employeeOptions.map(emp => emp.value);
      }
      const response = await Axios.post(
        `/admin/schedule?page=${currentPage}&limit=${perPage}`, {
        pod_ids: selectedMultiPodValues,
        team_ids: selectedMultiTeamValues,
        account_id: selectedMultiAccountsValues,
        brand_id: selectedMultiAccountsValues.length > 0 ? selectedBrandData : [],
        employee_id: updatedEmployeeValue,
        year: selectedYear,
        month: selectedMonth,
      });
      console.log(response.data.data)
      const responseData = response.data.data;
      setTotalPages(response.data.total_pages);

      const aggregatedDataArray = [];
      let currentAccount = "";
      let currentBrand = "";
      let accountSubtotal = 0;
      let brandSubtotal = 0;
      let accountUserTimersSubtotal = 0; // Separate subtotal for account level
      let brandUserTimersSubtotal = 0;  // Separate subtotal for brand level

      // Helper function to convert time duration to minutes
      const convertTimeToMinutes = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      };

      // Helper function to format duration as "0:00"
      const formatDuration = (minutes) => {
        // debugger;
        if (minutes === 0) {
          return '0:00';
        }
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`;
      };

      // Iterate through the data array
      responseData.forEach((item) => {
        const { account_id, brand_id, duration, user_timers, from } = item;
        const accountName = account_id.name;
        const brandName = brand_id.brand_name;



        // Check if the brand changes
        if (brandName !== currentBrand) {
          // Insert brand subtotal
          if (currentBrand !== "") {
            aggregatedDataArray.push({
              brandSubtotalDuration: brandSubtotal,
              userTimersSubtotalDuration: formatDuration(brandUserTimersSubtotal), // Include user_timers subtotal at brand level
            });

            // Reset userTimers subtotal for brand
            brandUserTimersSubtotal = 0;
          }

          // Reset brand subtotal
          currentBrand = brandName;
          brandSubtotal = 0;
        }

        // Check if the account changes
        if (accountName !== currentAccount) {
          // Insert account subtotal
          if (currentAccount !== "") {
            aggregatedDataArray.push({
              accountSubtotalDuration: accountSubtotal,
              AccountuserTimersSubtotalDuration: formatDuration(accountUserTimersSubtotal),
            });

            // Reset userTimers subtotal for account
            accountUserTimersSubtotal = 0;
          }

          // Reset account subtotal
          currentAccount = accountName;
          accountSubtotal = 0;
        }

        // Aggregate data
        accountSubtotal += parseFloat(duration);
        brandSubtotal += parseFloat(duration);

        // Sum user_timers duration for the current month
        // const currentMonth = new Date(from).getMonth();
        const currentMonth = moment(from).tz('America/New_York').toDate().getMonth() + 1;
        const currentYear = moment(from).tz('America/New_York').toDate().getFullYear();

        user_timers.forEach((timer) => {
          const timerDate = moment(timer.date);
          const timerMonth = timerDate.tz('America/New_York').add(1, 'day').month() + 1;
          const timerYear = timerDate.tz('America/New_York').add(1, 'day').year();

          if (timerMonth === currentMonth && timerYear === currentYear) {
            accountUserTimersSubtotal += convertTimeToMinutes(timer.duration);
            brandUserTimersSubtotal += convertTimeToMinutes(timer.duration);
          }
        });

        aggregatedDataArray.push(item);
      });

      // Insert the last subtotals
      if (currentBrand !== "") {
        aggregatedDataArray.push({
          brandSubtotalDuration: brandSubtotal,
          userTimersSubtotalDuration: formatDuration(brandUserTimersSubtotal), // Include user_timers subtotal at the last brand
        });
      }

      if (currentAccount !== "") {
        aggregatedDataArray.push({
          accountSubtotalDuration: accountSubtotal,
          AccountuserTimersSubtotalDuration: formatDuration(accountUserTimersSubtotal),
        });
      }

      // Grand totals
      const grandTotalAccountSubtotal = aggregatedDataArray.reduce((total, item) => {
        return total + (item.accountSubtotalDuration || 0);
      }, 0);

      const grandTotalUserTimersSubtotal = aggregatedDataArray.reduce((total, item) => {
        return total + convertTimeToMinutes(item.AccountuserTimersSubtotalDuration || '0:00');
      }, 0);
      console.log(grandTotalAccountSubtotal.length, 'grandTotalAccountSubtotal');
      // console.log(formatDuration(grandTotalUserTimersSubtotal),'grandTotalUserTimersSubtotal');
      // Add grand totals to the end of the array
      if (grandTotalAccountSubtotal || grandTotalUserTimersSubtotal) {
        aggregatedDataArray.push({
          grandTotalAccountSubtotal,
          grandTotalUserTimersSubtotal: formatDuration(grandTotalUserTimersSubtotal),
        });
      }

      // Log the result
      console.log(aggregatedDataArray, 'aggregatedDataArray');


      setExportData(aggregatedDataArray);

      const toCheckDuplication = JSON.parse(JSON.stringify(aggregatedDataArray));
      const mergedDataMap = new Map();

      toCheckDuplication.forEach((item) => {

        const { account_id, brand_id, user_id, duration, user_timers } = item;
        // const { _id } = user_id;

        // Check if account_id, brand_id, and _id exist
        if (account_id && brand_id && user_id) {

          const key = `${account_id.name}-${brand_id.brand_name}-${user_id._id}`;
          // console.log('Key:', key);
          // Check if an item with the same key already exists in the map
          if (mergedDataMap.has(key)) {
            const existingItem = mergedDataMap.get(key);

            // Combine duration
            existingItem.duration = (parseFloat(existingItem.duration) || 0) + (parseFloat(duration) || 0);
            // Combine user_timers
            user_timers.forEach((timer) => {
              // debugger;
              // Extract the month part from the timer date
              // const timerMonth = moment(timer.date).tz('America/New_York').toDate().getMonth() + 1;
              const timerMonth = moment(timer.date).tz('America/New_York').add(1, 'day').month() + 1;
              const timerYear = moment(timer.date).tz('America/New_York').add(1, 'day').year();
              // const timerYear = moment(timer.date).tz('America/New_York').toDate().getFullYear();

              // Extract the month part from the item.from date
              const fromMonth = moment(item.from).tz('America/New_York').toDate().getMonth() + 1;
              const fromYear = moment(item.from).tz('America/New_York').toDate().getFullYear();
              // debugger;
              // Check if the item.from month is equal to the timer date month
              if (fromMonth === timerMonth && timerYear === fromYear) {
                existingItem.user_timers.push({ date: existingItem.from, duration: timer.duration });
              }
            });
          } else {
            // If no matching item exists, add the current item to the map
            mergedDataMap.set(key, { ...item });
          }
        }
        else {
          // If any of the required fields is missing, just add the item to the map
          mergedDataMap.set(item, { ...item });
        }
      });

      // Convert map values to an array
      const mergedDataArray = Array.from(mergedDataMap.values());

      // Log the result
      console.log(mergedDataArray, 'mergedDataArray');
      setData(mergedDataArray);
      // setData(response.data.data);

      // setTotalPages(Number(response.data.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };



  useEffect(() => {
    setCurrentPage(1);
    const fetchDataAsync = async () => {
      setData([]);
      if (areFiltersFetched && employeeFetched) {
        const userData = TokenServices.getUser();
        if (userData.role === 'Admin'|| userData.role==="Employee" || (userData.role !== 'Admin' && (selectedMultiPodValues.length > 0 || selectedMultiTeamValues.length > 0 || selectedMultiAccountsValues.length > 0 || selectedMultiEmployeeValues.length > 0))) {
          await fetchData(1);
        }else{
          toast.error('Select at least one filter');
        }
      }
    };

    fetchDataAsync();
  }, [
    // selectedMultiPodValues, selectedMultiTeamValues,
    // selectedMultiAccountsValues, selectedBrandData, selectedMultiEmployeeValues,
    // ApplyDateFilter
    runAPI
  ]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setData([]);
      if (areFiltersFetched && employeeFetched) {
        await fetchData(currentPage);
      }
    };

    fetchDataAsync();
  }, [currentPage, perPage]);


  const durationToMinutes = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const getTotalDuration = (row) => {

    // Extract the month and year from 'row.from'
    const rowMonth = moment(row.from).tz('America/New_York').toDate().getMonth() + 1;
    const rowYear = moment(row.from).tz('America/New_York').toDate().getFullYear();
    // if (row.user_id.first_Name == 'Dakota') {
    //   debugger;
    //   console.log(row.user_id.first_Name);
    // }
    // Calculate the sum of durations in minutes for the specified month and year
    const totalMinutes = row?.user_timers
      ?.filter((timer) => {
        const timerMonth = moment(timer.date).tz('America/New_York').add(1, 'day').month() + 1;
        // console.log(moment(timer.date).tz('America/New_York').add(1, 'day'));
        const timerYear = moment(timer.date).tz('America/New_York').add(1, 'day').year();

        // Compare both month and year
        return (timerMonth === rowMonth) && (timerYear === rowYear);
      })
      .reduce((total, timer) => total + durationToMinutes(timer.duration), 0);

    // Convert total minutes back to the format hh:mm
    return `${Math.floor(totalMinutes / 60)}:${(totalMinutes % 60)
      .toString()
      .padStart(2, "0")}`;
  };


  // Function to subtract durations in the format "hh:mm"
  function subtractDurations(duration1, duration2) {
    // debugger;

    // console.log(duration1, 'duration1');
    // console.log(duration2, 'duration2');
    duration2 = duration2 + ':0'
    const [hours1, minutes1] = duration1.split(':').map(Number);
    const [hours2, minutes2] = duration2.split(':').map(Number);

    const totalMinutes1 = hours1 * 60 + minutes1;
    // const totalMinutes2 = duration2 * 60 ;
    const totalMinutes2 = hours2 * 60 + minutes2;
    // console.log(totalMinutes2);

    const differenceMinutes = totalMinutes1 - totalMinutes2;

    // Calculate hours and remaining minutes
    const hoursDifference = Math.floor(Math.abs(differenceMinutes) / 60);
    const minutesDifference = Math.abs(differenceMinutes % 60);

    // Format the result as "hh:mm" with correct sign
    const sign = differenceMinutes < 0 ? '-' : '';
    return `${sign}${hoursDifference}:${String(minutesDifference).padStart(2, '0')}`;
  }

  const getVariance = (row) => {
    // debugger;
    if (!row) {
      return null; // Handle the case where the duration is not available
    }
    if (row?.userTimersSubtotalDuration != null && row?.brandSubtotalDuration != null) {
      return subtractDurations(row.userTimersSubtotalDuration, row.brandSubtotalDuration);
    }

    if (row?.AccountuserTimersSubtotalDuration != null && row?.accountSubtotalDuration != null) {
      return subtractDurations(row.AccountuserTimersSubtotalDuration, row.accountSubtotalDuration);
    }
    if (row?.grandTotalUserTimersSubtotal != null && row?.grandTotalAccountSubtotal != null) {
      return subtractDurations(row.grandTotalUserTimersSubtotal, row.grandTotalAccountSubtotal);
    }

    const totalDuration = getTotalDuration(row);
    if (!totalDuration) {
      return null; // Handle the case where the total duration is not available
    }

    // Subtract the individual row duration from the total duration
    return subtractDurations(totalDuration, row.duration);
  };
  const bgColor = { color: 'blue' }
  //function to handle Excel export

  useEffect(() => {
    if (data.length > 0) {
      let newCumulativeTotals = {
        grandTotalUserTimersSubtotal: 0,
        grandTotalAccountSubtotal: 0,
        variance: 0
      };


      data.forEach((row, index) => {
        if (row?.grandTotalAccountSubtotal != null) {
          const userTimersSubtotal = parseFloat(row?.grandTotalUserTimersSubtotal.replace(':', '.')).toFixed(2);
          const accountSubtotal = parseFloat(row?.grandTotalAccountSubtotal.toFixed(2));
          const variance = parseFloat(getVariance(row).replace(':', '.')).toFixed(2);

          newCumulativeTotals.grandTotalUserTimersSubtotal += parseFloat(userTimersSubtotal);
          newCumulativeTotals.grandTotalAccountSubtotal += parseFloat(accountSubtotal);
          newCumulativeTotals.variance += parseFloat(variance);
        }
      });

      console.log(cumulativeTotals, 'cumulativeTotals');
      console.log(newCumulativeTotals, 'newCumulativeTotals');
      console.log(previouSum, 'previouSum');
      // Update cumulative totals based on page navigation direction
      if (currentPage > previousPage) {
        setCumulativeTotals(prevTotals => [...prevTotals, {
          grandTotalUserTimersSubtotal: newCumulativeTotals.grandTotalUserTimersSubtotal,
          grandTotalAccountSubtotal: newCumulativeTotals.grandTotalAccountSubtotal,
          variance: newCumulativeTotals.variance,
          currentPage: currentPage
        }]);
      }
      if (currentPage == 1) {
        setCumulativeTotals([{
          grandTotalUserTimersSubtotal: newCumulativeTotals.grandTotalUserTimersSubtotal,
          grandTotalAccountSubtotal: newCumulativeTotals.grandTotalAccountSubtotal,
          variance: newCumulativeTotals.variance,
          currentPage: currentPage
        }])
      }
      else if (currentPage < previousPage) {
        cumulativeTotals.pop();
        // setCumulativeTotals(prevTotals => ({
        //   grandTotalUserTimersSubtotal: (prevTotals.grandTotalUserTimersSubtotal - previouSum),
        //   grandTotalAccountSubtotal: (prevTotals.grandTotalAccountSubtotal - previouSum),
        //   variance: (prevTotals.variance - previouSum)
        // }));
      }

      // Update previous page
      setPreviousPage(currentPage);
    }
  }, [data]);

  console.log(cumulativeTotals, 'cumulativeTotals');

  const addTimes = (time1, time2) => {
    // Ensure time values are converted to strings with 2 decimal places
    time1 = Number(time1).toFixed(2).toString();
    time2 = Number(time2).toFixed(2).toString();

    // Split hours and minutes from the time strings
    const [hours1, minutes1] = time1.split('.').map(parseFloat);
    const [hours2, minutes2] = time2.split('.').map(parseFloat);

    let totalHours = hours1 + hours2;
    let totalMinutes = minutes1 + minutes2;

    // Adjust total hours and minutes if necessary
    if (totalMinutes >= 60) {
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;
    }

    // Format the result as hh.mm
    return `${totalHours.toString().padStart(2, '0')}.${totalMinutes.toString().padStart(2, '0')}`;
  };


  const woFilterData = data?.map((row, index) => {

    const variance = parseFloat(getVariance(row).replace(':', '.')).toFixed(2);

    if (variance == '0.00' && typeof (row?.duration) == 'undefined' && (row?.brandSubtotalDuration == 0 || row?.accountSubtotalDuration == 0)) {
      console.log(row, 'typeof(row?.duration)');
      return null;
    }
    if (variance == '0.00' && parseFloat(row?.duration) == 0) {
      console.log(parseFloat(row?.duration), 'parseFloat(row?.duration)');
      return null; // Skip rendering for this row
    }

    return (
      <tr key={row?._id}>
        {row?.account_id ? ( // Check if it's an account level row
          <>
            {/* Render account details */}
            <td>{row?.account_id?.name}</td>
            <td>{row?.brand_id?.brand_name}</td>
            <td>{row?.brand_id?.pod_id?.name}</td>
            <td>{row?.user_id?.team?.name}</td>
            <td>{row?.user_id?.first_Name} {row?.user_id?.last_Name}</td>
            <td className='text-center'>{parseFloat(getTotalDuration(row).replace(':', '.')).toFixed(2).replace('.', ':')} h</td>
            <td className='text-center'>{parseFloat(row?.duration).toFixed(2).replace('.', ':')} h</td>
            <td className='text-center'>{parseFloat(getVariance(row).replace(':', '.')).toFixed(2).replace('.', ':')} h</td>
          </>
        ) : (
          // It's not an account level row
          <>
            {/* Render brand level subtotal */}
            {row?.brandSubtotalDuration != null && (
              <>
                <td></td>
                <td className='dark-border'><b>Sub Total</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='text-center'><b>{parseFloat(row?.userTimersSubtotalDuration.replace(':', '.')).toFixed(2).replace('.', ':')} h</b></td>
                <td className='text-center'><b>{row?.brandSubtotalDuration.toFixed(2).replace('.', ':')} h</b></td>
                <td className='text-center'><b>{parseFloat(getVariance(row).replace(':', '.')).toFixed(2).replace('.', ':')} h</b></td>
              </>
            )}

            {/* Render account level total */}
            {row?.AccountuserTimersSubtotalDuration != null && (
              <>
                <td className='dark-border'><b>Total</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='text-center'><b>{parseFloat(row?.AccountuserTimersSubtotalDuration.replace(':', '.')).toFixed(2).replace('.', ':')} h</b></td>
                <td className='text-center'><b>{row?.accountSubtotalDuration.toFixed(2).replace('.', ':')} h</b></td>
                <td className='text-center'><b>{parseFloat(getVariance(row).replace(':', '.')).toFixed(2).replace('.', ':')} h</b></td>
              </>
            )}

            {/* Render grand total */}
            {row?.grandTotalAccountSubtotal != null && (
              <>
                {/* {currentPage === totalPages && cumulativeTotals.length > 0 && totalPages > 1 && (
                  <>
                    <td style={bgColor} className='dark-border'><b>Grand Total</b></td>
                    <td style={bgColor}></td>
                    <td style={bgColor}></td>
                    <td style={bgColor}></td>
                    <td style={bgColor}></td>
                    <td className='text-center' style={bgColor}>
                      <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.grandTotalUserTimersSubtotal), 0)}</b>
                    </td>
                    <td className='text-center' style={bgColor}>
                      <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.grandTotalAccountSubtotal), 0)}</b>
                    </td>
                    <td className='text-center' style={bgColor}>
                      <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.variance), 0)}</b>
                    </td>
                  </>
                )} */}
                {currentPage === totalPages && cumulativeTotals.length > 0 && totalPages > 1 && (
                  <>
                    <td style={bgColor} className='dark-border'><b>Grand Total</b></td>
                    <td style={bgColor}></td>
                    <td style={bgColor}></td>
                    <td style={bgColor}></td>
                    <td style={bgColor}></td>
                    <td className='text-center' style={bgColor}>
                      <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.grandTotalUserTimersSubtotal), 0)}</b>
                    </td>
                    <td className='text-center' style={bgColor}>
                      <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.grandTotalAccountSubtotal), 0)}</b>
                    </td>
                    <td className='text-center' style={bgColor}>
                      <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.variance), 0)}</b>
                    </td>
                  </>
                )}
                {(totalPages === 1) && (
                  //                   <>
                  //   <td style={bgColor} className='dark-border'><b>Grand Total</b></td>
                  //   <td style={bgColor}></td>
                  //   <td style={bgColor}></td>
                  //   <td style={bgColor}></td>
                  //   <td style={bgColor}></td>
                  //   <td style={bgColor} className='text-center'><b>{parseFloat(row?.grandTotalUserTimersSubtotal).toFixed(2).replace('.', ':')} h</b></td>
                  //   <td style={bgColor} className='text-center'><b>{row?.grandTotalAccountSubtotal.toFixed(2).replace('.', ':')} h</b></td>
                  //   <td style={bgColor} className='text-center'><b>{parseFloat(getVariance(row)).toFixed(2).replace('.', ':')} h</b></td>
                  // </>
                    <>
                      <td style={bgColor} className='dark-border'><b>Grand Total</b></td>
                      <td style={bgColor}></td>
                      <td style={bgColor}></td>
                      <td style={bgColor}></td>
                      <td style={bgColor}></td>
                      <td className='text-center' style={bgColor}>
                        <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.grandTotalUserTimersSubtotal), 0)}</b>
                      </td>
                      <td className='text-center' style={bgColor}>
                        <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.grandTotalAccountSubtotal), 0)}</b>
                      </td>
                      <td className='text-center' style={bgColor}>
                        <b>{cumulativeTotals.reduce((acc, curr) => addTimes(acc, curr.variance), 0)}</b>
                      </td>
                    </>
                )}
              </>
            )}



          </>
        )}
      </tr>
    );

  }).filter(Boolean)

  // Filter out null values and elements without props

  const sorted = woFilterData.sort((a, b) => {
    // debugger;
    // Compare Account names
    const accountNameA = (a?.props?.children?.props?.children[0]?.props?.children || '');
    const accountNameB = (b?.props?.children?.props?.children[0]?.props?.children || '');

    // Convert to lowercase if the value is a string
    const lowerCaseAccountNameA = typeof accountNameA === 'string' ? accountNameA.toLowerCase() : accountNameA;
    const lowerCaseAccountNameB = typeof accountNameB === 'string' ? accountNameB.toLowerCase() : accountNameB;



    // Compare Brand names
    const brandNameA = (a?.props?.children?.props?.children[1]?.props?.children || '');
    const brandNameB = (b?.props?.children?.props?.children[1]?.props?.children || '');

    // Convert to lowercase if the value is a string
    const lowerCaseBrandNameA = typeof brandNameA === 'string' ? brandNameA.toLowerCase() : brandNameA;
    const lowerCaseBrandNameB = typeof brandNameB === 'string' ? brandNameB.toLowerCase() : brandNameB;

    // If brand names are not equal, sort by brand name
    if (accountNameA === '' || accountNameB === '') {
      return
    }

    if (brandNameA === '' || brandNameB === '') {
      return
    }
    if (lowerCaseAccountNameA !== lowerCaseAccountNameB) {
      return lowerCaseAccountNameA < lowerCaseAccountNameB ? -1 : 1;
    }
    if (lowerCaseBrandNameA !== lowerCaseBrandNameB) {
      return lowerCaseBrandNameA < lowerCaseBrandNameB ? -1 : 1;
    }

    // If account names are not equal, sort by account name



    // Compare Actual Hours
    const actualHoursA = parseFloat(a?.props?.children?.props?.children[5]?.props?.children[0].replace(':', '.')) || 0;
    const actualHoursB = parseFloat(b?.props?.children?.props?.children[5]?.props?.children[0].replace(':', '.')) || 0;
    // console.log(actualHoursA,'actualHoursAactualHoursA');
    // Sort by actual hours
    return actualHoursB - actualHoursA;
  });



  console.log(sorted, 'sorted');
  // Function to handle page change
  return (
    <Wrapper>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='section-left'>
            <>
              <Table responsive bordered>
                <thead>
                  <tr>
                    {/* <th>S.No.</th> */}
                    <th className='text-center'>Account</th>
                    <th className='text-center'>Brand</th>
                    <th className='text-center'>POD</th>
                    <th className='text-center'>Team</th>
                    <th className='text-center'>Employee</th>
                    {/* <th className='text-center'>Month</th> */}
                    <th className='text-center'>Actual Hours</th>
                    <th className='text-center'>Schedule Hours</th>
                    <th className='text-center'>Variance</th>
                  </tr>
                </thead>

                <tbody>
                  {sorted
                  }
                </tbody>




              </Table>
              {/* {totalPages > 1 && <Pagination totalPages={totalPages} onPageChange={handlePageChange} />} */}
              {<Pagination
                total={totalPages}
                page={currentPage}
                setPage={setCurrentPage}
                perPage={perPage}
                setPerPage={setPerPage}
                paginationText={paginationText}
              />}
              {/* {totalPages > 1 &&<Pagination
                total={totalPages}
                page={currentPage}
                setPage={setCurrentPage}
                perPage={perPage}
                setPerPage={setPerPage}
                paginationText={paginationText}
              />} */}

              {/* <Pagination
                total={totalPages}
                // page={page}
                // setPage={setPage}
                // perPage={perPage}
                // setPerPage={setPerPage}
              /> */}
            </>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default TableComp;




const Wrapper = Styled.div`
  .section {
    border-radius: 16px;
    background: #FFF;
    margin: 15px 0;
    padding: 15px;
  }
  .section-left {
    border-radius: 16px;
    background: #FFF;
    margin: 15px 0;
  }
  .secttion-right{
    margin-left:10px;
  }
  .hSpent-text{
    color: #0D1821;
/* text-align: center; */
font-size: 20px;

  }
  
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
    z-index: 0 !important;
}
.table-responsive{
  border-radius: 16px;
    background: #fff;
}
td{
    /* text-align: center; */
    vertical-align: middle;
    padding:6px 10px;
    border-color: #fff;
  }
th{
  padding:10px 10px;
    /* text-align: center; */
    vertical-align: middle;
    border-color: #EAF3FF;
    border-right: 1px solid #dcdfe5;
  }
  .bar-chart{
    margin-top:20px
  }
tr:has(.dark-border) {
     color: black; 
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}



.pagination {
    display: flex;
    justify-content: center;
}

.pagination button {
    padding: 5px 10px;
    margin: 5px 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #f0f0f0;
}

.pagination button.active {
    background-color: #007bff;
    color: #fff;
}

`;
