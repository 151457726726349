import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import Select from 'react-select';
import { Header } from '../../../components/Header/Header';
// import EmployeeTabBody from './EmployeeTabBody'
// import { Sidebar } from "./Sidebar";
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Employee from './employee/Employee';
import Teams from './Teams/Teams';
import TaskActivity from './TaskActivity/TaskActivity';
import SkillSet from './SkillSet/SkillSet';
import Accounts from './Accounts/Accounts';

import Pod from './pod/Pod';
import Title from './title/Title';
const Archive = () => {
  const [activeTab, setActiveTab] = useState('Employee');
  // console.log(activeTab)

  const handleSelect = (key) => {
    console.log(key);
    if (key === 'first') setActiveTab('Employee');
    if (key === 'second') setActiveTab('Teams');
    if (key === 'third') setActiveTab('Task/Activity');
    if (key === 'fourth') setActiveTab('Title');
    if (key === 'fifth') setActiveTab('Skillset');
    if (key === 'sixth') setActiveTab('Accounts');
    if (key === 'seventh') setActiveTab('Pod');
  };

  return (
    <Wrapper>
      <div className=''>
        {/* header start */}
        {/* <Header /> */}
        {/* header end */}

        <section className='bg'>
          <div class='row flex-nowrap'>
            <div class='col py-0 content-area'>
              <Tabs ActiveKey={activeTab} onSelect={(e) => handleSelect(e)} className='tabs'>
                <Tab
                  eventKey='first'
                  title='Employee'
                  className='tab'
                  onClick={() => setActiveTab('Employee')}
                >
                  {activeTab === 'Employee' && <Employee activeTab={activeTab} />}
                  {/* <h1 style={{background:"red"}}>h</h1> */}
                </Tab>

                <Tab
                  eventKey='second'
                  title='Teams'
                  className='tab'
                  onClick={() => setActiveTab('Teams')}
                >
                  {/* <TicketTabBody screenName='Tickets' /> */}
                  {activeTab === 'Teams' && <Teams activeTab={activeTab} />}
                </Tab>
                <Tab
                  eventKey='third'
                  title='Task/Activity'
                  className='tab'
                  onClick={() => setActiveTab('Task/Activity')}
                >
                  {activeTab === 'Task/Activity' && <TaskActivity activeTab={activeTab} />}
                </Tab>
                <Tab
                  eventKey='fourth'
                  title='Title'
                  className='tab'
                  onClick={() => setActiveTab('Title')}
                >
                  {activeTab === 'Title' && <Title activeTab={activeTab} />}
                </Tab>
                <Tab
                  eventKey='fifth'
                  title='Skillset'
                  className='tab'
                  onClick={() => setActiveTab('Skillset')}
                >
                  {activeTab === 'Skillset' && <SkillSet activeTab={activeTab} />}
                </Tab>
                <Tab
                  eventKey='sixth'
                  title='Accounts'
                  className='tab'
                  onClick={() => setActiveTab('Accounts')}
                >
                  {activeTab === 'Accounts' && <Accounts activeTab={activeTab} />}
                </Tab>
                <Tab
                  eventKey='seventh'
                  title='Pod'
                  className='tab'
                  onClick={() => setActiveTab('Pod')}
                >
                  {activeTab === 'Pod' && <Pod activeTab={activeTab} />}
                </Tab>
              </Tabs>
            </div>
          </div>
        </section>
      </div>
      {/* <ToastContainer />  */}
    </Wrapper>
  );
};

export default Archive;

const Wrapper = Styled.div`

.react-icon{
    height: 22px;
    width: 22px;
}
    .bg .row{
    --bs-gutter-x: 0px !important;
    }

.tabs-ride-container{
    position:absolute;
    /* left:50%; */
    right: 1%;
    top:6px;
    display: flex;
flex-direction: row;
align-items: flex-end;
padding: 0px;
gap: 20px;
z-index: 2;

@media(max-width: 1200px){
  top: 60px !important;
}

@media(max-width:  631px){
  top: 100px !important;
  flex-direction: column;
  width: 100%;
  align-items: end;
  gap: 10px;
}
}
label {
    font-size: 12px;
    color: #201E20;
}
td{
    /* text-align: center; */
    vertical-align: middle;
    padding:2px 10px;
    border-color: #fff;
  }
th{
  padding:10px 10px;
    /* text-align: center; */
    vertical-align: middle;
    border-color: #EAF3FF;
    border-right: 1px solid #dcdfe5;
  }
.search-input-container {
  display: flex;
  align-items: center;
  width: 255px;
  height: 38px;

  border: 1px solid #636263;
border-radius: 8px;
  padding: 5px;
}
.add-emp-btn-container {
  display: flex;
  align-items: center;
  width: 150px;
  height: 38px;
  cursor:pointer;
  border: 1px solid #2984FD;
  background: #2984FD;
border-radius: 8px;
  padding: 5px;
  color:#fff;
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  width: 162px;
  border: 1px solid #636263;
border-radius: 8px;
max-height: 2rem !important;
overflow: auto !important;
}
.search-icon-container {
  margin-right: 5px;
}

.search-input-container input[type="text"] {
  flex: 1;
  border: none;
  outline: none;
}
.add-emp-btn-container input[type="button"] {
    background: #2984FD;
    color:#fff;
  flex: 1;
  border: none;
  outline: none;
  padding: 7px 0px;
}
.content-area{
    position:relative;
}

.tabs{
   
        background: #FFF;
        border-radius: 8px;
        padding:15px;
        @media(max-width: 1200px) {
          padding: 15px 15px 72px !important;
        }
      
        @media(max-width: 631px) {
          padding: 15px 15px 200px !important;
        }
    }
    .tab{
        /* margin: 20px 0; */
        background: #FFF;
        border-radius: 8px;
        color: #000;
    }

    .nav-tabs .nav-link {
      color  : #1C2B48 ;
      border:none;
    }
    .nav-tabs .nav-link:hover {
    isolation: isolate;
    border:none;
}
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #2984FD;
    border:none;    
    border-bottom: 2px solid #2984FD;
}  
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}

.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
@media screen and (max-width: 768px) {
    .nav-tabs {
    // display: block !important;
}
    /* .nav-tabs li{
      color: rgb(255, 255, 255);
    border-radius: 8px;
    background-color: rgb(28, 43, 72)
} */
}

/* td{
  text-transform:capitalize;
}
.email{
  text-transform:lowercase;
} */
tr td:nth-child(2){
  font-weight:bold;
}

`;
