import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Styled from 'styled-components';
import Axios from '../../../../utils/api';
import { GiNetworkBars } from 'react-icons/gi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditSkillSetModal({ showModal, setShowModal, refreshPage, setRefreshPage, selectedId }) {
  const [errors, setErrors] = useState({});
  const [skillset, setSkillset] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseModal = () => {
    setShowModal(false);
    setErrors({});
  };

  const getSkillset = async () => {
    try {
      const response = await Axios.get(`/admin/skillset?_id=${selectedId}`);

      setSkillset(response?.data?.data?.name);
    } catch (error) {
      console.error(error);
    }
  };

  const editSkillset = async () => {
    if (!skillset.trim()) {
      setErrors({ name: 'Please enter the skillset' });
      return;
    }
    try {
      setSuccessMessage('');
      setErrorMessage('');
      const response = await Axios.put(`/admin/skillset`, { name: skillset, _id: selectedId });
      toast.success(response.data.message);
      setTimeout(() => {
        setShowModal(false);
        setRefreshPage(!refreshPage);
      }, 1000);
    } catch (error) {
      toast.error(error.response.data.message);

      console.error(error);
    }
  };

  useEffect(() => {
    getSkillset();
  }, []);

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>Edit Skill Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class='row'>
            <div class='form-border'>
              <div class=''>
                <div className='input-container d-flex  align-items-center w-100  '>
                  <div className='me-3 mb-2'>
                    <GiNetworkBars />
                  </div>
                  <div className='w-100'>
                    <label htmlFor='podname'>Skill Set</label>
                    <div className='input-box'>
                      <input
                        type='text'
                        className={`form-control shadow-none ${errors.name ? 'is-invalid' : ''}`}
                        placeholder='e.g. Creative'
                        value={skillset}
                        onChange={(e) => setSkillset(e.target.value)}
                      />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>
                  </div>
                </div>

                <div class='d-flex justify-content-center'>
                  <div class='mt-2 mx-2'>
                    <button
                      class='btn btn-primary employee-cancel-button'
                      type='button'
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </button>
                  </div>
                  <div class='mt-2 mx-2'>
                    <button
                      class='btn btn-primary employee-save-button'
                      type='button'
                      onClick={editSkillset}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default EditSkillSetModal;

const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

`;
