import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/common.scss';
// import 'antd/dist/antd.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
// document.title = window.location.hostname === 'hub.code3.com' ? 'Code3 Hub' : 'Hub Sandbox';

// console.log = function no_console() {};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <GoogleOAuthProvider
        clientId={`922003399486-vepjbjeq9v4dricp2nr9tbetsqkhj20r.apps.googleusercontent.com`}
      >
        <App />
      </GoogleOAuthProvider>
    </React.StrictMode>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
