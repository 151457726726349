import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import { MdOutlineFileDownload } from 'react-icons/md';
import {
  BarChart, XAxis,
  YAxis, CartesianGrid,
  Bar, PieChart, Pie, Legend, Tooltip, Cell, Label, ResponsiveContainer
} from 'recharts';
import * as XLSX from 'xlsx'; // Import the xlsx library

const ChartRow1 = ({ selectedYear, accountsByYear, forecastSumByYear, budgetSumByYear, actualForecastSum, YoYData, ClientHours, revenueOverviewData, revenueYoYData }) => {
  // const [data, setData] = useState({});
  // console.log('j',forecastSumByYear);
  const currentYear = selectedYear.value;
  const previousYear = currentYear - 1;
  const data01 = [
    { name: 'Hours', value: ClientHours?.totalClientHours, color: '#2984FD' },
    { name: 'Total Hours', value: ClientHours?.totalScheduledHours, color: '#FFC542' },
  ]

  const data02 = [
    { Forecast: forecastSumByYear, Budget: budgetSumByYear },
  ]

  const data03 = [
    { [currentYear]: YoYData?.forecastSumByCurrentYear, [previousYear]: YoYData?.forecastSumByPreviousYear }
  ]
  const COLORS = ['#DEE7F2', '#2984FD'];

  const exportAC = () => {
    // Prepare data for Excel export
    const data = accountsByYear.clientAccountData;

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Accounts');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, 'accounts.xlsx');
  };

  const exportForecastOverview = () => {
    // Prepare data for Excel export
    let exportedData = [];
    for (let obj in revenueOverviewData) {
      const objValue = revenueOverviewData[obj];
      for (let month in objValue.forecast) {
        exportedData.push({
          Account_Name: obj,
          Month: month,
          ForeCast: objValue.forecast[month] || 0,
          Budget: objValue.budget && objValue.budget[month] ? objValue.budget[month] : 0,
        })
      }
    }
    const exportData = exportedData;

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'ForecastOverview');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, 'forecast_overview.xlsx');
  };

  const exportChart = () => {
    // Prepare data for Excel export
    const exportData = [{
      total_hours: ClientHours?.totalScheduledHours,
      client_hours: ClientHours?.totalClientHours,
      year: selectedYear.value
    }];

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'ClientHours');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, 'client_hours.xlsx');
  }

  const exportRevenueYoY = () => {
    // Prepare data for Excel export
    const exportData = revenueYoYData;
    // data03?.forEach(item => {
    //   console.log(item);
    //   for(let key in item){
    //     exportData.push({
    //       Revenue: item[key],
    //       Year: key
    //     })
    //   }
    // });

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'RevenueYoY');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, 'revenue_yoy.xlsx');
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          {/* <p className='label'>{`${label}`}</p> */}
          {payload.map((entry, index) => (
            <p key={index} className='label'>{`${entry.name} : ${entry.value?.toLocaleString()}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomTooltip1 = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip' style={{ flexWrap: 'nowrap' }}>
          {payload.map((entry, index) => {
            return <p key={index} style={{ whiteSpace: 'nowrap' }} className='label'>{`${entry.name} : ${entry.value?.toLocaleString()}`}</p>
          })}
        </div>
      );
    }

    return null;
  };

  const wrapperStyle = {
    fontWeight: 'bold',
    background: 'white',
    border: '1px solid #ccc',
    padding: '5px 10px',
    fontSize: '14px',
    borderRadius: '5px'
  };


  return (
    <Wrapper>
      <div className='row'>
        <div className='col-xl-3 col-md-6 col-sm-12'>

          <div className="section mt-3 me-2">
            <div className='chart-filter-section'>
              <div className='chart-header'>
                <h3 className='fw-bold'>Revenue Overview</h3>
              </div>
              <div className='chart-filter-right'>
                <div className='add-task-btn-container' onClick={exportForecastOverview}>
                  <div className='search-icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                  <input type='button' value='Export' />
                </div>
              </div>
            </div>
            <div className='chart-filter-section'>
              <div>
                <div className='forecast-txt'>${data02[0]?.Forecast?.toLocaleString()}</div>
                <div className='budget-txt'>${data02[0]?.Budget?.toLocaleString()}</div>
              </div>

              <div className='hour-chart'>
                <ResponsiveContainer width={200} height={120}>
                  <BarChart
                    margin={{
                      top: 0,
                      right: 15,
                      left: 0,
                      bottom: 0,
                    }}
                    data={data02}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    {/* <XAxis dataKey='month' /> */}
                    {/* <YAxis /> */}
                    <Tooltip
                      content={<CustomTooltip />}
                      wrapperStyle={wrapperStyle}
                      cursor={{ fill: 'transparent' }}
                    />
                    <Legend />
                    <Bar dataKey="Forecast" fill='#2984FD' barSize={35} />
                    <Bar dataKey="Budget" fill='#c6c6c6' barSize={35} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-md-6 col-sm-12'>
          <div className="section mt-3 me-2">
            <div className='chart-filter-section'>
              <div className='chart-header'>
                <h3 className='fw-bold'>Revenue YoY</h3>
              </div>
              <div className='chart-filter-right'>
                <div className='add-task-btn-container' onClick={exportRevenueYoY}>
                  <div className='search-icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                  <input type='button' value='Export' />
                </div>
              </div>
            </div>

            <div className='chart-filter-section'>
              <div>
                <div className='forecast-txt'>${YoYData?.forecastSumByCurrentYear?.toLocaleString()}</div>
                <div className='chart-filter-section'>
                  <div className='add-task-btn-container2'>
                    <input type='button' value={`${YoYData?.diff || ''}%`} />
                  </div>
                  <div className='add-task-btn-container'>
                    <input type='button' value={`$${YoYData?.forecastSumByPreviousYear?.toLocaleString() || ''}`} />
                  </div>
                </div>
              </div>

              <div className='hour-chart'>
                <ResponsiveContainer width={180} height={120}>
                  <BarChart
                    margin={{
                      top: 0,
                      right: 15,
                      left: 0,
                      bottom: 0,
                    }}
                    data={data03}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    {/* <XAxis dataKey='month' /> */}
                    {/* <YAxis /> */}
                    <Tooltip
                      content={<CustomTooltip />}
                      wrapperStyle={wrapperStyle}
                      cursor={{ fill: 'transparent' }}
                    />
                    <Legend />
                    <Bar dataKey={`${currentYear}`} fill='#2984FD' barSize={35} />
                    <Bar dataKey={`${previousYear}`} fill='#c6c6c6' barSize={35} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-md-6 col-sm-12'>
          <div className="section mt-3 me-2" style={{minHeight: '90%'}}>
            <div className='chart-filter-section'>
              <div className='chart-header'>
                <h3 className='fw-bold'>Clients</h3>
              </div>
              <div className='chart-filter-right'>
                <div className='add-task-btn-container' onClick={exportAC}>
                  <div className='search-icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                  <input type='button' value='Export' />
                </div>
              </div>
            </div>
            <div className='acount-section'>
              <div className='acount-total-body'>
                <div className='acount-total-text'>Current Active Total</div>
                <div className='acount-total-num'>{accountsByYear?.totalAccountCount}</div>
              </div>
              <div className='acount-this-month-body'>
                New Client's Added <span>{accountsByYear?.totalNewAccount}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3 col-md-6 col-sm-12'>
          <div className="section mt-3">
            <div className='chart-filter-section'>
              <div className='chart-header'>
                <h3 className='fw-bold'>Client Hours</h3>
              </div>
              <div className='chart-filter-right'>
                {/* <div className='add-task-btn-container' onClick={exportChart}>
                  <div className='search-icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                  <input type='button' value='Export' />
                </div> */}
              </div>
            </div>
            <div className='chart-filter-section'>
              <div className='hour-chart'>
                <ResponsiveContainer width={110} height={120}>
                  <PieChart>
                    <Pie
                      data={data01}
                      cx={48}
                      cy={52}
                      innerRadius={30}
                      outerRadius={50}
                      fill="#8884d8"
                      paddingAngle={0}
                    >
                      <Label
                        value={`${ClientHours?.diff || ''}%`} position="center" className='label-top' fontSize='26'
                      />
                      {
                        data01.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                      }
                    </Pie>
                    <Tooltip
                      content={<CustomTooltip1 />}
                      wrapperStyle={wrapperStyle}
                      cursor={{ fill: '#787878d0' }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div>
                <div className='hour-txt'>{ClientHours?.totalClientHours?.toLocaleString()} <span>hours</span></div>
                <div className='totalHour-txt'><span className='fw-bold'>{ClientHours?.totalScheduledHours?.toLocaleString()}</span> <span>Total hours</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ChartRow1;

const Wrapper = Styled.div`
   .section {
    border-radius: 16px;
    background: #FFF;
    /* margin: 15px 10px 15px 0; */
    padding: 20px;
  }

  .recharts-surface{
    @media (min-width: 1200px) and (max-width: 1372px){
      width: 140px;
    }
  }
  .recharts-responsive-container{
    @media (min-width: 1200px) and (max-width: 1372px){
      width: 140px;

      .recharts-legend-wrapper{
        position: absolute;
        left: 0px;
        bottom: 0px;

        .recharts-default-legend{
          text-align: left !important;
        }

        svg.recharts-surface{
          width: 8px;
          height: 8px;
        }
        span.recharts-legend-item-text{
          font-size: 14px;
        }
      }
    }
  }
  .chart-filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
  .chart-header h3 {
    color: var(--black-text, #201E20);
    font-size: 18px;
font-style: normal;
font-weight: 500;
  }
  .chart-filter-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .add-task-btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 2px;
    color: #fff;
    cursor: pointer;
  }
  .add-task-btn-container2 {
    display: flex;
    align-items: center;
    border: 1px solid #14A119;
    background: #14A119;
    border-radius: 8px;
    padding: 2px;
    color: #fff;
    cursor: pointer;
  }
  .add-task-btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 4px;
  }
  .add-task-btn-container2 input[type="button"] {
    background: #14A119;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 4px;
  }
  .react-icon {
    height: 21px;
    width: 21px;
  }
  .recharts-legend-item-text {
    color: var(--black-text, #201E20) !important;
  }
  .acount-section{
    display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
align-self: stretch;
  }
  .acount-total-body{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  }
  .acount-total-num{
    color: #201E20;
font-size: 40px;
font-weight: 600;
  }
  .acount-this-month-body{
    color: var(--dark-color, #201E20);
  }
  .acount-this-month-body span{
    font-weight: 600;
    font-size: 16px;
  }
  .forecast-txt{
    display: flex;
    gap: 5px;
    align-items: center;
    color: var(--dark-color, #201E20);
    font-size: 30px;
    font-weight: 600;

  }
  .forecast-txt span,.budget-txt{
    color: var(--dark-color, #201E20);
font-size: 16px;
opacity: 0.7;
font-weight: 400;
text-align: left;
  }
  .hour-txt{
    display: flex;
    gap: 5px;
    align-items: center;
    color: var(--dark-color, #201E20);
    font-size: 35px;
    font-weight: 600;

  }
  .hour-txt span,.totalHour-txt{
    color: var(--dark-color, #201E20);
font-size: 16px;
opacity: 0.7;
font-weight: 400;
text-align: right;
  }
`;