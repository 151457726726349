import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
// import Axios from '../../../utils/api';
function DeleteModal({ show,countdown,onLogout,onContinue }) {
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
  // console.log(selectedId)

  

  return (
    <>
      <Modal show={show} onHide={onLogout} centered className='fw-bold'>
        {/* <div className='d-flex justify-content-end cursor-pointer'>
          <MdClose className='mt-2 me-2' onClick={onLogout} />
        </div> */}
        <div
          style={{
            height: '100px',
            display: 'flex',
            flexDirection:"column",
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>The current session is about to expire in {countdown} seconds.</div>
          <div>{`Would you like to continue the session?`}</div>
        </div>
        <div className='d-flex justify-content-center mb-3'>
          <button className='btn btn-danger me-4 px-4' onClick={() => onLogout()}>
            Logout
          </button>
          <button className='btn btn-danger px-4' onClick={onContinue}>
            Continue
          </button>
        </div>
      </Modal>
    </>
  );
}

export default DeleteModal;
