import { MdDashboard } from 'react-icons/md';
import { GrMultiple } from 'react-icons/gr';
import {
  FaUsers,
  FaUserSecret,
  FaChalkboardTeacher,
  FaFistRaised,
  FaTeamspeak,
} from 'react-icons/fa';
import { BsLaptopFill, BsStack, BsFileEarmarkSpreadsheetFill } from 'react-icons/bs';
import {
  MdFilterListAlt,
  MdDns,
  MdOutlineSchedule,
  MdSettings,
  MdOutlineQueryStats,
} from 'react-icons/md';
import { AiOutlineHome, AiOutlineSetting } from 'react-icons/ai';

const DashboardIcon = () => {
  return <img src={require('../../assets/icons/dashboard-icon.png')} />;
};

const ArchiveIcon = () => {
  return <img src={require('../../assets/icons/archive-icon.png')} className='me-2' />;
};
const CalendarIcon = () => {
  return <img src={require('../../assets/icons/calendar-icon.png')} className='me-2' />;
};
const DollarIcon = () => {
  return <img src={require('../../assets/icons/dollar-icon.png')} />;
};

const MyTeamIcon = () => {
  return <img src={require('../../assets/icons/myteam.png')} className='me-2' />;
};

const InsightsIcon = () => {
  return <img src={require('../../assets/icons/insights-icon.png')} className='me-2' />;
};
const ResourceIcon = () => {
  return <img src={require('../../assets/icons/resource-icon.png')} className='me-2' />;
};

const TimerIcon = () => {
  return <img src={require('../../assets/icons/clock.png')} className='me-2' />;
};

export const adminLinks = [
  

  {
    path: '/dashboard',
    name: 'Dashboard',
    Icon: DashboardIcon,
  },
  {
    path: '/scheduler',
    name: 'Scheduler',
    Icon: CalendarIcon,
  },

  {
    path: '/revenue',
    name: 'Revenue',
    Icon: DollarIcon,
  },

  {
    path: '/setupResources',
    name: 'Setup Resources',
    Icon: ResourceIcon,
  },
  {
    path: '/hours',
    name: 'Insights',
    Icon: InsightsIcon,
  },
  {
    path: '/archive',
    name: 'Archive',
    Icon: ArchiveIcon,
  },
  {
    path: '/timer',
    name: 'Timer',
    Icon: TimerIcon,
  },
  ];

export const employeeLinks = [
  

  
  {
    path: '/dashboard',
    name: 'Dashboard',
    Icon: DashboardIcon,
  },

  {
    path: '/hours',
    name: 'Insights',
    Icon: InsightsIcon,
  },
  {
    path: '/timer',
    name: 'Timer',
    Icon: TimerIcon,
  },
];

export const contractorLinks = [
  

  {
    path: '/timer',
    name: 'Timer',
    Icon: TimerIcon,
  },

];

export const leadLinks = [
  

  {
    path: '/dashboard',
    name: 'Dashboard',
    Icon: DashboardIcon,
  },

 
  {
    path: '/scheduler',
    name: 'Scheduler',
    Icon: CalendarIcon,
  },

  {
    path: '/hours',
    name: 'Insights',
    Icon: InsightsIcon,
  },
  {
    path: '/timer',
    name: 'Timer',
    Icon: TimerIcon,
  },
];
export const managerLinks = [
  

  
  {
    path: '/dashboard',
    name: 'Dashboard',
    Icon: DashboardIcon,
  },

  {
    path: '/team',
    name: 'My Team',
    Icon: MyTeamIcon,
  },

  
  {
    path: '/hours',
    name: 'Insights',
    Icon: InsightsIcon,
  },
  {
    path: '/timer',
    name: 'Timer',
    Icon: TimerIcon,
  },
];

// export const enterpriseLinks= [
//     {
//       path: "/enterprise/Home",
//       name: "Home",
//       Icon: AiOutlineHome,
//     },
//     // {
//     //   path: "/profile",
//     //   name: "Profile",
//     //   icon: "nc/icon nc/single/02",
//     //   component: UpdateUserProfile,
//     //   layout: "",
//     // },
//     {
//       path: "/enterprise/mytransactions",
//       name: `My Transactions`,
//       Icon: MyTransaction,
//       Icon2:Down
//     },
//     {
//       path: "/enterprise/settings",
//       name: "Settings",
//       Icon: AiOutlineSetting,
//     },
//     {
//       path: "/enterprise/newyorktime",
//       name: "NewYork Time",
//       Icon: NewYorkTIme,

//     },

//   ];

// export const superAdminLinks = [
//     {
//       path: "/superadmin/dashboard",
//       name: "Dashboard",
//       Icon: MdDashboard,
//     },

//     {
//       path: "/superadmin/projects",
//       name: "Projects",
//       Icon: BsStack,

//     },

//     {
//       path: "/superadmin/resources",
//       name: "Resources",
//       Icon: FaUsers,

//     },

//     {
//       path: "/superadmin/statusupdates",
//       name: "Status",
//       Icon: MdDns,

//     },
//     {
//       path: "/superadmin/statusfilter",
//       name: "Status Filter",
//       Icon: MdFilterListAlt,

//     },
//     {
//       path: "/superadmin/clients",
//       name: "Client List",
//       Icon: FaUserSecret,

//     },
//     {
//       path: "/superadmin/expertise",
//       name: "Expertise",
//       Icon: BsLaptopFill,

//     },
//     // {
//     //   path: "/ongoingprojects",
//     //   name: "Ongoing Projects",
//     //   icon: "nc/icon nc/bullet/list/67",
//     //   component: OngoingProjects,
//     //   layout: "",
//     // },
//     {
//       path: "/superadmin/mastersheets",
//       name: "Master Sheets",
//       Icon: FaChalkboardTeacher,

//     },
//     // {
//     //   path: "/deliviredprojects",
//     //   name: "Delivired Projects",
//     //   icon: "nc/icon nc/bullet/list/67",
//     //   component: DeliviredProjects,
//     //   layout: "",
//     // },

//   ];

//   export const reviewerLinks=[
//     {
//       path: "/reviewer/review",
//       name: "Review Invites",
//       Icon: BsStack,
//     },

//   ]

//   export const pcPmLinks = [
//     {
//       path: "/pcpm/projects",
//       name: "Projects",
//       Icon: BsStack,
//     },
//     {
//       path: "/pcpm/riserequest",
//       name: "Request",
//       Icon:FaFistRaised
//     },

//   ];

//   export const teamLeadLinks = [
//     {
//       path: "/tl/projects",
//       name: "Projects List",
//       Icon: BsStack,
//     },
//     {
//       path: "/tl/status",
//       name: "Status",
//       Icon: MdDns,
//     },
//     {
//       path: "/tl/expertise",
//       name: "Expertise",
//       Icon: BsLaptopFill,

//     },
//     {
//       path: "/tl/resources",
//       name: "Team Members",
//       Icon: FaUsers,

//     },
//     {
//       path: "/tl/teamstatusupdates",
//       name: "Team Status",
//       Icon:FaTeamspeak

//     },
//   ];
