import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { Header } from '../../../../components/Header/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Sidebar } from "./Sidebar";
import { BiSearch } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';

import { Modal } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';
import { MdMoreVert } from 'react-icons/md';
import AddPodModal from './AddPodModal';
import EditPodModal from './EditPodModal';
import axios from '../../../../utils/api';
import DeletePodModal from '../../../../components/modals/DeleteModal';
import ArchivePodModal from '../../../../components/modals/ArchiveModal';
import Loader from '../../../../components/LoaderWave';
function Pod() {
  const [loading, setLoading] = useState(false);
  const [showAddPodModal, setShowAddPodModal] = useState(false);
  const [showEditPodModal, setShowEditPodModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [podItems, setPodItems] = useState([]);
  const [activePodItem, setActivePodItem] = useState('');
  const [showpodFilter, setShowPodFilter] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [showDeletePodModal, setShowDeletePodModal] = useState(false);
  const [showArchivePodModal, setShowArchivePodModal] = useState(false);
  const [slectedpodDatas, setslectedpodDatas] = useState(null);
  const [search, setSearch] = useState('');

  const handleShowModal = () => {
    setShowAddPodModal(true);
  };

  const handleCloseModal = () => {
    setShowAddPodModal(false);
  };

  const fetchData = async () => {
    // console.log('pod search',search);
    try {
      setLoading(true);
      const response = await axios.get(`/admin/pods?keyword=${search}`);
      setPodItems(response.data.data?.sort((a,b)=>{
        return a.name > b.name ? 1 : -1;
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleCloseDeletePodModal = () => setShowDeletePodModal(false);
  const handleCloseArchivePodModal = () => setShowArchivePodModal(false);
  const handleShowDeleteModal = (pod) => {
    setslectedpodDatas(pod);
    setShowDeletePodModal(true);
  };
  const handleShowArchivePodModal = (pod) => {
    setslectedpodDatas(pod);
    setShowArchivePodModal(true);
  };
  const handleConfirmDelete = () => {
    handlePodDelete(slectedpodDatas);
  };
  const handleConfirmArchive = () => {
    handlePodArchive(slectedpodDatas);
  };
  const handlePodDelete = async (slectedpodDatas) => {
    try {
      const response = await axios.delete(`/admin/pod?_id=${slectedpodDatas._id}`);
      // console.log(response.data);
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseDeletePodModal();
      setslectedpodDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };
  const handlePodArchive = async (slectedpodDatas) => {
    try {
      const response = await axios.delete(`/admin/archivePod?_id=${slectedpodDatas._id}`);
      // console.log(response.data);
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseArchivePodModal();
      setslectedpodDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [refreshPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    axios
      .get('/admin/resources_wopaging')
      .then((response) => {
        if (response.data && response.data.data) {
          setEmployees(
            response.data.data?.map((item, index) => ({
              value: item?._id,
              label: item?.first_Name + ' ' + item?.last_Name,
              key: index,
            })).sort((a, b) => {
              return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
            })
          )
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])


  return (
    <Wrapper>
      {loading && <Loader />}
      <DeletePodModal
        show={showDeletePodModal}
        setShow={handleCloseDeletePodModal}
        onYes={handleConfirmDelete}
        message='Are you sure you want to Delete this pod?'
      />
      <ArchivePodModal
        show={showArchivePodModal}
        setShow={handleCloseArchivePodModal}
        onYes={handleConfirmArchive}
        message='Are you sure you want to Archive this pod?'
      />
      <AddPodModal
        show={showAddPodModal}
        setShow={setShowAddPodModal}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
        employeeOptions={employees}
      />
      {showEditPodModal && (
        <EditPodModal
          show={showEditPodModal}
          setShow={setShowEditPodModal}
          editingPod={slectedpodDatas}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          employeeOptions={employees}
        />
      )}
      <div className='tabs-ride-container'>
        <label htmlFor='search-input'>
          Search By Pods
          <div className='search-input-container'>
            <div className='search-icon-container'>
              <BiSearch className='react-icon' />
            </div>
            <input
              type='text'
              id='search-input'
              value={search}
              onChange={handleSearchChange}
              placeholder='Type in a keyword or name'
            />
          </div>
        </label>

        <div className='add-emp-btn-container' onClick={handleShowModal}>
          <div className='search-icon-container'>
            <BsPlus className='react-icon' />
          </div>
          <input type='button' id='addEmployee' value='Add Pod' />
        </div>
      </div>
      <div className='container-main'>
        {podItems.map((item, i) => {
          return (
            <div className='pod-container' key={i}>
              <div className='d-flex' style={{flexDirection: 'column'}}>
                <span className='ms-2'>{item.name}</span>
                <span className='ms-2'>{item?.user_id?.first_Name} {item?.user_id?.last_Name}</span>
              </div>
              <div className='pod-icon'>
                <MdMoreVert
                  className='me-2'
                  onClick={() => {
                    if (i === activePodItem) {
                      setShowPodFilter(!showpodFilter);
                      setActivePodItem(i);
                      return;
                    }
                    setShowPodFilter(true);
                    setActivePodItem(i);
                  }}
                />
                {showpodFilter && activePodItem === i && (
                  <ul className='pod-filter' onClick={() => setShowPodFilter(false)}>
                    <li
                      className='d-flex pod-filter-item'
                      onClick={() => {
                        setShowEditPodModal(true);
                        setslectedpodDatas(item);
                      }}
                    >
                      <FaEdit className='m-1' /> <span className='m-2'>Edit</span>
                    </li>
                    <li
                      className='d-flex pod-filter-item'
                      onClick={() => handleShowArchivePodModal(item)}
                    >
                      <BiArchiveIn className='m-1' /> <span className='m-2'>Archive</span>
                    </li>
                    {/* <li className='d-flex pod-filter-item' onClick={() => handleShowDeleteModal(item)}>
              <FaTrashAlt className='m-1'/> <span className='m-2'>Delete</span>
              </li>    */}
                  </ul>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

export default Pod;

const Wrapper = styled.div`
  background: #dee7f2;
  .container-main {
    background: #fff;
    width: 98%;
    margin: 0px auto;
    padding: 10px;
    margin-top: 20px;
    border-radius: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .pod-container {
    border: 1px solid black;
    min-width: 200px;
    border-radius: 10px;
    height: fit-content;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .pod-icon {
    position: relative;
    cursor: pointer;
  }
  .pod-filter {
    position: absolute;
    min-width: 150px;
    background: white;
    top: 10px;
    right: 40px;
    border-radius: 10px;
    padding: 0px;
  }
  .pod-filter-item {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #d2d2d2;
  }

  .pod-filter-item:last-child {
    border-bottom: none;
  }
  .pod-filter-item:hover {
    background: #eaf3ff;
  }
`;
