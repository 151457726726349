import Routes from './routes/Routes';
import { ToastContainer, toast } from 'react-toastify';
function App() {
  return (
    <>
      <Routes />
      <ToastContainer />
    </>
  );
}

export default App;
