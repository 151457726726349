import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import { Table, Image, Button, ButtonGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from '../../../../components/Pagination';
import Axios from '../../../../utils/api';
import { BiArchiveIn } from 'react-icons/bi';
import ArchiveEmployeeModal from '../../../../components/modals/ArchiveModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../components/LoaderWave';

const EmployeeTabBody = ({ search, selectedMultiTeamValues, refreshPage, setRefreshPage }) => {
  const renderTooltip = (text) => <Tooltip>{text}</Tooltip>;
  const [loading, setLoading] = useState(false);
  const [showArchiveEmployeeModal, setShowArchiveEmployeeModal] = useState(false);
  const [slectedemployeeDatas, setslectedemployeeDatas] = useState(null);
  const [data, setData] = useState([]);
  const [profileColor, setprofileColor] = useState(sessionStorage.getItem('color'));

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);

  const handleCloseArchiveEmployeeModal = () => setShowArchiveEmployeeModal(false);
  const handleShowArchiveEmployeeModal = (employee) => {
    setslectedemployeeDatas(employee);
    setShowArchiveEmployeeModal(true);
  };
  const handleConfirmArchive = () => {
    handleTitleArchive(slectedemployeeDatas);
  };
  const handleTitleArchive = async (slectedemployeeDatas) => {
    try {
      const response = await Axios.delete(
        `/admin/unarchiveResources?_id=${slectedemployeeDatas._id}`,
      );
      // console.log(response.data);
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseArchiveEmployeeModal();
      setslectedemployeeDatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await Axios.post(
        `/admin/ArchiveResources?page=${page}&limit=${perPage}&keyword=${search}`,
        {
          teamIds: selectedMultiTeamValues,
        },
      );
      console.log('emptab', response.data.data);
      setData(response.data.data);
      setTotalPages(Number(response.data.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [page, perPage, refreshPage, selectedMultiTeamValues]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Wrapper>
      <div className='third-row'>
        {loading && <Loader />}
        <ArchiveEmployeeModal
          show={showArchiveEmployeeModal}
          setShow={handleCloseArchiveEmployeeModal}
          onYes={handleConfirmArchive}
          message='Are you sure you want to unarchive this employee?'
        />
        <>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Resource Name</th>
                <th>Team</th>
                <th>Hourly Rate</th>
                <th>Employee ID</th>
                <th>Email</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={row._id}>
                  <td>{perPage * (page - 1) + index + 1}</td>
                  <td>
                    <div className='avatar-container'>
                      {row.profile_pic ? (
                        <Image
                        src={row.profile_pic}
                        roundedCircle
                        className='avatar-image'
                      />
                      ) : (
                        <div>
                          <div className='avatar-image profile-image' style={{background: profileColor}}>
                            <span>{row.first_Name ? row.first_Name?.toUpperCase()[0] : ''}</span>
                          </div>
                        </div>
                      )}
                      <div className='avatar-name'>
                        {row.first_Name} {row.last_Name && row.last_Name}
                        <div className='profile-role'>{row.title && row.title.name}</div>
                      </div>
                    </div>
                  </td>
                  <td>{row.team && row.team.name}</td>
                  <td>${row.hourly_rate && row.hourly_rate}</td>
                  <td>{row.emp_code && row.emp_code}</td>
                  <td className='email'>{row.email && row.email}</td>
                  <td>{row.role && row.role}</td>
                  <td>
                    <ButtonGroup className='action-btn'>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Unarchive')}>
                        <Button
                          className='archive-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleShowArchiveEmployeeModal(row)}
                        >
                          <BiArchiveIn style={{ color: '#008000' }} />
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            total={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </>
      </div>
    </Wrapper>
  );
};

const Wrapper = Styled.div`

.third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
.avatar-container {
  display: flex;
  align-items: center;
}
.avatar-image {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.action-btn {
    background: none;
    border: none;
    border-radius: 0px !important;
}
.edit-btn{
    margin-right:10px;
    background: #D4E6FF;
    border:1px solid #D4E6FF;
    border-radius: 8px;
}
.archive-btn{
    margin-right:10px;
    background: #CCE6CC;
    border:1px solid #CCE6CC;
    border-radius: 8px;
}
.delete-btn{
    background: #FFDBDC;
    border:1px solid #FFDBDC;
    border-radius: 8px;
}

.btn-group .button:only-child {
    border-radius: 6px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

div.avatar-image{
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 36px;
}

    `;

export default EmployeeTabBody;
