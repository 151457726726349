import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { baseUrl } from '../../config/base';
const isTestEnv = process.env.REACT_APP_IS_TEST_ENV;
function HomeCode3() {
  return (
    <div>
      <Header />
      { baseUrl !=="https://hub.code3.com/api" && (
         <div className='text-center' style={{color: '#fff', background: 'red', fontWeight: 'bold'}}>
          Hub Sandbox
        </div>
      )}
      <Outlet />
    </div>
  );
}

export default HomeCode3;
