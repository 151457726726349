import React, { useState, useEffect } from 'react';
import VerticalBarChart from './VerticalBarChart';
import ForecastBudget from './ForecastBudget';
import axios from '../../../utils/api';
import Loader from '../../../components/LoaderWave';

function Revenue() {
  const [loading, setLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [budgetYear, setBudgetYear] = useState(null);
  const [forecastYear, setForecastYear] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);
  const [chartData, setChartData] = useState([]);

  // Fetch the revenue data from the API
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/admin/getForecastandBudgetSUM', {
        budgetYear: budgetYear.value,
        forecastYear: forecastYear.value,
      });
      const { months, forecast, budget } = response.data;

      // Create the chart data array
      const data = months.map((month, index) => ({
        month,
        forecast: forecast[index],
        budget: budget[index],
      }));
      setChartData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (budgetYear && forecastYear) {
      fetchData();
    }
  }, [budgetYear, forecastYear, refreshPage]);

  const currentYear = new Date().getFullYear()+3;
  const years = Array.from({ length: 15 }, (_, index) => ({
    value: currentYear - index,
    label: currentYear - index,
  }));

  useEffect(() => {
    setYearOptions(years);
    setBudgetYear({ value: new Date().getFullYear(), label: new Date().getFullYear() });
    setForecastYear({ value: new Date().getFullYear(), label: new Date().getFullYear() });
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <VerticalBarChart
        data={chartData}
        yearOptions={yearOptions}
        budgetYear={budgetYear}
        setBudgetYear={setBudgetYear}
        forecastYear={forecastYear}
        setForecastYear={setForecastYear}
        setRefreshPage={setRefreshPage}
        refreshPage={refreshPage}
      />
      <ForecastBudget
        setLoading={setLoading}
        loading={loading}
        setRefreshPage={setRefreshPage}
        refreshPage={refreshPage}
      />
    </div>
  );
}

export default Revenue;
