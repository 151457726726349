import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Styled from 'styled-components';
import axios from '../../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BiUserCheck } from 'react-icons/bi';
import { GiNetworkBars } from 'react-icons/gi';
import { AiOutlineUserSwitch } from 'react-icons/ai';

function EditTeamsModal({
  showModal,
  setShowModal,
  refreshPage,
  setRefreshPage,
  editingUser,
  MultiTeamoptions,
}) {
  const [taskType, setTaskType] = useState({
    value: editingUser?.task_type,
    label: editingUser?.task_type,
  });
  // console.log(editingUser);
  const [errors, setErrors] = useState({});
  const preSelectedOptions = editingUser?.team_id?.map((row, index) => ({
    value: row?._id,
    label: `${row?.name}`,
  }));
  const preSelectedValues = editingUser?.team_id?.map((row, index) => [row?._id]);
  const [SelectedTeamOptions, setSelectedTeamOptions] = useState(preSelectedOptions);
  const [SelectedTeamValues, setSelectedTeamValues] = useState(preSelectedValues);
  const [Task, setTask] = useState(editingUser?.task || '');

  const taskTypeOptions = [
    { value: 'Client Related', label: 'Client Related' },
    { value: 'Internal', label: 'Internal' },
  ];

  const handleCloseModal = () => {
    setShowModal(false);
    setErrors({});
  };

  // const handleTeamSelect = (SelectedTeamOptions) => {
  //   setSelectedTeamOptions(SelectedTeamOptions);
  // };
  const handleTeamSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedTeamOptions(selectedOptions);
    setSelectedTeamValues(selectedValues);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (SelectedTeamValues.length <= 0) {
      errors.team = 'Please select the team';
    }

    if (!Task.trim()) {
      errors.task = 'Please enter the task/activity';
    }
    if (!taskType) {
      errors.taskType = 'Please enter the task type';
    }

    setErrors(errors);

    // Check if any errors exist
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }

    const details = {
      _id: editingUser._id,
      task: Task,
      team_id: SelectedTeamValues && SelectedTeamValues,
      task_type: taskType?.value,
    };
    // console.log('details',details);
    axios
      .put('/admin/task', details)
      .then((response) => {
        // console.log(response);
        toast.success(response.data.message);
        setTimeout(() => {
          setShowModal(false);
          setRefreshPage(!refreshPage);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task/Activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddSubmit}>
            <div class='row'>
              <div class='form-border'>
                <div class=''>
                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Team Name</label>
                      <div class='input-group mb-1'>
                        <span class='input-group-text'>
                          <BiUserCheck />
                        </span>
                        {/* <input type="text" class="form-control shadow-none " placeholder="Enter Team name" value={editingUser?.Team_Name || ""} /> */}
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.team ? 'is-invalid' : ''
                          }`}
                          options={MultiTeamoptions}
                          value={SelectedTeamOptions}
                          onChange={handleTeamSelect}
                          isMulti={true}
                        />
                        {errors.team && <div className='invalid-feedback'>{errors.team}</div>}
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Task/Activity</label>
                      <div class='input-group mb-1'>
                        <span class='input-group-text'>
                          <BiUserCheck />
                        </span>
                        <textarea
                          className={`form-control shadow-none ${errors.task ? 'is-invalid' : ''}`}
                          placeholder='Write Task/Activity and put comma to differentiate the Task/Activity'
                          value={Task}
                          onChange={(event) => setTask(event.target.value)}
                        />
                        {errors.task && <div className='invalid-feedback'>{errors.task}</div>}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label className='form-label'>Task Type</label>
                      <div className='input-group mb-1'>
                        <span className='input-group-text'>
                          <AiOutlineUserSwitch />
                        </span>
                        <Select
                          className={`select-form-control shadow-none ${
                            errors.taskType ? 'is-invalid' : ''
                          }`}
                          options={taskTypeOptions}
                          value={taskType}
                          onChange={(selectedOption) => setTaskType(selectedOption)}
                        />
                        {errors.taskType && (
                          <div className='invalid-feedback'>{errors.taskType}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class='d-flex justify-content-center'>
                    <div class='mt-2 mx-2'>
                      <button
                        class='btn btn-primary employee-cancel-button'
                        type='button'
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                    </div>
                    <div class='mt-2 mx-2'>
                      <button class='btn btn-primary employee-save-button' type='submit'>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default EditTeamsModal;

const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}
`;
