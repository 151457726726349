import React, { useState } from 'react';
import Styled from 'styled-components';
import { BiSearch } from 'react-icons/bi';
import SkillSetTabBody from './SkillSetTabBody';
function SkillSet() {
  const [refreshPage, setRefreshPage] = useState(false);
  const [search, setSearch] = useState('');

  return (
    <Wrapper>
      <div>
        <div className='tabs-ride-container'>
          <label htmlFor='search-input'>
            Search by Skill Set
            <div className='search-input-container'>
              <div className='search-icon-container'>
                <BiSearch className='react-icon' />
              </div>
              <input
                type='text'
                id='search-input'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder='Type in a keyword or name'
              />
            </div>
          </label>
        </div>

        <SkillSetTabBody
          screenName='SkillSet'
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          search={search}
        />
      </div>
    </Wrapper>
  );
}

export default SkillSet;

const Wrapper = Styled.div`

.react-icon{
    height: 22px;
    width: 22px;
}
    .bg .row{
    --bs-gutter-x: 0px !important;
    }

.tabs-ride-container{
    position:absolute;
    /* left:50%; */
    right: 1%;
    top:6px;
    display: flex;
flex-direction: row;
align-items: flex-end;
padding: 0px;
gap: 20px;
}
label {
    font-size: 12px;
    color: #201E20;
}
.search-input-container {
  display: flex;
  align-items: center;
  width: 255px;
  height: 38px;

  border: 1px solid #636263;
border-radius: 8px;
  padding: 5px;
}
.add-emp-btn-container {
  display: flex;
  align-items: center;
  width: 150px;
  height: 38px;

  border: 1px solid #2984FD;
  background: #2984FD;
border-radius: 8px;
  padding: 5px;
  color:#fff;
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  min-width: 182px;
  border: 1px solid #636263;
border-radius: 8px;
}
.search-icon-container {
  margin-right: 5px;
}

.search-input-container input[type="text"] {
  flex: 1;
  border: none;
  outline: none;
}
.add-emp-btn-container input[type="button"] {
    background: #2984FD;
    color:#fff;
  flex: 1;
  border: none;
  outline: none;
  padding: 7px 0px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

.multi-select-container{
  /* height: 200px;  */
  /* overflow-y: scroll;  */
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
    .nav-tabs {
    display: block !important;
}
    .nav-tabs li{
      color: rgb(255, 255, 255);
    border-radius: 8px;
    background-color: rgb(28, 43, 72)
}
}

`;
