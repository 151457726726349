import React, { useEffect, useState } from 'react';
import { IoChevronBackCircleSharp, IoChevronForwardCircleSharp } from 'react-icons/io5';
import styled from 'styled-components';

function Pagination({ total, page, setPage, perPage, setPerPage, paginationText }) {
  const [perPageData, setPerPageData] = useState([10, 20, 50, 100, 200, 500]);
  useEffect(()=>{
    if (paginationText == 'Show accounts per page:') {
      setPerPageData([6])
    }
    // if (paginationText == 'Show employees per page:') {
    //   setPerPageData([15, 20,])
    // }
  }, [])
  
  const handleNext = () => {
    if (page < total) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handlePerPageChange = (e) => {
    const newPerPage = parseInt(e.target.value);
    setPerPage(newPerPage);
    setPage(1);
  };

  return (
    <Wrapper>
      <span onClick={handlePrev} className='icons'>
        <IoChevronBackCircleSharp />
      </span>
      <div className='text-muted total-text'>
        <span>{page}</span>
        <span className='total-middle-text'> of </span>
        <span>{total}</span>
      </div>
      <span onClick={handleNext} className='icons'>
        <IoChevronForwardCircleSharp />
      </span>
      {paginationText==' '?(<></>):(
<div className='per-page-dropdown'>
        <label htmlFor='per-page'>{paginationText ? paginationText : 'Show per page:'}</label>
        <select id='per-page' value={perPage} onChange={handlePerPageChange}>
          {perPageData.map((data) => (
            <option key={data} value={data}>
              {data}
            </option>
          ))}
        </select>
      </div >
      )}
    </Wrapper>
  );
}

export default Pagination;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0;

  .icons {
    font-size: 30px;
    margin: 0 15px;
    color: var(--sidebar-color);
    cursor: pointer;
  }

  .total-text {
    letter-spacing: 1px;
    margin: 0 10px;
  }

  .per-page-dropdown {
    margin-left: 20px;

    label {
      margin-right: 5px;
      font-size: 14px;
    }

    select {
      padding: 5px;
      font-size: 14px;
    }
  }
`;
