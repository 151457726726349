import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import Axios from '../../../utils/api';
function DeleteModal({ show, setShow, selectedId, setRefreshPage,refreshPage }) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(selectedId)

  const deleteEvent = async () => {
    try {
      // console.log(selectedId, '...');
      const response = await Axios.delete(`/admin/scheduler?id=${selectedId}`);
      toast.success(response.data.message);
      setRefreshPage(!refreshPage);
      handleClose();
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className='fw-bold'>
        <div className='d-flex justify-content-end cursor-pointer'>
          <MdClose className='mt-2 me-2' onClick={handleClose} />
        </div>
        <div
          style={{
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>Are you sure you want to delete this schedule?</div>
        </div>
        <div className='d-flex justify-content-center mb-3'>
          <button className='btn btn-danger me-4 px-4' onClick={() => deleteEvent()}>
            Yes
          </button>
          <button className='btn btn-danger px-4' onClick={handleClose}>
            No
          </button>
        </div>
      </Modal>
    </>
  );
}

export default DeleteModal;
