import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import { MdOutlineFileDownload } from 'react-icons/md';
import * as XLSX from 'xlsx'; // Import the xlsx library
import { ComposedChart, Area, XAxis, YAxis, Tooltip, Legend, CartesianGrid, Bar, Line, ResponsiveContainer, BarChart } from 'recharts'

const ChartRow2 = ({ ChartRow2Data, hoursYear, budgetYear,
  forecastYear, quaterlyData, quaterlyMarginData }) => {

  const chartData = quaterlyData?.map((item) => ({
    Quarter: item.month,
    [`${forecastYear.value} Forecast`]: item.forecast,
    [`${budgetYear.value} Budget`]: item.budget,
  }));

  const data = quaterlyMarginData;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>{`${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} className='label'>{`${entry.name} : $${entry.value?.toLocaleString()}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomTooltip1 = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>{`${label}`}</p>
          {payload.map((entry, index) => {
            if (index !== 1) {
              return <p key={index} className='label'>{`${entry.name} : ${(entry.name !== 'ContributionMargin' ? '$' : '')}${entry.value?.toLocaleString() + (entry.name === 'ContributionMargin' ? '%' : '')}`}</p>
            }
          })}
        </div>
      );
    }
    return null;
  };

  const wrapperStyle = {
    fontWeight: 'bold',
    background: 'white',
    border: '1px solid #ccc',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px'
  };

  const exportChart = () => {
    // Prepare data for Excel export
    const exportData = data.map((item) => {
      return {
        Quarter: item.month,
        Profit: item.Profit,
        ContributionMargin: item.ContributionMargin
      }
    });

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'QuarterlyMargin');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, 'quaterly_profitability.xlsx');
  }

  const exportQuaterlyChart = () => {
    // Prepare data for Excel export
    const exportData = chartData;

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'QuarterlyRevenue');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, 'quaterly_revenue.xlsx');
  };

  return (
    <Wrapper>
      <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <div className='section mt-3 me-2'>
            <div className='chart-filter-section'>
              <div className='chart-header'>
                <h3 className='fw-bold'>Quarterly Revenue Forecast vs. Budget</h3>
              </div>
              <div className='chart-filter-right'>
                <div className='add-task-btn-container' onClick={exportQuaterlyChart}>
                  <div className='search-icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                  <input type='button' value='Export' />
                </div>
              </div>
            </div>
            <ResponsiveContainer width='100%' height={300}>
              <BarChart
                margin={{
                  top: 10,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
                data={chartData}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='Quarter' />
                <YAxis tickFormatter={(value) => `$${value.toLocaleString()}`} />
                <Tooltip
                  content={<CustomTooltip />}
                  wrapperStyle={wrapperStyle}
                  cursor={{ fill: '#787878d0' }}
                />
                <Legend />
                <Bar dataKey={`${forecastYear?.value} Forecast`} fill='#2984FD' />
                <Bar dataKey={`${budgetYear?.value} Budget`} fill='#c6c6c6' />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <div className="section mt-3 me-2">
            <div className='chart-filter-section'>
              <div className='chart-header'>
                <h3 className='fw-bold'>Quarterly Profitability</h3>
              </div>
              <div className='chart-filter-right'>
                <div className='add-task-btn-container' onClick={exportChart}>
                  <div className='search-icon-container'>
                    <MdOutlineFileDownload className='react-icon' />
                  </div>
                  <input type='button' value='Export' />
                </div>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                margin={{
                  top: 5,
                  right: 0,
                  left: 30,
                  bottom: 5,
                }}
                data={data}
              >
                <XAxis dataKey="month" />
                <YAxis yAxisId={2} orientation="right" tickLine={false} tickFormatter={(value) => `${value}%`} />
                {/* <YAxis yAxisId={1} orientation="right" label={{ value: 'ContributionMargin%', angle: -90 ,position: 'left' ,offset:-40}} /> */}
                <YAxis yAxisId={1} tickFormatter={(value) => `$${value.toLocaleString()}`} />
                {/* <YAxis yAxisId={2} label={{ value: 'ContributionMargin', angle: -90  ,position: 'left',offset:0}} /> */}
                <Tooltip
                  content={<CustomTooltip1 />}
                  wrapperStyle={wrapperStyle}
                  cursor={{ fill: '#787878d0' }}
                />
                {/* <Legend /> */}
                <CartesianGrid stroke="#f5f5f5" />
                {/* <Bar yAxisId={1} dataKey="hours" barSize={35} fill="#413ea0" /> */}
                <Bar yAxisId={1} dataKey="Profit" fill="#2984FD" barSize={60} />
                <Line yAxisId={2} type="monotone" dataKey="ContributionMargin" unit={'%'} strokeWidth={3} stroke="#E7F3AC" />
                <Area yAxisId={2} type="monotone" dataKey="ContributionMargin" stroke="none" fill="#E7F3AC" tooltipType='none' fillOpacity={0.3} />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ChartRow2;

const Wrapper = Styled.div`
   .section {
    border-radius: 16px;
    background: #FFF;
    /* margin: 15px 10px 15px 0; */
    padding: 20px;
  }
  .chart-filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .chart-header h3 {
    color: var(--black-text, #201E20);
    font-size: 18px;
font-style: normal;
font-weight: 500;
  }
  .chart-filter-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .add-task-btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 2px;
    color: #fff;
    cursor: pointer;
  }
  .add-task-btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 4px;
  }
  .recharts-legend-item-text {
    color: var(--black-text, #201E20) !important;
  }
  .react-icon {
    height: 21px;
    width: 21px;
  }
  .bar-chart{
    margin-top:20px
  }

  .recharts-default-tooltip {
    margin-left: -55px;
    background: white;
    padding: 6px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(74, 5, 5, 0.15);
  }

  .recharts-default-tooltip p, li.recharts-tooltip-item {
    font-weight: bold;
    font-size: 14px;
  }
`;