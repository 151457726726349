import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import Select from 'react-select';
import { Header } from '../../../../components/Header/Header';
import axios from '../../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Sidebar } from "./Sidebar";
import { BiSearch } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';

import { HiOutlineUser } from 'react-icons/hi';
import { HiOutlineMail } from 'react-icons/hi';
import { BiUserCircle } from 'react-icons/bi';
import { AiOutlineIdcard } from 'react-icons/ai';
import { BiUserCheck } from 'react-icons/bi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { MdOutlineFileDownload } from 'react-icons/md';

import { Modal, Form } from 'react-bootstrap';
import EmployeeTabBody from './EmployeeTabBody';
import Loader from '../../../../components/LoaderWave';
import * as XLSX from 'xlsx'

function Employee(activeTab) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [SelectedTitleOptions, setSelectedTitleOptions] = useState([]);
  const [SelectedTeamOptions, setSelectedTeamOptions] = useState([]);
  const [SelectedTeamValues, setSelectedTeamValues] = useState([]);
  const [SelectedRoleOptions, setSelectedRoleOptions] = useState([]);
  const [TitleOptions, setTitleOptions] = useState([]);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [emp, setEmp] = useState({
    first_Name: '',
    last_Name: '',
    hourly_rate: '',
    emp_code: '',
    email: '',
    role: '',
    team: '',
    manager:'',
    title: '',
  });
  const [refreshPage, setRefreshPage] = useState(false);
  const [search, setSearch] = useState('');
  const [MultiTeamoptions, setMultiTeamoptions] = useState([]);
  const [teamWP, setTeamWP] = useState([]);
  const [multiManagerOptions, setMultiManagerOptions] = useState([]);
  const [selectedManagerOption,setSelectedManagerOption]=useState()
  const [emailAlertCheckbox,setEmailAlertCheckbox]=useState(false);


  const RoleOptions = [
    { value: 'Employee', label: 'Employee' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Lead', label: 'Lead' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Contractor', label: 'Contractor' },
  ].sort((a,b)=>{
    return a.label > b.label ? 1 : -1;
  });

  const handleEmailAlertCheckbox = (e) => {
    setEmailAlertCheckbox(e.target.checked);
  }

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
  };
  // const handleMultiSelectTeamChange = (selectedMultiTeamOptions) => {
  //   setSelectedMultiTeamOptions(selectedMultiTeamOptions);
  // };
  const handleTitleSelect = (SelectedTitleOptions) => {
    setSelectedTitleOptions(SelectedTitleOptions);
    console.log(SelectedTitleOptions);
    setEmp({ ...emp, hourly_rate: SelectedTitleOptions.hourly_rate, utilization: SelectedTitleOptions.utilization });
  };
  const handleTeamSelect = (SelectedTeamOptions) => {
    setSelectedTeamOptions(SelectedTeamOptions);
  };
  // const handleTeamSelect = (selectedOptions) => {
  //   const selectedValues = selectedOptions.map((option) => option.value);
  //   setSelectedTeamOptions(selectedOptions);
  //   setSelectedTeamValues(selectedValues);
  // };
  const handleRoleSelect = (SelectedRoleOptions) => {
    setSelectedRoleOptions(SelectedRoleOptions);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrors({});
    setEmp({
      first_Name: '',
      last_Name: '',
      hourly_rate: '',
      emp_code: '',
      email: '',
      role: '',
      team: '',
      manager:'',
      title: '',
      utilization:'',
    });
    setSelectedTitleOptions([]);
    setSelectedTeamOptions([]);
    setSelectedRoleOptions([]);
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleAddChange = (e) => {
    setEmp({ ...emp, [e.target.name]: e.target.value });
  };
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!emp.first_Name.trim()) {
      errors.first_Name = 'Please enter the first name';
    }
    if (!emp.last_Name.trim()) {
      errors.last_Name = 'Please enter the last name';
    }

    if (!SelectedTitleOptions.value) {
      errors.Title = 'Please select the Title';
    }

    // if (!emp.emp_code.trim()) {
    //   errors.emp_code = 'Please enter the Employee ID';
    // }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[^\s@]+@code3\.com$/;
    if (!emp.email.trim()) {
      errors.email = 'Email is required!';
    } else if (!regex.test(emp.email)) {
      errors.email = 'Please enter valid email!';
    } else if (!emailRegex.test(emp.email)) {
      errors.email = 'Please use email with code3 domain only';
    }

    if (!SelectedRoleOptions.value) {
      errors.role = 'Please select the role';
    }

    setErrors(errors);

    // Check if any errors exist
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      return;
    }

    const details = {
      ...emp,
      title: SelectedTitleOptions.value && SelectedTitleOptions.value,
      team: SelectedTeamOptions.value && SelectedTeamOptions.value,
      // team: SelectedTeamValues&&SelectedTeamValues,
      role: SelectedRoleOptions.value && SelectedRoleOptions.value,
      manager:selectedManagerOption?.value,
      emailAlert: emailAlertCheckbox
    };
    axios
      .post('/admin/resource', details)
      .then((response) => {
        // console.log(response);
        toast.success(response.data.message);
        setTimeout(() => {
          setShowModal(false);
          setRefreshPage(!refreshPage);
          setEmp({
            first_Name: '',
            last_Name: '',
            hourly_rate: '',
            emp_code: '',
            email: '',
            role: '',
            team: '',
            title: '',
            manager:'',
            utilization:'',
          });
          setSelectedTitleOptions([]);
          setSelectedTeamOptions([]);
          setSelectedRoleOptions([]);
          setSelectedManagerOption([])
          setEmailAlertCheckbox(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    axios
      .get('/admin/Titles_wopaging')
      .then((response) => {
        // console.log(response.data);
        setTitleOptions(
          response.data.data.map((item) => ({
            value: item._id,
            label: item.name,
            hourly_rate: item.hourly_rate,
            utilization: item.utilization,
          })).sort((a,b)=>{
            if(typeof(a.label) === 'string'){
              return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
            }
            return a.label > b.label ? 1 : -1;
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [activeTab]);

  useEffect(() => {
    axios
      .get('/admin/teams_employee')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setMultiTeamoptions(
          response.data.data.map((item) => ({ value: item._id, label: item.name })).sort((a,b)=>{
            if(typeof(a.label) === 'string'){
              return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
            }
            return a.label > b.label ? 1 : -1;
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });

      axios
      .get('/admin/teams_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setTeamWP(
          response.data.data.map((item) => ({ value: item._id, label: item.name })).sort((a,b)=>{
            if(typeof(a.label) === 'string'){
              return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
            }
            return a.label > b.label ? 1 : -1;
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });

      axios
      .get('/admin/resources_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setMultiManagerOptions(
          response.data.data.filter((item)=>item.role==="Admin"||item.role==="Manager"||item.role==="Lead").map((item) => ({ value: item._id, label: item.first_Name+" "+item.last_Name})).sort((a,b)=>{
            if(typeof(a.label) === 'string'){
              return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
            }
            return a.label > b.label ? 1 : -1;
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refreshPage]);

  const fetchExportData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/admin/Exportresources?keyword=${search}`,
        {
          teamIds: selectedMultiTeamValues,
        }
      );
      setLoading(false);

      return response.data.data;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data: ", error);
    }
  };

  const handleExport = async () => {
    try {
      let exportData = await fetchExportData();

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(
        exportData.map((item) => ({
          "Resource Name": item.first_Name + ' ' + item.last_Name,
          "Team": item.team?.[0]?.name,
          "Utilization Target": Number(Number(item.utilization/100).toFixed(2)),
          "Hourly Rate": Number(item.hourly_rate),
          "Title": item.title?.[0]?.name,
          "Manager": item.manager?.[0]?.first_Name + " " + item.manager?.[0]?.last_Name,
          "Email": item.email,
          "Role": item.role,
          "isEmailAlertChecked":item.emailAlert?1:0
        }))
      );

      XLSX.utils.book_append_sheet(wb, ws, 'Resource Data');
      XLSX.writeFile(wb, 'ResourceData.xlsx');
    } catch (error) {
      console.error("Error exporting data: ", error);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Wrapper>
          <Modal.Header closeButton>
            <Modal.Title>New Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddSubmit}>
              <div class='row'>
                <div class='form-border'>
                  <div class=''>
                    <div class='row mt-2'>
                      <div class='col-md-6'>
                        <label class='form-label'>First Name</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUser />
                          </span>
                          <input
                            type='text'
                            className={`form-control shadow-none ${errors.first_Name ? 'is-invalid' : ''
                              }`}
                            placeholder='e.g Peter'
                            name='first_Name'
                            onChange={handleAddChange}
                            value={emp.first_Name}
                          />
                          {errors.first_Name && (
                            <div className='invalid-feedback'>{errors.first_Name}</div>
                          )}
                        </div>
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Last Name</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUser />
                          </span>
                          <input
                            type='text'
                            className={`form-control shadow-none ${errors.last_Name ? 'is-invalid' : ''
                              }`}
                            name='last_Name'
                            onChange={handleAddChange}
                            value={emp.last_Name}
                            placeholder='e.g. Parker'
                          />
                          {errors.last_Name && (
                            <div className='invalid-feedback'>{errors.last_Name}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Title</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <BiUserCheck />
                          </span>
                          <Select
                            className={`select-form-control shadow-none ${errors.Title ? 'is-invalid' : ''
                              }`}
                            id='TitleSelect'
                            options={TitleOptions}
                            value={SelectedTitleOptions}
                            onChange={handleTitleSelect}
                          />
                          {errors.Title && <div className='invalid-feedback'>{errors.Title}</div>}
                        </div>
                      </div>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Utilization Target</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                          <img src={require('../../../../assets/icons/clock.png')} alt='profile-check' />
                          </span>
                          <input
                              type='number'
                              placeholder='eg.12'
                              className={`form-control shadow-none remove-arrow ${errors.utilization ? 'is-invalid' : ''
                                }`}
                              name='utilization'
                              onChange={handleAddChange}
                              value={emp.utilization ? emp.utilization : ''}
                            />
                                                        <div class='input-group-append'>
                              <span class='input-group-text'>%</span>
                            </div>
                          {errors.utilization && (
                              <div className='invalid-feedback'>{errors.utilization}</div>
                            )}
                        </div>
                      </div>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Team</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUserGroup />
                          </span>
                          <Select
                            className='select-form-control'
                            id='TeamSelect'
                            options={MultiTeamoptions}
                            value={SelectedTeamOptions}
                            onChange={handleTeamSelect}
                          // isMulti={true}
                          />
                        </div>
                      </div>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Manager</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUserGroup />
                          </span>
                          <Select
                            className='select-form-control'
                            id='TeamSelect'
                            options={multiManagerOptions}
                            value={selectedManagerOption}
                            onChange={(e)=>{setSelectedManagerOption(e);setEmp((prev)=>({...prev,manager:e.value}))}}
                          // isMulti={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div class='row mt-2'>
                      <div class='col-md-6'>
                        <label class='form-label'>Hourly Rate</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineUser />
                          </span>
                          {/* <div class="input-group"> */}
                          <div class='input-group-prepend'>
                            <span class='input-group-text'>$</span>
                          </div>
                          <input
                            type='number'
                            class='form-control shadow-none '
                            placeholder=''
                            name='hourly_rate'
                            //  onChange={handleAddChange}
                            value={emp.hourly_rate}
                            disabled
                          />

                          {/* </div>                 */}
                        </div>
                      </div>
                      <div class='col-md-6'>
                        <label class='form-label'>Employee ID</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <AiOutlineIdcard />
                          </span>
                          <input
                            type='text'
                            className={`form-control shadow-none`}
                            name='emp_code'
                            onChange={handleAddChange}
                            value={emp.emp_code}
                            placeholder='e.g. EMP-4584'
                          />
                          {/* {errors.emp_code && <div className="invalid-feedback">{errors.emp_code}</div>} */}
                        </div>
                      </div>
                    </div>

                    <div class='row'>
                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Email</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <HiOutlineMail />
                          </span>
                          <input
                            type='email'
                            className={`form-control shadow-none ${errors.email ? 'is-invalid' : ''
                              }`}
                            placeholder='e.g. markspencer@code3.com'
                            name='email'
                            onChange={handleAddChange}
                            value={emp.email}
                          />
                          {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                        </div>
                      </div>

                      <div class='col-md-12 mt-3'>
                        <label class='form-label'>Role</label>
                        <div class='input-group mb-1'>
                          <span class='input-group-text'>
                            <BiUserCircle />
                          </span>
                          <Select
                            className={`select-form-control shadow-none ${errors.role ? 'is-invalid' : ''
                              }`}
                            id='RoleSelect'
                            options={RoleOptions.sort((a,b)=>{
                              return a.label > b.label ? 1: -1;
                            })}
                            value={SelectedRoleOptions}
                            onChange={handleRoleSelect}
                          />
                          {errors.role && <div className='invalid-feedback'>{errors.role}</div>}
                        </div>
                      </div>

                      <div class='col-md-12 mt-3'>
                        <div className="email-alert">
                          <Form.Check
                            type='checkbox'
                            id='emailAlert'
                            label={`Email Alert`}
                            checked={emailAlertCheckbox}
                            onChange={handleEmailAlertCheckbox}
                          />
                        </div>
                      </div>
                    </div>

                    <div class='d-flex justify-content-center'>
                      <div class='mt-2 mx-2'>
                        <button
                          class='btn btn-primary employee-cancel-button'
                          type='button'
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                      <div class='mt-2 mx-2'>
                        <button class='btn btn-primary employee-save-button' type='submit'>
                          Add Employee
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Wrapper>
      </Modal>

      <div className='tabs-ride-container'>
        <div></div>
        <label htmlFor='search-input'>
          Search By Employee Name
          <div className='search-input-container'>
            <div className='search-icon-container'>
              <BiSearch className='react-icon' />
            </div>
            <input
              type='text'
              id='search-input'
              value={search}
              onChange={handleSearchChange}
              placeholder='Type in a keyword or name'
            />
          </div>
        </label>

        <div className='multi-select-container'>
          <label>
            Team
            <Select
              id='multiSelectTeam'
              isMulti
              options={teamWP}
              value={selectedMultiTeamOptions}
              onChange={handleMultiSelectTeamChange}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option: CheckboxOption }}
            />
          </label>
        </div>

        <div className='add-emp-btn-container' onClick={handleShowModal}>
          <div className='search-icon-container'>
            <BsPlus className='react-icon' />
          </div>
          <input type='button' id='addEmployee' value='Add Employee' />
        </div>
        
        <div className='employee-export-button' onClick={handleExport}>
            <MdOutlineFileDownload className='react-icon text-primary cursor-pointer'  />
          
        </div>
      </div>

      <EmployeeTabBody
        selectedMultiTeamValues={selectedMultiTeamValues}
        multiManagerOptions={multiManagerOptions}
        search={search}
        MultiTeamoptions={MultiTeamoptions}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
        TitleOptions={TitleOptions}
        setTitleOptions={setTitleOptions}
      />

    </div>
  );
}

// Custom component to render checkboxes
const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

export default Employee;
const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
.employee-export-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
cursor:pointer;
}

.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

.email-alert{
  margin-left: 33px;
}

.remove-arrow::-webkit-inner-spin-button, .remove-arrow::-webkit-outer-spin-button{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

`;
