import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from '../pages/common/Login';
import Reset from '../pages/common/Reset';
import HomeCode3 from '../pages/common/HomeCode3';
// import Signup from "../pages/common/Signup";
// import Carousel from "../pages/common/Carousel";
// import Home2 from "../pages/common/Home2";

// admin imports

import AdminDashboard from '../pages/code3_admin/dashboard/Dashboard';

import AdminScheduler from '../pages/code3_admin/scheduler/Scheduler';

import AdminRevenue from '../pages/code3_admin/revenue/Revenue';

import AdminSetupResources from '../pages/code3_admin/setupResources/SetupResources';

import AdminInsightsReport from '../pages/code3_admin/insights/hours/Report';
import AdminInsightsRealization from '../pages/code3_admin/insights/realization/Realization';
import AdminInsightsShedule from '../pages/code3_admin/insights/shedule/Shedule';
import AdminInsightsUtilization from '../pages/code3_admin/insights/utilization/Utilization';
import AdminArchive from '../pages/code3_admin/archive/Archive';
import AdminMyTeam from '../pages/code3_admin/myteam/AdminMyTeam';
import LoginHistory from '../pages/code3_admin/insights/loginHistory/LoginHistory';

// Employee imports
const EmployeeDashboard = lazy(() => import('../pages/code3_employee/dashboard/Dashboard'));

const EmployeeTimer = lazy(() => import('../pages/code3_employee/Timer/Timer'));
const CronTimer = lazy(() => import('../components/CronLoggedUserCheck/CronTimer'));
const CronShedule = lazy(() => import('../components/CronLoggedUserCheck/CronShedule'));
const CronHours = lazy(() => import('../components/CronLoggedUserCheck/CronHours'));
const CronProfitability = lazy(() => import('../components/CronLoggedUserCheck/CronProfitability'));
const CronUtilization = lazy(() => import('../components/CronLoggedUserCheck/CronUtilization'));
const EmployeeTimer2 = lazy(() => import('../pages/code3_employee/Timer/Timer2'));

const EmployeeProfile = lazy(() => import('../pages/code3_employee/profile/Profile'));
const Password = lazy(() => import('../pages/code3_employee/profile/Password'));
const Notifications = lazy(() => import('../pages/code3_employee/profile/Notifications'));
const EmployeeSupport = lazy(() => import('../pages/code3_employee/profile/Support'));
const TimerSettings = lazy(() => import('../pages/code3_employee/profile/TimerSettings'));

//enterprise imports
// const EnterpriseForgotPassword = lazy(() =>
//   import("../pages/common/ForgotPassword")
// );
// const EnterpriseResetPassword = lazy(() =>
//   import("../pages/common/ResetPassword")
// );
// const EnterpriseEmailVerification = lazy(() =>
//   import("../pages/common/EmailVerification")
// );
// const EnterpriseContact = lazy(() => import("../pages/common/Contact"));
// const EnterpriseMainScreenHome = lazy(() =>
//   import("../pages/common/EnterpriseHome")
// );
// const EnterpriseHome = lazy(() => import("../pages/enterprise/home/Home"));

// const EnterpriseAccount = lazy(() =>
//   import("../pages/enterprise/account/Account")
// );
// const EnterpriseEditAccount = lazy(() =>
//   import("../pages/enterprise/account/EditAccount")
// );
// const EnterpriseSettings = lazy(() =>
//   import("../pages/enterprise/setting/Setting")
// );
// const EnterpriseMyTransactions = lazy(() =>
//   import("../pages/enterprise/transaction/Transaction")
// );
// const EnterpriseNewyorkTime = lazy(() =>
//   import("../pages/enterprise/newyorkTime/NewYorkTime")
// );
// const EnterpriseNewyorkTimePendingREquests = lazy(() =>
//   import("../pages/enterprise/newyorkTime/PendingRequests")
// );

function routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/resetpassword/:resetToken' element={<Reset />} />
          <Route path='/schedule/:userFromCron' element={<CronShedule />} />
          <Route path='/hours/:userFromCron' element={<CronHours />} />
          <Route path='/timer/:userFromCron' element={<CronTimer />} />
          <Route path='/profitability/:userFromCron' element={<CronProfitability />} />
          <Route path='/utilization/:userFromCron' element={<CronUtilization />} />
          <Route path='/' element={<Login />} />
          <Route  element={<PrivateRoute><HomeCode3 /></PrivateRoute>}>
            {/* <Route path="/admin" > */}
            {/* <Route index element={<AdminDashboard/>}/> */}
            <Route path='dashboard' element={<AdminDashboard />} />
            <Route path='scheduler' element={<AdminScheduler />} />
            <Route path='revenue' element={<AdminRevenue />} />
            <Route path='setupResources' element={<AdminSetupResources />} />
            <Route path='hours' element={<AdminInsightsReport />} />
            <Route path='profitability' element={<AdminInsightsRealization />} />
            <Route path='loginhistory' element={<LoginHistory />} />
            <Route path='schedule' element={<AdminInsightsShedule />} />
            <Route path='utilization' element={<AdminInsightsUtilization />} />
            <Route path='archive' element={<AdminArchive />} />
            <Route path='profile' element={<EmployeeProfile />} />
            <Route path='timerSettings' element={<TimerSettings />} />

            <Route path='password' element={<Password />} />
            <Route path='support' element={<EmployeeSupport />} />
            <Route path='timer' element={<EmployeeTimer />} />
            <Route path='notifications' element={<Notifications />} />
            <Route path='team' element={<AdminMyTeam />} />

            {/* </Route> */}
            {/* <Route path="/employee">
            <Route path="dashboard" element={<AdminDashboard/>}/>
            <Route path="revenue" element={<AdminRevenue/>}/>

            </Route> */}
          </Route>

          {/* <Route path="/" element={<Login />}>
            <Route index element={<EnterpriseMainScreenHome />} />
            <Route path="login" element={<Login />} />
            <Route
              path="forgotpassword"
              element={<EnterpriseForgotPassword />}
            />
            <Route
              path="emailverification"
              element={<EnterpriseEmailVerification />}
            />
            <Route path="resetpassword" element={<EnterpriseResetPassword />} />
            <Route path="signup" element={<Signup />} />
            <Route path="contact" element={<EnterpriseContact />} />
            <Route path="mainhome" element={<EnterpriseMainScreenHome />} />
          </Route> */}

          {/* <Route
            element={
              <PrivateRoute>
                <Home2 />
              </PrivateRoute>
            }
          >
            <Route path="/enterprise">
              <Route
                index
                element={
                  <PrivateRoute>
                    <EnterpriseHome />
                  </PrivateRoute>
                }
              />
              <Route
                path="home"
                element={
                  <PrivateRoute>
                    <EnterpriseHome />
                  </PrivateRoute>
                }
              />
              <Route path="settings" element={<EnterpriseSettings />} />
              <Route path="account" element={<EnterpriseAccount />} />
              <Route path="editaccount" element={<EnterpriseEditAccount />} />
              <Route
                path="mytransactions"
                element={<EnterpriseMyTransactions />}
              />
              <Route path="newyorktime" element={<EnterpriseNewyorkTime />} />
              <Route
                path="newyorktime-pendingrequests"
                element={<EnterpriseNewyorkTimePendingREquests />}
              />
            </Route>
          </Route> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default routes;
