import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Styled from 'styled-components';
import axios from '../../../utils/api';
import { BsRepeat } from 'react-icons/bs';
import 'react-toastify/dist/ReactToastify.css';
import {MdOutlineWarning} from "react-icons/md"



function WarningModal({ showModal, setShowModal, data,ignoreWarningMessage,setIgnoreWarningMessage }) {
 
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
 

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='d-flex'>
              
              <MdOutlineWarning style={{color:"red"}}/>
              <span className='mx-3'>Warning</span>
              
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {data.map((item,i)=>{
            return (
                <div key={i} className='warning-message'>
                   {item} 
                </div>
            )
         })}

               <div class='d-flex justify-content-center'>
                    <div class='mt-2 mx-2'>
                      <button
                        class='btn btn-primary employee-cancel-button'
                        type='button'
                        onClick={() => handleCloseModal()}
                      >
                        Cancel
                      </button>
                    </div>
                    <div class='mt-2 mx-2'>
                      <button class='btn btn-danger employee-save-button'
                       onClick={() => {handleCloseModal();setIgnoreWarningMessage(true)}} >
                        Ignore Warning
                      </button>
                    </div>
                  </div>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default WarningModal;

const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #ffcccb;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 10px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background:red;
    color:white;
border-radius: 8px;
border-color:red;
}
.select-form-control{
    width:100%;
}
.select-form-control div{
    border-radius: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

.date-inputs{
    max-width:150px;
}
.warning-message{
    color:red;
}

`;
