import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import Select from 'react-select';
import { Header } from '../../../../components/Header/Header';
// import { Sidebar } from "./Sidebar";
import { BiSearch } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';

import { HiOutlineUser } from 'react-icons/hi';
import { HiOutlineMail } from 'react-icons/hi';
import { BiUserCircle } from 'react-icons/bi';
import { AiOutlineIdcard } from 'react-icons/ai';
import { BiUserCheck } from 'react-icons/bi';
import { HiOutlineUserGroup } from 'react-icons/hi';

import { Modal } from 'react-bootstrap';
import EmployeeTabBody from './TitleTabBody';
import AddTitleModal from './AddTitleModal';
function Employee({ activeTab }) {
  const [showAddTitleModal, setShowAddTitleModal] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [SelectedTitleOptions, setSelectedTitleOptions] = useState([]);
  const [SelectedTeamOptions, setSelectedTeamOptions] = useState([]);
  const [SelectedRoleOptions, setSelectedRoleOptions] = useState([]);

  const [refreshPage, setRefreshPage] = useState(false);

  const MultiTeamoptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    { value: 'option4', label: 'Option 4' },
    { value: 'option5', label: 'Option 5' },
  ];
  const TitleOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    { value: 'option4', label: 'Option 4' },
    { value: 'option5', label: 'Option 5' },
  ];
  const TeamOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    { value: 'option4', label: 'Option 4' },
    { value: 'option5', label: 'Option 5' },
  ];
  const RoleOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    { value: 'option4', label: 'Option 4' },
    { value: 'option5', label: 'Option 5' },
  ];

  const handleMultiSelectTeamChange = (selectedMultiTeamOptions) => {
    setSelectedMultiTeamOptions(selectedMultiTeamOptions);
  };
  const handleTitleSelect = (SelectedTitleOptions) => {
    setSelectedTitleOptions(SelectedTitleOptions);
  };
  const handleTeamSelect = (SelectedTeamOptions) => {
    setSelectedTeamOptions(SelectedTeamOptions);
  };
  const handleRoleSelect = (SelectedRoleOptions) => {
    setSelectedRoleOptions(SelectedRoleOptions);
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div>
      {showAddTitleModal && (
        <AddTitleModal
          show={showAddTitleModal}
          setShow={setShowAddTitleModal}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
        />
      )}
      <div className='tabs-ride-container'>
        <div></div>
        <label htmlFor='search-input'>
          Search By Title
          <div className='search-input-container'>
            <div className='search-icon-container'>
              <BiSearch className='react-icon' />
            </div>
            <input
              type='text'
              id='search-input'
              value={search}
              onChange={handleSearchChange}
              placeholder='Type in a keyword or name'
            />
          </div>
        </label>

        <div className='add-emp-btn-container' onClick={() => setShowAddTitleModal(true)}>
          <div className='search-icon-container'>
            <BsPlus className='react-icon' />
          </div>
          <input type='button' id='addEmployee' value='Add Title' />
        </div>
      </div>

      <EmployeeTabBody
        search={search}
        screenName='Frequently Asked Questions'
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
      />
    </div>
  );
}

export default Employee;
const Wrapper = Styled.div`
.avatar-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.avatar-image {
  width: 44px;
  height: 44px;
}
.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.scrollable-div {
  height: 200px; /* Set the height of the div */
  overflow-y: scroll; /* Add vertical scrolling */
}

.scrollable-div::-webkit-scrollbar {
  width: 8px;
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #2984FD;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
`;
