import { ViewType, SummaryPos } from './default';



export default {
  schedulerWidth: '100%',
  besidesWidth: 40,
  schedulerMaxHeight: 0,
  tableHeaderHeight: 50,
  schedulerContentHeight: '500px',

  responsiveByParent:false,

  agendaResourceTableWidth: 160,
  agendaMaxEventWidth: 100,

  dayResourceTableWidth: 160,
  weekResourceTableWidth: '16%',
  monthResourceTableWidth: 200,
  quarterResourceTableWidth: 160,
  yearResourceTableWidth: 160,
  customResourceTableWidth:220,

  dayCellWidth: 30,
  weekCellWidth: '12%',
  monthCellWidth:80 ,
  quarterCellWidth: 80,
  yearCellWidth: 80,
  customCellWidth:80,

  dayMaxEvents: 99,
  weekMaxEvents: 99,
  monthMaxEvents: 99,
  quarterMaxEvents: 99,
  yearMaxEvents: 99,
  customMaxEvents: 99,

  eventItemPopoverTrigger: 'hover',
  eventItemPopoverPlacement: 'bottomLeft',
  eventItemPopoverWidth: 300,

  eventItemHeight: 22,
  eventItemLineHeight: 91,
  nonAgendaSlotMinHeight: 0,
  dayStartFrom: 0,
  dayStopTo: 31,
  defaultEventBgColor: '#80C5F6',
  selectedAreaColor: '#ba7cf7',
  nonWorkingTimeHeadColor: '#999999',
  nonWorkingTimeHeadBgColor: '#d4d2d2',
  nonWorkingTimeBodyBgColor: '#d4d2d2',
  summaryColor: '#666',
  summaryPos: SummaryPos.TopRight,
  groupOnlySlotColor: '#F8F8F8',

  startResizable: false,
  endResizable: false,
  movable: false,
  creatable: true,
  crossResourceMove: true,
  checkConflict: false,
  scrollToSpecialDayjsEnabled: false,
  eventItemPopoverEnabled: true,
  eventItemPopoverShowColor:false,
  calendarPopoverEnabled: true,
  recurringEventsEnabled: false,
  viewChangeSpinEnabled: true,
  dateChangeSpinEnabled: true,
  headerEnabled: true,
  resourceViewEnabled: true,
  displayWeekend: true,
  relativeMove: false,
  defaultExpanded: true,
  dragAndDropEnabled: false,

  schedulerHeaderEventsFuncsTimeoutMs: 100,

  resourceName: 'Resource Name',
  taskName: 'Task Name',
  agendaViewHeader: 'Agenda',
  addMorePopoverHeaderFormat: 'MMM D, YYYY dddd',
  eventItemPopoverDateFormat: 'MMM D',
  nonAgendaDayCellHeaderFormat: 'ha',
  nonAgendaWeekCellHeaderFormat: 'ww/YYYY',
  nonAgendaMonthCellHeaderFormat: 'MMM YYYY',
  nonAgendaYearCellHeaderFormat: 'YYYY',
  nonAgendaOtherCellHeaderFormat: 'ddd D',

  minuteStep: 30,

  views: [
    // { viewName: 'Day', viewType: ViewType.Day, showAgenda: false, isEventPerspective: false },
    // { viewName: 'Week', viewType: ViewType.Week, showAgenda: false, isEventPerspective: false },
    // { viewName: 'Month', viewType: ViewType.Month, showAgenda: false, isEventPerspective: false },
    // { viewName: 'Quarter', viewType: ViewType.Quarter, showAgenda: false, isEventPerspective: false },
    // { viewName: 'Year', viewType: ViewType.Year, showAgenda: false, isEventPerspective: false },
  ],
};
