import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import ReactSelect from 'react-select';
import axios from '../../../../utils/api';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';

import AccountsTabBody from './AccountsTabBody';
function Accounts() {
  const { Option } = Select;
  const [selectedDate, setSelectedDate] = useState(null);
  const [pickerMode, setPickerMode] = useState('date');
  const [SelectedDateFormattedData, setSelectedDateFormattedData] = useState({
    pickerMode: '',
    formattedDate: '',
    startDate: '',
    endDate: '',
    formattedMonth: '',
  });
  const [refreshPage, setRefreshPage] = useState(false);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);

  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [selectedMultiAccountsOptions, setSelectedMultiAccountsOptions] = useState([]);
  const [selectedMultiAccountsValues, setSelectedMultiAccountsValues] = useState([]);

  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
    if (pickerMode === 'date') {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      setSelectedDateFormattedData({
        pickerMode,
        formattedDate,
        startDate: '',
        endDate: '',
        formattedMonth: '',
      });
    } else if (pickerMode === 'week') {
      const startDate = dayjs(date).startOf('week').format('YYYY-MM-DD');
      const endDate = dayjs(date).endOf('week').format('YYYY-MM-DD');
      setSelectedDateFormattedData({
        pickerMode,
        formattedDate: '',
        startDate,
        endDate,
        formattedMonth: '',
      });
    } else if (pickerMode === 'month') {
      const formattedMonth = dayjs(date).format('MM YYYY');
      setSelectedDateFormattedData({
        pickerMode,
        formattedDate: '',
        startDate: '',
        endDate: '',
        formattedMonth,
      });
    }
  };

  const handlePickerModeChange = (value) => {
    setPickerMode(value);
  };

  const renderCalendar = () => {
    if (pickerMode === 'date') {
      return <DatePicker value={selectedDate} onChange={handleDateChange} mode='date' />;
    } else if (pickerMode === 'week') {
      return <DatePicker.WeekPicker value={selectedDate} onChange={handleDateChange} />;
    } else if (pickerMode === 'month') {
      return <DatePicker.MonthPicker value={selectedDate} onChange={handleDateChange} />;
    }
  };

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
    console.log('selectedMultiTeamValues', selectedMultiTeamValues);
  };
  const handleMultiSelectAccountsChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiAccountsOptions(selectedOptions);
    setSelectedMultiAccountsValues(selectedValues);
    console.log('selectedMultiAccountsValues', selectedMultiAccountsValues);
  };

  useEffect(() => {
    axios
      .get('/admin/Archiveaccount_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setAccountOptions(
          response.data.data.map((item) => ({ value: item._id, label: item.name })).sort((a,b)=>{
            return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/teams_wopagingAndStatus')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setTeamOptions(response.data.data.map((item) => ({ value: item._id, label: item.name })));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refreshPage]);

  return (
    <Wrapper>
      <div>
        <div className='tabs-ride-container'>
          <div className='multi-select-container'>
            <label>
              Accounts
              <ReactSelect
                id='multiSelectAccounts'
                isMulti
                options={accountOptions}
                value={selectedMultiAccountsOptions}
                onChange={handleMultiSelectAccountsChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: CheckboxOption }}
              />
            </label>
          </div>

          <div className='multi-select-container'>
            <label>
              Team
              <ReactSelect
                id='multiSelectTeam'
                isMulti
                options={TeamOptions}
                value={selectedMultiTeamOptions}
                onChange={handleMultiSelectTeamChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option: CheckboxOption }}
              />
            </label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', padding: '0px' }}>
            <Select defaultValue='date' onChange={handlePickerModeChange}>
              <Option value='date'>Date</Option>
              <Option value='week'>Week</Option>
              <Option value='month'>Month</Option>
            </Select>
            {renderCalendar()}
          </div>
        </div>
        <AccountsTabBody
          selectedMultiTeamValues={selectedMultiTeamValues}
          selectedMultiAccountsValues={selectedMultiAccountsValues}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          SelectedDateFormattedData={SelectedDateFormattedData}
        />
      </div>
    </Wrapper>
  );
}

// Custom component to render checkboxes
const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

export default Accounts;

const Wrapper = Styled.div`

.react-icon{
    height: 22px;
    width: 22px;
}
    .bg .row{
    --bs-gutter-x: 0px !important;
    }

.tabs-ride-container{
    position:absolute;
    /* left:50%; */
    right: 1%;
    top:6px;
    display: flex;
flex-direction: row;
align-items: flex-end;
padding: 0px;
gap: 20px;
}
label {
    font-size: 12px;
    color: #201E20;
}
.search-input-container {
  display: flex;
  align-items: center;
  width: 255px;
  height: 38px;

  border: 1px solid #636263;
border-radius: 8px;
  padding: 5px;
}
.add-task-btn-container {
  display: flex;
  align-items: center;
  width: 135px;
  height: 38px;

  border: 1px solid #2984FD;
  background: #2984FD;
border-radius: 8px;
  padding: 5px;
  color:#fff;
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  min-width: 160px!important;
  border: 1px solid #636263;
border-radius: 8px;
}
.ant-picker{
  height: 38px;
    border: 1px solid #636263;
    width: 125px;
}
.ant-select-selector{
  height: 38px !important;
    border: 1px solid #636263!important;
    /* border-right:none; */
}
.search-icon-container {
  margin-right: 5px;
}

.search-input-container input[type="date"] {
  flex: 1;
  border: none;
  outline: none;
}
.add-task-btn-container input[type="button"] {
    background: #2984FD;
    color:#fff;
  flex: 1;
  border: none;
  outline: none;
  padding: 7px 0px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

.multi-select-container{
  /* height: 200px;  */
  /* overflow-y: scroll;  */
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
    .nav-tabs {
    display: block !important;
}
    .nav-tabs li{
      color: rgb(255, 255, 255);
    border-radius: 8px;
    background-color: rgb(28, 43, 72)
}
}

.table-responsive{
  height:auto;
}

`;
