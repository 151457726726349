import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import Select from 'react-select';
import axios from '../../../../utils/api';
import 'react-toastify/dist/ReactToastify.css';
import { BiSearch } from 'react-icons/bi';
import EmployeeTabBody from './EmployeeTabBody';
function Employee(activeTab) {
  const [errors, setErrors] = useState({});
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [TitleOptions, setTitleOptions] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [search, setSearch] = useState('');
  const [MultiTeamoptions, setMultiTeamoptions] = useState([]);

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    axios
      .get('/admin/teams_wopagingAndStatus')
      .then((response) => {
        // console.log("resources_wopaging",response.data.data);
        setMultiTeamoptions(
          response.data.data.map((item) => ({ value: item._id, label: item.name })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refreshPage]);

  return (
    <div>
      <div className='tabs-ride-container'>
        <div></div>
        <label htmlFor='search-input'>
          Search By Employee Name
          <div className='search-input-container'>
            <div className='search-icon-container'>
              <BiSearch className='react-icon' />
            </div>
            <input
              type='text'
              id='search-input'
              value={search}
              onChange={handleSearchChange}
              placeholder='Type in a keyword or name'
            />
          </div>
        </label>

        <div className='multi-select-container'>
          <label>
            Team
            <Select
              id='multiSelectTeam'
              isMulti
              options={MultiTeamoptions}
              value={selectedMultiTeamOptions}
              onChange={handleMultiSelectTeamChange}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option: CheckboxOption }}
            />
          </label>
        </div>
      </div>

      <EmployeeTabBody
        selectedMultiTeamValues={selectedMultiTeamValues}
        search={search}
        MultiTeamoptions={MultiTeamoptions}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
      />
    </div>
  );
}

// Custom component to render checkboxes
const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

export default Employee;
const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

`;
