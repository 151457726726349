import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSessionStarted: false,
  sessionDuration: '',
  sessionDurationStartingMilliseconds: '',
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateIsSessionStarted: (state, action) => {
      state.isSessionStarted = action.payload;
    },
    updateSessionDuration: (state, action) => {
      state.sessionDuration = action.payload;
    },
    updateSessionDurationStartingMilliseconds: (state, action) => {
      state.sessionDurationStartingMilliseconds = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateIsSessionStarted,
  updateSessionDuration,
  updateSessionDurationStartingMilliseconds,
} = sessionSlice.actions;

export default sessionSlice.reducer;
