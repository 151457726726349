import { ViewType, CellUnit } from '../components';
import dayjs from "dayjs"

// getSummary func example
// export const getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => ({ text: 'Summary', color: 'red', fontSize: '1.2rem' });
export const getSummary = () => ({ text: 'Summary', color: 'red', fontSize: '1.2rem' });

// getCustomDate example
export const getCustomDate = (schedulerData, num, date = dayjs(schedulerData.startDate)) => {
  const { viewType, localeDayjs } = schedulerData;
  let startDate;
  let endDate;
  let cellUnit;

  if (viewType === ViewType.Custom1) {
    const monday = localeDayjs(new Date(date)).startOf('week');
    startDate = num === 0 ? monday : localeDayjs(new Date(monday)).add(2 * num, 'weeks');
    endDate = localeDayjs(new Date(startDate)).add(1, 'weeks').endOf('week');
    cellUnit = CellUnit.Day;
    } else if (viewType === ViewType.Custom2) {
      console.log(date,"date from inside behaviorr")
    const firstDayOfMonth = localeDayjs(date).startOf("month");
    // startDate =
    //   num === 0 ? firstDayOfMonth : localeDayjs(new Date(firstDayOfMonth)).add(2 * num, 'months');
      startDate=localeDayjs(firstDayOfMonth)
    endDate = localeDayjs(startDate).add(11,"M").endOf("month");
    console.log("from behaviors",startDate,endDate)
    cellUnit = CellUnit.Month;
  }
  // else if (viewType === ViewType.Custom2) {
  //   const firstDayOfMonth = dayjs("2023-12-01").add(1,"M");
  //   // startDate =
  //   //   num === 0 ? firstDayOfMonth : localeDayjs(new Date(firstDayOfMonth)).add(2 * num, 'months');
  //     startDate=firstDayOfMonth
  //   endDate = dayjs(startDate).add(12,"M").endOf("month");
  //   console.log("from behaviors",startDate,endDate)
  //   cellUnit = CellUnit.Month;
  // }
  
  else {
    startDate = num === 0 ? date : localeDayjs(new Date(date)).add(2 * num, 'days');
    endDate = localeDayjs(new Date(startDate)).add(1, 'days');
    cellUnit = CellUnit.Hour;
  }
 console.log(startDate,endDate,cellUnit)
  return { startDate, endDate, cellUnit };
};

// getNonAgendaViewBodyCellBgColor example
export const getNonAgendaViewBodyCellBgColor = (schedulerData, slotId, header) =>
  header.nonWorkingTime ? undefined : '#87e8de';

// getDateLabel func example
export const getDateLabel = (schedulerData, viewType, startDate, endDate) => {
  const { localeDayjs } = schedulerData;
  const start = localeDayjs(new Date(startDate));
  const end = localeDayjs(endDate);
  let dateLabel = '';

  if (
    viewType === ViewType.Week ||
    (start !== end &&
      (viewType === ViewType.Custom ||
        viewType === ViewType.Custom1 ||
        viewType === ViewType.Custom2))
  ) {
    dateLabel = `${start.format('MMM D')}-${end.format('D, YYYY')}`;
    if (start.month() !== end.month())
      dateLabel = `${start.format('MMM D')}-${end.format('MMM D, YYYY')}`;
    if (start.year() !== end.year())
      dateLabel = `${start.format('MMM D, YYYY')}-${end.format('MMM D, YYYY')}`;
  } else if (viewType === ViewType.Month) {
    dateLabel = start.format('MMMM YYYY');
  } else if (viewType === ViewType.Quarter) {
    dateLabel = `${start.format('MMM D')}-${end.format('MMM D, YYYY')}`;
  } else if (viewType === ViewType.Year) {
    dateLabel = start.format('YYYY');
  } else {
    dateLabel = start.format('MMM D, YYYY');
  }

  return dateLabel;
};

export const getEventText = (schedulerData, event) =>
  schedulerData.isEventPerspective
    ? schedulerData.resources.find((item) => item.id === event.resourceId)?.name || event.title
    : event.title;

export const getScrollSpecialDayjs = (schedulerData) => {
  const { localeDayjs } = schedulerData;
  return localeDayjs(new Date());
};

export const isNonWorkingTime = (schedulerData, time) => {
  const { localeDayjs, cellUnit } = schedulerData;
  if (cellUnit === CellUnit.Hour) {
    const hour = localeDayjs(new Date(time)).hour();
    return hour < 9 || hour > 18;
  }
  const dayOfWeek = localeDayjs(new Date(time)).weekday();
  return dayOfWeek === 0 || dayOfWeek === 6;
};

export default {
  getSummaryFunc: undefined,
  getCustomDateFunc: getCustomDate,
  getNonAgendaViewBodyCellBgColorFunc: undefined,
  getScrollSpecialDayjsFunc: getScrollSpecialDayjs,
  getDateLabelFunc: getDateLabel,
  getEventTextFunc: getEventText,
  isNonWorkingTimeFunc: isNonWorkingTime,
};
