import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Axios from '../../../../utils/api';
import Styled from 'styled-components';
import { Table, Image, Button, ButtonGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import EditTeamsModal from './EditSkillSetModal';
import ViewAllResourcesModal from './ViewAllResourcesModal';
import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';
import DeleteModal from '../../../../components/modals/DeleteModal';
import ArchiveModal from '../../../../components/modals/ArchiveModal';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/LoaderWave';

const SkillSetTabBody = ({ search, refreshPage, setRefreshPage }) => {
  const renderTooltip = (text) => <Tooltip>{text}</Tooltip>;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');

  const [showEditSkillsetModal, setShowEditSkillsetModal] = useState(false);
  const [showViewAllResourcesModal, setShowViewAllResourcesModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [User, setUser] = useState(null);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [profileColor, setprofileColor] = useState(sessionStorage.getItem('color'));

  const sortData = () => {
    const sortedData = [...data]; // Make a copy of your data
    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        if(typeof(a[sortBy]) === 'string'){
          return a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1;
        }
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        if(typeof(a[sortBy]) === 'string'){
          return a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : -1;
        }
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });
    return sortedData;
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      // Toggle the sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set a new sorting field and default to ascending order
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (field) => {
    if (field === sortBy) {
      return <span className={`arrow ${sortOrder === 'asc' ? 'asc' : 'desc'}`}><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
    }
    return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>; // Add a default icon
  };

  const handleMultiSelectTeamChange = (selectedMultiTeamOptions) => {
    setSelectedMultiTeamOptions(selectedMultiTeamOptions);
  };

  const rowsPerPage = 10;

  const deleteSkillset = async () => {
    try {
      const response = await Axios.delete(`/admin/skillset?_id=${selectedId}`);
      console.log(response);
      setRefreshPage(!refreshPage);
      setShowDeleteModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const archiveSkillset = async () => {
    try {
      const response = await Axios.delete(`/admin/archiveSkillset?_id=${selectedId}`);
      console.log(response);
      setRefreshPage(!refreshPage);
      setShowArchiveModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(
        `/admin/skillsets?page=${page}&limit=${perPage}&keyword=${search}`,
      );
      setData(response.data.data);
      // console.log(response.data.data)
      setTotalPages(Number(response.data.total_pages));
      if(search && page > Number(response.data.total_pages)){
        setPage(1)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [page, perPage, refreshPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Wrapper>
      {loading && <Loader />}
      {showEditSkillsetModal && (
        <EditTeamsModal
          showModal={showEditSkillsetModal}
          setShowModal={setShowEditSkillsetModal}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          selectedId={selectedId}
        />
      )}
      {showViewAllResourcesModal && (
        <ViewAllResourcesModal
          showModal={showViewAllResourcesModal}
          setShowModal={setShowViewAllResourcesModal}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          editingUser={User}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          message='Are you sure you want to delete this skillset?'
          onYes={deleteSkillset}
        />
      )}

      {showArchiveModal && (
        <ArchiveModal
          show={showArchiveModal}
          setShow={setShowArchiveModal}
          message='Are you sure you want to archive this skillset?'
          onYes={archiveSkillset}
        />
      )}
      <div className='third-row'>
        <>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => handleSort('name')}>Skill Set {renderSortIcon('name')}</th>
                <th>No. of Resources</th>
                <th>Resources</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortData()?.map((row, index) => (
                <tr key={row._id}>
                  <td>{perPage * (page - 1) + index + 1}</td>
                  <td>{row?.name}</td>
                  <td>{row?.total_resources}</td>
                  <td>
                    <div className='avatar-container'>
                      <div
                        class='image-container'
                        onClick={() => {
                          setShowViewAllResourcesModal(true);
                          setUser(row);
                        }}
                      >
          {row.resources.map((resource) => (
            resource.profile_pic ? (
              <Image
              key={resource._id}
              src={resource.profile_pic}
              roundedCircle
              className='avatar-image overlap'
            />
            ) : (
              <div className='avatar-image overlap' key={resource._id} style={{background: profileColor}}>
                <span>{resource.first_Name ? resource.first_Name?.toUpperCase()[0] : ''}</span>
              </div>
            )
          ))}
                        
                      </div>
                    </div>
                  </td>
                  <td>
                    <ButtonGroup className='action-btn'>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Edit')}>
                        <Button
                          className='edit-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => {
                            setShowEditSkillsetModal(true);
                            setSelectedId(row._id);
                          }}
                        >
                          <FaEdit style={{ color: '#2984FD' }} />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Archive')}>
                        <Button
                          className='archive-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => {
                            setShowArchiveModal(true);
                            setSelectedId(row._id);
                          }}
                        >
                          <BiArchiveIn style={{ color: '#008000' }} />
                        </Button>
                      </OverlayTrigger>
                      {/* <OverlayTrigger placement="top" overlay={renderTooltip('Delete')}>
              <Button className='delete-btn' variant="outline-secondary" style={{borderRadius: '8px'}} onClick={()=>{setShowDeleteModal(true);setSelectedId(row._id)}}><FaTrashAlt style={{color:'#FF4D4F'}}/></Button>
             </OverlayTrigger> */}
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            total={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </>
      </div>
    </Wrapper>
  );
};

const Wrapper = Styled.div`

.third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
td{
    text-align: left;
    vertical-align: middle;
  }
th{
    text-align: left;
    vertical-align: middle;
  }
.avatar-container {
  display: flex;
  align-items: left;
  justify-content: left;
}
.image-container{
cursor:pointer;
display: flex;
}

.avatar-image {
  width: 30px;
  height: 30px;
  border: 2px solid #FFFFFF;
  /* display:flex; */
  /* flex-direction:row; */
  justify-content:center;
  text-align:center;
}
.overlap{
  margin-left:-12px
}

.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.action-btn {
    background: none;
    border: none;
    border-radius: 0px !important;
}
.edit-btn{
    margin-right:10px;
    background: #D4E6FF;
    border:1px solid #D4E6FF;
    border-radius: 8px;
}
.archive-btn{
    margin-right:10px;
    background: #CCE6CC;
    border:1px solid #CCE6CC;
    border-radius: 8px;
}
.delete-btn{
    background: #FFDBDC;
    border:1px solid #FFDBDC;
    border-radius: 8px;
}

.btn-group .button:only-child {
    border-radius: 6px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

div.avatar-image{
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 28px;
}
    `;

export default SkillSetTabBody;
