import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';

function ForgotPassword({
  show,
  setShow,
  errorMessage,
  setErrorMessage,
  handleForgetPasswordSubmit,
  setFEmail,
  Femail,
  handleFEmailChange,
}) {
  const handleClose = () => {
    setShow(false);
    setErrorMessage('');
    setFEmail('');
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className='fw-bold' backdrop='static'>
        <div className='d-flex justify-content-end cursor-pointer'>
          <MdClose className='mt-2 me-2' onClick={handleClose} />
        </div>

        <Modal.Body>
          <form onSubmit={handleForgetPasswordSubmit}>
            <div
              style={{
                height: '40px',
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                marginTop: '-20px',
              }}
            >
              <div>
                <h4>
                  <strong>Forgot Password ?</strong>
                </h4>
              </div>
            </div>
            <p style={{ color: '#636263', fontSize: '14px' }}>
              Don’t worry we have got you covered. Enter the email address associated with this
              account. We’ll send you a password reset link on your email.
            </p>
            <div className='mb-3'>
              <label htmlFor='email'>Email Address</label>
              <input
                type='email'
                id='email'
                value={Femail}
                onChange={handleFEmailChange}
                className='form-control shadow-none'
                placeholder='Enter your email address'
              />
              {errorMessage?.Femail && <div className='text-danger'>{errorMessage?.Femail}</div>}
            </div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-center'>
              <button type='submit' className='btn btn-primary'>
                Send link to email
              </button>
              <button type='button' className='btn btn-secondary me-md-2' onClick={handleClose}>
                Cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ForgotPassword;
