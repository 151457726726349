import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import axios from '../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactSelect from 'react-select';
import SheduleShareModal from './SheduleShareModal';
import Loader from '../../../components/LoaderWave';

const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

function ShareDataModal({ show, setShow, refreshPage, setRefreshPage }) {
  const location = useLocation();
  const routePath = location.pathname.substring(1);
  // console.log(routePath,'1');
  const [loading, setLoading] = useState(false);
  const handleShow = () => setShow(true);
  const [errors, setErrors] = useState({});
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedMultiEmployeeOptions, setSelectedMultiEmployeeOptions] = useState([]);
  const [selectedMultiEmployeeValues, setSelectedMultiEmployeeValues] = useState([]);
  const [showSheduleShareModal, setshowSheduleShareModal] = useState(false);

  const handleClose = () => {
    setSelectedMultiEmployeeValues([]);
    setSelectedMultiEmployeeOptions([]);
    setErrors({});
    setShow(false);
  }

  const handleSheduleShareClick = () => {
    const errors = {};
    if (selectedMultiEmployeeValues.length === 0) {
      errors.employee = 'Please select the Employee';
    }
    setErrors(errors);

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }
    setshowSheduleShareModal(true);
  };

  const handleShedule = (e) => {
    e.preventDefault();
    handleSheduleShareClick();
  };

  const handleMultiSelectEmployeeChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiEmployeeOptions(selectedOptions);
    setSelectedMultiEmployeeValues(selectedValues);
  };

  const handleShareDataNowClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = {};
    if (selectedMultiEmployeeValues.length === 0) {
      errors.employee = 'Please select the Employee';
    }
    setErrors(errors);

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      setLoading(false);
      return;
    }
    const postData = {
      selectedMultiEmployeeValues,
      routePath
    };
    try {
      // Make the POST request using Axios
      const response = await axios.post('/admin/submitShareDataNow', postData);
      // console.log('Response from POST request:', response.data);
      setLoading(false);
      handleClose();
      toast.success(response.data.message);
      // Handle response as needed
    } catch (error) {
      setLoading(false);
      console.error('Error making POST request:', error);
      toast.error(error.response.data.message);
      // Handle error as needed
    }
  };
  function getRsources() {
    axios
      .get('/admin/resources_wopaging')
      .then((response) => {
        setEmployeeOptions(
          response?.data?.data.map((item, index) => ({
            value: item?._id,
            label: item?.first_Name + ' ' + item?.last_Name,
            key: index,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getRsources();
  }, [])
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Wrapper>
          {loading && <Loader />}
          <SheduleShareModal
            show={showSheduleShareModal}
            setShow={setshowSheduleShareModal}
            refreshPage={refreshPage}
            setRefreshPage={setRefreshPage}
            routePath={routePath}
            selectedMultiEmployeeValues={selectedMultiEmployeeValues}
            prevModalShow={setShow}
          />

          <Modal.Header closeButton>
            <Modal.Title>Share Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='input-container d-flex  align-items-end w-100 '>
                <div className='me-3 mb-2'>
                  {/* <img
                    src={require('../../../../assets/icons/profile-check.png')}
                    alt='profile-check'
                  /> */}
                </div>
                <div className='w-100'>
                  <label htmlFor='resources' className="form-label">Find Resources</label>
                  <ReactSelect
                    className={`shadow-none ${errors.employee ? 'is-invalid' : ''}`}
                    id='resources'
                    isMulti
                    options={employeeOptions}
                    value={selectedMultiEmployeeOptions}
                    onChange={handleMultiSelectEmployeeChange}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option: CheckboxOption }}
                  />
                  {errors.employee && <div className='invalid-feedback'>{errors.employee}</div>}
                </div>
              </div>
              <div class='d-flex justify-content-center'>
                <div class='mt-2 mx-2'>
                  <button class='btn btn-primary employee-save-button' type='submit' onClick={handleShareDataNowClick}>
                    Share Data Now
                  </button>
                </div>
                <div class='mt-2 mx-2'>
                  <button class='btn btn-primary employee-save-button' type='submit' onClick={handleShedule}>
                    Shedule Share
                  </button>
                </div>

              </div>
            </form>
          </Modal.Body>
        </Wrapper>
      </Modal>
    </>
  );
}

export default ShareDataModal;
const Wrapper = styled.div`
  /* .modal-header{
        background: #EAF3FF;
    }
    .input-container{
        width:100%;

    } */
  .modal-title {
    font-weight: 500;
    font-size: 16px;
  }
  .modal-header .btn-close {
    width: 24px;
    height: 24px;
  }
  .modal-header {
    background: #eaf3ff;
    border-radius: 16px 16px 0px 0px;
  }

  .employee-cancel-button {
    background: #ffffff;
    border: none;
    color: #2984fd;
  }
  .employee-save-button {
    background: #2984fd;
    border-radius: 8px;
    border-color: #2984fd;
  }




  
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
  width:20px
}

`;
