import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Styled from 'styled-components';
import Switch from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import brand from '../../assets/Horizontal-logo.png';
import brand from '../../assets/images/C3_Hub.png';
import { BiBell } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { HiOutlineUser } from 'react-icons/hi';
import { HiOutlineKey } from 'react-icons/hi';
import { HiOutlineLogout } from 'react-icons/hi';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { useLocation } from 'react-router-dom';
import { adminLinks, employeeLinks, leadLinks, managerLinks,contractorLinks} from './HeaderLinks';
import { clearUserAndToken } from '../../redux/slices/userSlice';
import { addNotification } from '../../redux/slices/notificationsSlice';
import NotificationPopup from './NotificationPopup';
import SessionTimeout from './Session';
import { io } from "socket.io-client";
import { baseUrl } from '../../config/base';
export const Header = () => {
  const [theme, setTheme] = useState('light');
  const popupRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false);
  const [isAuthenticated,setIsAuthenticated]=useState(false)
  const [activeLink, setActiveLink] = useState('dashboard');
  const [navLinks, setNavLinks] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const [socket, setSocket] = useState(null);
  // const [notifications, setNotifications] = useState([]);
  const [userData, setuserData] = useState(null);
  const notifications = useSelector((state) => state.notifications?.notifications);
  const [profileColors, setProfileColors] = useState(['#fd5306', '#fe2712', '#a61a49', '#8501ad', '#3b01a3', '#0247fe', '#0292ce', '#64b131', '#cfea2b', '#fcfe31', '#f9bc01', '#fa9801']);
  const color = '#0086fd'//profileColors[Math.floor(Math.random() * profileColors.length)];

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    if(!sessionStorage.getItem('color')){
      sessionStorage.setItem('color', color);
    }
    setuserData(user);
  }, [user]);

  const logout = () => {
    localStorage.clear();
    // localStorage.removeItem("timer_duration")

    dispatch({ type: 'user/clearUserAndToken', payload: { user: {}, token: '' } });

    navigate('/');
  };



  useEffect(() => {
    if (user.role === 'Admin') {
      setNavLinks(adminLinks);
    } else if (user.role === 'Employee') {
      setNavLinks(employeeLinks);
    } else if (user.role === 'Lead') {
      setNavLinks(leadLinks);
    } else if (user.role === 'Manager') {
      setNavLinks(managerLinks);
    }else if (user.role === 'Contractor') {
      setNavLinks(contractorLinks);
    }
    
    else {
      setNavLinks(adminLinks);
    }
   if(user){
    setIsAuthenticated(true)
   }

  }, [user]);

  // Socket Initialization and Connection
  // useEffect(() => {
  //   setSocket(io(`${baseUrl}`));  
  // }, []);

  // Emitting User Data on Connection
  // useEffect(() => {
  //   socket?.emit("newUser", userData?._id);
  // }, [socket, userData?._id]);

  // Listening for Incoming Notifications
  // useEffect(() => {
  //   socket?.on("getNotification", (data) => {
  //     // setNotifications((prev) => [...prev, data]);
  //     dispatch(addNotification(data));
  //   });
  // }, [socket]);

  // const sendNotification = () => {
  //   socket?.emit("sendNotification", {
  //     senderId: user._id,
  //     receiverId: user._id,
  //     message: 'This is a test notification', // Add your notification message
  //     date: new Date().toLocaleDateString(), // Add the notification date
  //     senderProfileImage: 'https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg', // Update with the sender's profile image URL
  //   });
  // };

  // console.log('notifications', notifications);
  // console.log(baseUrl);

  return (
    <Wrapper>
      {showPopup && (
         <NotificationPopup setShowPopup={setShowPopup} showPopup={showPopup} popupRef={popupRef}>
       </NotificationPopup>
      )}

      {<SessionTimeout logout={logout}  />}
      {/* <Switch>
      <Switch.Check
        type="switch"
        id="custom-switch"
        checked={theme === 'dark'} 
        onChange={toggleTheme} 
      />
    </Switch> */}
      
      <Navbar collapseOnSelect expand='xl' className='navstyle' >
        <Navbar.Brand>
          <img alt='' height='auto' src={brand} width={'130px'} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            {navLinks &&
              navLinks.map((item, i) => {
                return (
                  <Nav.Link
                    key={i}
                    className={
                      item.path === 'revenue' && location?.pathname === 'realization'
                        ? 'active'
                        : location?.pathname === item.path
                          ? 'active'
                          : ''
                    }
                  >
                    <Link to={item.path} class='dropdown-item'>
                      <item.Icon />
                      <span className='ms-2'>{item.name}</span>
                      {/* {item?.Icon2 && <item.Icon2 className=" icons" />} */}
                    </Link>
                  </Nav.Link>
                );
              })}
            {/* <Nav.Link  className='active'><Link to="" class="dropdown-item">Dashboard</Link></Nav.Link> */}
            {/* <Nav.Link  className={activeLink==="dashboard"?"active":""} onClick={()=>setActiveLink("dashboard")}><Link to="" class="dropdown-item">Dashboard</Link></Nav.Link>
            <Nav.Link  className={activeLink==="schedule"?"active":""} onClick={()=>setActiveLink("schedule")}><Link to="/schedule" class="dropdown-item">Schedule</Link></Nav.Link>
            <Nav.Link  className={activeLink==="revenue"?"active":""} onClick={()=>setActiveLink("revenue")}><Link to="/revenue" class="dropdown-item">Revenue</Link></Nav.Link>
            <Nav.Link className={activeLink==="setupResources"?"active":""} onClick={()=>setActiveLink("setupResources")} ><Link to="/setupResources" class="dropdown-item">Setup Resources</Link> </Nav.Link>
            <Nav.Link className={activeLink==="insights"?"active":""} onClick={()=>setActiveLink("insights")}  ><Link to="/insights" class="dropdown-item">Insights</Link> </Nav.Link>
            <Nav.Link  className={activeLink==="archive"?"active":""} onClick={()=>setActiveLink("archive")}><Link to="/archive" class="dropdown-item">Archive</Link></Nav.Link> */}
          </Nav>
          <Nav>
          {/* <button onClick={sendNotification}>Send Notification</button> */}
            {/* <Nav.Link className='bell-nav' onClick={togglePopup}>
              <BiBell />
              <span className='badge badge-style position-absolute top-2 start- translate-middle p-1 bg-danger rounded-circle'>
                {notifications?.length}
              </span>
            </Nav.Link> */}

            <NavDropdown
              style={{ color: 'white !important' }}
              title={
                <span>
                  {user?.first_Name}
                  {user?.profile_pic ? (
                    <img
                    className='thumbnail-image rounded-circle user_img'
                    src={user.profile_pic}
                    alt='user pic'
                  />
                  ) : (
                    <span className='thumbnail-image rounded-circle user_img' style={{background: sessionStorage.getItem('color')}}>
                    <span>{user ? user.first_Name?.toUpperCase()[0] : ''}</span>
                  </span>
                  )}
                </span>
              }
              id='collasible-nav-dropdown'
            >
             { /*<NavDropdown.Item>
                <Link to='#' class='dropdown-item'>
                  <TfiHeadphoneAlt /> Support
                </Link>
              </NavDropdown.Item>*/}
              {/* <NavDropdown.Item ><Link to="/support" class="dropdown-item"><TfiHeadphoneAlt /> Support</Link></NavDropdown.Item> */}
              <NavDropdown.Item>
                <Link to='/profile' class='dropdown-item'>
                  <HiOutlineUser /> Profile
                </Link>{' '}
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to='/Password' class='dropdown-item'>
                  <HiOutlineKey />
                  {/* {userData?.hasOwnProperty('password') ? 'Change Password' : 'Set Password'} */}
                  {userData?.pass_set ? 'Change Password' : 'Set Password'}
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={logout}>
                <Link class='dropdown-item'>
                  <HiOutlineLogout /> Logout
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Wrapper>
  );
};

const Wrapper = Styled.div`
    nav {
        padding: 10px;
    }

    .navstyle {
        background: #201E20;
    }

    .navbar-brand {
        margin-right: 100px;
        display: flex;
        align-items: center;
        @media(max-width: 1382px){
          margin-right: 20px;
        }
    }

    .dropdown-menu {
        // margin: -1px;
    }

    .navbar-nav a {
        color: #fff;
        border-radius: 8px;
        margin: 0 5px;

        @media(max-width: 1362px){
          margin: 0 2px;
        }
    }

    .navbar-nav a:hover {
        background: #4D4B4D;
    }

    .active {
        background: #4D4B4D;
    }

    .dropdown-menu {
        background: #fff;
    }

    .dropdown-menu a {
        color: #000;
    }

    .dropdown-menu a {
        padding: 5px 10px;
        margin: 0px;
        text-align: left;
    }

    .dropdown-menu a:hover {
        background: #D4E6FF;
    }

    .badge-style {
        font-weight: 400;
        font-size: 10px;
    }

    .user_img {
        height: 35px;
        width: 35px;
        margin-left: 5px;
        font-size: 20px;
        color: #fff;
        text-align: center;
        line-height: 35px;
        display: inline-block;
    }

    svg {
        width: 24px;
        height: 24px;
    }

    .dropdown-menu[data-bs-popper] {
        top: 100%;
        left: -60px;
    }

    .navbar-toggler {
        border-color: #fff;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    #collasible-nav-dropdown:active {
        color: #fff !important;
    }

    .dropdown-item img {
    vertical-align: middle;
    border-style: none;
    margin-right: 8px !important;
}
    @media screen and (max-width: 1200px) {
       .dropdown-item img {
    vertical-align: middle;
    border-style: none;
    margin-right: 8px !important;
}
    }
`;
