import React, { useState } from 'react';
import Styled from 'styled-components';
import { BiSearch } from 'react-icons/bi';
import EmployeeTabBody from './TitleTabBody';
function Employee({ activeTab }) {
  const [search, setSearch] = useState('');

  const [refreshPage, setRefreshPage] = useState(false);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div>
      <div className='tabs-ride-container'>
        <div></div>
        <label htmlFor='search-input'>
          Search By Title
          <div className='search-input-container'>
            <div className='search-icon-container'>
              <BiSearch className='react-icon' />
            </div>
            <input
              type='text'
              id='search-input'
              value={search}
              onChange={handleSearchChange}
              placeholder='Type in a keyword or name'
            />
          </div>
        </label>
      </div>

      <EmployeeTabBody
        search={search}
        screenName='Frequently Asked Questions'
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
      />
    </div>
  );
}

export default Employee;
const Wrapper = Styled.div`
.avatar-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.avatar-image {
  width: 44px;
  height: 44px;
}
.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.scrollable-div {
  height: 200px; /* Set the height of the div */
  overflow-y: scroll; /* Add vertical scrolling */
}

.scrollable-div::-webkit-scrollbar {
  width: 8px;
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #2984FD;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
`;
