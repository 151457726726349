import React, { useState, useEffect } from 'react'
import Styled from 'styled-components';
import { FaEdit } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';
import { Table, Image } from 'react-bootstrap';
import Loader from '../../../components/LoaderWave';
import Pagination from '../../../components/Pagination';
import Axios from '../../../utils/api';
import TokenServices from '../../../utils/token.services';

export default function AdminMyTeam() {
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState('first_Name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [totalPages, setTotalPages] = useState(1);
    const [data, setData] = useState([]);

    const sortData = () => {
        const sortedData = [...data]; // Make a copy of your data
        sortedData.sort((a, b) => {
            if (sortOrder === 'asc') {
                if (sortBy === 'title') {
                    return a[sortBy][0].name.toLowerCase() > b[sortBy][0].name.toLowerCase() ? 1 : -1;
                }
                if (typeof (a[sortBy]) === 'string') {
                    return a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1;
                }
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                if (sortBy === 'title') {
                    return a[sortBy][0].name.toLowerCase() < b[sortBy][0].name.toLowerCase() ? 1 : -1;
                }
                if (typeof (a[sortBy]) === 'string') {
                    return a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : -1;
                }
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });
        return sortedData;
    };

    const handleSort = (field) => {
        if (field === sortBy) {
            // Toggle the sorting order
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // Set a new sorting field and default to ascending order
            setSortBy(field);
            setSortOrder('asc');
        }
    };

    const renderSortIcon = (field) => {
        if (field === sortBy) {
            return <span className={`arrow ${sortOrder === 'asc' ? 'asc' : 'desc'}`}><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
        }
        return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>; // Add a default icon
    };

    const fetchData = async () => {
        try {
            const userData = TokenServices.getUser();
            const user_id = userData ? userData._id : '';
            const role = userData ? userData.role : '';
            console.log(userData);
            setLoading(true);
            const response = await Axios.post(
                `/admin/myTeamresources?page=${page}&limit=${perPage}&keyword=${search}&sortOrder=${sortOrder}`,
                {
                    teamIds: [],
                    user_id,
                    role
                },
            );
            setData(response.data.data);
            setTotalPages(Number(response.data.total_pages));
            if (search && page > Number(response.data.total_pages)) {
                setPage(1);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        setData([]);
        fetchData();
    }, [page, perPage, sortOrder]);
    return (
        <Wrapper>
            <div className='third-row'>
                {loading && <Loader />}
                <>
                    <Table responsive bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th onClick={() => handleSort('first_Name')}>
                                    Resource Name {renderSortIcon('first_Name')}
                                </th>
                                <th>
                                    Team
                                </th>
                                <th className='UtilizationTargetTh' onClick={() => handleSort('utilization')}>
                                    Utilization Target {renderSortIcon('utilization')}
                                </th>
                                <th onClick={() => handleSort('title')}>
                                    Title {renderSortIcon('title')}
                                </th>
                                <th>
                                    Manager
                                </th>
                                <th>
                                    Email
                                </th>
                                <th onClick={() => handleSort('role')}>
                                    Role {renderSortIcon('role')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortData()?.map((row, index) => (
                                <tr key={row._id}>
                                    <td>{perPage * (page - 1) + index + 1}</td>
                                    <td>
                                        <div className='avatar-container'>
                                            <Image
                                                src={
                                                    row.Resource_img
                                                        ? row.Resource_img
                                                        : 'https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg'
                                                }
                                                roundedCircle
                                                className='avatar-image'
                                            />
                                            <div className='avatar-name'>
                                                {row.first_Name} {row.last_Name && row.last_Name}
                                                <div className='profile-role'>{row.title && row.title.name}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{row.team && row.team[0]?.name}</td>
                                    <td>{row.utilization && row.utilization} %</td>
                                    <td>{row?.title[0]?.name}</td>
                                    {/* <td>{row.emp_code && row.emp_code}</td> */}
                                    <td>{(row?.manager)?(row?.manager[0]?.first_Name+" "+row?.manager[0]?.last_Name): " "}</td>
                                    <td className='email'>{row.email && row.email}</td>
                                    <td>{row.role && row.role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        total={totalPages}
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </>
            </div>
        </Wrapper>
    );
}

const Wrapper = Styled.div`

.third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
thead th {
    cursor: pointer;
    font-size: 0.9rem;
    border-right: 1px solid #dcdfe5;
    padding: 10px;
    vertical-align: middle;
    border-color: rgb(234, 243, 255);
    border-right: 1px solid #dcdfe5;
  }

 tbody td{
    background-color: rgb(255, 255, 255) !important;
    vertical-align: middle;
    padding: 3px 10px;
    border-color: rgb(255, 255, 255);
 } 
.UtilizationTargetTh{
    min-width: 158px;
}
.HourlyRateTh{
    min-width: 122px;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}

.avatar-container {
  display: flex;
  align-items: center;
}

.avatar-image {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.action-btn {
    background: none;
    border: none;
    border-radius: 0px !important;
}
.edit-btn{
    margin-right:10px;
    background: #D4E6FF;
    border:1px solid #D4E6FF;
    border-radius: 8px;
}
.archive-btn{
    margin-right:10px;
    background: #CCE6CC;
    border:1px solid #CCE6CC;
    border-radius: 8px;
}
.delete-btn{
    background: #FFDBDC;
    border:1px solid #FFDBDC;
    border-radius: 8px;
}

.btn-group .button:only-child {
    border-radius: 6px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;
}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
.invalid-feedback{
  margin-left: 43px;
}

.arrow{
    font-size: 12px;
    color: rgb(204, 204, 204);
    margin-left: 5px;
    float: right;
}

/* Style for ascending order */
.asc .arrow-up{
  color: #7a7c7f;
}

/* Style for descending order */
.desc .arrow-down{
  color: #7a7c7f;
}
    `;
