import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Styled from 'styled-components';
import { MdOutlineFileDownload } from 'react-icons/md';
import { BiFilterAlt } from 'react-icons/bi';
import ReactSelect from 'react-select';
import * as XLSX from 'xlsx';

const VerticalBarChart = ({
  data,
  yearOptions,
  budgetYear,
  setBudgetYear,
  forecastYear,
  setForecastYear,
  setRefreshPage,
  refreshPage,
}) => {
  // console.log('forecastYear',forecastYear);
  const chartData = data.map((item) => ({
    month: item.month,
    [`${forecastYear.value} Forecast`]: item.forecast,
    [`${budgetYear.value} Budget`]: item.budget,
  }));

  const exportChart = () => {
    const sheetName = 'Monthly Forecast And Budget';
    const sheetData = chartData.map((item) => ({
      Month: item.month,
      [`${forecastYear?.value} Forecast`]: item[`${forecastYear?.value} Forecast`],
      [`${budgetYear?.value} Budget`]: item[`${budgetYear?.value} Budget`],
    }));

    const worksheet = XLSX.utils.json_to_sheet(sheetData, {
      header: ['Month', `${forecastYear?.value} Forecast`, `${budgetYear?.value} Budget`],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const fileName = 'monthly-forecast-and-budget.xlsx';
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    if (navigator.msSaveBlob) {
      // For IE browser
      navigator.msSaveBlob(data, fileName);
    } else {
      // For other browsers
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label'>{`${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} className='label'>{`${entry.name} : ${entry.value.toFixed(2)}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const wrapperStyle = {
    fontWeight: 'bold',
  };

  const handleBudgetYearChange = (selectedOption) => {
    setBudgetYear(selectedOption);
  };

  const handleForecastYearChange = (selectedOption) => {
    setForecastYear(selectedOption);
  };

  return (
    <Wrapper>
      <div className='section'>
        <div className='chart-filter-section'>
          <div className='chart-header'>
            <h3>Monthly Forecast VS Budget</h3>
          </div>
          <div className='chart-filter-right'>
            <div className='filter-icon-container'>
              <div className='filter-icon'>
                <BiFilterAlt className='react-icon' />
              </div>
              <div className='filter-text'>Filter by:</div>
            </div>
            <div className='year-dropdown'>
              <label htmlFor=''>
                Forecast Year
                <ReactSelect
                  options={yearOptions}
                  value={forecastYear}
                  onChange={handleForecastYearChange}
                  placeholder='Select Year'
                />
              </label>
            </div>
            <span className='vs'>VS</span>
            <div className='year-dropdown'>
              <label htmlFor=''>
                Budget Year
                <ReactSelect
                  options={yearOptions}
                  value={budgetYear}
                  onChange={handleBudgetYearChange}
                  placeholder='Select Year'
                />
              </label>
            </div>
            <div className='add-task-btn-container' onClick={exportChart}>
              <div className='search-icon-container'>
                <MdOutlineFileDownload className='react-icon' />
              </div>
              <input type='button' value='Export' />
            </div>
          </div>
        </div>

        <ResponsiveContainer width='100%' height={300}>
          <BarChart
            margin={{
              top: 5,
              right: 30,
              left: 60,
              bottom: 5,
            }}
            data={chartData}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='month' />
            <YAxis />
            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={wrapperStyle}
              cursor={{ fill: '#787878d0' }}
            />
            <Legend />
            <Bar dataKey={`${forecastYear?.value} Forecast`} fill='#2984FD' />
            <Bar dataKey={`${budgetYear?.value} Budget`} fill='#c6c6c6' />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Wrapper>
  );
};

export default VerticalBarChart;

const Wrapper = Styled.div`
  .section {
    border-radius: 16px;
    background: #FFF;
    margin: 20px;
    padding: 20px;
  }

  .chart-filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chart-header h3 {
    color: var(--black-text, #201E20);
    text-align: center;
    font-size: 16px;
  }

  .chart-filter-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .add-task-btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 24px;
  }

  .add-task-btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 5px;
  }

  .react-icon {
    height: 22px;
    width: 22px;
  }

  .recharts-legend-item-text {
    color: var(--black-text, #201E20) !important;
  }
  .css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  /* min-width: 160px!important; */
  border: 1px solid #636263;
border-radius: 8px;
}
  .custom-tooltip {
    margin-left: -55px;
    background: white;
     padding: 6px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(74, 5, 5, 0.15);
  }

  .custom-tooltip p {
    /* margin-left: -55px; */
  }

  .custom-tooltip .label {
    font-weight: bold;
  }
  .filter-icon-container {
    display: flex;
    padding: 12px 0px;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
}
  span.vs {
    margin-top: 20px;
}
`;
