import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdClose } from 'react-icons/md';
import OtpInput from 'react-otp-input';

function OTPModal({ show, setShow, message, OTPmail, handleVerifyOTPbtn }) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [code, setCode] = useState('');

  const handleChange = (code) => setCode(code);

  const isCodeValid = code.length === 6; // Check if code length is equal to 6
  return (
    <>
      <Modal show={show} onHide={handleClose} centered className='fw-bold' backdrop='static'>
        <div className='d-flex justify-content-end cursor-pointer'>
          <MdClose className='mt-2 me-2' onClick={handleClose} />
        </div>
        <div
          style={{
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>{message}</div>
        </div>
        <div
          style={{
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={6}
            renderSeparator={<span style={{ width: '5px' }}> - </span>}
            renderInput={(props) => <input {...props} />}
            inputType='tel'
            shouldAutoFocus={true}
            inputStyle={{
              border: '1px solid ',
              borderRadius: '8px',
              width: '38px',
              height: '38px',
              fontSize: '16px',
              color: '#000',
              fontWeight: '400',
            }}
            focusStyle={{
              border: '1px solid #CFD3DB',
              outline: 'none',
            }}
          />
        </div>
        <div className='d-flex justify-content-center mb-3'>
          <button
            className='btn btn-success px-4'
            onClick={() => handleVerifyOTPbtn('abc', OTPmail, code)}
            disabled={!isCodeValid} // Disable button if code length is not equal to 6
          >
            Verify
          </button>
        </div>
      </Modal>
    </>
  );
}

export default OTPModal;
