import React, { Component } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import * as dayjsLocale from 'dayjs/locale/en';
import * as antdLocale from 'antd/locale/en_US';
//  import "./react-big-schedule/src/css/style.css";
import '../../../react-big-schedule/src/css/style.css';
import Styled from 'styled-components';
import 'antd/dist/reset.css';
import Axios from "../../../utils/api"
import './Scheduler.scss';

import {
  Scheduler,
  SchedulerData,
  ViewType,
  DemoData,
  wrapperFun,
} from '../../../react-big-schedule/src/index';
import AddSchedulerModal from './AddSchedulerModal';
import { connect } from 'react-redux';
import {
  updateTimerDuration,
  updateIsTimerStarted,
  updateTimerDurationStartingMilliseconds,
} from '../../../redux/slices/timerSlice';
import FilterModal from './FilterModal';
import { FiFilter } from 'react-icons/fi';
import Loader from '../../../components/LoaderWave';
import colorCodes from "./colorcodes"
import dayjs from "dayjs"
import SchedulerPagination from "../../../components/PaginationScheduler"

class Basic extends Component {
  constructor(props) {
    super(props);
  

    let schedulerData = new SchedulerData(dayjs(new Date()).startOf("year"), ViewType.Custom2, false, false, {
      dayMaxEvents: 99,
      weekMaxEvents: 9669,
      monthMaxEvents: 9669,
      quarterMaxEvents: 6599,
      yearMaxEvents: 9956,
      customMaxEvents: 9965,
      eventItemPopoverTrigger: 'click',
      schedulerContentHeight: '90vh',
      customCellWidth:document.body.clientWidth >1200?Math.ceil((document.body.clientWidth-200)/13):80
    });    
    schedulerData.setSchedulerLocale(dayjsLocale);
    schedulerData.setCalendarPopoverLocale(antdLocale);
    schedulerData.setResources([]);
    schedulerData.setEvents([]);

   this.timer=this.timer.bind(this)
    this.state = {
      viewModel:schedulerData,
      showAddSchedulerModal: false,
      refreshPage: false,
      showFilterModal: false,
      eventStartTime: '',
      eventEndTime: '',
      loading:false,
      previousRefreshPage:false,
      resources:[],
      events:[],
      selectedSlot:"",
      showDateFromCurrentMonth:true,
      filters:{users:[],pods:[],teams:[],skillsets:[],titles:[],brands:[],search:""},
      optionsData:{},
      selectedDate:"",
      width:document.body.clientWidth,
      height:"",
      page:1,
      totalPage:1
    };

    
  }

  async populateData() {
    try {
      // this.setState({loading:true})

     

      this.setState({ refreshPage:false,loading:true });

      let schedulerDate=dayjs(new Date()).startOf("year").startOf("month")
      if(this.state.selectedDate){
        console.log(this.state.selectedDate,"selected date .... populate")
        schedulerDate=dayjs(this.state.selectedDate)
      }
      else if(this.props.user_id){
       
      const userDetails= await Axios.get(`/admin/userDetail?user_id=${this.props.user_id}`)
      this.setState({filters:userDetails.data.data.filter})
      console.log(userDetails.data.data.scheduler_start_date,"starttt daaateee")
       schedulerDate= userDetails.data?.data?.scheduler_start_date || dayjs(new Date())
       const defaultFilter={
        "pods" : [

        ],
        "skillsets" : [

        ],
        "teams" : [

        ],
        "brands" : [

        ],
        "users" : [

        ],
        "titles" : [

        ],
        "search" : ""
    }
       this.setState({filters:userDetails.data.data.filter?userDetails.data.data.filter:defaultFilter,selectedDate:schedulerDate})

      
      }
     
      const response = await Axios.post(`/admin/schedulers`,{user_id:this.props.user_id,date:this.state.selectedDate,page:this.state.page});
       this.setState({totalPage:Math.ceil(response?.data?.data?.total/15)})
      // if(Object.keys(this.state.optionsData).length<1){
        const optionResponse= await Axios.get('/admin/schedulerOptions')
        this.setState({optionsData:optionResponse.data.data})
      // }
     
      console.log(response.data)
       
        const brands={}
        let colorCounter=0; 
       response?.data?.data?.data?.forEach((item,i)=>{
              if(brands.hasOwnProperty(item?.brand_id?.brand_name)){
                return
              }
              else{
                brands[item?.brand_id?.brand_name]=colorCodes[colorCounter]
                colorCounter++;
              }   
      })

     const setRefreshPage=(value)=>{
      this.setState({refreshPage:value})
     }
     
       const events=response?.data?.data?.data?.map((item,index)=>{
        return {id:index,start:item.from,end:item.to,resourceId:item.user_id?._id,title:item.duration+"Hrs",
        bgColor:brands[item?.brand_id?.brand_name],resource:item?.user_id?.first_Name+" "
      +item?.user_id?.last_Name,scheduler:item?.scheduler_id?.first_Name+" "+item?.scheduler_id?.last_Name,
       brand:item?.brand_id?.brand_name,account_status:item?.account_id?.account_status,showIcons:false,
       eventId:item._id,setRefreshPage,refreshPage:this.state.refreshPage,scheduler_id:item?.scheduler_id?._id,
       brand_id:item?.brand_id?._id,account:item?.account_id?._id,account_name:item?.account_id?.name
      ,hour_percentage:item.hour_percentage, image:item?.user_id?.profile_pic}
       }
       )
       let resources=[]

      resources= response?.data?.data?.resources?.map((item)=>{
        return {id:item?._id,name:item?.first_Name+" "+item?.last_Name,title:item?.title?.name, profile_pic: item?.profile_pic}
      })
      //  let modify_resources=response?.data?.data?.forEach((item,index)=>{
      //    let count=0

      //    if(resources?.length>0){
      //     for(let i=0;i<resources.length;i++){
      //       if(resources[i]?.id.toString()==item?.user_id?._id?.toString()){
      //         count++
      //       }
      //    }
         
         
      //    }
      //    if(count==0){
      //     resources.push({id:item?.user_id?._id,name:item?.user_id?.first_Name+" "+item?.user_id?.last_Name,showPopover: false,})
      //    }
      //   // return {id:item?.user_id?._id,name:item?.user_id?.first_Name+" "+item?.user_id?.last_Name}
      //  })

       if(resources.length<1){
        resources=[{id:1,name:"No user Available"}]
       }
       
       console.log(events,resources,".../")
      let schedulerData = new SchedulerData(schedulerDate, ViewType.Custom2, false, false, {
        dayMaxEvents: 99,
        weekMaxEvents: 9669,
        monthMaxEvents: 9669,
        quarterMaxEvents: 6599,
        yearMaxEvents: 9956,
        customMaxEvents: 9965,
        eventItemPopoverTrigger: 'click',
        schedulerContentHeight: '90vh',
        customCellWidth:this.state.width>1200?Math.ceil((this.state.width-200)/13):80,
      });    
      
      schedulerData.setSchedulerLocale(dayjsLocale);
      schedulerData.setCalendarPopoverLocale(antdLocale);
      schedulerData.setResources(resources);
      schedulerData.setEvents(events);

      this.setState({events,resources,viewModel:schedulerData,loading:false});

    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  }

  timer=()=>{
    clearTimeout(this.timerId)
    return setTimeout(() => {
       this.updateDimensions()
 
 
 }, 500)
   }

   handleNextPage=()=>{
    console.log("next pagee..")
    if (this.state.page < this.state.totalPage) {
      this.setState({page:this.state.page+1})
      this.populateData()
    }
   }
   handlePreviousPage=()=>{
    if (this.state.page > 1) {
      this.setState({page:this.state.page-1})
      this.populateData()
    }
   }

  updateDimensions = () => {
    

    this.setState({ width:document.body.clientWidth, height: window.innerHeight,refreshPage:!this.state.refreshPage });
  };
  componentDidMount() {
    // this.updateDates()
    window.addEventListener('resize', ()=>{
      this.timerId=this.timer()
    });

    this.populateData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

 

  componentDidUpdate(prevState, prevProps) {
  
   
   
    if (
      (this.state.refreshPage===true)
    ) {
      console.log("inside component did update")
      this.populateData();
      
    }
    
  }

  componentWillUnmount() {
    unmountComponentAtNode(document.getElementById("scheduler-node"));

  }

  render() {
    const { viewModel, showAddSchedulerModal, refreshPage, showFilterModal } = this.state;

    const resourceHeader = () => {
      return (
        <>
        <th
          className='header3-text'
          style={{ fontWeight: 'bold', fontSize: '14px', marginLeft: '0px' }}
        >
          <button
            className='btn btn-primary px-4 me-2'
            onClick={() => this.setState({ showFilterModal: !this.state.showFilterModal })}
          >
            Filter <FiFilter />
          </button>
        </th>
       
        </>
      );
    };

    return (
      <Wrapper id='scheduler-node'>
        <div style={{ width: '100%', height: '100%', background: 'white' }}>

          {this.state.loading && <Loader/>}
          {showAddSchedulerModal && (
            <AddSchedulerModal
              showModal={showAddSchedulerModal}
              setShowModal={(value) => this.setState({ showAddSchedulerModal: value })}
              refreshPage={refreshPage}
              setRefreshPage={(value) => this.setState({ refreshPage: value })}
              eventStartTime={this.state.eventStartTime}
              eventEndTime={this.state.eventEndTime}
              selectedSlot={this.state.selectedSlot}
            />
          )}

          {showFilterModal && (
            <FilterModal
              showModal={showFilterModal}
              setShowModal={(value) => this.setState({ showFilterModal: value })}
              refreshPage={refreshPage}
              setRefreshPage={(value) => this.setState({ refreshPage: value })}
              filters={this.state.filters}
              setFilters={(value)=>this.setState({filters:value})}
              options={this.state.optionsData}
              user_id={this.props.user_id}
            />
          )}
          

          {!this.state.loading && 
          
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            // eventItemClick={this.eventClicked}
            viewEventClick={this.ops1}
            viewEventText='Ops 1'
            viewEvent2Text='Ops 2'
            viewEvent2Click={this.ops2}
            updateEventStart={this.updateEventStart}
            updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            onScrollTop={this.onScrollTop}
            onScrollBottom={this.onScrollBottom}
            toggleExpandFunc={this.toggleExpandFunc}
            resourceHeader={resourceHeader}
            
          />
          
          }
          {!this.state.loading && this.state.filters.teams.length===0 &&this.state.filters.users.length===0&& 
          this.state.filters.titles.length===0 && this.state.filters.brands.length===0 
          && this.state.filters.search.length===0 &&this.state.filters.skillsets.length===0 &&
         <SchedulerPagination handleNext={()=>this.handleNextPage()} handlePrev={()=>this.handlePreviousPage()}
        total={this.state.totalPage} page={this.state.page}/> }
 
          
        </div>
      </Wrapper>
    );
  }

  prevClick = async(schedulerData) => {
    const date=dayjs(schedulerData.getSelectedDate()).add(-1,"year").startOf("month")
    // this.onChangeDate(schedulerData,date)
    schedulerData.prev();
    schedulerData.setEvents(this.state.events);
    this.setState({ viewModel: schedulerData });
    console.log(date,"date from prev")
    this.setState({selectedDate:date})
   
   
  };

  nextClick = async(schedulerData) => {
    const date=dayjs(schedulerData.getSelectedDate()).add(1,"year").startOf("month")
    // this.onChangeDate(schedulerData,date)
    schedulerData.next();
    schedulerData.setEvents(this.state.events);
    // schedulerData.setDate(dayjs(date));

    this.setState({ viewModel: schedulerData });
    this.setState({selectedDate:date})
   
    
  };

  onViewChange = (schedulerData, view) => {
    const start = new Date();
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.state.events);
    this.setState({ viewModel: schedulerData });
    function secondsBetween(date1, date2) {
      var diff = Math.abs(date1.getTime() - date2.getTime());
      return diff / 1000;
    }

    console.log('Elapsed seconds: ' + secondsBetween(start, new Date()));
  };

  onSelectDate = async(schedulerData, date) => {
    console.log(date, '...date');
    this.setState({selectedDate:date})
    if(this.props.user_id){
      const schedulerDateDetails= await Axios.post("/admin/setSchedulerDate",{user_id:this.props.user_id,date})

    }
      schedulerData.setDate(dayjs(date));
    
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
    // this.populateData()
  };

  onChangeDate = async(schedulerData, date) => {
    console.log(date, '...date');
    this.setState({selectedDate:date})
    
      schedulerData.setDate(dayjs(date));
    
    schedulerData.setEvents(this.state.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventClicked = (schedulerData, event) => {
    alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
  };

  ops1 = (schedulerData, event) => {
    alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  ops2 = (schedulerData, event) => {
    alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    console.log(start, end, schedulerData, type,item,slotId,"new event...");
    console.log(typeof slotId)
    this.setState({ showAddSchedulerModal: true, eventStartTime: start, eventEndTime: end,selectedSlot:slotId });
    return;
    if (
      alert(
        `Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`,
      )
    ) {
      let newFreshId = 0;
      schedulerData.events.forEach((item) => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });

      let newEvent = {
        id: newFreshId,
        title: 'New event you just created',
        start: start,
        end: end,
        resourceId: slotId,
        bgColor: 'purple',
      };
      schedulerData.addEvent(newEvent);
      this.setState({
        viewModel: schedulerData,
      });
    }
  };

  updateEventStart = (schedulerData, event, newStart) => {
    if (
      alert(
        `Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`,
      )
    ) {
      schedulerData.updateEventStart(event, newStart);
    }
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    if (
      alert(
        `Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`,
      )
    ) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    this.setState({
      viewModel: schedulerData,
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    console.log('moved event');
    // if (
    //   alert(
    //     `Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`
    //   )
    // ) {
    //   schedulerData.moveEvent(event, slotId, slotName, start, end);
    //   this.setState({
    //     viewModel: schedulerData,
    //   });
    // }
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewType.Day) {
      schedulerData.next();
      schedulerData.setEvents(DemoData.events);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewType.Day) {
      schedulerData.prev();
      schedulerData.setEvents(DemoData.events);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log('onScrollTop');
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log('onScrollBottom');
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };
}

const mapStateToProps = (state) => {
  const { user } = state;
  const { _id:user_id } = user.user;
  return { user_id };
};


const mapDispatchToProps = (dispatch) => {
  return {
    updateTimerDuration: (payload) => dispatch(updateTimerDuration(payload)),
    updateIsTimerStarted: (payload) => dispatch(updateIsTimerStarted(payload)),
    updateTimerDurationStartingMilliseconds: (payload) =>
      dispatch(updateTimerDurationStartingMilliseconds(payload)),
    dispatch,
  };
};



export default connect(mapStateToProps,mapDispatchToProps)(wrapperFun(Basic));
const Wrapper = Styled.div`
.anticon svg {
    display: none;
}
  /* .ant-picker-body,.ant-picker-date-panel,.ant-picker-panel{
    display:none !important;
  }
  .ant-picker-content{
    display:none !important;

  } */
`;
