import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker,createStaticRanges } from "react-date-range";
import { startOfWeek, endOfWeek,addDays,endOfDay, startOfDay, addMonths } from "date-fns";
import { formatInTimeZone } from 'date-fns-tz'

import dayjs from "dayjs"
import Styled from "styled-components";
import { MdClose } from 'react-icons/md';
import moment from "moment"
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const DateRangeModal = ({ showModal, setShowModal, handleDateSelection, selectedRange }) => {
  
  const staticRanges = createStaticRanges([
    {
      label: "Past Week",
      range: () => ({
        startDate: new Date(dayjs(new Date()).add(-1,"w").startOf("w")),
        endDate: new Date(dayjs(new Date()).add(-1,"w").endOf("w"))
      })
    },
    {
      label: "Current Week",
      range: () => ({
        startDate: new Date(dayjs(new Date()).startOf("w")),
        endDate: new Date(dayjs(new Date()).endOf("w"))
      })
    },
    {
      label: "Past Month",
      range: () => ({
        startDate: new Date(dayjs(new Date()).add(-1,"M").startOf("M")),
        endDate: new Date(dayjs(new Date()).add(-1,"M").endOf("M"))
      })
    },
    {
      label: "Current Month",
      range: () => ({
        startDate: new Date(dayjs(new Date()).startOf("M")),
        endDate: new Date(dayjs(new Date()).endOf("M"))
      })
    },
    {
      label: "Past 6 Months",
      range: () => ({
        startDate: new Date(dayjs(new Date()).add(-6,"M").startOf("M")),
        endDate: new Date(dayjs(new Date()).add(-1,"M").endOf("M"))
      })
    },
    {
      label: "Past Year",
      range: () => ({
        startDate: new Date(dayjs(new Date()).add(-1,"year").startOf("year")),
        endDate: new Date(dayjs(new Date()).add(-1,"year").endOf("year"))
      })
    },
    {
      label: "Current Year",
      range: () => ({
        startDate: new Date(dayjs(new Date()).startOf("year")),
        endDate: new Date(dayjs(new Date()).endOf("year"))
      })
    }
  ]);
  
  
  
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(dayjs.tz(new Date(), "Asia/Tokyo")),
    endDate: addDays(new Date(dayjs.tz(new Date(), "Asia/Tokyo")), 6),
    key: 'selection',
  });

 

  useEffect(()=>{
    setSelectionRange({
      startDate: new Date(dayjs.tz(new Date(selectedRange.startDate), "Asia/Tokyo")),
      endDate: new Date(dayjs.tz(new Date(selectedRange.endDate), "Asia/Tokyo")),
      key: 'selection',
    })
  },[selectedRange])

  const handleSelect = (ranges) => {
    console.log(ranges,"ranges")
    console.log(dayjs(ranges.selection.startDate),dayjs(ranges.selection.endDate),"ranges.....")
  
    setSelectionRange({
      ...ranges.selection,
    });
  };

  const handleApply = () => {
    handleDateSelection(selectionRange);
    setShowModal(false);
  };
  const handleClose = () => {
    // setSelectionRange({
    //   ...selectedRange,
    // });
    setShowModal(false);
  };
  return (
    <Wrapper>
      <div className={`modal`} style={{ display: showModal ? 'block' : 'none' }} tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Date Range</h5>
              <div className='d-flex justify-content-end cursor-pointer'>
                <MdClose className='mt-2 me-2' onClick={handleClose} />
              </div>
            </div>
            <div className="modal-body">
              <DateRangePicker
                onChange={handleSelect}
                // showSelectionPreview={true}
                // moveRangeOnFirstSelection={false}
                ranges={[selectionRange]}
                inputRanges={[]}

                staticRanges={staticRanges}

                months={2}
                direction="horizontal"
              />
            </div>
            <div className="d-flex justify-content-center mb-2">
              <button type="button" className="btn btn-secondary me-4 px-4" onClick={handleClose}>
                Close
              </button>
              <button type="button" className="btn btn-primary px-4" onClick={handleApply}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

// Calendar.propTypes = {
//   onChange: PropTypes.func
// };

export default DateRangeModal;
const Wrapper = Styled.div`

@media(max-width:1000px){
  .modal{
    overflow:auto;
  }
}

.modal-content{
  box-shadow: 0px 4px 16px; 
}
.modal-body{
  padding:0px;  
}
.rdrDefinedRangesWrapper {
  font-size: 13px;  
  width: auto;
  border-right: solid 1px #eff2f7;
  background: #fff;
}
`