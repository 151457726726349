import React, { useState } from 'react';
import { MdPassword } from 'react-icons/md';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from '../../utils/api';
import Styled from 'styled-components';
import Loader from '../../components/LoaderWave';
import imgback from '../../assets/images/login-background.png';
import Button from 'react-bootstrap/Button';
import axios from '../../utils/api';
import { useDispatch, useSelector } from 'react-redux';

const initialState = {
  password: '',
  password2: '',
};

const Reset = () => {
  const [formData, setformData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { password, password2 } = formData;

  const navigate = useNavigate();
  const { resetToken } = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const reset = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!password) {
      errors.password = 'New password is required';
    } else if (password.length < 10) {
      errors.password = 'Password must be at least 10 characters long';
    } else if (!/(?=.*[A-Z])/.test(password)) {
      errors.password = 'Password must contain at least one uppercase letter';
    } else if (!/[!@#$%^&*]/.test(password)) {
      errors.password = 'Password must contain at least one special character';
    }

    if (!password2) {
      errors.password2 = 'Confirm password is required';
    } else if (password !== password2) {
      errors.password2 = 'New password and confirm password do not match';
    }
    setErrorMessage(errors);

    // Check if any errors exist
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }

    const userData = {
      password,
      password2,
    };

    axios
      .put(`/admin/resetpassword/${resetToken}`, userData)
      .then((response) => {
        if (response?.data?.statusCode == '200') {
          // handle success response
          console.log('resetpassword data', response?.data);
          toast.success(response?.data?.message);
          navigate('/');
        } else {
          // handle error response
          console.error('resetpassword1 error', response?.data);
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.error('resetpassword2 error', error);
        toast.error(error?.response?.data.message);
      });
  };

  return (
    <Wrapper>
      {loading && <Loader />}
      <div className='container'>
        <div className='d-flex justify-content-center mb-4'>
          <img src={require('../../assets/images/C3_Hub.png')} width={340} alt='code3-logo' />
        </div>
        <Card>
          <div className='form'>
            <h2>Reset Password</h2>

            <form onSubmit={reset}>
              <input
                type='password'
                placeholder='New Password'
                required
                name='password'
                value={password}
                onChange={handleInputChange}
              />
              {errorMessage && errorMessage.password && (
                <div className='error text-danger'>{errorMessage.password}</div>
              )}
              <input
                type='password'
                placeholder='Confirm New Password'
                required
                name='password2'
                value={password2}
                onChange={handleInputChange}
              />
              {errorMessage && errorMessage.password2 && (
                <div className='error text-danger'>{errorMessage.password2}</div>
              )}
              <button type='submit' className='btn-primary btn-block'>
                Reset Password
              </button>
              <div className='links'>
                <p>
                  <Link to='/'> Home</Link>
                </p>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};

export default Reset;

const Wrapper = Styled.div`
  background-image: url(${imgback});
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = Styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;

  .form {
    text-align: center;

    .logo {
      margin-bottom: 24px;

      img {
        max-width: 200px;
      }
    }

    h2 {
      font-size: 24px;
      margin-bottom: 24px;
    }

    form {
        text-align: left;
      input {
        width: 100%;
        padding: 12px;
        margin-bottom: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
      }
      .error{
        margin-bottom: 6px;
      }

      .btn-primary {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 12px;
        border-radius: 4px;
        width: 100%;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .links {
      margin-top: 24px;

      p {
        margin: 4px 0;
      }

      a {
        color: #007bff;
      }
    }
  }
`;
