import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';
import { Table, Image, Button, ButtonGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import EditTeamsModal from './EditTeamsModal';
import ViewAllResourcesModal from './ViewAllResourcesModal';
import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { BiArchiveIn } from 'react-icons/bi';
import Axios from '../../../../utils/api';
import Pagination from '../../../../components/Pagination';
import DeleteTeamModal from '../../../../components/modals/DeleteModal';
import ArchiveTeamModal from '../../../../components/modals/ArchiveModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../../../utils/api';
import Loader from '../../../../components/LoaderWave';

const TeamsTabBody = ({
  search,
  selectedMultiTeamNames,
  refreshPage,
  setRefreshPage,
  TeamManagerOption,
  ResourceOptions,
}) => {
  const renderTooltip = (text) => <Tooltip>{text}</Tooltip>;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [showArchiveTeamModal, setShowArchiveTeamModal] = useState(false);
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [showViewAllResourcesModal, setShowViewAllResourcesModal] = useState(false);
  const [User, setUser] = useState(null);
  const [slectedteamdatas, setslectedteamdatas] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [profileColor, setprofileColor] = useState(sessionStorage.getItem('color'));

  const handleCloseDeleteTeamModal = () => setShowDeleteTeamModal(false);
  const handleCloseArchiveTeamModal = () => setShowArchiveTeamModal(false);

  const sortData = () => {
    const sortedData = [...data]; // Make a copy of your data
    sortedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        if(typeof(a[sortBy]) === 'string'){
          return a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1;
        }
        return a[sortBy] > b[sortBy] ? 1 : -1;
      } else {
        if(typeof(a[sortBy]) === 'string'){
          return a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : -1;
        }
        return a[sortBy] < b[sortBy] ? 1 : -1;
      }
    });
    return sortedData;
  };

  const handleSort = (field) => {
    if (field === sortBy) {
      // Toggle the sorting order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set a new sorting field and default to ascending order
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (field) => {
    if (field === sortBy) {
      return <span className={`arrow ${sortOrder === 'asc' ? 'asc' : 'desc'}`}><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>;
    }
    return <span className="arrow"><span className="arrow-up">&#9650;</span><span className="arrow-down">&#9660;</span></span>; // Add a default icon
  };

  const handleShowDeleteModal = (team) => {
    setslectedteamdatas(team);
    setShowDeleteTeamModal(true);
  };
  const handleShowArchiveTeamModal = (team) => {
    setslectedteamdatas(team);
    setShowArchiveTeamModal(true);
  };
  const handleConfirmDelete = () => {
    handleTeamDelete(slectedteamdatas);
  };
  const handleConfirmArchive = () => {
    handleTeamArchive(slectedteamdatas);
  };
  const handleTeamDelete = async (slectedteamdatas) => {
    try {
      const response = await axios.delete(`/admin/team?_id=${slectedteamdatas._id}`);
      console.log(response.data); // do something with the response
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseDeleteTeamModal();
      setslectedteamdatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };
  const handleTeamArchive = async (slectedteamdatas) => {
    try {
      const response = await axios.delete(`/admin/archiveTeam?_id=${slectedteamdatas._id}`);
      console.log(response.data); // do something with the response
      setRefreshPage(!refreshPage);
      toast.success(response.data.message);
      handleCloseArchiveTeamModal();
      setslectedteamdatas(null);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response.data); // handle the error
    }
  };

  const fetchData = async () => {
    console.log(selectedMultiTeamNames);
    try {
      setLoading(true);
      const response = await Axios.post(
        `/admin/teams?page=${page}&limit=${perPage}&keyword=${search}`,
        {
          team_names: selectedMultiTeamNames,
        },
      );
      // console.log('teams',response.data.data);
      // const modifyData=[]
      // response.data.data.forEach((item)=>{

      // })
      setData(response.data.data);
      if(search && page > Number(response.data.total_pages)){
        setPage(1)
      }
      setTotalPages(Number(response.data.total_pages));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [page, perPage, refreshPage, selectedMultiTeamNames]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Wrapper>
      {loading && <Loader />}
      <DeleteTeamModal
        show={showDeleteTeamModal}
        setShow={handleCloseDeleteTeamModal}
        onYes={handleConfirmDelete}
        message='Are you sure you want to Delete this team?'
      />
      <ArchiveTeamModal
        show={showArchiveTeamModal}
        setShow={handleCloseArchiveTeamModal}
        onYes={handleConfirmArchive}
        message='Are you sure you want to Archive this team?'
      />
      {showEditTeamModal && (
        <EditTeamsModal
          showModal={showEditTeamModal}
          setShowModal={setShowEditTeamModal}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          editingUser={User}
          TeamManagerOption={TeamManagerOption}
          ResourceOptions={ResourceOptions}
        />
      )}
      {showViewAllResourcesModal && (
        <ViewAllResourcesModal
          showModal={showViewAllResourcesModal}
          setShowModal={setShowViewAllResourcesModal}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
          editingUser={User}
        />
      )}
      <div className='third-row'>
        <>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <th onClick={() => handleSort('name')}>Team Name {renderSortIcon('name')}</th>
                <th>Manager Name</th>
                <th>Resources</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortData()?.map((row, index) => (
                <tr key={row._id}>
                  <td>{perPage * (page - 1) + index + 1}</td>
                  <td>{row?.name}</td>
                  <td>
                    {row?.manager_id?.first_Name} {row?.manager_id?.last_Name}
                  </td>
                  <td>
                    <div className='avatar-container'>
                      <div
                        class='image-container'
                        onClick={() => {
                          setShowViewAllResourcesModal(true);
                          setUser(row);
                        }}
                      >
                        {row &&
                          row?.resource_id?.map((row, index) => (
                            row.profile_pic ? (
                              <Image
                              src={row.profile_pic}
                              roundedCircle
                              className='avatar-image overlap'
                            />
                            ) : (
                              <div className='avatar-image overlap' style={{background: profileColor}}>
                                <span>{row.first_Name ? row.first_Name?.toUpperCase()[0] : ''}</span>
                              </div>
                            )
                          ))}
                      </div>
                    </div>
                  </td>
                  <td>
                    <ButtonGroup className='action-btn'>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Edit')}>
                        <Button
                          className='edit-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => {
                            setShowEditTeamModal(true);
                            setUser(row);
                          }}
                        >
                          <FaEdit style={{ color: '#2984FD' }} />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger placement='top' overlay={renderTooltip('Archive')}>
                        <Button
                          className='archive-btn'
                          variant='outline-secondary'
                          style={{ borderRadius: '8px' }}
                          onClick={() => handleShowArchiveTeamModal(row)}
                        >
                          <BiArchiveIn style={{ color: '#008000' }} />
                        </Button>
                      </OverlayTrigger>
                      {/* <OverlayTrigger placement="top" overlay={renderTooltip('Delete')}>
              <Button className='delete-btn' variant="outline-secondary" style={{borderRadius: '8px'}} onClick={() => handleShowDeleteModal(row)}><FaTrashAlt style={{color:'#FF4D4F'}}/></Button>
              </OverlayTrigger> */}
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            total={totalPages}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </>
      </div>
    </Wrapper>
  );
};

const Wrapper = Styled.div`

.third-row{
    padding:20px;
    background: #DEE7F2;
}
tbody tr:nth-of-type(even) td {
  background-color: #fff !important;
}
tbody tr:nth-of-type(odd) td {
  background-color: #fff !important;
}
.table tbody > tr:last-child > td {
  /* border: 1px solid #dee2e6; */
}
thead{
    background: #EAF3FF;
}
.table-responsive{
    border-radius: 8px;
    background: #fff;
}
td{
    text-align: left;
    vertical-align: middle;
  }
th{
    text-align: left;
    vertical-align: middle;
  }
.avatar-container {
  display: flex;
  align-items: left;
  justify-content: left;
}
.image-container{
cursor:pointer;
display: flex;
}

.avatar-image {
  width: 30px;
  height: 30px;
  border: 2px solid #FFFFFF;
  /* display:flex; */
  /* flex-direction:row; */
  justify-content:center;
  text-align:center;
}
.overlap{
  margin-left:-12px
}

.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 16px;
}
.action-btn {
    background: none;
    border: none;
    border-radius: 0px !important;
}
.edit-btn{
    margin-right:10px;
    background: #D4E6FF;
    border:1px solid #D4E6FF;
    border-radius: 8px;
}
.archive-btn{
    margin-right:10px;
    background: #CCE6CC;
    border:1px solid #CCE6CC;
    border-radius: 8px;
}
.delete-btn{
    background: #FFDBDC;
    border:1px solid #FFDBDC;
    border-radius: 8px;
}

.btn-group .button:only-child {
    border-radius: 6px;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}

div.avatar-image{
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 28px;
}
    `;

export default TeamsTabBody;
