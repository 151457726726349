import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import { Sidebar } from '../Sidebar';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineFileDownload } from 'react-icons/md';
import { IoMdShareAlt } from 'react-icons/io';
import { Modal, Button } from 'react-bootstrap';
import ReactSelect from 'react-select';
// import 'react-select/dist/react-select.css';
import axios from '../../../../utils/api';
import Loader from '../../../../components/LoaderWave';
import { BiSearch } from 'react-icons/bi';
import Table from './TableComp';
import FullDataExportCSV from './FullDataExportCSV';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import ShareDataModal from '../ShareDataModal';
import { Link, useParams, useNavigate } from 'react-router-dom';
import TokenServices from '../../../../utils/token.services';
const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);
// Custom component to render checkboxes for Accounts
const CustomCheckboxOption = (props) => {
  // console.log('CustomCheckboxOption props', props);
  return (<div className='custom-option'>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
        id={`account-checkbox-${props.data.value}`}
      />
      <label className='checkbox-label' htmlFor={`account-checkbox-${props.data.value}`}>
        {props.label}
      </label>
    </div>
    {props.data.brands && props.isSelected && (

      <div className='brand-checkboxes'>
        {props.data.brands.map((brand) => (
          <div key={brand._id} className='checkbox-container'>
            <input
              type='checkbox'
              checked={brand.isSelected}
              onChange={() => props.selectBrandOption(brand)}
              id={`brand-checkbox-${brand._id}`}
            />
            <label className='checkbox-label' htmlFor={`brand-checkbox-${brand._id}`}>
              {brand.brand_name}
            </label>
          </div>
        ))}
      </div>
    )}
  </div>
  )
}

const Popup = ({ message, setisExportClicked, isExportClicked, shareData }) => {
  return (
    <div className='popup-container'>
      <div className='popup-message'>
        <span className='export-txt'>Export data - </span>Download the data to your computer or
        local storage.{' '}
      </div>
      <div className='popup-message'>
        <span className='share-txt'>Share data - </span>Share the data with anyone within the app.{' '}
      </div>
      <div className='popup-buttons'>
        <button onClick={() => { setisExportClicked(true); }}>
          <div className='icon-container'>
            <MdOutlineFileDownload className='react-icon' />
          </div>
          Export Data
        </button>
        <button onClick={shareData}>
          <div className='icon-container'>
            <IoMdShareAlt className='react-icon' />
          </div>
          Share Data
        </button>
      </div>
    </div>
  );
};

const Shedule = () => {
  const [runAPI, setRunAPI] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [showShareDataModal, setShowShareDataModal] = useState(false);
  const [ApplyDateFilter, setApplyDateFilter] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const currentMonth = dayjs().format('YYYY-MM');
  // const nextMonth = dayjs().add(1, 'month').format('YYYY-MM');
  const nextMonth = dayjs().format('YYYY-MM');
  const [areFiltersFetched, setAreFiltersFetched] = useState(false)
  const [employeeFetched, setEmployeeFetched] = useState(false);

  const months = [currentMonth, nextMonth];
  const [selectedMonth, setSelectedMonth] = useState(months);
  const [filterDisabled, setFilterDisabled] = useState({ pod: false, team: false, account: false, employee: false, date: false })

  const handleShowModal = () => {
    setShowShareDataModal(true);
  };

  const calculateGapMonths = (startYear, endYear, startMonth, endMonth) => {
    let currentYear = parseInt(startYear, 10);
    const end = parseInt(endMonth, 10) - 1 + (parseInt(endYear, 10) - currentYear) * 12;
    const start = parseInt(startMonth, 10) - 1 + (parseInt(startYear, 10) - currentYear) * 12;

    const gap = end - start + 1;

    if (gap > 0) {
      const gapArray = Array.from({ length: gap }, (_, index) => {
        const month = (start + index) % 12;
        const year = currentYear + Math.floor((start + index) / 12);
        return `${year}-${(month + 1).toString().padStart(2, '0')}`;
      });
      if (startMonth === endMonth && startYear == endYear) {
        setSelectedMonth([gapArray.toString(), gapArray.toString()]);
        syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, [startYear, endYear], [gapArray.toString(), gapArray.toString()], !ApplyDateFilter)
      }
      else {
        setSelectedMonth(gapArray);
        syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, [startYear, endYear], gapArray, !ApplyDateFilter)
      }

    } else {
      setSelectedMonth([]);
      syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, [startYear, endYear], [], !ApplyDateFilter)
    }
  };

  const handleCalendarChange = (dates, dateStrings) => {
    const [startDate, endDate] = dateStrings;

    if (startDate && endDate) {
      // Extract and update selectedYear and selectedMonth
      const startYear = startDate ? dayjs(startDate, 'MMM-YYYY').year().toString() : null;
      const endYear = endDate ? dayjs(endDate, 'MMM-YYYY').year().toString() : null;

      const startMonth = startDate ? dayjs(startDate, 'MMM-YYYY').format('MM') : null;
      const endMonth = endDate ? dayjs(endDate, 'MMM-YYYY').format('MM') : null;
      setSelectedYear([startYear, endYear]);
      // Format months as two digits
      const formattedStartMonth = startMonth ? startMonth : null;
      const formattedEndMonth = endMonth ? endMonth : null;
      setSelectedMonth([formattedStartMonth, formattedEndMonth]);
      calculateGapMonths(startYear, endYear, startMonth, endMonth);

      setApplyDateFilter(!ApplyDateFilter);

      // syncFilter(selectedMultiPodOptions,selectedMultiTeamOptions,selectedMultiEmployeeOptions, selectedMultiAccountsOptions, selectedYear, selectedMonth, ApplyDateFilter, [startYear, endYear], [formattedStartMonth, formattedEndMonth], !ApplyDateFilter)
    } else if (startDate || endDate) {
      setSelectedRange(dateStrings);
      setSelectedYear([]);
      setSelectedMonth([]);
      // Your logic for handling the selected dates goes here

      syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, [], [], ApplyDateFilter)
    } else {
      // Clear selectedRange when both start and end dates are empty
      setSelectedRange([]);
      setSelectedYear([]);
      setSelectedMonth([]);
      setApplyDateFilter(!ApplyDateFilter);

      syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, [], [], !ApplyDateFilter)
    }
  };


  const disableYearChange = (current) => {

    // const [startDate, endDate] = selectedRange;
    // console.log(startDate);
    // if (!startDate && !endDate) {
    //   return false;
    // }
    // if (!startDate || !endDate || (startDate && endDate)) {
    //   return (
    //     current.year() != dayjs(startDate, 'MM-YYYY').year() && current.year() !== dayjs(endDate, 'MM-YYYY').year()
    //   );
    // }

    // else { return false; }

  };

  const handleOpenChange = (open) => {
    const [startDate, endDate] = selectedRange;
    if (!open && (!startDate || !endDate)) {
      // Clear selectedRange when the calendar panel is closed
      setSelectedRange([]);
    }
  };

  const [loading, setLoading] = useState(false);
  const [isExportClicked, setisExportClicked] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedMultiEmployeeOptions, setSelectedMultiEmployeeOptions] = useState([]);
  const [selectedMultiEmployeeValues, setSelectedMultiEmployeeValues] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedMultiAccountsOptions, setSelectedMultiAccountsOptions] = useState([]);
  const [selectedMultiAccountsValues, setSelectedMultiAccountsValues] = useState([]);
  const [isAccountValueGotChanged, setIsAccountValueGotChanged] = useState(false)
  const [TeamOptions, setTeamOptions] = useState([]);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [PodOption, setPodOption] = useState([]);
  const [selectedMultiPodOptions, setSelectedMultiPodOptions] = useState([]);
  const [selectedMultiPodValues, setselectedMultiPodValues] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const [selectedBrandData, setSelectedBrandData] = useState([]);
  // console.log(selectedYearOptions);
  const handleMultiSelectAccountsChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiAccountsOptions(selectedOptions);
    setSelectedMultiAccountsValues(selectedValues);
    console.log('selectedMultiAccountsValues', selectedMultiAccountsValues);
    syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedOptions, selectedYear, selectedMonth, ApplyDateFilter)
  };
  const handleMultiSelectBrandChange = (selectedBrand) => {
    const brandIndex = selectedBrandData.findIndex((brand) => brand.id === selectedBrand._id);

    if (brandIndex === -1) {
      // If not in selectedBrandData, add it
      setSelectedBrandData([...selectedBrandData, { id: selectedBrand._id, name: selectedBrand.brand_name }]);
    } else {
      // If already in selectedBrandData, remove it
      const updatedBrandData = [...selectedBrandData];
      updatedBrandData.splice(brandIndex, 1);
      setSelectedBrandData(updatedBrandData);
    }
  };

  const handleMultiSelectEmployeeChange = (selectedOptions) => {
    // console.log(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiEmployeeOptions(selectedOptions);
    setSelectedMultiEmployeeValues(selectedValues);
    // console.log('selectedMultiEmployeeValues', selectedMultiEmployeeValues);
    syncFilter(selectedMultiPodOptions, selectedMultiTeamOptions, selectedOptions, selectedMultiAccountsOptions, selectedYear, selectedMonth, ApplyDateFilter)
  };

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
    // console.log('selectedMultiTeamValues', selectedMultiTeamValues);
    syncFilter(selectedMultiPodOptions, selectedOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, selectedYear, selectedMonth, ApplyDateFilter)
  };

  const handleMultiSelectPodChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiPodOptions(selectedOptions);
    setselectedMultiPodValues(selectedValues);
    // console.log('selectedMultiPodValues', selectedMultiTeamValues);
    syncFilter(selectedOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, selectedYear, selectedMonth, ApplyDateFilter)
  };



  useEffect(() => {
    const userData = TokenServices.getUser();

    let user_id = userData ? userData._id : '';

    axios.get(`/admin/getFilter?user_id=${user_id}`).then((res) => {
      console.log('data', res);
      const { scheduleFilter } = res?.data?.data;
      if (scheduleFilter && userData.role!=="Employee") {
        setSelectedMultiPodOptions(scheduleFilter?.pods || [])
        setselectedMultiPodValues(scheduleFilter?.pods.map((option) => option.value) || [])
        if(userData.role!=="Manager"){
          setSelectedMultiTeamOptions(scheduleFilter?.teams || [])
          setselectedMultiTeamValues(scheduleFilter?.teams.map((option) => option.value) || [])
        }
        
        setSelectedMultiEmployeeOptions(scheduleFilter?.users || [])
        setSelectedMultiEmployeeValues(scheduleFilter?.users.map((option) => option.value) || [])
        setSelectedMultiAccountsOptions(scheduleFilter?.accounts || [])
        setSelectedMultiAccountsValues(scheduleFilter?.accounts.map((option) => option.value) || [])
        if (scheduleFilter.dateRange) {
          setSelectedMonth(scheduleFilter?.dateRange?.month)
          setSelectedYear(scheduleFilter?.dateRange?.year)
          setApplyDateFilter(scheduleFilter?.dateRange?.dateFilter)
        }
        setAreFiltersFetched(true);
      } else {
        setAreFiltersFetched(true);
      }
    }).catch((error) => {
      setAreFiltersFetched(true);
      console.log('error', error);
    })

    if (userData && userData.role === 'Employee') {
      setFilterDisabled({ pod: true, team: true, account:true, employee: true, date: false })
    }
    if (userData && userData.role === 'Manager') {
      setFilterDisabled({ ...filterDisabled, team: true });
    }

    let userId = userData ? userData._id : '';
    let role = userData ? userData.role : '';
    let teamsToExclude=[]
    let employeeResponse=[]
    Promise.all([
     axios
     .post('/admin/accounts?page=&limit=', {
       account_id: '',
       team_ids: '',
       SelectedDateFormattedData: '',
     }), 
     axios
     .get(`/admin/resources_wopaging?user_id=${userId}&role=${role}`),
     axios
     .get('/admin/teams_wopaging'),
     axios
     .get('/admin/pods_wopaging')


   ])
   .then((response) => {

     const modifyData = response[0].data.data.map((item) => {
       for (let i = 0; i < item.brands.length; i++) {
         item.brands[i].isChecked = false
       }
       return item
     })
     setAccountOptions(
       modifyData
     );
     //
     employeeResponse=response[1]?.data?.data

     //
     let modifyResponse=response[2]?.data?.data.filter((item)=>{
       if(item.includeInInsights){
        return item
       }
       else {
        teamsToExclude.push(item._id)
       }
     })

     

     employeeResponse=employeeResponse.filter((item)=>{
       if(teamsToExclude.indexOf(item.team)<0){
         return item
       }
     })
     console.log(employeeResponse)

     setEmployeeOptions(
       employeeResponse?.map((item, index) => ({
         value: item?._id,
         label: item?.first_Name + ' ' + item?.last_Name,
         key: index,
       })).sort((a, b) => {
         return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
       }),
     );
     setEmployeeFetched(true)


     setTeamOptions(
       modifyResponse?.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
         return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
       })
     );

     //
     setPodOption(response[3]?.data?.data.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
       return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
     }));
     

   }).catch((error)=>console.log(error));


    //options loading different filters
    // let teamsToExclude = []
    // let employeeResponse = []
    // axios
    //   .post('/admin/accounts?page=&limit=', {
    //     account_id: '',
    //     team_ids: '',
    //     SelectedDateFormattedData: '',
    //   })
    //   .then((response) => {
    //     // console.log("resources_wopaging",response.data.data);
    //     const modifyData = response.data.data.map((item) => {
    //       for (let i = 0; i < item.brands.length; i++) {
    //         item.brands[i].isChecked = false
    //       }
    //       return item
    //     })
    //     setAccountOptions(
    //       modifyData
    //     );
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // let userId = userData ? userData._id : '';
    // let role = userData ? userData.role : '';

    // axios
    //   .get(`/admin/resources_wopaging?user_id=${userId}&role=${role}`)
    //   .then((response) => {
    //     // console.log('response?.data?.data', response?.data?.data);
    //     // setEmployeeOptions(
    //     //   response?.data?.data.map((item, index) => ({
    //     //     value: item?._id,
    //     //     label: item?.first_Name + ' ' + item?.last_Name,
    //     //     key: index,
    //     //   })),
    //     // );
    //     // setEmployeeFetched(true);
    //     employeeResponse = response?.data?.data
    //   })
    //   .catch((error) => {
    //     setEmployeeFetched(true);
    //     console.log(error);
    //   });

    // axios
    //   .get('/admin/teams_wopaging')
    //   .then((response) => {
    //     let modifyResponse = response?.data?.data.filter((item) => {
    //       if (item.includeInInsights) {
    //         return item
    //       }
    //       else {
    //         teamsToExclude.push(item._id)
    //       }
    //     })

    //     employeeResponse = employeeResponse.filter((item) => {
    //       if (teamsToExclude.indexOf(item.team) < 0) {
    //         return item
    //       }
    //     })

    //     setEmployeeOptions(
    //       employeeResponse?.map((item, index) => ({
    //         value: item?._id,
    //         label: item?.first_Name + ' ' + item?.last_Name,
    //         key: index,
    //       })).sort((a, b) => {
    //         return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    //       }),
    //     );
    //     setEmployeeFetched(true)


    //     setTeamOptions(
    //       modifyResponse?.map((item) => ({ value: item?._id, label: item?.name })),
    //     );
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // axios
    //   .get('/admin/pods_wopaging')
    //   .then((response) => {
    //     // console.log("resources_wopaging",response?.data?.data);
    //     setPodOption(response?.data?.data.map((item) => ({ value: item?._id, label: item?.name })));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

   

  }, []);

  const syncFilter = async (selectedMultiPodOptions, selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountsOptions, year, month, dateFilter) => {
    const userData = TokenServices.getUser();
    const user_id = userData ? userData._id : '';
    const modifyPods = selectedMultiPodOptions
    const modifyTeams = selectedMultiTeamOptions
    const modifyUsers = selectedMultiEmployeeOptions
    const modifyAccount = selectedMultiAccountsOptions
    const modifyRange = {
      year, month, dateFilter
    }

    if (user_id) {
      const response = await axios.post("/admin/setInsightFilter", {
        user_id,
        filter: {
          pods: modifyPods,
          teams: modifyTeams,
          users: modifyUsers,
          accounts: modifyAccount,
          dateRange: modifyRange
        },
        page: 'schedule'
      })
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !event.target.closest('.btn-action-container')
      ) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const updatedAccountOptions = accountOptions.map((account) => ({
      ...account,
      brands: account.brands.map((brand) => ({
        ...brand,
        isSelected: selectedBrandData.some((selectedBrand) => selectedBrand.id === brand._id),
      })),
    }));

    setAccountOptions(updatedAccountOptions);
  }, [selectedBrandData]);

  useEffect(() => {
    console.log(selectedYear, 'selectedYear');
    console.log(selectedMonth, 'selectedMonth');
  }, [ApplyDateFilter]);


  const handleActionClick = () => {
    setShowPopup(!showPopup);
  };

  const shareData = () => {
    setShowPopup(false);
    handleShowModal();
  };

  useEffect(() => {
    if (selectedMultiAccountsValues.length <= 0) {
      if (isAccountValueGotChanged === true) {
        setSelectedBrandData([]);
      }
      setIsAccountValueGotChanged(false)

    } else {
      const deselectedAccountIds = accountOptions.filter((account) => !selectedMultiAccountsValues.includes(account._id));
      let allDeselectedBrands = [];
      deselectedAccountIds.forEach(account => {
        account?.brands.forEach(brand => {
          allDeselectedBrands.push(brand._id);
        });
      });
      setSelectedBrandData((prev) => prev.filter((brand) => !allDeselectedBrands.includes(brand.id)));
    }
  }, [selectedMultiAccountsValues]);

  return (
    <Wrapper>
      {loading && <Loader />}
      <div className='HistoryOfRequest'>

        <ShareDataModal
          show={showShareDataModal}
          setShow={setShowShareDataModal}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
        />

        <section className='bg'>
          <div className='row flex-nowrap'>
            <div className='col-auto sidebar-container'>
              <Sidebar />
            </div>
            <div className='col py-3 content-area'>
              <div className='row'>
                <div className='action-row'>
                  <div className='right-btn-section'>
                    {loading ? (<div className='btn-container btn-run-container'>
                      <input type='button' value='Running...' disabled={loading} />
                    </div>) : (<div className='btn-container btn-run-container' onClick={() => setRunAPI(!runAPI)}>
                      <input type='button' value='Run' />
                    </div>)}
                    {
                      !exportLoader ? (
                        <>
                          <div className='btn-container btn-action-container' onClick={handleActionClick}>
                            <input type='button' value='Action' />
                            <div className='icon-container'>
                              <IoIosArrowDown className='react-icon' />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='btn-container btn-action-container'>
                            <input type='button' value='Exporting...' />
                            <div className='icon-container'>
                              <IoIosArrowDown className='react-icon' />
                            </div>
                          </div>
                        </>
                      )
                    }

                    {/* Conditional rendering of the popup */}
                    {showPopup && (
                      <div ref={popupRef} className='popup-div'>
                        <Popup setisExportClicked={setisExportClicked} isExportClicked={isExportClicked} shareData={shareData} />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row-1'>
                {/* <div>
                  <h1 className='row-1-title'>Schedule</h1>
                </div> */}
                <div className='filter-right-section'>

                  <div className='multi-select-container'>
                    <label>
                      Pods
                      <ReactSelect
                        id='multiSelectPod'
                        isMulti
                        options={PodOption}
                        value={selectedMultiPodOptions}
                        onChange={handleMultiSelectPodChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CheckboxOption }}
                        isDisabled={filterDisabled.pod || loading}
                      />
                    </label>
                  </div>
                  <div className='multi-select-container'>
                    <label>
                      Team
                      <ReactSelect
                        id='multiSelectTeam'
                        isMulti
                        options={TeamOptions}
                        value={selectedMultiTeamOptions}
                        onChange={handleMultiSelectTeamChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CheckboxOption }}
                        isDisabled={filterDisabled.team || loading}
                      />
                    </label>
                  </div>
                  <div className='multi-select-container'>
                    <label>
                      Accounts
                      <ReactSelect
                        id='multiSelectAccounts'
                        isMulti
                        options={accountOptions.map((account) => ({
                          value: account._id,
                          label: account.name,
                          brands: account.brands, // Include the brands as part of the option
                        }))}
                        value={selectedMultiAccountsOptions}
                        onChange={handleMultiSelectAccountsChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        filterOption={(option, inputValue) => {
                          const lowercaseInput = inputValue.toLowerCase();

                          // Check if the account label matches the input value
                          const accountLabel = option?.label?.toLowerCase();
                          if (accountLabel?.includes(lowercaseInput)) {
                            return true;
                          }

                          // Check if any of the brand names within this account match the input value
                          const brandLabels = option?.data?.brands?.map((brand) => brand.brand_name.toLowerCase());
                          return brandLabels?.some((label) => label?.includes(lowercaseInput));
                        }}
                        components={{
                          Option: (props) => <CustomCheckboxOption {...props} selectBrandOption={handleMultiSelectBrandChange} />,
                        }}
                        isDisabled={filterDisabled.account || loading}

                      />
                    </label>
                  </div>
                  <div className='multi-select-container'>
                    <label>
                      Employee
                      <ReactSelect
                        id='multiSelectAccounts'
                        isMulti
                        options={employeeOptions}
                        value={selectedMultiEmployeeOptions}
                        onChange={handleMultiSelectEmployeeChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CheckboxOption }}
                        isDisabled={filterDisabled.employee || loading}
                      />
                    </label>
                  </div>
                  <div className='multi-select-container date-range'>
                    <label>Date Range</label>
                    <label>
                      <DatePicker.RangePicker
                        picker="month"
                        // style={{ height: "100%" }}
                        format="MMM-YYYY"
                        onCalendarChange={handleCalendarChange}
                        onOpenChange={handleOpenChange} // Use onOpenChange to detect when the calendar panel is closed
                        allowClear={false}
                        mode={['month', 'month']}
                        showTime={true}
                        disabledDate={disableYearChange}
                        value={[
                          selectedMonth[0] ? dayjs(selectedMonth[0], 'YYYY-MM') : '',
                          selectedMonth[1] ? dayjs(selectedMonth[selectedMonth.length - 1], 'YYYY-MM') : ''
                        ]}
                        disabled={filterDisabled.date || loading}
                      />
                    </label>
                  </div>


                </div>
              </div>
              <div className='row-2'>

              </div>

              {/* Table component */}
              {
                // isExportClicked && (
                <FullDataExportCSV
                  exportLoader={exportLoader}
                  setExportLoader={setExportLoader}
                  isExportClicked={isExportClicked}
                  setisExportClicked={setisExportClicked}
                  setShowPopup={setShowPopup}
                  selectedMultiPodValues={selectedMultiPodValues}
                  selectedMultiTeamValues={selectedMultiTeamValues}
                  selectedMultiAccountsValues={selectedMultiAccountsValues}
                  selectedBrandData={selectedBrandData}
                  selectedMultiEmployeeValues={selectedMultiEmployeeValues}
                  setLoading={setLoading}
                  ApplyDateFilter={ApplyDateFilter}
                  selectedYear={selectedYear}
                  selectedMonth={selectedMonth}
                  areFiltersFetched={areFiltersFetched}
                  employeeOptions={employeeOptions}
                  employeeFetched={employeeFetched} />
                // )
              }
              <Table
                selectedMultiPodValues={selectedMultiPodValues}
                selectedMultiTeamValues={selectedMultiTeamValues}
                selectedMultiAccountsValues={selectedMultiAccountsValues}
                selectedBrandData={selectedBrandData}
                selectedMultiEmployeeValues={selectedMultiEmployeeValues}
                setLoading={setLoading}
                ApplyDateFilter={ApplyDateFilter}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                setShowPopup={setShowPopup}
                areFiltersFetched={areFiltersFetched}
                employeeOptions={employeeOptions}
                employeeFetched={employeeFetched}
                runAPI={runAPI}
              />
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Shedule;

const Wrapper = Styled.div`
    
    .bg{
      background: #e9eaed;
      /* padding: 20px; */
    }
    .profile-body{
background:#fff;
margin-top:14px;
    }
    .bg .row{
    --bs-gutter-x: 0px !important;
    }
    .sidebar-container{
      background: #FFFFFF;
border-radius: 16px;
margin:15px
    }
    .content-area{
      margin:0px 10px 0px 0px;
      width: 70%;
    }
        .action-row {
    display: flex;
    padding: 0px 10px;
    border-radius: 20px 20px 0px 0px;
    background: var(--white, #FFF);
	justify-content: flex-end;
}
  .right-btn-section{
    display: flex;
    gap: 20px;
    margin-top: -10px;
  }
    .row-1{
display: flex;
padding: 0px 10px 10px 10px;
border-radius: 0px 0px 20px 20px;
background: var(--white, #FFF); 
    }
//     .row-2{
// display: flex;
// padding: 0 20px 20px 20px;
// justify-content: space-between;
// align-items: center;
// border-bottom-left-radius: 16px;
// border-bottom-right-radius: 16px;
// background: var(--white, #FFF); 
//     }
    .row-1-title{
color: var(--black-text, #201E20);
font-size: 24px;
    }
    .row-2-title{
color: var(--black-text, #201E20);
font-size: 24px;
    }

    .filter-right-section{
  display: flex;
    // justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  width: 160px!important;
  border: 1px solid #636263;
border-radius: 8px;
}
.css-16xfy0z-control{
  width: 160px;
  border-radius: 8px;
}
.date-range{
  display: flex;
  flex-direction: column;
}
.ant-picker{
    min-width: 200px;
    max-width: 220px !important;
    border: 1px solid #636263;
    border-radius: 8px;
    height: 2.4rem;
    // margin-top: 1.3rem;
}
.react-icon {
    height: 21px;
    width: 21px;
  }
  .btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 24px;

  }
  
  .btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 5px;
  }
  .popup-div {
  position: absolute;
  top: 175px;
    right: 35px;
  border-radius: 16px;
background: #FFF;
box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.50);
  padding: 20px;
  z-index: 9999;
  gap: 20px;
}

.popup-message {
  font-size: 16px;
  margin-bottom: 20px;
}
span.share-txt,span.export-txt {
    font-weight: 600;
}

.popup-buttons {
  display: flex;
align-items: flex-start;
gap: 20px;
align-self: stretch;
}

.popup-buttons button {
  display: flex;
padding: 10px ;
justify-content: center;
align-items: center;
gap: 16px;
flex: 1 0 0;
border-radius: 8px;
background: #2984FD;
  cursor: pointer;
  border: none;
  color: #FFF;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}
.css-13cymwt-control,.css-t3ipsp-control{
  max-height: 38px !important;
  overflow: auto !important;
}

.ynm{
  margin-top: 23px;
    min-width: 180px;
    margin-right: 5px;
}

.custom-option {
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type='checkbox'] {
  margin-right: 8px; /* Add some spacing between the checkbox and label */
}

.brand-checkboxes {
  margin-left: 20px; /* Indent the brand checkboxes */
}

/* Style the labels to make them more visually appealing */
.checkbox-label {
  font-weight: bold;
  font-size: 14px;
  color: #333; /* Change the color to your desired text color */
}

/* Style the checkboxes */
.checkbox-container input[type='checkbox'] {
  cursor: pointer;
}

/* Style the selected checkboxes */
// .checkbox-container input[type='checkbox']:checked::before {
//   content: '✔'; /* You can use any symbol you like, or replace with a checkmark image */
//   font-size: 12px;
//   color: #007bff; /* Change the color to your desired selected color */
// }


`;
