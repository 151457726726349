import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import { Sidebar } from '../Sidebar';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineFileDownload } from 'react-icons/md';
import { IoMdShareAlt } from 'react-icons/io';
import ReactSelect from 'react-select';
import axios from '../../../../utils/api';
import Loader from '../../../../components/LoaderWave';
import Table from './TableComp';
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import ShareDataModal from '../ShareDataModal';
import TokenServices from '../../../../utils/token.services';
import FullDataExportCSV from './FullDataExportCSV';

const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

const Popup = ({ message, setisExportClicked, isExportClicked, shareData }) => {
  return (
    <div className='popup-container'>
      <div className='popup-message'>
        <span className='export-txt'>Export data - </span>Download the data to your computer or
        local storage.{' '}
      </div>
      <div className='popup-message'>
        <span className='share-txt'>Share data - </span>Share the data with anyone within the app.{' '}
      </div>
      <div className='popup-buttons'>
        <button onClick={() => { setisExportClicked(true) }}>
          <div className='icon-container'>
            <MdOutlineFileDownload className='react-icon' />
          </div>
          Export Data
        </button>
        <button onClick={shareData}>
          <div className='icon-container'>
            <IoMdShareAlt className='react-icon' />
          </div>
          Share Data
        </button>
      </div>
    </div>
  );
};

const Utilization = () => {
  const [runAPI, setRunAPI] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [showShareDataModal, setShowShareDataModal] = useState(false);
  const [isExportClicked, setisExportClicked] = useState(false);
  const [ApplyDateFilter, setApplyDateFilter] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [areFiltersFetched, setAreFiltersFetched] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const currentMonth = dayjs().format('YYYY-MM');
  // const nextMonth = dayjs().add(1, 'month').format('YYYY-MM');
  const nextMonth = dayjs().format('YYYY-MM');

  const months = [currentMonth, nextMonth];
  const [selectedMonth, setSelectedMonth] = useState(months);
  const [filterDisabled, setFilterDisabled] = useState({ team: false, employee: false, date: false })

  const handleShowModal = () => {
    setShowShareDataModal(true);
  };
  // useEffect(() => {
  //   const currentMonth = dayjs().format('YYYY-MM');
  //   // const nextMonth = dayjs().add(1, 'month').format('YYYY-MM');
  //   const nextMonth = dayjs().format('YYYY-MM');

  //   const months = [currentMonth, nextMonth];
  //   setSelectedMonth(months);
  // }, []);

  const calculateGapMonths = (startYear, endYear, startMonth, endMonth) => {
    // console.log(startMonth, 'startMonth');
    // console.log(endMonth, 'endMonth');
    // console.log(startYear, 'startYear');
    // console.log(endYear, 'endYear');
    let currentYear = parseInt(startYear, 10);
    const end = parseInt(endMonth, 10) - 1 + (parseInt(endYear, 10) - currentYear) * 12;
    const start = parseInt(startMonth, 10) - 1 + (parseInt(startYear, 10) - currentYear) * 12;
    // console.log(end,'end');
    // console.log(start,'start');
    const gap = end - start + 1;
    // console.log(gap, 'gap');
    if (gap > 0) {
      const gapArray = Array.from({ length: gap }, (_, index) => {
        const month = (start + index) % 12;
        const year = currentYear + Math.floor((start + index) / 12);
        return `${year}-${(month + 1).toString().padStart(2, '0')}`;
      });
      if (startMonth === endMonth && startYear == endYear) {
        setSelectedMonth([gapArray.toString(), gapArray.toString()]);
        syncFilter(selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountTypeOptions, [startYear, endYear], [gapArray.toString(), gapArray.toString()], !ApplyDateFilter)
      }
      else {
        setSelectedMonth(gapArray);
        syncFilter(selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountTypeOptions, [startYear, endYear], gapArray, !ApplyDateFilter)
      }

    } else {
      setSelectedMonth([]);
      syncFilter(selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountTypeOptions, [startYear, endYear], [], !ApplyDateFilter)
    }
  };

  const handleCalendarChange = (dates, dateStrings) => {
    const [startDate, endDate] = dateStrings;

    if (startDate && endDate) {
      // Extract and update selectedYear and selectedMonth
      const startYear = startDate ? dayjs(startDate, 'MMM-YYYY').year().toString() : null;
      const endYear = endDate ? dayjs(endDate, 'MMM-YYYY').year().toString() : null;

      const startMonth = startDate ? dayjs(startDate, 'MMM-YYYY').format('MM') : null;
      const endMonth = endDate ? dayjs(endDate, 'MMM-YYYY').format('MM') : null;
      setSelectedYear([startYear, endYear]);
      // Format months as two digits
      const formattedStartMonth = startMonth ? startMonth : null;
      const formattedEndMonth = endMonth ? endMonth : null;
      setSelectedMonth([formattedStartMonth, formattedEndMonth]);
      calculateGapMonths(startYear, endYear, startMonth, endMonth);

      setApplyDateFilter(!ApplyDateFilter);
    }
    else if (startDate || endDate) {
      setSelectedRange(dateStrings);
      setSelectedYear([]);
      setSelectedMonth([]);
      // Your logic for handling the selected dates goes here
      syncFilter(selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountTypeOptions, [], [], ApplyDateFilter)
    }
    else {
      // Clear selectedRange when both start and end dates are empty
      setSelectedRange([]);
      setSelectedYear([]);
      setSelectedMonth([]);
      setApplyDateFilter(!ApplyDateFilter);

      syncFilter(selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountTypeOptions, [], [], !ApplyDateFilter)
    }
  };

  const disableYearChange = (current) => {

    // const [startDate, endDate] = selectedRange;
    // console.log(startDate);
    // if (!startDate && !endDate) {
    //   return false;
    // }
    // if (!startDate || !endDate || (startDate && endDate)) {
    //   return (
    //     current.year() != dayjs(startDate, 'MM-YYYY').year() && current.year() !== dayjs(endDate, 'MM-YYYY').year()
    //   );
    // }

    // else { return false; }

  };

  const handleOpenChange = (open) => {
    const [startDate, endDate] = selectedRange;
    if (!open && (!startDate || !endDate)) {
      // Clear selectedRange when the calendar panel is closed
      setSelectedRange([]);
    }
  };

  const [loading, setLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [employeeFetched, setEmployeeFetched] = useState(false);
  const [selectedMultiEmployeeOptions, setSelectedMultiEmployeeOptions] = useState([]);
  const [selectedMultiEmployeeValues, setSelectedMultiEmployeeValues] = useState([]);
  const AccountTypeOptions = [
    { value: 'active', label: 'Active' },
    { value: 'prospect', label: 'Prospect' },
  ];
  const [selectedMultiAccountTypeOptions, setSelectedMultiAccountTypeOptions] = useState([]);
  const [selectedMultiAccountsTypeValues, setSelectedMultiAccountsValues] = useState([]);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  // console.log(selectedYearOptions);

  const handleMultiSelectEmployeeChange = (selectedOptions) => {
    // console.log(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiEmployeeOptions(selectedOptions);
    setSelectedMultiEmployeeValues(selectedValues);
    // console.log('selectedMultiEmployeeValues', selectedMultiEmployeeValues);
    syncFilter(selectedMultiTeamOptions, selectedOptions, selectedMultiAccountTypeOptions, selectedYear, selectedMonth, ApplyDateFilter)
  };

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
    // console.log('selectedMultiTeamValues', selectedMultiTeamValues);
    syncFilter(selectedOptions, selectedMultiEmployeeOptions, selectedMultiAccountTypeOptions, selectedYear, selectedMonth, ApplyDateFilter)
  };
  const handleMultiSelectAccountTypeChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiAccountTypeOptions(selectedOptions);
    setSelectedMultiAccountsValues(selectedValues);
    // console.log('selectedMultiAccountsTypeValues', selectedMultiAccountsTypeValues);
    syncFilter(selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedOptions, selectedYear, selectedMonth, ApplyDateFilter)
  };

  const handleActionClick = () => {
    setShowPopup(!showPopup);
  };
  const shareData = () => {
    setShowPopup(false);
    handleShowModal();
  };

  useEffect(() => {
    const userData = TokenServices.getUser();
    let userId = userData ? userData._id : '';
     let role = userData ? userData.role : '';
     let teamsToExclude=[]
     let employeeResponse=[]
     Promise.all([
     
      axios
      .get(`/admin/resources_wopaging?user_id=${userId}&role=${role}`),
      axios
      .get('/admin/teams_wopaging'),
     


    ])
    .then((response) => {

      
      //
      employeeResponse=response[0]?.data?.data

      //
      let modifyResponse=response[1]?.data?.data.filter((item)=>{
        if(item.includeInInsights){
         return item
        }
        else {
         teamsToExclude.push(item._id)
        }
      })

      

      employeeResponse=employeeResponse.filter((item)=>{
        if(teamsToExclude.indexOf(item.team)<0){
          return item
        }
      })
      console.log(employeeResponse)

      setEmployeeOptions(
        employeeResponse?.map((item, index) => ({
          value: item?._id,
          label: item?.first_Name + ' ' + item?.last_Name,
          key: index,
        })).sort((a, b) => {
          return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
        }),
      );
      setEmployeeFetched(true)


      setTeamOptions(
        modifyResponse?.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
          return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
        })
      );

      //
     
      

    }).catch((error)=>console.log(error));



    // axios
    //   .get(`/admin/resources_wopaging?user_id=${userId}&role=${role}`)
    //   .then((response) => {
    //     console.log('response?.data?.data', response?.data?.data);
    //     employeeResponse = response?.data?.data

    //     // setEmployeeOptions(
    //     //   response?.data?.data.map((item, index) => ({
    //     //     value: item?._id,
    //     //     label: item?.first_Name + ' ' + item?.last_Name,
    //     //     key: index,
    //     //   })),
    //     // );
    //     // setEmployeeFetched(true);
    //   })
    //   .catch((error) => {
    //     setEmployeeFetched(true);
    //     console.log(error);
    //   });

    // axios
    //   .get('/admin/teams_wopaging')
    //   .then((response) => {
    //     let modifyResponse = response?.data?.data.filter((item) => {
    //       if (item.includeInInsights) {
    //         return item
    //       }
    //       else {
    //         teamsToExclude.push(item._id)
    //       }
    //     })

    //     employeeResponse = employeeResponse.filter((item) => {
    //       if (teamsToExclude.indexOf(item.team) < 0) {
    //         return item
    //       }
    //     })

    //     setEmployeeOptions(
    //       employeeResponse?.map((item, index) => ({
    //         value: item?._id,
    //         label: item?.first_Name + ' ' + item?.last_Name,
    //         key: index,
    //       })).sort((a, b) => {
    //         return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    //       }),
    //     );
    //     setEmployeeFetched(true)


    //     setTeamOptions(
    //       modifyResponse?.map((item) => ({ value: item?._id, label: item?.name })),
    //     );
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    const user_id = userData ? userData._id : '';
    axios.get(`/admin/getFilter?user_id=${user_id}`).then((res) => {
      const { utilizationFilter } = res?.data?.data;
      if (utilizationFilter && userData.role!=="Employee") {
        if(userData.role!=="Manager"){
        setSelectedMultiTeamOptions(utilizationFilter?.teams || [])
          setselectedMultiTeamValues(utilizationFilter?.teams.map((option) => option.value) || [])
        }
        setSelectedMultiEmployeeOptions(utilizationFilter?.users || [])
        setSelectedMultiEmployeeValues(utilizationFilter?.users.map((option) => option.value) || [])
        setSelectedMultiAccountTypeOptions(utilizationFilter?.accounts || [])
        setSelectedMultiAccountsValues(utilizationFilter?.accounts.map((option) => option.value) || [])
        if (utilizationFilter.dateRange) {
          setSelectedMonth(utilizationFilter?.dateRange?.month)
          setSelectedYear(utilizationFilter?.dateRange?.year)
          setApplyDateFilter(utilizationFilter?.dateRange?.dateFilter)
        }
        setAreFiltersFetched(true);
      } else {
        setAreFiltersFetched(true);
      }
    }).catch((error) => {
      setAreFiltersFetched(true);
      console.log('error', error);
    })

    if (userData && userData.role === 'Employee') {
      setFilterDisabled({ pod: true, team: true, account: true, employee: true, date: false })
    }
    if (userData && userData.role === 'Manager') {
      setFilterDisabled({ ...filterDisabled, team: true });
    }

  }, []);

  const syncFilter = async (selectedMultiTeamOptions, selectedMultiEmployeeOptions, selectedMultiAccountTypeOptions, year, month, dateFilter) => {
    const userData = TokenServices.getUser();
    const user_id = userData ? userData._id : '';
    const modifyTeams = selectedMultiTeamOptions
    const modifyUsers = selectedMultiEmployeeOptions
    const modifyAccount = selectedMultiAccountTypeOptions
    const modifyRange = {
      year, month, dateFilter
    }

    if (user_id) {
      const response = await axios.post("/admin/setInsightFilter", {
        user_id,
        filter: {
          teams: modifyTeams,
          users: modifyUsers,
          accounts: modifyAccount,
          dateRange: modifyRange
        },
        page: 'utilization'
      })
    }
  }
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !event.target.closest('.btn-action-container')
      ) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  return (
    <Wrapper>
      {loading && <Loader />}
      <ShareDataModal
        show={showShareDataModal}
        setShow={setShowShareDataModal}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
      />
      <div className='HistoryOfRequest'>
        <section className='bg'>
          <div className='row flex-nowrap'>
            <div className='col-auto sidebar-container'>
              <Sidebar />
            </div>
            <div className='col py-3 content-area'>
              <div className='row'>
                <div className='action-row'>
                  <div className='right-btn-section'>
                    {loading ? (<div className='btn-container btn-run-container'>
                      <input type='button' value='Running...' disabled={loading} />
                    </div>) : exportLoader ? (<div className='btn-container btn-run-container'>
                      <input type='button' value='Run' disabled={loading} />
                    </div>)
                    : (<div className='btn-container btn-run-container' onClick={() => setRunAPI(!runAPI)}>
                      <input type='button' value='Run' />
                    </div>)}

                    {
                      !exportLoader ? (
                        <>
                          <div className='btn-container btn-action-container' onClick={handleActionClick}>
                            <input type='button' value='Action' />
                            <div className='icon-container'>
                              <IoIosArrowDown className='react-icon' />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='btn-container btn-action-container'>
                            <input type='button' value='Exporting...' />
                            <div className='icon-container'>
                              <IoIosArrowDown className='react-icon' />
                            </div>
                          </div>
                        </>
                      )
                    }

                    {/* Conditional rendering of the popup */}
                    {showPopup && (
                      <div ref={popupRef} className='popup-div'>
                        <Popup setisExportClicked={setisExportClicked} isExportClicked={isExportClicked} shareData={shareData} />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row-1'>
                {/* <div>
                                    <h1 className='row-1-title'>Utilization</h1>
                                </div> */}

                <div className='filter-right-section'>
                  <div className='row'>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                      <div className='multi-select-container'>
                        <label>
                          Employee Name
                          <ReactSelect
                            id='multiSelectAccounts'
                            isMulti
                            options={employeeOptions}
                            value={selectedMultiEmployeeOptions}
                            onChange={handleMultiSelectEmployeeChange}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option: CheckboxOption }}
                            isDisabled={filterDisabled.employee}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                      <div className='multi-select-container'>
                        <label>
                          Team
                          <ReactSelect
                            id='multiSelectTeam'
                            isMulti
                            options={TeamOptions}
                            value={selectedMultiTeamOptions}
                            onChange={handleMultiSelectTeamChange}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option: CheckboxOption }}
                            isDisabled={filterDisabled.team}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                      <div className='multi-select-container'>
                        <label>
                          Account Type
                          <ReactSelect
                            id='multiSelectAccountType'
                            isMulti
                            options={AccountTypeOptions}
                            value={selectedMultiAccountTypeOptions}
                            onChange={handleMultiSelectAccountTypeChange}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option: CheckboxOption }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 px-1'>
                      <div className='multi-select-container date-range'>
                        <label>Date Range</label>
                        <label>
                          <DatePicker.RangePicker
                            picker="month"
                            format="MMM-YYYY"
                            onCalendarChange={handleCalendarChange}
                            onOpenChange={handleOpenChange} // Use onOpenChange to detect when the calendar panel is closed
                            allowClear={false}
                            mode={['month', 'month']}
                            showTime={true}
                            disabledDate={disableYearChange}
                            value={[
                              selectedMonth[0] ? dayjs(selectedMonth[0], 'YYYY-MM') : '',
                              selectedMonth[1] ? dayjs(selectedMonth[selectedMonth.length - 1], 'YYYY-MM') : ''
                            ]}
                            disabled={filterDisabled.date}

                          />
                        </label>
                      </div>
                    </div>


                  </div>
                  {/* <div className='btn-container btn-action-container' onClick={handleActionClick}>
                                        <div className='icon-container'>
                                            <MdOutlineFileDownload className='react-icon' />
                                        </div>
                                        <input type='button' value='Export' />
                                    </div> */}
                </div>

              </div>

              <FullDataExportCSV
                exportLoader={exportLoader}
                setExportLoader={setExportLoader}
                isExportClicked={isExportClicked}
                setisExportClicked={setisExportClicked}
                setShowPopup={setShowPopup}
                selectedMultiTeamValues={selectedMultiTeamValues}
                selectedMultiEmployeeValues={selectedMultiEmployeeValues}
                setLoading={setLoading}
                ApplyDateFilter={ApplyDateFilter}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                areFiltersFetched={areFiltersFetched}
                employeeOptions={employeeOptions}
                employeeFetched={employeeFetched} />
              {/* Table component */}
              <Table
                selectedMultiTeamValues={selectedMultiTeamValues}
                selectedMultiAccountsTypeValues={selectedMultiAccountsTypeValues}
                selectedMultiEmployeeValues={selectedMultiEmployeeValues}
                setLoading={setLoading}
                ApplyDateFilter={ApplyDateFilter}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                isExportClicked={isExportClicked}
                setisExportClicked={setisExportClicked}
                areFiltersFetched={areFiltersFetched}
                employeeOptions={employeeOptions}
                employeeFetched={employeeFetched}
                runAPI={runAPI}
                setRunAPI={setRunAPI}
              />

            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Utilization;

const Wrapper = Styled.div`
    
    .bg{
      background: #e9eaed;
      /* padding: 20px; */
    }
    .profile-body{
background:#fff;
margin-top:14px;
    }
    .bg .row{
    --bs-gutter-x: 0px !important;
    }
    .sidebar-container{
      background: #FFFFFF;
border-radius: 16px;
margin:15px
    }
    .content-area{
      margin:0px 10px 0px 0px;
      min-width: 70%;
    }
    .action-row {
    display: flex;
    padding: 0px 10px;
    border-radius: 20px 20px 0px 0px;
    background: var(--white, #FFF);
	justify-content: flex-end;
}
  .right-btn-section{
    display: flex;
    gap: 20px;
    margin-top: -10px;
  }
    .row-1{
display: flex;
padding: 0px 10px 10px 10px;
border-radius: 0px 0px 20px 20px;
background: var(--white, #FFF); 
    }
    .row-1-title{
color: var(--black-text, #201E20);
font-size: 24px;
    }
    .chart-filter-right{
      margin-top: 24px;
    }

    .filter-right-section{
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 2;

    .row{
      @media(max-width: 1110px){
        > div{
          width: unset !important;
        }
      }
    }
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  width: 180px!important;
  border: 1px solid #636263;
border-radius: 8px;
}
.css-16xfy0z-control{
  width: 180px;
  border-radius: 8px;
}
.date-range{
  display: flex;
  flex-direction: column;
}
.ant-picker{
    min-width: 200px;
    max-width: 220px !important;
    border: 1px solid #636263;
    border-radius: 8px;
    height: 2.4rem;
    // margin-top: 1.3rem;
}
.react-icon {
    height: 21px;
    width: 21px;
  }
  .btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 24px;

  }
  
  .btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 5px;
  }
  .popup-div {
  position: absolute;
  top: 175px;
    right: 35px;
  border-radius: 16px;
background: #FFF;
box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.50);
  padding: 20px;
  z-index: 9999;
  gap: 20px;
}

.popup-message {
  font-size: 16px;
  margin-bottom: 20px;
}
span.share-txt,span.export-txt {
    font-weight: 600;
}

.popup-buttons {
  display: flex;
align-items: flex-start;
gap: 20px;
align-self: stretch;
}

.popup-buttons button {
  display: flex;
padding: 10px ;
justify-content: center;
align-items: center;
gap: 16px;
flex: 1 0 0;
border-radius: 8px;
background: #2984FD;
  cursor: pointer;
  border: none;
  color: #FFF;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}
.css-13cymwt-control,.css-t3ipsp-control{
  max-height: 38px !important;
  overflow: auto !important;
}

.custom-option {
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type='checkbox'] {
  margin-right: 8px; /* Add some spacing between the checkbox and label */
}

.brand-checkboxes {
  margin-left: 20px; /* Indent the brand checkboxes */
}

/* Style the labels to make them more visually appealing */
.checkbox-label {
  font-weight: bold;
  font-size: 14px;
  color: #333; /* Change the color to your desired text color */
}

/* Style the checkboxes */
.checkbox-container input[type='checkbox'] {
  cursor: pointer;
}

/* Style the selected checkboxes */
// .checkbox-container input[type='checkbox']:checked::before {
//   content: '✔'; /* You can use any symbol you like, or replace with a checkmark image */
//   font-size: 12px;
//   color: #007bff; /* Change the color to your desired selected color */
// }
  .add-task-btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
  }
  .add-task-btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 4px;
  }
`;
