import React, { useState, useEffect } from 'react';
import { Image, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Styled from 'styled-components';

import { GiNetworkBars } from 'react-icons/gi';

function ViewAllResourcesModal({
  showModal,
  setShowModal,
  refreshPage,
  setRefreshPage,
  editingUser,
}) {
  console.log('editingUser', editingUser);
  // const handleShowModal = (user) => {
  //     setEditingUser(user);
  //     setShowModal(true);
  //   };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered size='sm'>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>Resources</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-2'>
          <div class='row'>
            <div class='form-border'>
              <div class=''>
                <div class='row'>
                  <div class='col-md-12'>
                    <div class='scrollable-div'>
                      {editingUser?.resource_id?.map((row, index) => (
                        <div className='avatar-container'>
                          <Image
                            src={'https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg'}
                            roundedCircle
                            className='avatar-image'
                          />
                          <div className='avatar-name'>
                            {row?.first_Name} {row?.last_Name}
                            <div className='profile-role'>
                              {editingUser?.title_data?.map((data, index) =>
                                data?._id == row?.title ? data?.name : '',
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default ViewAllResourcesModal;

const Wrapper = Styled.div`

.avatar-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}
.avatar-image {
  width: 41px;
  height: 41px;
}
.avatar-name {
  font-weight: bold;
}
.profile-role {
    color: #636263;
    font-weight: 400;
font-size: 14px;
}
.scrollable-div {
  height: 250px; /* Set the height of the div */
  overflow-y: scroll; /* Add vertical scrolling */
}

.scrollable-div::-webkit-scrollbar {
  width: 8px;
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #2984FD;
}

.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}

`;
