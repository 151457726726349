import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import axios from "axios";
import axios from '../../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdClose } from 'react-icons/md';
function AddTitleModal({ show, setShow, refreshPage, setRefreshPage }) {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState({ name: '', hourly_rate: '', utilization: '' });
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!data.name.trim()) {
      errors.name = 'Please enter the title name';
    }
    if (!data?.utilization) {
      errors.utilization = 'Please enter the utilization';
    } 
    
    if (!data?.hourly_rate) {
      errors.hourly_rate = 'Please enter the hourly rate';
    } else if (data.hourly_rate <= 0) {
      errors.hourly_rate = 'Please enter a valid hourly rate';
    }

    setErrors(errors);

    // Check if any errors exist
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }

    axios
      .post('/admin/title', data)
      .then((response) => {
        console.log(response);
        toast.success(response.data.message);
        setTimeout(() => {
          setShow(false);
          setRefreshPage(!refreshPage);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  const handleChange = (e) => {
    if (e.target.name == 'hourly_rate') {
      setData({ ...data, [e.target.name]: Number(e.target.value) });
      return;
    }

    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Wrapper>
          <Modal.Header closeButton>
            <Modal.Title>New Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className='input-container d-flex  align-items-end w-100 '>
                <div className='me-3 mb-2'>
                  <img
                    src={require('../../../../assets/icons/profile-check.png')}
                    alt='profile-check'
                  />
                </div>
                <div className='w-100'>
                  <label htmlFor='podname'>Title Name</label>
                  <input
                    type='text'
                    placeholder='eg.Creative'
                    className={`form-control shadow-none ${errors.name ? 'is-invalid' : ''}`}
                    name='name'
                    onChange={handleChange}
                    value={data.name}
                  />
                  {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                </div>
              </div>
              <div className='input-container d-flex  align-items-end w-100 '>
                <div className='me-3 mb-2'>
                  <img src={require('../../../../assets/icons/clock.png')} alt='profile-check' />
                </div>
                <div className='w-100'>
                  <label htmlFor='hourly rate'>Hourly Rate</label>

                  <div class='input-group'>
                    <div class='input-group-prepend'>
                      <span class='input-group-text'>$</span>
                    </div>
                    <input
                      type='number'
                      placeholder='eg.12'
                      className={`form-control shadow-none ${
                        errors.hourly_rate ? 'is-invalid' : ''
                      }`}
                      name='hourly_rate'
                      onChange={handleChange}
                      value={data.hourly_rate ? data.hourly_rate : ''}
                    />
                    {errors.hourly_rate && (
                      <div className='invalid-feedback'>{errors.hourly_rate}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='input-container d-flex  align-items-end w-100 '>
                <div className='me-3 mb-2'>
                  <img src={require('../../../../assets/icons/clock.png')} alt='profile-check' />
                </div>
                <div className='w-100'>
                  <label htmlFor='hourly rate'>Utilization Target</label>

                  <div class='input-group'>

                    <input
                      type='number'
                      placeholder='eg.12'
                      className={`form-control shadow-none ${
                        errors.utilization ? 'is-invalid' : ''
                      }`}
                      name='utilization'
                      onChange={handleChange}
                      value={data.utilization ? data.utilization : ''}
                    />
                                        <div class='input-group-append'>
                      <span class='input-group-text'>%</span>
                    </div>
                    {errors.utilization && (
                      <div className='invalid-feedback'>{errors.utilization}</div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end my-3">
        <Button variant="outline-primary" className="me-4" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="outline-primary" className="me-2">Add Title</Button>
        </div> */}
              <div class='d-flex justify-content-center'>
                <div class='mt-2 mx-2'>
                  <button
                    class='btn btn-primary employee-cancel-button'
                    type='button'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
                <div class='mt-2 mx-2'>
                  <button class='btn btn-primary employee-save-button' type='submit'>
                    Add Title
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Wrapper>
      </Modal>
    </>
  );
}

export default AddTitleModal;
const Wrapper = styled.div`
  /* .modal-header{
        background: #EAF3FF;
    }
    .input-container{
        width:100%;

    } */
  .modal-title {
    font-weight: 500;
    font-size: 16px;
  }
  .modal-header .btn-close {
    width: 24px;
    height: 24px;
  }
  .modal-header {
    background: #eaf3ff;
    border-radius: 16px 16px 0px 0px;
  }
  .employee-cancel-button {
    background: #ffffff;
    border: none;
    color: #2984fd;
  }
  .employee-save-button {
    background: #2984fd;
    border-radius: 8px;
    border-color: #2984fd;
  }
`;
