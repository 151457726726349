import React, { useState, useEffect } from 'react';
import Axios from '../../../../utils/api';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import TokenServices from '../../../../utils/token.services';

const FullDataExportCSV = ({ exportLoader,setExportLoader,setisExportClicked, setShowPopup, isExportClicked, ApplyDateFilter, selectedMultiPodValues, selectedMultiTeamValues, selectedMultiAccountsValues, selectedBrandData, selectedMultiEmployeeValues, selectedYear, selectedMonth, setLoading, areFiltersFetched, employeeOptions, employeeFetched }) => {
    const [data, setData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const fetchData = async () => {
        try {
            setExportLoader(true);
            const userData = TokenServices.getUser();
            let updatedEmployeeValue = [...selectedMultiEmployeeValues];
            if (userData && userData.role === 'Employee') {
                updatedEmployeeValue = [userData._id];
            } else if (userData && userData.role === 'Manager' && selectedMultiEmployeeValues.length === 0 && employeeOptions.length > 0) {
                updatedEmployeeValue = employeeOptions.map(emp => emp.value);
            }
            const response = await Axios.post(
                `/admin/scheduleExport`, {
                pod_ids: selectedMultiPodValues,
                team_ids: selectedMultiTeamValues,
                account_id: selectedMultiAccountsValues,
                brand_id: selectedMultiAccountsValues.length > 0 ? selectedBrandData : [],
                employee_id: updatedEmployeeValue,
                year: selectedYear,
                month: selectedMonth,
            });
            console.log(response.data.data, 'rrrrrrr')
            const responseData = response.data.data;

            const aggregatedDataArray = [];
            let currentAccount = "";
            let currentBrand = "";
            let accountSubtotal = 0;
            let brandSubtotal = 0;
            let accountUserTimersSubtotal = 0; // Separate subtotal for account level
            let brandUserTimersSubtotal = 0;  // Separate subtotal for brand level

            // Helper function to convert time duration to minutes
            const convertTimeToMinutes = (time) => {
                const [hours, minutes] = time.split(":").map(Number);
                return hours * 60 + minutes;
            };

            // Helper function to format duration as "0:00"
            const formatDuration = (minutes) => {
                // debugger;
                if (minutes === 0) {
                    return '0:00';
                }
                const hours = Math.floor(minutes / 60);
                const remainingMinutes = minutes % 60;
                return `${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`;
            };

            // Iterate through the data array
            responseData.forEach((item) => {
                const { account_id, brand_id, duration, user_timers, from } = item;
                const accountName = account_id.name;
                const brandName = brand_id.brand_name;



                // Check if the brand changes
                if (brandName !== currentBrand) {
                    // Insert brand subtotal
                    if (currentBrand !== "") {
                        aggregatedDataArray.push({
                            brandSubtotalDuration: brandSubtotal,
                            userTimersSubtotalDuration: formatDuration(brandUserTimersSubtotal), // Include user_timers subtotal at brand level
                        });

                        // Reset userTimers subtotal for brand
                        brandUserTimersSubtotal = 0;
                    }

                    // Reset brand subtotal
                    currentBrand = brandName;
                    brandSubtotal = 0;
                }

                // Check if the account changes
                if (accountName !== currentAccount) {
                    // Insert account subtotal
                    if (currentAccount !== "") {
                        aggregatedDataArray.push({
                            accountSubtotalDuration: accountSubtotal,
                            AccountuserTimersSubtotalDuration: formatDuration(accountUserTimersSubtotal),
                        });

                        // Reset userTimers subtotal for account
                        accountUserTimersSubtotal = 0;
                    }

                    // Reset account subtotal
                    currentAccount = accountName;
                    accountSubtotal = 0;
                }

                // Aggregate data
                accountSubtotal += parseFloat(duration);
                brandSubtotal += parseFloat(duration);

                // Sum user_timers duration for the current month
                // const currentMonth = new Date(from).getMonth();
                const currentMonth = moment(from).tz('America/New_York').toDate().getMonth() + 1;
                const currentYear = moment(from).tz('America/New_York').toDate().getFullYear();

                user_timers.forEach((timer) => {
                    const timerDate = moment(timer.date);
                    const timerMonth = timerDate.tz('America/New_York').add(1, 'day').month() + 1;
                    const timerYear = timerDate.tz('America/New_York').add(1, 'day').year();

                    if (timerMonth === currentMonth && timerYear === currentYear) {
                        accountUserTimersSubtotal += convertTimeToMinutes(timer.duration);
                        brandUserTimersSubtotal += convertTimeToMinutes(timer.duration);
                    }
                });

                aggregatedDataArray.push(item);
            });

            // Insert the last subtotals
            if (currentBrand !== "") {
                aggregatedDataArray.push({
                    brandSubtotalDuration: brandSubtotal,
                    userTimersSubtotalDuration: formatDuration(brandUserTimersSubtotal), // Include user_timers subtotal at the last brand
                });
            }

            if (currentAccount !== "") {
                aggregatedDataArray.push({
                    accountSubtotalDuration: accountSubtotal,
                    AccountuserTimersSubtotalDuration: formatDuration(accountUserTimersSubtotal),
                });
            }

            // Grand totals
            const grandTotalAccountSubtotal = aggregatedDataArray.reduce((total, item) => {
                return total + (item.accountSubtotalDuration || 0);
            }, 0);

            const grandTotalUserTimersSubtotal = aggregatedDataArray.reduce((total, item) => {
                return total + convertTimeToMinutes(item.AccountuserTimersSubtotalDuration || '0:00');
            }, 0);
            console.log(grandTotalAccountSubtotal.length, 'grandTotalAccountSubtotal');
            // console.log(formatDuration(grandTotalUserTimersSubtotal),'grandTotalUserTimersSubtotal');
            // Add grand totals to the end of the array
            if (grandTotalAccountSubtotal || grandTotalUserTimersSubtotal) {
                aggregatedDataArray.push({
                    grandTotalAccountSubtotal,
                    grandTotalUserTimersSubtotal: formatDuration(grandTotalUserTimersSubtotal),
                });
            }

            // Log the result


            if (aggregatedDataArray.length > 0) {
                setExportData(aggregatedDataArray);
            }
            // setData(response.data.data);

            // setTotalPages(Number(response.data.total_pages));
            setExportLoader(false);
            setTimeout(() => {
                exportToExcel(aggregatedDataArray);
            }, 500);
            
        } catch (error) {
            setExportLoader(false);
            console.error(error);
        }
    };

    // useEffect(() => {
    //     if (isExportClicked) {
    //         setData([]);
    //         if (areFiltersFetched && employeeFetched) {
    //             fetchData();
    //         }
    //     }
    // }, [
    //     isExportClicked
    // ]);

    const durationToMinutes = (duration) => {
        const [hours, minutes] = duration.split(":").map(Number);
        return hours * 60 + minutes;
    };

    const getTotalDuration = (row) => {

        // Extract the month and year from 'row.from'
        const rowMonth = moment(row.from).tz('America/New_York').toDate().getMonth() + 1;
        const rowYear = moment(row.from).tz('America/New_York').toDate().getFullYear();
        // if (row.user_id.first_Name == 'Dakota') {
        //   debugger;
        //   console.log(row.user_id.first_Name);
        // }
        // Calculate the sum of durations in minutes for the specified month and year
        const totalMinutes = row?.user_timers
            ?.filter((timer) => {
                const timerMonth = moment(timer.date).tz('America/New_York').add(1, 'day').month() + 1;
                // console.log(moment(timer.date).tz('America/New_York').add(1, 'day'));
                const timerYear = moment(timer.date).tz('America/New_York').add(1, 'day').year();

                // Compare both month and year
                return (timerMonth === rowMonth) && (timerYear === rowYear);
            })
            .reduce((total, timer) => total + durationToMinutes(timer.duration), 0);

        // Convert total minutes back to the format hh:mm
        return `${Math.floor(totalMinutes / 60)}:${(totalMinutes % 60)
            .toString()
            .padStart(2, "0")}`;
    };


    // Function to subtract durations in the format "hh:mm"
    function subtractDurations(duration1, duration2) {
        // debugger;

        // console.log(duration1, 'duration1');
        // console.log(duration2, 'duration2');
        duration2 = duration2 + ':0'
        const [hours1, minutes1] = duration1.split(':').map(Number);
        const [hours2, minutes2] = duration2.split(':').map(Number);

        const totalMinutes1 = hours1 * 60 + minutes1;
        // const totalMinutes2 = duration2 * 60 ;
        const totalMinutes2 = hours2 * 60 + minutes2;
        // console.log(totalMinutes2);

        const differenceMinutes = totalMinutes1 - totalMinutes2;

        // Calculate hours and remaining minutes
        const hoursDifference = Math.floor(Math.abs(differenceMinutes) / 60);
        const minutesDifference = Math.abs(differenceMinutes % 60);

        // Format the result as "hh:mm" with correct sign
        const sign = differenceMinutes < 0 ? '-' : '';
        return `${sign}${hoursDifference}:${String(minutesDifference).padStart(2, '0')}`;
    }

    const getVariance = (row) => {
        // debugger;
        if (!row) {
            return null; // Handle the case where the duration is not available
        }
        if (row?.userTimersSubtotalDuration != null && row?.brandSubtotalDuration != null) {
            return subtractDurations(row.userTimersSubtotalDuration, row.brandSubtotalDuration);
        }

        if (row?.AccountuserTimersSubtotalDuration != null && row?.accountSubtotalDuration != null) {
            return subtractDurations(row.AccountuserTimersSubtotalDuration, row.accountSubtotalDuration);
        }
        if (row?.grandTotalUserTimersSubtotal != null && row?.grandTotalAccountSubtotal != null) {
            return subtractDurations(row.grandTotalUserTimersSubtotal, row.grandTotalAccountSubtotal);
        }

        const totalDuration = getTotalDuration(row);
        if (!totalDuration) {
            return null; // Handle the case where the total duration is not available
        }

        // Subtract the individual row duration from the total duration
        return subtractDurations(totalDuration, row.duration);
    };
    const bgColor = { color: 'blue' }

    useEffect(() => {
        if (isExportClicked) {
            setData([]);
            if (areFiltersFetched && employeeFetched) {
                fetchData();
            }
            setisExportClicked(false);           
            setShowPopup(false);
        }
    }, [isExportClicked,]);

    const exportToExcel = (aggregatedDataArray) => {
        const excelData = [];

        // Add headers
        excelData.push([
            'Account',
            'Brand',
            'POD',
            'Team',
            'Title',
            'Employee',
            'Month',
            'Month #',
            'Year',
            'Actual Hours',
            'Schedule Hours',
            'Variance',
        ]);

        // Add data rows
        aggregatedDataArray?.forEach((row) => {
            if (row.account_id) {
                const totalDuration = getTotalDuration(row);
                let totalDurationArray = totalDuration.split(":");
                let durationMinute = ((totalDurationArray[1] / 60) * 100).toFixed(0).toString().padStart(2, "0");

                const variance = getVariance(row);
                let varianceArray = variance.split(":");
                let varianceMinute = ((varianceArray[1] / 60) * 100).toFixed(0).toString().padStart(2, "0");

                const varianceCheck = parseFloat(getVariance(row).replace(':', '.')).toFixed(2);
                if (varianceCheck == '0.00' && parseFloat(row?.duration) == 0) {
                    console.log(parseFloat(row?.duration), 'parseFloat(row?.duration)');
                    return null; // Skip rendering for this row
                }
                excelData.push([
                    row.account_id.name,
                    row.brand_id.brand_name,
                    row.brand_id.pod_id.name,
                    row?.user_id?.team?.name,
                    row?.user_id?.title?.name,
                    // row.brand_id.team_id?.map((team) => team.name).join(', '),
                    `${row.user_id.first_Name} ${row.user_id.last_Name}`,
                    moment(row.from).format('MMMM YYYY'),
                    (moment(row.from).month() + 1),
                    parseFloat(moment(row.from).format('YYYY')),
                    parseFloat(totalDurationArray[0] + '.' + durationMinute),
                    // getTotalDuration(row),
                    parseFloat(row.duration),
                    parseFloat(varianceArray[0] + '.' + varianceMinute),
                ]);
            }
        });

        // Sort excelData array
        excelData.sort((a, b) => {
            // Compare Account names
            const accountNameA = a[0];
            const accountNameB = b[0];
            const accountComparison = accountNameA.localeCompare(accountNameB);
            if (accountComparison !== 0) {
                return accountComparison;
            }

            // Compare Brand names
            const brandNameA = a[1];
            const brandNameB = b[1];
            const brandComparison = brandNameA.localeCompare(brandNameB);
            if (brandComparison !== 0) {
                return brandComparison;
            }

            // Compare Actual Hours
            const actualHoursA = parseFloat(a[6]);
            const actualHoursB = parseFloat(b[6]);
            return actualHoursB - actualHoursA; // Sort in descending order of actual hours
        });

        // Create a worksheet
        const ws = XLSX.utils.aoa_to_sheet(excelData);

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

        // Save the file
        var expFileName = 'schedule data';
        if (selectedMonth.length > 0) {
            let lastMonthKey = selectedMonth.length - 1;
            expFileName = selectedMonth[0] + ' to ' + selectedMonth[lastMonthKey];
        }
        XLSX.writeFile(wb, expFileName + '.xlsx');

    };
    //function to handle Excel export
    return (
        <></>
    )
}

export default FullDataExportCSV
