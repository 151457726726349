import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Styled from 'styled-components';
import { BiStopwatch } from 'react-icons/bi';
import { VscCalendar } from 'react-icons/vsc';
import { FaHandHoldingUsd } from 'react-icons/fa';
import { VscCompass } from "react-icons/vsc";
import { MdHistory } from "react-icons/md";
import TokenServices from '../../../utils/token.services';

export const Sidebar = () => {
  const location = useLocation(); // Access the current location from React Router
  const userData = TokenServices.getUser();
  let role = userData ? userData.role : '';
  return (
    <Wrapper>
      <div className='d-flex flex-column align-items-center align-items-sm-start min-vh-100 px-1 py-1'>
        <ul
          className='nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start'
          id='menu'
        >
          <li className={`nav-item ${location.pathname === '/hours' ? 'active' : ''}`}>
            <Link to='/hours' className='nav-link px-0 align-middle'>
              <BiStopwatch /> <span className='ms-1 d-none d-sm-inline sidebar-text'>Hours</span>
            </Link>
          </li>
          <li className={`nav-item ${location.pathname === '/schedule' ? 'active' : ''}`}>
            <Link to='/schedule' className='nav-link px-0 align-middle'>
              <VscCalendar /> <span className='ms-1 d-none d-sm-inline sidebar-text'>Schedule</span>
            </Link>
          </li>
          <li className={`nav-item ${location.pathname === '/utilization' ? 'active' : ''}`}>
            <Link to='/utilization' className='nav-link px-0 align-middle'>
              <VscCompass /> <span className='ms-1 d-none d-sm-inline sidebar-text'>Utilization</span>
            </Link>
          </li>
          <li className={`nav-item ${location.pathname === '/profitability' ? 'active' : ''}`}>
            <Link to='/profitability' className='nav-link px-0 align-middle'>
              <FaHandHoldingUsd />{' '}
              <span className='ms-1 d-none d-sm-inline sidebar-text'>Profitability</span>
            </Link>
          </li>
          {
            role === 'Admin' && (
              <li className={`nav-item ${location.pathname === '/loginhistory' ? 'active' : ''}`}>
                <Link to='/loginhistory' className='nav-link px-0 align-middle'>
                  <MdHistory />{' '}
                  <span className='ms-1 d-none d-sm-inline sidebar-text'>Login History</span>
                </Link>
              </li>
            )
          }
        </ul>
      </div>
    </Wrapper>
  );
};

const Wrapper = Styled.div`


.nav-item{
width: 100%;
border-radius: 16px;
margin-top:5px;
padding:5px 10px 
}
.nav-item:hover{
    background:#D4E6FF;
}
.nav-item .nav-link{
color: #000;
}
.nav-item .nav-link svg{
width:23px;
height:23px;
}
.nav-item .nav-link .sidebar-text{
margin-left: 5px !important;
}
.active{
background:#D4E6FF;
    }

`;
