import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Styled from 'styled-components';
import { Form } from 'react-bootstrap';
import axios from '../../../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BiUserCheck } from 'react-icons/bi';
import { VscBlank } from 'react-icons/vsc';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { TbBinaryTree2 } from 'react-icons/tb';

function RadioButtons(props) {
  const handleOptionChange = (event) => {
    props.setSelectedOption(event.target.value);
  };

  return (
    <Form.Group>
      <Form.Label>{props.label}</Form.Label>
      <div>
        <Form.Check
          inline
          type='radio'
          label='Active'
          name='option'
          value='active'
          checked={props.selectedOption === 'active'}
          onChange={handleOptionChange}
        />
        <Form.Check
          inline
          type='radio'
          label='Prospect'
          name='option'
          value='prospect'
          checked={props.selectedOption === 'prospect'}
          onChange={handleOptionChange}
        />
      </div>
    </Form.Group>
  );
}
function BrandStatusRadioButtons({ index, brands, setBrands, selectedbrandstatusOption }) {
  const handleBrandStatusChange = (event) => {
    const newBrands = [...brands];
    newBrands[index] = { ...newBrands[index], status: event.target.value };
    setBrands(newBrands);
  };
  return (
    <Form.Group>
      <Form.Label></Form.Label>
      <div>
        <Form.Check
          inline
          type='radio'
          label='Active'
          name={`option-active-${index}`} // Unique name for active option with index
          value='active'
          checked={selectedbrandstatusOption === 'active'}
          onChange={handleBrandStatusChange}
        />
        <Form.Check
          inline
          type='radio'
          label='Prospect'
          name={`option-prospect-${index}`} // Unique name for prospect option with index
          value='prospect'
          checked={selectedbrandstatusOption === 'prospect'}
          onChange={handleBrandStatusChange}
        />
      </div>
    </Form.Group>
  );
}
function EditAccountsModal({
  showModal,
  setShowModal,
  refreshPage,
  selectedAccount,
  setRefreshPage,
  TeamOptions,
  PodOption,
  LeadOptions,
}) {
  const [parentErrors, setparentErrors] = useState({});
  const initialBrands = [...selectedAccount?.brands];
  const [errors, setErrors] = useState([]);
  const [selectedOption, setSelectedOption] = useState(selectedAccount?.account_status);
  const [SelectedPodOption, setSelectedPodOption] = useState([]);
  const [SelectedLeadOptions, setSelectedLeadOptions] = useState([]);
  const [SelectedTeamOptions, setSelectedTeamOptions] = useState([]);
  const [SelectedTeamValues, setSelectedTeamValues] = useState([]);
  const [brandCount, setBrandCount] = useState(1);
  const [brands, setBrands] = useState(
    initialBrands?.map((item) => {
      const modifyItem = {};
      modifyItem.name = item.brand_name;
      modifyItem.lead = [
        { label: item?.lead_id?.first_Name + item?.lead_id?.last_Name, value: item?.lead_id?._id },
      ];
      modifyItem.pod = [{ label: item?.pod_id?.name, value: item?.pod_id?._id }];
      modifyItem.teams_options = item?.team_id?.map((ele) => {
        return { value: ele?._id, label: ele?.name };
      });
      modifyItem.teams_id = item?.team_id?.map((ele) => {
        return ele?._id;
      });
      modifyItem.status = item.status;
      modifyItem.goal_percentage=item.goal_percentage
      modifyItem._id = item._id;
      return modifyItem;
    }),
  );
  const [parentAccount, setParentAccount] = useState(selectedAccount?.name);

  // console.log(selectedAccount,".../")
  console.log('brands', brands);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrors({});
  };

  function handleParentAccountChange(event) {
    setParentAccount(event.target.value);
    setparentErrors({});
  }

  const handleAddBrandClick = () => {
    const modifyBrands = [...brands];
    modifyBrands.push({
      name: '',
      pod: [],
      lead: [],
      goal_percentage:"",
      teams_id: [],
      teams_options: [],
      status: 'active',
    });
    console.log(modifyBrands, 'modiiiify brands');
    setBrands(modifyBrands);
  };

  const handleBrandNameChange = (event, index) => {
    const newBrands = [...brands];
    newBrands[index] = { ...newBrands[index], [event.target.name]: event.target.value };
    setBrands(newBrands);

    // Clear the corresponding error for the changed brand name
    // const updatedErrors = [...errors];
    // console.log(updatedErrors);
    // if (updatedErrors.length>0) {
    // updatedErrors[index].name = '';
    // setErrors(updatedErrors);
    // }
  };

  const handlePodSelect = (SelectedPodOption, index) => {
    const newBrands = [...brands];
    newBrands[index] = { ...newBrands[index], pod: SelectedPodOption };
    setBrands(newBrands);

    // Clear the corresponding error for the changed Pod
    // const updatedErrors = [...errors];
    // updatedErrors[index].pod = '';
    // setErrors(updatedErrors);
  };

  const handleLeadSelect = (SelectedLeadOptions, index) => {
    const newBrands = [...brands];
    newBrands[index] = { ...newBrands[index], lead: SelectedLeadOptions };
    setBrands(newBrands);

    // Clear the corresponding error for the changed Pod
    // const updatedErrors = [...errors];
    // updatedErrors[index].lead = '';
    // setErrors(updatedErrors);
  };

  const handleTeamSelect = (selectedOptions, index) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    const newBrands = [...brands];
    newBrands[index] = {
      ...newBrands[index],
      teams_id: selectedValues,
      teams_options: selectedOptions,
    };
    setBrands(newBrands);

    // Clear the corresponding error for the changed Pod
    // const updatedErrors = [...errors];
    // updatedErrors[index].team = '';
    // setErrors(updatedErrors);
  };

  const rows = brands.map((brand, index) => (
    <div className='row' key={index}>
      <div className='col-md-12'>
        <label className='form-label'>Brand</label>
        <div className='input-group mb-1'>
          <span className='input-group-text'>
            <TbBinaryTree2 />
          </span>
          <input
            type='text'
            className={`form-control shadow-none ${
              errors[index] && errors[index].name ? 'is-invalid' : ''
            }`}
            placeholder='Enter Brand'
            value={brand.name}
            name='name'
            onChange={(e) => handleBrandNameChange(e, index)}
          />
          {errors[index] && errors[index].name && (
            <div className='invalid-feedback'>{errors[index].name}</div>
          )}
        </div>
      </div>
      <div className='col-md-12'>
        <label className='form-label'>Goal%</label>
        <div className='input-group mb-1'>
          <span className='input-group-text'>
            <TbBinaryTree2 />
          </span>
          <input
            type='number'
            max="100"
            min="0"
            className={`form-control shadow-none`}
            placeholder='Enter Goal Percentage'
            value={brand.goal_percentage}
            name="goal_percentage"
            onWheel={ event => event.currentTarget.blur() }
            onChange={(e) => handleBrandNameChange(e, index)}
          />
          
        </div>
      </div>
      <div class='row mx-3'>
        <div class='col-md-12'>
          <BrandStatusRadioButtons
            label=''
            selectedbrandstatusOption={brand.status}
            index={index}
            brands={brands}
            setBrands={setBrands}
          />
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <label className='form-label'>Pod</label>
        <div className='input-group mb-1'>
          <span className='input-group-text'>
            <VscBlank />
          </span>
          <Select
            className={`select-form-control ${
              errors[index] && errors[index].pod ? 'is-invalid' : ''
            }`}
            options={PodOption}
            value={brand.pod}
            onChange={(options) => handlePodSelect(options, index)}
          />
          {errors[index] && errors[index].pod && (
            <div className='invalid-feedback'>{errors[index].pod}</div>
          )}
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <label className='form-label'>Lead Allocation</label>
        <div className='input-group mb-1'>
          <span className='input-group-text'>
            <VscBlank />
          </span>
          <Select
            className={`select-form-control ${
              errors[index] && errors[index].lead ? 'is-invalid' : ''
            }`}
            options={LeadOptions}
            value={brand.lead}
            onChange={(options) => handleLeadSelect(options, index)}
          />
          {errors[index] && errors[index].lead && (
            <div className='invalid-feedback'>{errors[index].lead}</div>
          )}
        </div>
      </div>
      <div className='col-md-12 mt-3'>
        <label className='form-label'>Teams Allocation</label>
        <div className='input-group mb-1'>
          <span className='input-group-text'>
            <VscBlank />
          </span>
          <Select
            className={`select-form-control ${
              errors[index] && errors[index].team ? 'is-invalid' : ''
            }`}
            options={TeamOptions}
            value={brand.teams_options}
            onChange={(options) => handleTeamSelect(options, index)}
            isMulti={true}
          />
          {errors[index] && errors[index].team && (
            <div className='invalid-feedback'>{errors[index].team}</div>
          )}
        </div>
      </div>
    </div>
  ));

  const validateForm = () => {
    if (!parentAccount.trim()) {
      setparentErrors({ parentAccount: 'Please enter the parent account' });
      return;
    }
    const err = [];
    const updatedErrors = brands.forEach((brand) => {
      let error = {
        // Add more fields for other errors if needed
      };
      if (!brand.name) {
        error.name = 'Please enter the brand name';
      }

      if (brand.pod.length == 0) {
        error.pod = 'Please select a Pod';
      }
      if (brand.lead.length == 0) {
        error.lead = 'Please select a lead';
      }
      if (brand.teams_id.length == 0) {
        error.team = 'Please select a team';
      }
      // console.log("inside fill",error)
      // console.log(Object.keys(error))
      if (Object.keys(error).length > 0) {
        err.push(error);
      } else {
        err.push({});
      }

      // Add more validation rules for other fields if needed
    });
    console.log(err, 'upddddd');
    setErrors(err);
    return err;
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    // Validate the form before submission
    validateForm();
    let count = 0;
    validateForm().forEach((item) => {
      if (Object.keys(item).length > 0) {
        count++;
      }
    });
    // console.log(validateForm(),"kjlkjlkj")
    if (count > 0) {
      return;
    }

    const brandsData = brands.map((item) => ({
      brand_name: item.name,
      pod_id: item.pod.value,
      lead_id: item.lead.value,
      goal_percentage:item.goal_percentage,
      team_id: item.teams_id,
      status: item.status,
      _id: item._id,
    }));
    console.log(brandsData);

    const details = {
      parentAccountName: parentAccount,
      _id: selectedAccount._id,
      brands: brandsData,
      status: selectedOption,
    };
    console.log('details', details);
    axios
      .put('/admin/account', details)
      .then((response) => {
        console.log(response);
        toast.success(response.data.message);
        setTimeout(() => {
          setShowModal(false);
          setRefreshPage(!refreshPage);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>Edit Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEditSubmit}>
            <div class='row'>
              <div class='form-border'>
                <div class=''>
                  <div class='row'>
                    <div class='col-md-12'>
                      <label class='form-label'>Parent Account</label>
                      <div class='input-group'>
                        <span class='input-group-text'>
                          <HiOutlineUserGroup />
                        </span>
                        <input
                          type='text'
                          className={`form-control shadow-none ${
                            parentErrors.parentAccount ? 'is-invalid' : ''
                          }`}
                          placeholder='Enter Parent Account'
                          value={parentAccount}
                          onChange={handleParentAccountChange}
                        />
                        {parentErrors.parentAccount && (
                          <div className='invalid-feedback'>{parentErrors.parentAccount}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class='row mx-3'>
                    <div class='col-md-12'>
                      <RadioButtons
                        label=''
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                      />
                    </div>
                  </div>

                  <div>
                    {rows}
                    <div className='mt-2 mx-2 Add-Brand'>
                      <button
                        className='btn btn-primary employee-cancel-button'
                        type='button'
                        onClick={handleAddBrandClick}
                      >
                        Add Brand
                      </button>
                    </div>
                  </div>

                  <div class='d-flex justify-content-center'>
                    <div class='mt-2 mx-2'>
                      <button
                        class='btn btn-primary employee-cancel-button'
                        type='button'
                        onClick={() => handleCloseModal()}
                      >
                        Cancel
                      </button>
                    </div>
                    <div class='mt-2 mx-2'>
                      <button class='btn btn-primary employee-save-button' type='submit'>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
}
export default EditAccountsModal;

const Wrapper = Styled.div`
.modal-title {
    font-weight: 500;
    font-size: 16px;
}
.modal-header .btn-close {
    width: 24px;
    height: 24px;
}
.modal-header {
    background: #EAF3FF;
    border-radius: 16px 16px 0px 0px;
}
.modal-dialog .modal-content {
    border-radius: 16px 16px 0px 0px !important;
}
.input-group-text{
    border:none;
    background: none;

}
.input-group-text svg{
    width: 20px;
    height: 20px;

}
.input-group {
    margin-left: -10px;
}

.form-label {
    margin-left: 33px;
}
.css-b62m3t-container{
    /* border:none; */
    border-radius: 8px!important;
}
.input-group input{
    border-radius: 8px!important;
}
.employee-cancel-button{
    background: #FFFFFF;
    border: none;
    color: #2984FD;
}
.employee-save-button{
    background: #2984FD;
border-radius: 8px;
border-color:#2984FD;
}
.select-form-control{
    width:90%;
}
.select-form-control div{
    border-radius: 8px;
}
.Add-Brand{
  display: flex;
flex-direction: row;
justify-content: flex-end;
}
.invalid-feedback{
  margin-left: 43px;
}

`;
