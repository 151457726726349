import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import { Sidebar } from '../Sidebar';
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineFileDownload } from 'react-icons/md';
import { IoMdShareAlt } from 'react-icons/io';
import ReactSelect from 'react-select';
import axios from '../../../../utils/api';
import Loader from '../../../../components/LoaderWave';
import Chart from './Chart';
import { DatePicker, Select } from 'antd';
import dayjs from "dayjs"
import * as XLSX from 'xlsx'
import ShareDataModal from '../ShareDataModal';
import TokenServices from '../../../../utils/token.services';
import { toast } from 'react-toastify';

const CheckboxOption = (props) => (
  <Wrapper>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
      />
      <label className='checkbox-label'>{props.label}</label>
    </div>
  </Wrapper>
);

// Custom component to render checkboxes for Accounts
const CustomCheckboxOption = (props) => {
  // console.log('CustomCheckboxOption props', props);
  return (<div className='custom-option'>
    <div className='checkbox-container'>
      <input
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.selectOption(props.data)}
        id={`account-checkbox-${props.data.value}`}
      />
      <label className='checkbox-label' htmlFor={`account-checkbox-${props.data.value}`}>
        {props.label}
      </label>
    </div>
    {props.data.brands && props.isSelected && (

      <div className='brand-checkboxes'>
        {props.data.brands.map((brand) => (
          <div key={brand._id} className='checkbox-container'>
            <input
              type='checkbox'
              checked={brand.isSelected}
              onChange={() => props.selectBrandOption(brand)}
              id={`brand-checkbox-${brand._id}`}
            />
            <label className='checkbox-label' htmlFor={`brand-checkbox-${brand._id}`}>
              {brand.brand_name}
            </label>
          </div>
        ))}
      </div>
    )}
  </div>
  )
}

const Popup = ({ message, exportData, shareData }) => {
  return (
    <div className='popup-container'>
      <div className='popup-message'>
        <span className='export-txt'>Export data - </span>Download the data to your computer or
        local storage.{' '}
      </div>
      <div className='popup-message'>
        <span className='share-txt'>Share data - </span>Share the data with anyone within the app.{' '}
      </div>
      <div className='popup-buttons'>
        <button onClick={exportData}>
          <div className='icon-container'>
            <MdOutlineFileDownload className='react-icon' />
          </div>
          Export Data
        </button>
        <button onClick={shareData}>
          <div className='icon-container'>
            <IoMdShareAlt className='react-icon' />
          </div>
          Share Data
        </button>
      </div>
    </div>
  );
};

const Realization = () => {
  const [exportLoader, setExportLoader] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  // const [paginationText, setPaginationText] = useState('Show employees per page:');
  const [paginationText, setPaginationText] = useState(' ');
  const [totalPages, setTotalPages] = useState(1);
  const [runAPI, setRunAPI] = useState(false);
  const [showShareDataModal, setShowShareDataModal] = useState(false);
  const [ApplyDateFilter, setApplyDateFilter] = useState(false);
  const [selectedRange, setSelectedRange] = useState([]);
  const [selectedYear, setSelectedYear] = useState([dayjs().format('YYYY')]);
  const [selectedMonth, setSelectedMonth] = useState([dayjs().format('MM')]);
  const [showMonth, setShowMonth] = useState([dayjs().format('MM'), dayjs().format('MM')]);
  const [exportName, setexportName] = useState('');
  const [filterDisabled, setFilterDisabled] = useState({ pod: false, account: false, date: false })
  const [selectedBrandData, setSelectedBrandData] = useState([]);

  const handleShowModal = () => {
    setShowShareDataModal(true);
  };

  // console.log(selectedYear, 'selectedYear');
  const calculateGapMonths = (startMonth, endMonth, startYear) => {
    let start = parseInt(startMonth, 10);
    const end = parseInt(endMonth, 10);
    console.log(startMonth, endMonth, 'starttt');
    const gap = end - start + 1;

    if (gap > 0) {
      const gapArray = Array.from({ length: gap }, (_, index) =>
        (start++).toString().padStart(2, '0')
      );
      setSelectedMonth(gapArray);
      setShowMonth([startMonth, endMonth]);
      syncFilter(selectedMultiPodOptions, selectedMultiAccountsOptions, [startYear], gapArray, selectedRange, !ApplyDateFilter, [startMonth, endMonth])
    } else {
      setSelectedMonth([]);
      setShowMonth([])
      syncFilter(selectedMultiPodOptions, selectedMultiAccountsOptions, [startYear], [], selectedRange, !ApplyDateFilter, [])
    }
  };

  const handleCalendarChange = (dates, dateStrings) => {
    const [startDate, endDate] = dateStrings;
    setexportName(`${startDate ? startDate : ''}~${endDate ? endDate : ''}`)

    if (startDate && endDate) {
      // Extract and update selectedYear and selectedMonth
      const startYear = startDate ? dayjs(startDate, 'MMM-YYYY').year().toString() : null;
      const endYear = endDate ? dayjs(endDate, 'MMM-YYYY').year().toString() : null;

      const startMonth = startDate ? dayjs(startDate, 'MMM-YYYY').month() + 1 : null; // Month is 0-based
      const endMonth = endDate ? dayjs(endDate, 'MMM-YYYY').month() + 1 : null; // Month is 0-based      
      setSelectedYear([startYear]);
      // Format months as two digits
      const formattedStartMonth = startMonth ? startMonth.toString().padStart(2, '0') : null;
      const formattedEndMonth = endMonth ? endMonth.toString().padStart(2, '0') : null;
      // setSelectedMonth([formattedStartMonth, formattedEndMonth]);
      calculateGapMonths(formattedStartMonth, formattedEndMonth, startYear);

      setApplyDateFilter(!ApplyDateFilter);
    }
    else if (startDate || endDate) {
      setSelectedRange(dateStrings);
      setSelectedYear([]);
      setSelectedMonth([]);
      setShowMonth([]);
      // Your logic for handling the selected dates goes here
      syncFilter(selectedMultiPodOptions, selectedMultiAccountsOptions, [], [], dateStrings, ApplyDateFilter, [])
    }
    else {
      // Clear selectedRange when both start and end dates are empty
      setSelectedRange([]);
      setSelectedYear([]);
      setSelectedMonth([]);
      setShowMonth([]);
      setApplyDateFilter(!ApplyDateFilter);
      syncFilter(selectedMultiPodOptions, selectedMultiAccountsOptions, [], [], [], !ApplyDateFilter, [])
    }
  };

  const disableYearChange = (current) => {
    // debugger;
    // console.log(current);
    const [startDate, endDate] = selectedRange;
    const starty = `${selectedYear[0] ? selectedYear[0] : selectedYear?.value}`;
    const endy = `${selectedYear[0] ? selectedYear[0] : selectedYear?.value}`;
    console.log(starty, 'startyff');
    if (starty !== undefined && endy !== undefined && showMonth[0] !== undefined) {
      console.log('kkk');
      return current.year() < starty || current.year() > endy;
    }

    // Allow year selection if neither startDate nor endDate is defined
    if (!startDate && !endDate) {
      return false;
    }

    // Disable year selection if either startDate or endDate is undefined
    if (!startDate || !endDate) {
      return (
        current.year() != dayjs(startDate, 'YYYY').year() && current.year() !== dayjs(endDate, 'YYYY').year()
      );
    }
    console.log(showMonth[0], 'showMonth[0]');
    console.log(startDate, 'startDate');
    // Disable year selection if showMonth[0] is not undefined


    // Allow year selection if none of the conditions are met
    return false;
  };


  const handleOpenChange = (open) => {
    console.log('oo');
    const current = dayjs(); // Define current as the current date
    disableYearChange(current); // Pass the current date to disableYearChange
    const [startDate, endDate] = selectedRange;
    if (!open && (!startDate || !endDate)) {
      // Clear selectedRange when the calendar panel is closed
      setSelectedRange([]);
    }

  };
  // useEffect(() => {
  //   console.log(selectedYear, 'selectedYear');
  //   console.log(selectedMonth, 'selectedMonth');
  // }, [ApplyDateFilter]);



  const [areFiltersFetched, setAreFiltersFetched] = useState(false)
  const [loading, setLoading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [data, setData] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [selectedMultiAccountsOptions, setSelectedMultiAccountsOptions] = useState([]);
  const [selectedMultiAccountsValues, setSelectedMultiAccountsValues] = useState([]);
  const [isAccountValueGotChanged, setIsAccountValueGotChanged] = useState(false)
  // const [selectedYear, setSelectedYear] = useState({ value: new Date().getFullYear() });
  const [yearOptions, setYearOptions] = useState([]);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [selectedMultiTeamOptions, setSelectedMultiTeamOptions] = useState([]);
  const [selectedMultiTeamValues, setselectedMultiTeamValues] = useState([]);
  const [PodOption, setPodOption] = useState([]);
  const [selectedMultiPodOptions, setSelectedMultiPodOptions] = useState([]);
  const [selectedMultiPodValues, setselectedMultiPodValues] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const [chartData, setChartData] = useState({
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    hours: [50, 50, 50, 270, 277, 330, 147, 148, 141, 153, 50, 50],
  });

  const handleMultiSelectAccountsChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiAccountsOptions(selectedOptions);
    setIsAccountValueGotChanged(true)
    setSelectedMultiAccountsValues(selectedValues);

    console.log('selectedMultiAccountsValues', selectedMultiAccountsValues);
    syncFilter(selectedMultiPodOptions, selectedOptions, selectedYear, selectedMonth, selectedRange, ApplyDateFilter, showMonth)
  };

  const handleMultiSelectBrandChange = (selectedBrand) => {
    const brandIndex = selectedBrandData.findIndex((brand) => brand.id === selectedBrand._id);

    if (brandIndex === -1) {
      // If not in selectedBrandData, add it
      setSelectedBrandData([...selectedBrandData, { id: selectedBrand._id, name: selectedBrand.brand_name }]);
    } else {
      // If already in selectedBrandData, remove it
      const updatedBrandData = [...selectedBrandData];
      updatedBrandData.splice(brandIndex, 1);
      setSelectedBrandData(updatedBrandData);
    }
  };

  const handleMultiSelectTeamChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiTeamOptions(selectedOptions);
    setselectedMultiTeamValues(selectedValues);
    console.log('selectedMultiTeamValues', selectedMultiTeamValues);
  };

  const handleMultiSelectPodChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedMultiPodOptions(selectedOptions);
    setselectedMultiPodValues(selectedValues);
    console.log('selectedMultiPodValues', selectedMultiTeamValues);
    syncFilter(selectedOptions, selectedMultiAccountsOptions, selectedYear, selectedMonth, selectedRange, ApplyDateFilter, showMonth)
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
  };

  const handleActionClick = () => {
    setShowPopup(!showPopup);
  };


  const exportHoursData = () => {
    // Prepare data for Excel export


    // Create a new workbook

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data.map((item) => {
      delete item._id
      return item
    }));

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'ProfitabilityData');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, `profitabilityData.xlsx`);
  };

  const exportData = async () => {
    setShowPopup(false);

    
    setExportLoader(true);

  let response_data=await axios
    .post(`/admin/profitablity`, {
      account_ids: selectedMultiAccountsValues,
      brand_id: selectedMultiAccountsValues.length > 0 ? selectedBrandData.map((item) => item.id) : [],
      team_ids: selectedMultiTeamValues,
      pod_ids: selectedMultiPodValues,
      year: selectedYear,
      month: selectedMonth,
      date_range: selectedRange
    })
    
    setExportLoader(false);


    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(response_data.data.data.flatMap((item1) => {
      delete item1._id
      return item1.brands.map(item => {
        let forcastExpense = parseFloat(((1 - (parseFloat(item.forecastedMargin) / 100)) * parseFloat(item.revenue)).toFixed(2));
      let forcastMargin = parseFloat((((parseFloat(item.forecastedMargin) / 100)) * parseFloat(item.revenue)).toFixed(2));
      let actualExpense = parseFloat(((1 - (parseFloat(item.actualMargin) / 100)) * parseFloat(item.revenue)).toFixed(2));
      let actualMargin = parseFloat((((parseFloat(item.actualMargin) / 100)) * parseFloat(item.revenue)).toFixed(2));
      return {
        Month: new Date(item.month).getMonth()+1,
        Year: new Date(item.month).getFullYear(),
        Brand: item.name,
        'Parent Account': item.account_name,
        Pod: item.podName,
        Revenue: parseFloat(item.revenue),
        "Revenue Goal":Number(parseFloat(item.goal_revenue)).toFixed(2),
        "Actual Expense $": Number(parseFloat(item.actualExpense).toFixed(2)),
        "Actual Margin $": actualMargin,
        "Actual Margin %": parseFloat(item.actualMargin) / 100,
        "Forecast Expense $": Number(parseFloat(item.forecastExpense).toFixed(2)),
        "Forecast Margin $": forcastMargin,
        "Forecast Margin %": parseFloat(item.forecastedMargin) / 100,
        "Goal Expense $":Number(item.goal_revenue-item.goalMargin).toFixed(2),
        "Goal Marign $" :item.goalMargin,
        "Goal Margin %" :item.goal_percentage/100,

        "Actual to Forecast Variance": parseFloat(item.variance) / 100,
        
        "Actual to Goal Variance":Number(item.goalVariance).toFixed(1)/100,
        "Forecast to Goal Variance":Number(Number((item.forecastedMargin/100)-(item.goal_percentage/100)).toFixed(2))
      }
      })
    }));
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'ProfitabilityData');

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, `profitability(${exportName === '' ? dayjs(startm, 'YYYY-MM').format('MMM-YYYY') + '~' + dayjs(endm, 'YYYY-MM').format('MMM-YYYY') : exportName}).xlsx`);
    // Perform exportData action here
  };

  const shareData = () => {
    setShowPopup(false);
    handleShowModal();
  };

  useEffect(() => {
    axios
      .post('/admin/accounts?page=&limit=', {
        account_id: '',
        team_ids: '',
        SelectedDateFormattedData: '',
      })
      .then((response) => {
        const modifyData = response.data.data.map((item) => {
          for (let i = 0; i < item.brands.length; i++) {
            item.brands[i].isChecked = false
          }
          return item
        })
        setAccountOptions(modifyData);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/teams_wopaging')
      .then((response) => {
        setTeamOptions(
          response?.data?.data.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
            return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get('/admin/pods_wopaging')
      .then((response) => {
        // console.log("resources_wopaging",response?.data?.data);
        setPodOption(response?.data?.data.map((item) => ({ value: item?._id, label: item?.name })).sort((a, b) => {
          return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
        }));
      })
      .catch((error) => {
        console.log(error);
      });

    const userData = TokenServices.getUser();
    const user_id = userData ? userData._id : '';
    axios.get(`/admin/getFilter?user_id=${user_id}`).then((res) => {
      console.log('data', res);
      const { profitabilityFilter } = res?.data?.data;
      if (profitabilityFilter) {
        setSelectedMultiPodOptions(profitabilityFilter?.pods || [])
        if (profitabilityFilter?.pods.length > 0) {
          setselectedMultiPodValues(profitabilityFilter?.pods.map((option) => option.value))

        }
        setSelectedMultiAccountsOptions(profitabilityFilter?.accounts || [])
        setSelectedMultiAccountsValues(profitabilityFilter?.accounts.map((option) => option.value) || [])
        if (profitabilityFilter.dateRange) {
          setSelectedYear(profitabilityFilter?.dateRange.year || [])
          setSelectedMonth(profitabilityFilter?.dateRange.month || [])
          setSelectedRange(profitabilityFilter?.dateRange.dateRange || [])
          if (profitabilityFilter?.dateRange.dateFilter) {
            setApplyDateFilter(profitabilityFilter?.dateRange.dateFilter);
          }

          setShowMonth(profitabilityFilter?.dateRange.showmonth || showMonth);
        }
        setAreFiltersFetched(true);
      } else {
        setAreFiltersFetched(true);
      }
    }).catch((error) => {
      setAreFiltersFetched(true);
      console.log('error', error);
    })

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, index) => ({
      value: currentYear - index,
      label: currentYear - index,
    }));
    setYearOptions(years);
    setSelectedYear({ value: currentYear, label: currentYear });

    const handleOutsideClick = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        !event.target.closest('.btn-action-container')
      ) {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const syncFilter = async (selectedMultiPodOptions, selectedMultiAccountsOptions, year, month, dateRange, dateFilter, showmonth) => {
    const userData = TokenServices.getUser();
    const user_id = userData ? userData._id : '';
    const modifyPods = selectedMultiPodOptions
    const modifyAccount = selectedMultiAccountsOptions
    const modifyDateRange = {
      year, month, dateRange, dateFilter, showmonth
    }

    if (user_id) {
      const response = await axios.post("/admin/setInsightFilter", {
        user_id,
        filter: {
          pods: modifyPods,
          accounts: modifyAccount,
          dateRange: modifyDateRange
        },
        page: 'profitability'
      })
    }
  }


  useEffect(() => {
    
    setPage(1)
    if (areFiltersFetched) {
      const userData = TokenServices.getUser();
      if(userData.role === 'Admin' || (userData.role !== 'Admin' && (selectedMultiAccountsValues.length > 0 || selectedBrandData.length > 0 || selectedMultiPodValues.length > 0))) {
        setLoading(true)
        console.log(selectedMultiAccountsValues, selectedMultiAccountsValues, selectedMultiTeamValues, selectedMultiPodValues, selectedYear, selectedMonth, selectedRange, "...")
        axios
          .post(`/admin/profitablity?page=${page}&limit=${perPage}`, {
            account_ids: selectedMultiAccountsValues,
            brand_id: selectedMultiAccountsValues.length > 0 ? selectedBrandData.map((item) => item.id) : [],
            team_ids: selectedMultiTeamValues,
            pod_ids: selectedMultiPodValues,
            year: selectedYear,
            month: selectedMonth,
            date_range: selectedRange
          })
          .then((response) => {
            // console.log(response.data.data)

            setData(response.data.data)
            setTotalPages(response.data.total_pages)
            setLoading(false);

          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }else{
        toast.error('Select at least one filter');
      }
    }
  }, [runAPI])

  useEffect(() => {
    
    if (areFiltersFetched) {
      const userData = TokenServices.getUser();
      if(userData.role === 'Admin' || (userData.role !== 'Admin' && (selectedMultiAccountsValues.length > 0 || selectedBrandData.length > 0 || selectedMultiPodValues.length > 0))) {
        setLoading(true)
        console.log(selectedMultiAccountsValues, selectedMultiAccountsValues, selectedMultiTeamValues, selectedMultiPodValues, selectedYear, selectedMonth, selectedRange, "...")
        axios
          .post(`/admin/profitablity?page=${page}&limit=${perPage}`, {
            account_ids: selectedMultiAccountsValues,
            brand_id: selectedMultiAccountsValues.length > 0 ? selectedBrandData.map((item) => item.id) : [],
            team_ids: selectedMultiTeamValues,
            pod_ids: selectedMultiPodValues,
            year: selectedYear,
            month: selectedMonth,
            date_range: selectedRange
          })
          .then((response) => {
            // console.log(response.data.data)

            setData(response.data.data)
            setTotalPages(response.data.total_pages)
            setLoading(false);

          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }else{
        toast.error('Select at least one filter');
      }
    }
  }, [page,perPage])

  const startm = `${selectedYear[0] ? selectedYear[0] : selectedYear?.value}-${showMonth[0]}`;
  console.log(startm, 'startm');
  const endm = `${selectedYear[0] ? selectedYear[0] : selectedYear?.value}-${showMonth[showMonth.length - 1]}`;

  useEffect(() => {
    const updatedAccountOptions = accountOptions.map((account) => ({
      ...account,
      brands: account.brands.map((brand) => ({
        ...brand,
        isSelected: selectedBrandData.some((selectedBrand) => selectedBrand.id === brand._id),
      })),
    }));

    setAccountOptions(updatedAccountOptions);
  }, [selectedBrandData]);

  useEffect(() => {
    if (selectedMultiAccountsValues.length <= 0) {
      if (isAccountValueGotChanged === true) {
        setSelectedBrandData([]);
      }

      setIsAccountValueGotChanged(false)

    } else {
      const deselectedAccountIds = accountOptions.filter((account) => !selectedMultiAccountsValues.includes(account._id));
      let allDeselectedBrands = [];
      deselectedAccountIds.forEach(account => {
        account?.brands.forEach(brand => {
          allDeselectedBrands.push(brand._id);
        });
      });
      setSelectedBrandData((prev) => prev.filter((brand) => !allDeselectedBrands.includes(brand.id)));
    }
  }, [selectedMultiAccountsValues]);
  return (
    <Wrapper>
      {loading && <Loader />}
      <ShareDataModal
        show={showShareDataModal}
        setShow={setShowShareDataModal}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
      />
      <div className='HistoryOfRequest'>
        <section className='bg'>
          <div className='row flex-nowrap'>
            <div className='col-auto sidebar-container'>
              <Sidebar />
            </div>
            <div className='col py-3 content-area'>
              <div className='row'>
                <div className='action-row'>
                  <div className='right-btn-section'>
                    {loading ? (<div className='btn-container btn-run-container'>
                      <input type='button' value='Running...' disabled={loading} />
                    </div>) : (<div className='btn-container btn-run-container' onClick={() => setRunAPI(!runAPI)}>
                      <input type='button' value='Run' />
                    </div>)}

                    {
                      !exportLoader ? (
                        <>
                          <div className='btn-container btn-action-container' onClick={handleActionClick}>
                            <input type='button' value='Action' />
                            <div className='icon-container'>
                              <IoIosArrowDown className='react-icon' />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='btn-container btn-action-container'>
                            <input type='button' value='Exporting...' />
                            <div className='icon-container'>
                              <IoIosArrowDown className='react-icon' />
                            </div>
                          </div>
                        </>
                      )
                    }
                    {/* Conditional rendering of the popup */}
                    {showPopup && (
                      <div ref={popupRef} className='popup-div'>
                        <Popup exportData={exportData} shareData={shareData} />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row-1'>
                {/* <div>
                  <h1 className='row-1-title'>Profitability</h1>
                </div> */}

                <div className='filter-right-section'>

                  <div className='multi-select-container'>
                    <label>
                      Pods
                      <ReactSelect
                        id='multiSelectPod'
                        isMulti
                        options={PodOption}
                        value={selectedMultiPodOptions}
                        onChange={handleMultiSelectPodChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CheckboxOption }}
                        isDisabled={filterDisabled.pod}
                      />
                    </label>
                  </div>
                  {/* <div className='multi-select-container'>
                    <label>
                      Team
                      <ReactSelect
                        id='multiSelectTeam'
                        isMulti
                        options={TeamOptions}
                        value={selectedMultiTeamOptions}
                        onChange={handleMultiSelectTeamChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CheckboxOption }}
                      />
                    </label>
                  </div> */}

                  <div className='multi-select-container'>
                    <label>
                      Accounts
                      <ReactSelect
                        id='multiSelectAccounts'
                        isMulti
                        options={accountOptions.map((account) => ({
                          value: account._id,
                          label: account.name,
                          brands: account.brands, // Include the brands as part of the option
                        }))}
                        value={selectedMultiAccountsOptions}
                        onChange={handleMultiSelectAccountsChange}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        filterOption={(option, inputValue) => {
                          const lowercaseInput = inputValue.toLowerCase();

                          // Check if the account label matches the input value
                          const accountLabel = option?.label?.toLowerCase();
                          if (accountLabel?.includes(lowercaseInput)) {
                            return true;
                          }

                          // Check if any of the brand names within this account match the input value
                          const brandLabels = option?.data?.brands?.map((brand) => brand.brand_name.toLowerCase());
                          return brandLabels?.some((label) => label?.includes(lowercaseInput));
                        }}
                        components={{
                          Option: (props) => <CustomCheckboxOption {...props} selectBrandOption={handleMultiSelectBrandChange} />,
                        }}
                        isDisabled={filterDisabled.account}
                      />
                    </label>
                  </div>

                  {/* <div className='year-select-container'>
                    <label>
                      Year
                      <ReactSelect
                        id='yearSelect'
                        options={yearOptions}
                        value={selectedYear}
                        onChange={handleYearChange}
                      />
                    </label>
                  </div> */}

                  <div className='multi-select-container date-range'>
                    <label>Date Range</label>
                    <label>
                      <DatePicker.RangePicker
                        picker="month"
                        className=''
                        format="MMM-YYYY"
                        onCalendarChange={handleCalendarChange}
                        onOpenChange={handleOpenChange} // Use onOpenChange to detect when the calendar panel is closed
                        allowClear={true}
                        mode={['month', 'month']}
                        value={[
                          showMonth[0] ? dayjs(startm, 'YYYY-MM') : undefined,
                          showMonth[1] ? dayjs(endm, 'YYYY-MM') : undefined
                        ]}
                        showTime={true}
                        disabledDate={disableYearChange}
                        disabled={filterDisabled.date}
                      />
                    </label>
                  </div>

                </div>

              </div>
              {/* chart component */}
              <Chart chartData={data} hoursYear={selectedYear?.value}
              page={page} setPage={setPage} setPerPage={setPerPage} totalPages={totalPages} paginationText={paginationText}
              perPage={perPage} />
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Realization;

const Wrapper = Styled.div`
    
    .bg{
      background: #e9eaed;
      /* padding: 20px; */
    }
    .profile-body{
background:#fff;
margin-top:14px;
    }
    .bg .row{
    --bs-gutter-x: 0px !important;
    }
    .sidebar-container{
      background: #FFFFFF;
border-radius: 16px;
margin:15px
    }
    .content-area{
      margin:0px 10px 0px 0px;
      width: 70%;
    }
    .action-row {
    display: flex;
    padding: 0px 10px;
    border-radius: 20px 20px 0px 0px;
    background: var(--white, #FFF);
	justify-content: flex-end;
}
  .right-btn-section{
    display: flex;
    gap: 20px;
    margin-top: -10px;
  }
    .row-1{
display: flex;
padding: 0px 10px 10px 10px;
border-radius: 0px 0px 20px 20px;
background: var(--white, #FFF); 
    }
    .row-1-title{
color: var(--black-text, #201E20);
font-size: 24px;
    }

    .filter-right-section{
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 2;
    flex-wrap: wrap;

    @media(max-width: 720px){
      flex-direction: column;
      gap: 10px;
      align-items: start;
    }
}
.css-13cymwt-control, .css-t3ipsp-control{
    display: flex;
  align-items: center;
  width: 180px!important;
  border: 1px solid #636263;
border-radius: 8px;
max-height: 38px !important;
  overflow: auto !important;
}
.css-16xfy0z-control{
  width: 180px;
  border-radius: 8px;
}
.date-range{
  display: flex;
  flex-direction: column;
}
.ant-picker{
    min-width: 200px;
    max-width: 220px !important;
    border: 1px solid #636263;
    border-radius: 8px;
    height: 2.4rem;
    // margin-top: 1.3rem;
}
.react-icon {
    height: 21px;
    width: 21px;
  }
  .btn-container {
    display: flex;
    align-items: center;
    border: 1px solid #2984FD;
    background: #2984FD;
    border-radius: 8px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 24px;

  }
  
  .btn-container input[type="button"] {
    background: #2984FD;
    color: #fff;
    flex: 1;
    border: none;
    outline: none;
    padding: 0px 5px;
  }
  .popup-div {
  position: absolute;
  top: 175px;
    right: 35px;
  border-radius: 16px;
background: #FFF;
box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.50);
  padding: 20px;
  z-index: 9999;
  gap: 20px;
}

.popup-message {
  font-size: 16px;
  margin-bottom: 20px;
}
span.share-txt,span.export-txt {
    font-weight: 600;
}

.custom-option {
  display: flex;
  flex-direction: column;
}
.brand-checkboxes {
  margin-left: 20px; /* Indent the brand checkboxes */
}

.popup-buttons {
  display: flex;
align-items: flex-start;
gap: 20px;
align-self: stretch;
}

.popup-buttons button {
  display: flex;
padding: 10px ;
justify-content: center;
align-items: center;
gap: 16px;
flex: 1 0 0;
border-radius: 8px;
background: #2984FD;
  cursor: pointer;
  border: none;
  color: #FFF;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.checkbox-container label {
  /* margin-left: 5px; */
  font-weight: normal;
  color: #666;
  font-size: 16px;
  color: #201E20;

}

.checkbox-container input[type="checkbox"] {
  margin-right: 8px;
}
thead tr:first-child th { position: sticky; top: 0px; }
`;