import React from 'react';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
// import { Alert } from "reactstrap";
import Styled from 'styled-components';
import { MdHelp, MdOutlineWavingHand, MdEmail } from 'react-icons/md';
import imgback from '../../assets/images/login-background.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import TokenServices from '../../utils/token.services';
// import { getGoogleUrl } from "./getGoogleUrl";
import {
  superAdmin,
  admin,
  pcPm,
  teamLead,
  user,
  reviewer,
  author,
} from '../../redux/slices/appInfoSlice';
import MessageModal from '../../components/modals/MessageModal';
import OTPModal from '../../components/modals/OTPModal';
import ForgotPasswordModal from '../../components/modals/ForgotPassword';
import Loader from '../../components/LoaderWave';
import Axios from '../../utils/api';
import { useGoogleLogin } from '@react-oauth/google';

import { AiFillEyeInvisible } from 'react-icons/ai';
import { AiFillEye } from 'react-icons/ai';
import { deviceType } from 'react-device-detect';
import { baseUrl } from '../../config/base';

function LoginForm() {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [Femail, setFEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [OTPmail, setOTPmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [country, setCountry] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [formErrors, setformErrors] = useState({});
  const handleForgetPassword = () => {
    setShowForgetPassword(true);
  };
  const handleBackToLogin = () => {
    setShowForgetPassword(false);
  };
  const handleFEmailChange = (e) => {
    setFEmail(e.target.value);
    setErrorMessage('');
  };

  const handleForgetPasswordSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const FemailRegex = /^[^\s@]+@code3\.com$/;
    if (!Femail.trim()) {
      errors.Femail = 'Email is required!';
    } else if (!regex.test(Femail)) {
      errors.Femail = 'Please enter valid email!';
    } else if (!FemailRegex.test(Femail)) {
      errors.Femail = 'Please use email with code3 domain only';
    }
    // Perform forget password logic using email
    // ...
    setErrorMessage(errors);

    // Check if any errors exist
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return;
    }

    try {
      setLoading(true);
      const { data } = await Axios.post('/admin/forgotPassword', {
        email: Femail,
      });
      setLoading(false);
      console.log('data 1', data);
      toast.success(data.message);
      setShowForgetPassword(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleCloseOTPModal = () => {
    setShowOTPModal(false);
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };

  const handleShowOTPModal = (mail) => {
    setOTPmail(mail);
    setShowOTPModal(true);
  };

  const handleVerifyOTPbtn = async (e, OTPmail, code) => {
    console.log(code);
    try {
      setLoading(true);
      const response = await Axios.post('/auth/verifyOtp', { email: OTPmail, otp: code });
      setLoading(false);
      console.log(response);
      // const data = response?.data?.message;
      const data = response?.data?.data?.user;
      console.log(data)

      if (data) {
        TokenServices.setUser(response?.data?.data?.user)
        // localStorage.setItem('user', JSON.stringify(response?.data?.data?.user));
        
        TokenServices.setAccessToken(response?.data?.data?.token)
        dispatch({ type: 'user/updateUser', payload: response?.data?.data?.user });
        dispatch({ type: 'user/updateToken', payload: response?.data?.data?.token });
        const timercronId = localStorage.getItem('timercronId');
        const hourscronId = localStorage.getItem('hourscronId');
        const schedulecronId = localStorage.getItem('schedulecronId');
        const utilizationcronId = localStorage.getItem('utilizationcronId');
        const profitabilitycronId = localStorage.getItem('profitabilitycronId');
        
        if (timercronId) {
          navigate('/timer');
        }
        else if (hourscronId) {
          navigate('/hours');
        }
        else if (schedulecronId) {
          navigate('/schedule');
        }
        else if (utilizationcronId) {
          navigate('/utilization');
        }
        else if (profitabilitycronId) {
          navigate('/profitability');
        }
        else if (data?.role==="Contractor") {
          navigate('/timer');
        }
        else {
          navigate('/timer');
        }
        
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.response.data.error);
    }
  };

  const handleShowMessageModal = (error) => {
    setErrorMessage(error);
    setShowMessageModal(true);
  };

  async function handleGoogleLoginSuccess(tokenResponse) {
    const accessToken = tokenResponse.access_token;
    // console.log(accessToken);
    try {
      setLoading(true);
      const { data } = await Axios.post('/auth/login', {
        googleAccessToken: accessToken,
        device: deviceType,
        location: country
      });
      setLoading(false);
      console.log('data', data);
      TokenServices.setUser(data?.result)

      // localStorage.setItem('user', JSON.stringify(data?.result));
      TokenServices.setAccessToken( data?.token);
      TokenServices.setRefreshToken( data?.refreshToken);


      dispatch({ type: 'user/updateUser', payload: data?.result });
      dispatch({ type: 'user/updateToken', payload: data?.token });
      const timercronId = localStorage.getItem('timercronId');
      const hourscronId = localStorage.getItem('hourscronId');
      const schedulecronId = localStorage.getItem('schedulecronId');
      const utilizationcronId = localStorage.getItem('utilizationcronId');
      const profitabilitycronId = localStorage.getItem('profitabilitycronId');
      if (timercronId) {
        navigate('/timer');
      }
      else if (hourscronId) {
        navigate('/hours');
      }
      else if (schedulecronId) {
        navigate('/schedule');
      }
      else if (utilizationcronId) {
        navigate('/utilization');
      }
      else if (profitabilitycronId) {
        navigate('/profitability');
      }
      else if (data?.result?.role==="Contractor") {
        navigate('/timer');
      }
      else {
        navigate('/timer');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleShowMessageModal(error.response.data.error);
    }
  }

  const login = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });

  async function UserLogin(e) {
    e.preventDefault();

    setformErrors(validate(email, password));

    if (validate(email, password)) return;
    try {
      setLoading(true);
      const response = await Axios.post('/auth/login', { email, password, device: deviceType, location: country });
      setLoading(false);
      console.log(response);
      const data = response?.data?.message;
      // const data = response?.data?.data?.user;

      if (response?.data?.statusCode === 200) {
        // localStorage.setItem("user", JSON.stringify(response?.data?.data?.user));
        // localStorage.setItem("token", JSON.stringify(response?.data?.data?.token));
        // dispatch({type:"user/updateUser",payload:response?.data?.data?.user})
        // dispatch({type:"user/updateToken",payload:response?.data?.data?.token})
        // navigate("/dashboard")
        // alert(response?.data?.data);
        handleShowOTPModal(response?.data?.data);
      } else {
        // alert("please check your username and password")
        toast.error(response.data.error);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.response.data.error);
    }
  }

  const validate = (email, password) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[^\s@]+@code3\.com$/;
    if (!email.trim()) {
      errors.email = 'Email is required!';
    } else if (!regex.test(email)) {
      errors.email = 'Please enter valid email!';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Please use email with code3 domain only';
    }
    if (!password.trim()) {
      errors.password = 'Password is required!';
    }
    if (Object.keys(errors).length < 1) {
      return false;
    }
    return errors;
  };

  useEffect(() => {
    document.title = baseUrl==="https://hub.code3.com/api"?"Code3 Hub":"Hub Sandbox"
    const fetchCountry = async () => {
      try {
        const response = await fetch('https://geolocation-db.com/json/');
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setCountry(data.country_name);
        } else {
          console.error('Error fetching country:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching country:', error);
      }
    };

    fetchCountry();
  }, [])
    
  
  

  return (
    <Wrapper>
      {loading && <Loader />}
      <MessageModal
        show={showMessageModal}
        setShow={handleCloseMessageModal}
        message={errorMessage}
        color='#FF0000'
      />
      <OTPModal
        // show={true}
        show={showOTPModal}
        setShow={handleCloseOTPModal}
        message='Please check your mail for verification code'
        OTPmail={OTPmail}
        handleVerifyOTPbtn={handleVerifyOTPbtn}
      />
      <ForgotPasswordModal
        show={showForgetPassword}
        setShow={handleBackToLogin}
        Femail={Femail}
        handleFEmailChange={handleFEmailChange}
        handleForgetPasswordSubmit={handleForgetPasswordSubmit}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setFEmail={setFEmail}
      />

      <div className=''>
        <div className='d-flex justify-content-center mb-4'>
          <img src={require('../../assets/images/C3_Hub.png')} width={'400px'} alt='code3-logo' />
        </div>

        <div className='card'>
          <form>
            <h2 className='title text-capitalize mb-3'> Welcome Back !</h2>
            <div className='d-flex justify-content-center'>
              <div
                className='google-btn'
                // onClick={() => getGoogleUrl("/profile")}
                onClick={() => login()}
              >
                <img src={require('../../assets/icons/google-icon.png')} alt='google-icon' />
                Google
              </div>
            </div>
            
            <div className='d-flex align-items-center justify-content-around my-2'>
              <div className='underline'></div>
              <h6> or continue with</h6>
              <div className='underline'></div>
            </div>

            <div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><p className='subtitle mt-2'>Enter Your Email & Password to Login</p></Accordion.Header>
                  <Accordion.Body>
                    <div className='email-login'>
                      <div className='input-box'>
                        <MdEmail className='icon' />
                        <input
                          type='text'
                          placeholder='Enter Email'
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                        {formErrors?.email && <div className='text-danger'>{formErrors?.email}</div>}
                      </div>

                      <div className='input-box'>
                        <img className='icon' src={require('../../assets/icons/key.png')} />
                        {/* <img
                  className="icon2"
                  src={require("../../assets/icons/eye.png")}
                  onClick={toggleNewPasswordVisibility}
                  /> */}
                        <div className='icon2'>
                          <button
                            type='button'
                            className='btn btn-outline-primary eye-btn'
                            onClick={toggleNewPasswordVisibility}
                          >
                            {showNewPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                          </button>
                        </div>
                        <input
                          type={showNewPassword ? 'text' : 'password'}
                          placeholder='Enter Password'
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                        />
                        {formErrors?.password && <div className='text-danger'>{formErrors?.password}</div>}
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <div className='fpass'>
                        {/* <a
                          className='forget-pass'
                          // onClick={() => navigate("/forgotpassword")}
                          onClick={() => navigate('/dashboard')}
                        >
                          Go to Admin
                        </a> */}
                      </div>
                      <div className='fpass'>
                        <a
                          className='forget-pass'
                          // onClick={() => navigate("/forgotpassword")}
                          onClick={handleForgetPassword}
                        >
                          Forgot password?
                        </a>
                      </div>
                    </div>

                    <button
                      className='cta-btn'
                      // onClick={()=>navigate("/dashboard")}
                      onClick={(e) => UserLogin(e)}
                    >
                      Sign In
                    </button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>


          </form>
        </div>
      </div>
    </Wrapper>
  );
}
export default LoginForm;

const Wrapper = Styled.div`
background-image:url(${imgback});
width:100%;
min-height:100vh;
display:flex;
align-items:center;
justify-content:center;
.social-login img {
  width: 24px;
}
a {
  text-decoration: none;
}


.card {
  font-family: sans-serif;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 3em;
  margin-bottom:3em; */
  border-radius: 10px;
  background-color: #ffff;
  padding: 1.8rem;
  box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.1);
  @media(max-width:530px){
    width:400px;
  }
}



.title {
  text-align: center;
  font-weight: bold;
  margin: 0;
}
.subtitle {
  text-align: center;
  font-weight: bold;
  opacity:0.7;
}
.btn-text {
  margin: 0;
}

.social-login {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.underline{
  height:1px;
  width:30%;
  background-color:black;
  opacity:0.4;
}
.google-btn {
  background: #fff;
  border: 1px solid #201E20;
  width:68%;
  border-radius: 8px;
  font-weight: bold;
  display: flex;
  padding: 10px 0px;
  align-items: center;
  gap: 5px;
  justify-content: center;
  cursor:pointer;
}
.fb-btn {
  background: #fff;
  border: solid 2px rgb(69, 69, 185);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.or {
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid rgb(245 239 239);
  line-height: 0.1em;
  margin: 25px 0;
}
.or span {
  background: #fff;
  padding: 0 10px;
}

.email-login {
  display: flex;
  flex-direction: column;
}
.email-login label {
  color: rgb(170 166 166);
}

input[type="text"],
input[type="password"] {
  width:100%;
  padding: 15px 40px;
  margin-top: 8px;
  margin-bottom: 15px;
  border: 1px solid #636263;

  border-radius: 8px;
  box-sizing: border-box;
}
input:focus{
  outline:  1px solid #6F7CED;
}
.cta-btn {
  background-color: #94C2FE;
  color: white;
  width:80%;
  padding:10px 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  opacity:0.8;
  border-radius: 10px;
  border: none;
}
.cta-btn:hover{
  background: #2984FD;
  opacity:1;
}

.fpass {
  text-align: right;
}

.forget-pass {
  color:#6F7CED;
  cursor:pointer;
}

.input-box{
  position:relative;
}
.icon{
  position:absolute;
  color: #6F7CED;
  top:25px;
  left:5px;
  font-size:25px;
  
}
.icon2{
  position:absolute;
  top:15px;
  right:5px;
  font-size:25px;
  
}
.eye-btn{
    border-radius: 0;
    border: none;
}
.eye-btn:hover{
  background: none;
    color: blue;
}
.eye-btn svg{
width:25px;
height:25px;
}

.accordion-button{
  &:focus{
    box-shadow: none;
  }
}
`;
