import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NotificationPopup = ({ showPopup, popupRef, setShowPopup }) => {
  const notifications = useSelector((state) => state.notifications.notifications);
  // console.log('kkl',notifications);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        showPopup &&
        !popupRef.current.contains(event.target) &&
        !event.target.classList.contains('bell-icon') &&
        !event.target.closest('.bell-nav')
      ) {
        setShowPopup(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setShowPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showPopup]);

  return (
    <Wrapper>
      <div className='notification-popup' ref={popupRef}>
        <div className='notification-header'>
          <h4>Notifications</h4>
        </div>
        <div className='list-scroll'>
  {notifications.map((notification, index) => (
    <Link to={`/notification/${index}`} className='notification-list' key={index}>
      <div className='notification-item'>
        <div className='sender-profile'>
          <img
            src={notification.senderProfileImage} // Replace with actual sender profile image
            alt='Sender Profile'
            className='profile-image'
          />
        </div>
        <div className='notification-content'>
          <p className='notification-message'>{notification.message}</p>
          <p className='notification-date'>{notification.date}</p>
        </div>
      </div>
    </Link>
  ))}
</div>

        <div className='view-all-div'>
          <Link to='/notifications' className='view-all-button' onClick={() => setShowPopup(false)}>
            View All
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default NotificationPopup;

const Wrapper = Styled.div`
  .notification-popup {
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    top: 55px;
    right: 20px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    background: #fff;
    width: 400px;
    z-index: 999;
  }

  .notification-header {
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 16px 16px 0px 0px;
    background: var(--blue-blue-10, #eaf3ff);
  }

  .notification-header h4 {
    color: var(--black-text, #201e20);
    font-size: 16px;
  }
  .list-scroll{
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
  }

  .notification-list {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 20px;
    border-bottom: 1px solid var(--black-black-20, #d2d2d2);
    background: #fff;
    text-decoration: none;
  }
  .notification-list:hover{
    background: #00000014 !important;
  }

  .notification-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .sender-profile {
  }

  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }

  .notification-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .notification-message {
    color: var(--black-text, #201e20);
  font-size: 16px;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
    margin-top: 15px;
  }

  .notification-date {
    color: var(--black-black-70-sub-text, #636263);
    font-size: 14px;
  }

  .view-all-div {
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  }

  .view-all-button {
    color: #2984fd !important;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    padding:10px;
  }

  .view-all-button:hover {
    background: #00000014 !important;
  }
`;
