import React, { useState, useRef, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Label, PieChart, Pie, Cell } from 'recharts';
import Styled from 'styled-components';

const Chart = ({ chartData, selectedMultiTeamValues }) => {
  console.log(chartData, 'chartData');
  const [data, setData] = useState({});
  const [doughnutData, setDoughnutData] = useState( []);
  const [totalMinutes,setTotalMinutes]=useState(0)
  const [totalHours,setTotalHours]=useState(0)
  const [remainingMinutes,setRemainingMinutes]=useState(0)
  const [donutLabel, setDonutLabel] = useState("Teams");
  // Convert chartData to an array of objects for rendering the chart


  // Convert brand data to an array of objects for the doughnut chart
  const brandData = [
    { brand: 'Brand A', hours: 8, minute: 8 },
    { brand: 'Brand B', hours: 10, minute: 12 },
    { brand: 'Brand C', hours: 30, minute: 8 },
    { brand: 'Brand D', hours: 6, minute: 4 },
    { brand: 'Brand E', hours: 23, minute: 36 },
  ];

  useEffect(() => {
    const cdata = chartData.months.map((month, index) => ({
      month: `${month}`,
      // month: `${month} ${hoursYear}`,
      Hours: chartData.hours[index],
    }));
    console.log(cdata);
    setData(cdata);

    // Prepare the data for the doughnut chart
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];
    const doughnutChartData = chartData?.donught_data?.map((item, index) => ({
      name: item.brand,
      value:Number(item.hours),
      // value: parseFloat(`${item.hours.toString().padStart(2, '0')}.${item.minute.toString().padStart(2, '0')}`), // Convert minutes to hours
      color: COLORS[index % COLORS.length], // Use modulo to cycle through the colors
    }));
    setDoughnutData(doughnutChartData);
    console.log(doughnutChartData,"chartData...")
    const totalMinutes1 = chartData?.donught_data?.reduce((total, item) => total + parseFloat(item.hours) * 60, 0)|| "00";

    const totalHours1 = Math.floor(totalMinutes1 / 60);
    const remainingMinutes1 = Math.floor(totalMinutes1 % 60);
    // console.log(totalMinutes1,totalHours1,remainingMinutes1,chartData.donughtData,"aremaaf")
    setTotalMinutes(totalMinutes1)
    setTotalHours(totalHours1)
    setRemainingMinutes(remainingMinutes1)
    setDonutLabel(selectedMultiTeamValues.length > 0 ? "Tasks" : "Teams");
  }, [
    chartData
  ])

  // const totalMinutes = brandData.reduce((total, item) => total + item.hours * 60 + item.minute, 0);
 

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {/* <p className="label">{`${label} ${hoursYear}`}</p> */}
          <p className="label">{`${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} className="label">{`${entry.name} : ${entry.value}`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const wrapperStyle = {
    fontWeight: 'bold',
  };

  return (
    <Wrapper>
      <div className='row'>
        <div className='col-lg-9 col-md-12 col-sm-12'>

          <div className="section">
            <ResponsiveContainer width="100%" height={303}>
              <BarChart
                margin={{
                  top: 15,
                  right: 0,
                  left: 0,
                  bottom: 15,
                }}
                data={data}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" >
                  {/* <Label value={hoursYear+" (Months)"} offset={-15} position="insideBottom" /> */}
                </XAxis>
                <YAxis label={{ value: '(Hours)', angle: -90, position: 'insideLeft', textAnchor: 'center' }} />
                <Tooltip content={<CustomTooltip />} wrapperStyle={wrapperStyle} cursor={{ fill: '#787878d0' }} />
                {/* <Legend /> */}
                <Bar dataKey="Hours" fill="#2984FD" isAnimationActive={false}>
                  <LabelList dataKey="Hours" position="top" dy={2} />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className='col-lg-3 col-md-12 col-sm-12'>

          <div className="section secttion-right">
            <div className='hSpent-text fw-bold'>Total Hours Spent by {selectedMultiTeamValues.length > 0 ? "Task" : "Team"}</div>
            <div className='hSpent-value'>{totalHours}:{String(remainingMinutes).padStart(2, '0')}:00</div>
            <div className='donut-chart'>
              <ResponsiveContainer width="100%" height={220}>
                <PieChart>
                  <Pie
                    data={doughnutData}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={0}
                    labelLine={true}
                    label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                      const RADIAN = Math.PI / 180;
                      const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);

                      return (
                        <text
                          x={x}
                          y={y}
                          fill="#000"
                          textAnchor={x > cx ? 'start' : 'end'}
                          dominantBaseline="central"
                        >
                          {`${(percent * 100).toFixed(0)}%`}
                        </text>
                      );
                    }}
                  >
                    {/* Custom label in the center */}
                    <Label
                      value={donutLabel}
                      position="center"
                      className="brand-label"
                    />
                    {doughnutData?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>

                  <Tooltip />

                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Chart;

const Wrapper = Styled.div`
   .section {
    border-radius: 16px;
    background: #FFF;
    margin: 15px 0;
    padding: 20px;
  }
  .secttion-right{
    margin-left:10px;
    padding: 20px 10px;

    @media(max-width: 992px){
      margin-left: 0px;
    }

    .recharts-responsive-container{
      @media(max-width: 1034px){
        height: 195px !important;
      }
    }

    svg.recharts-surface{
      @media(max-width: 1300px){
        height: 175px !important;
      }

      @media(max-width: 1092px){
        height: 155px !important;
      }

      @media(max-width: 992px){
        height: 220px !important;
      }
    }
  }
  .custom-tooltip {
    margin-left: -55px;
    background: white;
    padding: 6px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(74, 5, 5, 0.15);
  }
  .custom-tooltip .label {
    font-weight: bold;
  }
  .hSpent-text{
    color: #0D1821;
text-align: center;
  }
  .hSpent-value{
    color: var(--black-text, #201E20);
font-family: 'Barlow';
text-align: center;
font-size: 40px;
font-style: normal;
font-weight: 700;
  }
  .brand-label{
    color: var(--black-text, #201E20);
    font-weight: 600;
  }

`;